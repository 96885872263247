import { Button, Grid } from "@mui/material";
import TestEditor from "../../../Editor/testEditor";
import { useState } from "react";

export default function DialogWithTextField({ props }) {
  const [inputText, setInputText] = useState(props.Value)

  function onSelect() {
    if (props.onSelect) {
      props.onSelect({ value: inputText });
    }
  }

  function onEditEnd(data) {
    setInputText(data.value);
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ height: "calc(100% - 50px)" }}
      >
        <div style={{ marginRight: "5px" }}>
          {props.Title ? props.Title : ""}
        </div>
        <Grid
          item
          style={{
            height: "27px",
            width: "200px",
            border: "1px solid #cccccc",
          }}
        >
          <TestEditor
            value={props.Value}
            onEdit={onEditEnd}
          />
        </Grid>
      </Grid>
      <Grid
        item
        style={{
          width: "100%",
          height: "40px",
          marginTop: "5px",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ paddingTop: "8px" }}
        >
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none", marginRight: "10px" }}
            value="1"
            className="button_Modal_Select"
            onClick={onSelect}
          >
            Выбрать
          </Button>
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none" }}
            value="2"
            className="button_Modal_Close"
          >
            Отмена
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
