import { AxiosRequest } from "../../../Url";

export async function dbviewGrid(grid, source, props, data) {
  source.onHandleRequest = HandleRequest(props, data);
  await source.open();
  grid.defaultColumns = true;
  // if (!props.ObjRef) source.refresh();
  await grid.setSource(source);
  // if (props.ObjRef) grid.refreshSource(props.ObjRef);
  grid.updateGridSize();
}

function HandleRequest(props, data) {
  return function onHandleRequest(request) {
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "handleTable")
      .set("ID", data.id)
      .set("Refresh", request.command === "getFields" ? 1 : 0);
    return AxiosRequest(true, params, request);
  };
}
