import { Button, Grid } from "@mui/material";
import { GetAddressUseKladr, handleParameters } from "../../Tools/Requests";
import {
  formEdit,
  getModalSectionID,
  getParamsObject,
} from "../../Tools/Tools";
import React from "react";
import AddressParams from "../../Sections/ElementsSections/AddressParams";

export default function DialogAddress({ props }) {
  const [data, setData] = React.useState();
  const [useKladr, setUseKladr] = React.useState();
  const [objRef, setObjRef] = React.useState(props.ObjRef);

  React.useEffect(() => {
    getParams();
  }, []);

  async function getParams() {
    const json = await GetAddressUseKladr();
    json.service === "1" ? setUseKladr(true) : setUseKladr(false);

    const items = await handleParameters(
      "address",
      { Token: "GetAddressInfo" },
      undefined,
      props.ObjRef
    );
    if (items && items.Values) {
      setData(getParamsObject(items, "0", items.Caption));
    }
  }

  async function onSelect() {
    if (props.onEdit) {
      props.onEdit({
        tag: objRef,
        name: props.Name,
        record: props.record,
        textchanged: "0",
        setValue: props.setValue,
        setInputValue: props.setInputValue,
        type: props.Module,
        requestId: props.RequestID,
        addInfo: props.addInfo,
      });
    }

    if (props.RequestID) {
      await formEdit(
        "1",
        props.func,
        { Name: "ObjRef", Value: objRef },
        props.RequestID,
        props.from
      );
    }
  }

  return (
    <>
      {/* {Основной контейнер} */}
      <div style={{ height: "100%", width: "100%" }}>
        <Grid
          item
          style={{
            height: "calc(100% - 50px)",
            width: "100%",
            border: "1px solid #cccccc",
          }}
        >
          <AddressParams
            Module={"address"}
            SectionID={getModalSectionID()}
            data={data}
            useKladr={useKladr}
            setObjRef={setObjRef}
          />
        </Grid>
        <Grid item style={{ height: "40px", width: "100%", marginTop: "10px" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              size="small"
              variant="outlined"
              style={{ textTransform: "none", marginRight: "10px" }}
              value="1"
              className="button_Modal_Select"
              onClick={onSelect}
            >
              Выбрать
            </Button>
            <Button
              size="small"
              variant="outlined"
              value={"2"}
              style={{ textTransform: "none", width: 75 }}
              className="button_Modal_Close"
            >
              Отмена
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
