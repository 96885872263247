import React from "react";
import { TabItem } from "smart-webcomponents-react/tabs";
import { GridLoader } from "../../rcsgrid/createGridTools";
import { formEdit, getParamsObject, getRightsData } from "../../Tools/Tools";
import { AxiosRequest, XMLrequest } from "../../../Url";
import Search from "./Components/Search";
import ContentOrganization from "./Components/ContentOrganization";
import {
  GetAddressUseKladr,
  getBookContextMenu,
  GetDialogParamsForSearch,
  getObjText,
  GetTableLayout,
  handleParameters,
  handleTable,
  PostHandleTable,
  saveBookState,
  treeRequest,
} from "../../Tools/Requests";
import { store } from "../../../../store";
import TestTree from "../../Windows/ViewData/Tree/testTree";
import { parseColumns } from "../../rcsgrid/GridTools/parseColumnTools";
import TestParams from "../../Sections/ElementsSections/testParams";
import AddressParams from "../../Sections/ElementsSections/AddressParams";
import LoadingMask from "../../NotWorkArea(Side&Head)/LoadingMask";
import {
  InputButton,
  tokenProcessingTest,
} from "../../../TokenProcessing/TokenProcessing";
import {
  getDetailContent,
  updateTabContentFunc,
} from "./Components/DialogDetailsTools";

// Функция для сохранения текущего Source
//Входные данные:
// props:props ({Module, Objtype, SectionID})
// gridObject:{grid, source}
// groupID:"number"(ID выбранной таблицы из дерева)
// setSelectedRecordID: "number" (ID выбранной записи из таблицы)
// mainSources: new Map() (карта для сохранения данных о source)
async function saveCurrentGrid(
  props,
  gridObject,
  groupID,
  setSelectedRecordID,
  mainSources,
  ObjRef
) {
  const json = await PostHandleTable(
    props.Module,
    "0",
    groupID,
    props.SectionID
  );

  if (json) {
    gridObject.source.rights = getRightsData(json.Rights);
  }

  let objType = json ? json.ObjType : props.ObjType;
  if (objType === undefined) objType = "2310";
  const columns = parseColumns(json); // функция для выбора нужных полей parseColumnsWithOptions из createGridTools.js
  gridObject.grid.setColumns(columns);
  gridObject.source.onHandleRequest = handleTable({
    Module: props.Module,
    ObjType: objType,
    GroupID: groupID,
    selectedRecordID: ObjRef ? ObjRef : "0",
    SectionsID: props.SectionID,
  });
  gridObject.source.onRecordIndexChanged = function (source) {
    const id = source.getFieldTextSync("ID");
    setSelectedRecordID(id);
  };

  gridObject.grid.setImageFields(["CloseDate", "ExternalKey"]);
  gridObject.source.booksGetImage = orgGetImage;
  await gridObject.grid.setSource(gridObject.source);

  await gridObject.grid.refreshSource(ObjRef ? ObjRef : "0");
  gridObject.grid.updateGridSize();
  setSelectedRecordID(gridObject.source.getFieldTextSync("ID"));
  mainSources.set(groupID, {
    columns: columns,
    ID: gridObject.source.getFieldTextSync("ID"),
    request: handleTable,
    objType: objType,
  });
}

export function orgGetImage(value, field) {
  if (field === "CloseDate") {
    return value > 0 ? 154 : 0;
  }

  if (value > 0) {
    return 54;
  }

  if (value === "-1") {
    return 3;
  }

  return -value;
}

export default function DialogOrganizations({ props }) {
  //хуки для обновления Grid
  const [mainGrid, setMainGrid] = React.useState(undefined);
  const [selectedRecordID, setSelectedRecordID] = React.useState();
  const [accSelectedRecordID, setAccSelectedRecordID] = React.useState();

  const [selectRecord, setSelectRecord] = React.useState();

  // использовать kladr для адреса
  const [kladr, setKladr] = React.useState();

  //хук для отображения количества записей
  const [countRecords, setCountRecords] = React.useState("0");

  const gridPanel = React.useRef();
  const tabsPanel = React.useRef();

  //хук выбора номера(ID) таблицы из дерева
  const [groupID, setGroupID] = React.useState(
    props.NodeID ? props.NodeID : "0"
  );

  //хук для поиска
  const [searchResult, setSearchResult] = React.useState();

  //хуки для хранения Sources Для Таблиц
  const mainSources = React.useRef(new Map());

  //Сохранение значений деталей
  const detailValues = React.useRef();

  //хук состаяния для деталей
  const [tabs, setTabs] = React.useState();
  const [needUpdate, setNeedUpdate] = React.useState(false);
  const [groupIDChange, setGroupIDChange] = React.useState(false);

  const [timer, setTimer] = React.useState(null);

  //отрисовка Дерева
  const [tree, setTree] = React.useState(undefined);
  /////////////////////////////////////////////////////////////////////////////////
  /////////////////////////Секция Рендера Таблиц///////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////

  React.useEffect(() => {
    async function getKladr() {
      const json = await GetAddressUseKladr();
      json.service === "1" ? setKladr(true) : setKladr(false);
    }
    setTree(null);
    return getKladr();
  }, []);

  //Первичная отрисовка основной таблицы
  React.useEffect(() => {
    // обновление дерева при обновлении выбора таблиц из функций и поиска
    if (tree === null) {
      setTree(
        <TestTree
          props={{
            CLSID: props.CLSID,
            ObjType: props.ObjType,
            SectionID: props.SectionID,
            Module: props.Module,
            Current: groupID,
            ObjRef: groupID,
            selectID: setGroupID,
            UsedDate: "0",
            Info: "0",
            Internal: "0",
          }}
        />
      );
      return;
    }
    //отрисовка основной таблицы
    if (groupID && tree) {
      setMainGridLoader();
    }
    if (groupID === "0") {
      treeRequest("organizations", props.ObjType, props.SectionID).then(
        (res) => {
          setGroupID(res.Current);
        }
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tree]);

  //Первичная отрисовка Деталей после отрисовки Основной таблицы
  React.useLayoutEffect(() => {
    //отрисовка Деталей
    if (mainGrid) {
      //Сохранение первого Source
      saveCurrentGrid(
        props,
        mainGrid,
        groupID,
        setSelectedRecordID,
        mainSources.current,
        selectedRecordID ? selectedRecordID : props.ObjRef ? props.ObjRef : "0",
        true
      ).then(async () => {
        setTabs(await getTabs(groupID));
        setTimeout(() => {
          setNeedUpdate(true);
        });
        //количество записей в таблице
        setCountRecords(mainGrid.source.recordCount);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainGrid]);

  //Выбор Source и Columns для таблиц в зависимости от выбранной таблицы в дереве
  React.useLayoutEffect(() => {
    //Выбор Source
    if (groupID && groupID !== "0") {
      setMainSource().then(() => {
        //Количество записей в таблице
        if (mainGrid) setCountRecords(mainGrid.source.recordCount);
      });
    }

    //выбор записи для дополнительных таблиц при переключении между таблицами в дереве
    if (mainSources.current.get(groupID)) {
      setSelectedRecordID(mainGrid.source.getFieldTextSync("ID"));
    }

    setGroupIDChange(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupID]);

  //Обновление Деталей, в зависимости от выбранной записи в основной таблице
  React.useLayoutEffect(() => {
    if (mainGrid !== undefined && mainSources.current.get(groupID)) {
      mainSources.current.get(groupID).ID = selectedRecordID;
    }
    if (mainGrid !== undefined) {
      const tabID = tabsPanel.current.selectedIndex;
      updateTab(tabID, tabs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecordID]);

  //обновление в зависимости от результатов поиска
  React.useLayoutEffect(() => {
    if (mainGrid && searchResult) {
      // получение id таблицы для записи из поиска
      const id = GetDialogParamsForSearch(
        props.Module,
        props.ObjType,
        searchResult
      ).NodeID;
      //если id таблицы совпадает с id выбранной таблицы то позиционирумся на запись из поиска
      if (id === groupID) {
        findSearchRecordFromTable();
        //если не совпадает, то очищаем дерево, что в последствии инициирует создание нового дерева с нужным GroupID
      } else {
        setTree(null);
        setGroupID(id);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResult]);

  React.useLayoutEffect(() => {
    if (needUpdate) {
      updateTab(tabsPanel.current.selectedIndex, tabs);
      setNeedUpdate(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needUpdate]);

  //функции позиционирования таблиц при выборе значений из функций и поиска
  async function findSearchRecordFromTable() {
    mainGrid.grid.refreshSource(searchResult);
    setSelectedRecordID(searchResult);
    setSearchResult(undefined);
  }

  async function findSelectRecordFromTable() {
    await mainGrid.grid.refreshSource(selectRecord.ID);
    setSelectedRecordID(selectRecord.ID);
    setSelectRecord(undefined);
  }

  /////////////////////////////////////////////////////////////////////////////////
  /////////////////////////секция создания Таблиц//////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////

  //Сохранение и Выбор Source
  async function setMainSource() {
    if (mainGrid && mainSources.current.get(groupID) === undefined) {
      //Сохранение новых Sources если они не были сохранены ранее
      await saveCurrentGrid(
        props,
        mainGrid,
        groupID,
        setSelectedRecordID,
        mainSources.current
      );
    } else if (mainGrid) {
      //Выбор Source в зависимости от выбранной таблицы в дереве
      const mainSource = mainSources.current.get(groupID);
      mainGrid.grid.setColumns(mainSource.columns);
      mainGrid.source.onHandleRequest = mainSource.request({
        Module: props.Module,
        ObjType: mainSource.objType,
        GroupID: groupID,
        selectedRecordID: mainSource.ID ? mainSource.ID : "0",
        SectionsID: props.SectionID,
      });
      if (!(selectRecord || searchResult)) {
        await mainGrid.grid.refreshSource(mainSource.ID);
      }
      setSelectedRecordID(mainSource.ID);
      await mainGrid.grid.setSource(mainGrid.source);
      mainGrid.grid.updateGridSize();
    }

    if (mainGrid) {
      if (searchResult) {
        await findSearchRecordFromTable();
        return;
      }

      if (selectRecord) {
        await findSelectRecordFromTable();
        return;
      }
    }
  }

  //Функции создания таблиц
  async function setMainGridLoader() {
    if (!mainGrid) {
      setMainGrid(
        await GridLoader(gridPanel.current, props, {
          main: true,
          GroupID: groupID,
          setSelectedRecord: setSelectedRecordID,
          selectedRecordID: selectedRecordID,
        })
      );
    }
  }

  /////////////////////////////////////////////////////////////////////////////////
  /////////////////////////секция Табов(Детали)////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////

  async function getTabs(groupID) {
    const json = await GetTableLayout(
      props.Module,
      "0",
      groupID,
      props.SectionID
    );
    let details = [];
    if (json && json.Details && json.Details.items) {
      details = json.Details.items.map((item) => {
        return getLoadTab(item);
      });
      detailValues.current = json.Details.items;
    }
    const defaultTabs = getDefaultTabs();
    return [...details, ...defaultTabs];
  }

  function getDefaultTabs() {
    return [getLoadSearch()];
  }

  function getLoadSearch() {
    return (
      <TabItem
        key={"tabItem_Search"}
        id={"LoadTab_Search"}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
        label={"Поиск"}
      >
        <div id={"item_Search"} style={{ height: "100%", width: "100%" }}>
          <LoadingMask />
        </div>
      </TabItem>
    );
  }

  function getSearch() {
    return (
      <TabItem
        key={"tabItem_Search"}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
        label={"Поиск"}
      >
        <div id={"item_Search"} style={{ height: "100%", width: "100%" }}>
          <Search
            GroupId={groupID}
            Module={props.Module}
            ObjType={props.ObjType}
            return={setSearchResult}
          />
        </div>
      </TabItem>
    );
  }

  function getLoadTab(detailItem) {
    return (
      <TabItem
        id={`LoadTab_${detailItem.Caption}`}
        key={`TabItem_${detailItem.Caption}`}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
        label={detailItem.Caption}
      >
        <div
          id={`item_${detailItem.Caption}`}
          load="true"
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          <LoadingMask />
        </div>
      </TabItem>
    );
  }

  function getFullTab(detailItem, content) {
    return (
      <TabItem
        id={`Tab_${detailItem.Caption}`}
        key={`TabItem_${detailItem.Caption}`}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
        label={detailItem.Caption}
      >
        <div
          id={`item_${detailItem.Caption}`}
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          {content}
        </div>
      </TabItem>
    );
  }

  function updateTabsContent(data, firstLoad) {
    if (firstLoad) {
      data.firstLoadFunc();
    } else {
      updateTabContentFunc(data);
    }
  }

  function getContentForTab(detail, index) {
    switch (detail.Token) {
      case "HandleAccounts":
        return "accounts";
      case "HandleObjParams":
        return "documents";
      case "HandleParams":
        return "params";
      case "HandleAddress":
        return "addressParams";
      default:
        return "emptyTab";
    }
  }

  function getReqForContent(selector) {
    switch (selector) {
      case "params":
        return async (data) => {
          return handleParameters(
            data.Module,
            data.detailItem,
            data.selectedRecordID
          );
        };
      case "addressParams":
        return async (data) => {
          return handleParameters(
            data.Module,
            data.detailItem,
            data.selectedRecordID
          );
        };
      default:
        break;
    }
  }

  // функция Обновления контента Деталей
  function updateTab(index, tabs) {
    if (index !== undefined && tabs) {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        if (
          tabs[index] &&
          tabs[index].key === "tabItem_Search"
          // groupIDChange
        ) {
          if (tabs[index].props.id === `LoadTab_Search`) {
            tabs[index] = getSearch();
            setTabs([...tabs]);
            // setGroupIDChange(false);
            return;
          }
        }
        const detailItem = detailValues.current[index];
        if (!detailItem) return;
        const selector = getContentForTab(detailItem);

        const blockTab = tabsPanel.current.nativeElement.getTabContent(index);

        const firstLoad = blockTab.querySelector("div[load='true']");

        updateTabsContent(
          {
            Module: props.Module,
            detailItem: detailItem,
            selectedRecordID: selectedRecordID,
            index: index,
            element: blockTab.ownerElement,
            selector: selector,
            SectionID: props.SectionID,
            setAccSelectedRecordID: setAccSelectedRecordID,
            onRequest: getReqForContent(selector),
            firstLoadFunc: () => {
              const tabContent = getDetailContent(selector, props, {
                selectedRecordID: selectedRecordID,
                onRequest: getReqForContent(selector),
                detailItem: detailItem,
                kladr: kladr,
              });
              const tab = getFullTab(detailValues.current[index], tabContent);

              tabs[index] = tab;
              setTabs([...tabs]);
            },
          },
          firstLoad
        );
      }, 100);
      setTimer(newTimer);
    }
  }

  /////////////////////////////////////////////////////////////////////////////////
  /////////////////////////Функционал кнопок///////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////

  //функция для кнопки Основная организация
  async function getMainOrganization() {
    const params = new Map();
    params.set("prefix", "organizations").set("comand", "GetMainOrg");
    const json = await AxiosRequest(true, params);
    if (groupID !== json.GroupID) {
      setSelectRecord({ GroupID: json.GroupID, ID: json.ID });
      setTree(null);
      setGroupID();
    } else {
      await mainGrid.grid.refreshSource(json.ID);
      mainGrid.grid.updateGridSize();
      setSelectedRecordID(json.ID);
    }
  }

  async function onSelect() {
    saveBookState(props.Module);
    const short =
      gridPanel.current.grid.source.getFieldTextSync("ShortName") !== ""
        ? `(${gridPanel.current.grid.source.getFieldTextSync("ShortName")})`
        : "";
    const full = gridPanel.current.grid.source.getFieldTextSync("FullName");
    const name = gridPanel.current.grid.source.getFieldTextSync("Name");

    let value = `${short} ${full}`;
    if (value === " ") {
      value = name;
    }

    if (props.onEdit && props.setValue && props.setInputValue) {
      props.onEdit({
        value: value.trim(),
        tag:
          props.Selector === "Счета" ? accSelectedRecordID : selectedRecordID,
        name: props.Name,
        record: props.record,
        textchanged: "0",
        setValue: props.setValue,
        setInputValue: props.setInputValue,
        type: props.Module,
        requestId: props.RequestID,
        addInfo: props.addInfo,
      });
      return;
    }

    if (props.RequestID) {
      await formEdit(
        "1",
        props.func,
        {
          Name: "ObjRef",
          Value:
            props.Selector === "Счета" ? accSelectedRecordID : selectedRecordID,
        },
        props.RequestID,
        props.from
      );
    }

    if (props.input) {
      props.input.value = value.trim();

      if (props.from) {
        const params = new Map();
        params
          .set("prefix", props.from)
          .set("comand", "ElementEvent")
          .set("SectionID", store.getState().sideBar.selected.ID)
          .set("Name", props.input.dataset.name)
          .set("Tag", selectedRecordID)
          .set(
            "Text",
            getObjText(props.Module, props.ObjType, selectedRecordID, "3").Text
          )
          .set("WSM", "1");
        XMLrequest(params);
        return;
      }
      const params = new Map();
      params
        .set("prefix", "programs")
        .set("comand", "SetParamProperty")
        .set("Path", props.Path)
        .set("ID", props.id)
        .set("GroupID", "0")
        .set("ObjRef", selectedRecordID)
        .set("CheckState", "0")
        .set("TextChanged", "0")
        .set("WSM", "1");
      XMLrequest(params);
    }
  }

  function BookContextMenuHandler(data) {
    // GET /organizations~BookContextMenuHandler?LicGUID=003E967942C5ED97F5E1A5A51C3AB859&ID=2&ObjRef=165&ObjType=2310&WSM=1
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "BookContextMenuHandler")
      .set("ID", data.id)
      .set("ObjRef", data.ObjRef)
      .set("ObjType", data.ObjType)
      .set("WSM", "1");

    if (data.SectionID) params.set("SectionID", data.SectionID);
    return AxiosRequest(true, params);
  }

  async function ContextMenuHandler(data) {
    const comand = data.value;
    let json;
    switch (comand) {
      case "":
        break;

      default:
        if (data.ID !== undefined) {
          json = await BookContextMenuHandler({
            id: data.ID,
            ObjRef: selectedRecordID,
            ObjType: mainSources.current.get(groupID).objType,
          });
          if (json.Token) {
            tokenProcessingTest(json, {
              func: () => mainGrid.grid.refreshSource(selectedRecordID), //todo: проверить работу
            });
          } else await mainGrid.grid.refreshSource(selectedRecordID);
        }
        if (data.Caption === "Основная организация") getMainOrganization();
        break;
    }
  }

  /////////////////////////////////////////////////////////////////////////////////
  /////////////////////////отрисовка справочника///////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <ContentOrganization
        props={props}
        data={{
          tabs: tabs,
          tree: tree,
          tabsPanel: tabsPanel,
          gridPanel: gridPanel,
          mainGrid: mainGrid,
          updateTab: updateTab,
          getMainOrganization: getMainOrganization,
          ContextMenuHandler: ContextMenuHandler,
          countRecords: countRecords,
          onSelect: onSelect,
          mainMenuSelector: "organizations",
          mainMenuRequest: getBookContextMenu,
          objType: mainSources.current.get(groupID)
            ? mainSources.current.get(groupID).objType
            : props.ObjType,
          selectedRecordID: selectedRecordID ? selectedRecordID : "0",
        }}
        buttons={{
          search: tabs && tabs.length ? tabs.length - 1 : 0,
          grouping: true,
          contragent: false,
          tree: true,
          detail: true,
        }}
      />
    </>
  );
}
