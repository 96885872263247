import React from "react";
import Tabs, { TabItem } from "smart-webcomponents-react/tabs";
import SectionAccountPlan from "../AccountPlan/SectionAccountPlan";
import SectionTransaction from "../Transactions/SectionTransactions";
import DocsAndReportsContent from "./AccountingComponents/DocsAndReportsContent";
import SectionToolsJS from "../../../Tools/SectionToolsJS";

export default function SectionAccounting(props) {
  const tabPanel = React.useRef(null);
  const secTools = React.useRef();

  const [multipage, setMultipage] = React.useState({
    Pages: [
      {
        Name: "Журнал проводок",
        content: (
          <SectionTransaction
            sectionData={props.sectionData}
            SectionID={props.SectionID}
          />
        ),
      },
      { Name: "Документы и отчеты", content: <></> },
      { Name: "План счетов", content: <></> },
    ],
  });

  const [content, setContent] = React.useState();

  React.useEffect(() => {
    firstLoadContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onBreadCrumbClick() {
    tabPanel.current.select(1);
  }

  function getContent(index) {
    switch (index) {
      case 0:
        return (
          <SectionTransaction
            sectionData={props.sectionData}
            SectionID={props.SectionID}
          />
        );
      case 1:
        return (
          <DocsAndReportsContent
            CLSID={props.CLSID}
            id={props.id}
            ElemJson={props.ElemJson}
            onBreadCrumbClick={onBreadCrumbClick}
            NoSectionTools={true}
          />
        );
      case 2:
        return (
          <SectionAccountPlan
            sectionData={props.sectionData}
            SectionID={props.SectionID}
          />
        );
    }
  }

  async function firstLoadContent() {
    if (multipage && multipage.Pages !== undefined) {
      const elements = [];
      let index = 0;
      for (let Page of multipage.Pages) {
        const innerContent = Page.content;

        elements.push(
          <TabItem
            key={`multipage-${index}`}
            style={{ height: "100%", width: "100%" }}
            label={Page.Name}
          >
            {
              <div style={{ height: "100%", width: "100%" }}>
                {innerContent}
              </div>
            }
          </TabItem>
        );
        index++;
      }
      setContent([...elements]);
      return;
    }
    setContent(<></>);
  }

  async function updatePage(ev) {
    if (ev.target !== null) return;
    if (content) {
      const id = tabPanel.current.selectedIndex
        ? tabPanel.current.selectedIndex
        : 0;
      const Page = multipage.Pages[id];
      const innerContent = getContent(id);

      if (secTools.current) {
        const buttonsBox = secTools.current.querySelector(
          `#buttons_for_section${props.SectionID}`
        );
        if (buttonsBox) {
          if (id === 1) {
            buttonsBox.style.display = "flex";
          } else {
            buttonsBox.style.display = "none";
          }
        }
      }

      content[id] = (
        <TabItem
          key={`multipage-${id}`}
          style={{ height: "100%", width: "100%" }}
          label={Page.Name}
        >
          {<div style={{ height: "100%", width: "100%" }}>{innerContent}</div>}
        </TabItem>
      );
      setContent([...content]);
    }
  }

  return (
    <>
      <div style={{ width: "100%", height: "100%" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {props.SectionID && props.SectionID > 100 ? (
            <div ref={secTools} style={{ width: "100%", display: "flex" }}>
              <SectionToolsJS ID={props.SectionID}></SectionToolsJS>
            </div>
          ) : null}
          <div
            style={{
              width: "100%",
              display: "flex",
              flex: "1 1 0",
              height: "100%",
            }}
          >
            <Tabs
              ref={tabPanel}
              onChange={async (ev) => await updatePage(ev)}
              animation="none"
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "s",
              }}
            >
              {content}
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}
