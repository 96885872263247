import Tabs, { TabItem } from "smart-webcomponents-react/tabs";
import React from "react";
import { returnPageBasedOnCLSID } from "../../Windows/FullRightSideData";
import { Button, Grid, IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/PrintOutlined";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

export default function DialogMultiSection({ props }) {
  const tabPanel = React.useRef(null);
  const [multipage, setMultipage] = React.useState();
  const [content, setContent] = React.useState();
  const [reportButtonsVisible, setReportButtonsVisible] = React.useState(false);

  React.useEffect(() => {
    setMultipage(props.json);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (multipage) {
      firstLoadContent();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipage]);

  async function firstLoadContent() {
    if (multipage && multipage.Pages !== undefined) {
      const elements = [];
      if (
        multipage.Pages[0].CLSID === "{408E20A3-4BE3-4DCD-98BD-2613A8968783}"
      ) {
        setReportButtonsVisible(true);
      } else {
        setReportButtonsVisible(false);
      }
      let index = 0;
      for (let Page of multipage.Pages) {
        const innerContent =
          index === 0
            ? await returnPageBasedOnCLSID(
                Page,
                undefined,
                undefined,
                props.SectionID
              )
            : undefined;
        if (Page.CLSID) {
          elements.push(
            <TabItem
              key={`multipage-${index}`}
              style={{ height: "100%", width: "100%" }}
              label={Page.Name}
            >
              {
                <div style={{ height: "100%", width: "100%" }}>
                  {innerContent}
                </div>
              }
            </TabItem>
          );
        }
        index++;
      }
      // multipage.Pages.forEach(async (Page, index) => {
      // });
      setContent([...elements]);
      return;
    }
    setContent(<></>);
  }

  async function updatePage() {
    if (content) {
      const id = tabPanel.current.selectedIndex
        ? tabPanel.current.selectedIndex
        : 0;
      const Page = multipage.Pages[id];

      // кнопки для печати
      if (Page.CLSID === "{408E20A3-4BE3-4DCD-98BD-2613A8968783}") {
        setReportButtonsVisible(true);
      } else {
        setReportButtonsVisible(false);
      }

      const innerContent = await returnPageBasedOnCLSID(
        Page,
        undefined,
        undefined,
        props.SectionID
      );
      content[id] = (
        <TabItem
          key={`multipage-${id}`}
          style={{ height: "100%", width: "100%" }}
          label={Page.Name}
        >
          {<div style={{ height: "100%", width: "100%" }}>{innerContent}</div>}
        </TabItem>
      );
      setContent([...content]);
    }
  }

  function PrintReport() {
    let frame, tab;

    tab = tabPanel.current.nativeElement.querySelector(
      "smart-tab-item[selected]"
    );

    if (tab) {
      frame = tab.querySelector("IFRAME");
    }

    if (frame && frame.contentWindow) {
      frame.contentWindow.print();
    }
  }

  return (
    <>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        direction="column"
        style={{ height: "100%", width: "100%", border: "1px solid #C8B58F" }}
      >
        <Grid
          style={{
            width: "100%",
            height: "30px",
            borderBottom: "1px solid #C8B58F",
          }}
        >
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            direction="row"
          >
            <IconButton
              title="Печать документа"
              style={{
                height: "30px",
                width: "30px",
                display: reportButtonsVisible ? "" : "none",
              }}
              onClick={PrintReport}
            >
              <PrintIcon />
            </IconButton>
            <IconButton
              title="Параметры документа"
              style={{
                height: "30px",
                width: "30px",
                display: reportButtonsVisible ? "" : "none",
              }}
            >
              <DocumentScannerOutlinedIcon />
            </IconButton>
            <IconButton
              title="Сохранить в файл"
              style={{
                height: "30px",
                width: "30px",
                display: reportButtonsVisible ? "" : "none",
              }}
              onClick={() => {}}
            >
              <SaveOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          style={{
            width: "100%",
            height: "calc(100% - 30px - 50px)",
          }}
        >
          <Tabs
            ref={tabPanel}
            onChange={updatePage}
            animation="none"
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "s",
            }}
          >
            {content}
          </Tabs>
        </Grid>
        <Grid style={{ marginRight: "10px", width: "75px" }}>
          <Button
            onClick={() => {}}
            size="small"
            variant="outlined"
            style={{
              textTransform: "none",
              display: props.Buttons && (props.Button & 1) === 0 ? "none" : "",
            }}
            value="1"
            className="button_Modal_Select"
          >
            Выбрать
          </Button>
        </Grid>
        <Grid item style={{ height: "40px", width: "100%", marginTop: "10px" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              size="small"
              variant="outlined"
              style={{ textTransform: "none", marginRight: "10px" }}
              value="1"
              className="button_Modal_Close"
            >
              Отмена
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
