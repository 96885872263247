import SectionToolsJS from "../../../Tools/SectionToolsJS";
import TransactionsMainBlock from "./TransactionComponents/TransactionsMainBlock";

export default function SectionTransaction(props) {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flex: "1 1 0",
          }}
        >
          <TransactionsMainBlock SectionID={props.SectionID} />
        </div>
      </div>
    </div>
  );
}
