import React from "react";
import {
  getDetailContent,
  getFullTab,
  getLoadTab,
  updateTabContentFunc,
} from "../../../../Module/Dialogs/Components/DialogDetailsTools";
import { GridLoader } from "../../../../rcsgrid/createGridTools";
import SplitterLocal from "../../../../Tools/Splitter";
import { PostHandleTable } from "../../../../Tools/Requests";
import { updateGridById } from "../../../../Tools/Tools";
import Tabs, { TabItem } from "smart-webcomponents-react/tabs";
import LoadingMask from "../../../../NotWorkArea(Side&Head)/LoadingMask";
import { AxiosRequest } from "../../../../../Url";
import { store } from "../../../../../../store";
import ViewComfyOutlinedIcon from "@mui/icons-material/ViewComfyOutlined";
import { IconButton } from "@mui/material";

export default function PayTypesMainBlock({ props }) {
  const gridpanelRef = React.useRef(null);
  const [mainGrid, setMainGrid] = React.useState();

  const [countRecords, setCountRecords] = React.useState("0");
  const [tabs, setTabs] = React.useState(null);
  const [needUpdate, setNeedUpdate] = React.useState(false);

  const [collapsedDetails, setCollapsedDetails] = React.useState(false);

  const [timer, setTimer] = React.useState(null);

  const [selectedRecordID, setSelectedRecordID] = React.useState(props.ID);

  const tabsPanel = React.useRef();
  const Pages = React.useRef([]);

  React.useLayoutEffect(() => {
    PostHandleTable("paytypes", "2380", undefined, props.SectionID).then(
      (res) => {
        GridLoader(
          gridpanelRef.current,
          { ...props, Module: "paytypes", ObjType: "2380" },
          {
            json: res,
            setSelectedRecord: setSelectedRecordID,
            setCountRecords: setCountRecords,
            Columns: "Section",
          }
        ).then((res) => {
          setMainGrid(res);
        });
      }
    );

    Pages.current = [
      { Caption: "Параметры" },
      { Caption: "Зависимости" },
      { Caption: "Поиск" },
      { Caption: "Связи" },
      { Caption: "Программы" },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (mainGrid) {
      setTabs(getTabs());
      setNeedUpdate(true);

      if (store.getState().theme.animations) {
        setTimeout(() => {
          mainGrid.grid.refreshSource("!");
        }, 251);
      }
    }
  }, [mainGrid]);

  React.useLayoutEffect(() => {
    if (selectedRecordID) {
      setNeedUpdate(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecordID]);

  React.useLayoutEffect(() => {
    if (tabsPanel.current && needUpdate) {
      updateTab(tabsPanel.current.selectedIndex, tabs);
      setNeedUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needUpdate]);

  function getTabs() {
    let details = [];
    if (Pages.current && Pages.current.length) {
      details = Pages.current.map((page, index) => {
        return getLoadTab(page.Caption, index);
      });
    }
    return details;
  }

  async function getContentForTab(pages, index) {
    let page = pages.current[index];

    switch (page.Caption) {
      case "Параметры":
        return "params";
      case "Зависимости":
        return "treeMultiCheck";
      case "Поиск":
        return "paytypesSearch";
      case "Связи":
        return "links";
      case "Программы":
        return "paytypes";
      default:
        return;
    }
  }

  function updateTabsContent(data, firstLoad) {
    if (firstLoad) {
      data.firstLoadFunc();
    } else {
      updateTabContentFunc(data);
    }
  }

  function getReqForContent(selector) {
    switch (selector) {
      case "params":
        return async () => {
          const params = new Map();
          params
            .set("prefix", "paytypes")
            .set("comand", "GetPayTypeParams")
            .set("GroupID", "0")
            .set("Code", selectedRecordID)
            .set("NeedRefresh", "1");

          return AxiosRequest(true, params);
        };
      case "links":
        return async (id) => {
          const params = new Map();
          params
            .set("prefix", "paytypes")
            .set("comand", "GetPayTypeLinks")
            .set("Code", id !== undefined ? id : selectedRecordID);

          return AxiosRequest(true, params);
        };
        break;
      default:
        break;
    }
  }

  function onSearch(id) {
    mainGrid.grid.refreshSource(id);
  }

  async function updateTab(index, tabs) {
    if (
      index !== undefined &&
      tabs &&
      Pages.current &&
      Pages.current.length &&
      selectedRecordID
    ) {
      clearTimeout(timer);
      const newTimer = setTimeout(async () => {
        const selector = await getContentForTab(Pages, index);
        const detailItem = Pages.current[index];

        const blockTab = tabsPanel.current.nativeElement.getTabContent(index);

        const firstLoad = blockTab.querySelector("div[load='true']");

        updateTabsContent(
          {
            Module: "payTypes",
            detailItem: detailItem,
            selectedRecordID: selectedRecordID,
            index: index,
            element: blockTab.ownerElement,
            selector: selector,
            SectionID: props.SectionID,
            onRequest: getReqForContent(selector),
            onSearch: onSearch,
            firstLoadFunc: () => {
              const tabContent = getDetailContent(selector, props, {
                selectedRecordID: selectedRecordID,
                onRequest: getReqForContent(selector),
                onSearch: onSearch,
                detailItem: detailItem,
              });
              const tab = getFullTab(Pages.current[index], tabContent, index);

              tabs[index] = tab;
              setTabs([...tabs]);
            },
          },
          firstLoad
        );
      }, 100);
      setTimer(newTimer);
    }
  }

  async function ContextMenuHandler(data) {
    const comand = data.value;
    switch (comand) {
      default:
        break;
    }
  }

  function refreshGrid() {
    updateGridById(0);
  }

  function collapseDetail() {
    setCollapsedDetails(!collapsedDetails);
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div
        style={{
          border: "1px solid #C8B58F",
          height: "35px",
        }}
      >
        <IconButton title="Детали" onClick={collapseDetail}>
          <ViewComfyOutlinedIcon style={{ fontSize: "large" }} />
        </IconButton>
      </div>
      <div style={{ width: "100%", height: "calc(100% - 35px)" }}>
        <SplitterLocal
          onResizeEnd={refreshGrid}
          onCollapse={refreshGrid}
          onExpand={refreshGrid}
          orientation="horizontal"
          style={{
            width: "100%",
            height: "100%",
          }}
          transitionDuration={251}
          primaryIndex={0}
          collapsed={collapsedDetails}
          sizeSecondary={"20%"}
          hideButtons={true}
        >
          <div style={{ height: "100%", width: "100%" }}>
            <div
              id={`rcgrid_panel`}
              style={{
                height: "100%",
                width: "100%",
                position: "relative",
              }}
              ref={gridpanelRef}
            ></div>
          </div>
          <div style={{ height: "100%", width: "100%" }}>
            <Tabs
              onChange={() => {
                setNeedUpdate(true);
              }}
              style={{ height: "100%", width: "100%" }}
              ref={tabsPanel}
            >
              {tabs}
            </Tabs>
          </div>
        </SplitterLocal>
      </div>
    </div>
  );
}
