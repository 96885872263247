import { IconButton } from "@mui/material";
import SplitterLocal from "../../../../Tools/Splitter";
import ViewComfyOutlinedIcon from "@mui/icons-material/ViewComfyOutlined";
import React from "react";
import { GridLoader } from "../../../../rcsgrid/createGridTools";
import { handleAnaliticRequest } from "../../../../rcsgrid/GridFunctions/accountsAnaliticTableGrid";
import ContextMenu from "../../../../NotWorkArea(Side&Head)/ContextMenu";
import CheckIcon from "@mui/icons-material/Check";
import {
  getDefaultMenu,
  updateGridById,
  updateGridData,
} from "../../../../Tools/Tools";
import { AxiosRequest } from "../../../../../Url";
import { handleAccountsPlanRequest } from "../../../../rcsgrid/GridFunctions/accountsPlanTableGrid";

export default function AccountPlanMainBlock({ props }) {
  const [mainGrid, setMainGrid] = React.useState();
  const subGrid = React.useRef();

  const [paramsColapsed, setParamsColapsed] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState();

  const gridPanel = React.useRef();
  const gridPanel2 = React.useRef();

  const accPlans = React.useRef();
  const ObjType = React.useRef();

  React.useEffect(() => {
    GetAccPlans({ SectionID: props.SectionID })
      .then(async (res) => {
        accPlans.current = res.Items;
        return accPlans.current;
      })
      .then((res) => {
        ObjType.current = res.Current ? res.Current : "0";
        GridLoader(
          gridPanel.current,
          {
            Module: "accounts",
            ObjType: ObjType.current,
            ...props,
          },
          { plan: true, setSelectedRecord: setSelectedRecord }
        ).then((res) => {
          setMainGrid(res);
        });
      });
  }, []);

  React.useEffect(() => {
    if (selectedRecord) {
      if (subGrid.current === undefined) {
        GridLoader(
          gridPanel2.current,
          {
            Module: "accounts",
            ObjType: "-1",
            ObjRef: selectedRecord ? selectedRecord : "0",
            ...props,
          },
          { analitic: true }
        ).then((res) => {
          subGrid.current = res;
        });
        return;
      }

      subGrid.current.source.onHandleRequest = handleAnaliticRequest({
        SectionID: props.SectionID,
        Module: "accounts",
        ObjType: "-1",
        ObjRef: selectedRecord,
      });
      subGrid.current.grid.setSource(subGrid.current.source).then(async () => {
        await subGrid.current.grid.refreshSource(selectedRecord);
        subGrid.current.grid.updateGridSize();
      });
    }
  }, [selectedRecord]);

  function colapseDetails() {
    setParamsColapsed(!paramsColapsed);
  }

  async function ContextMenuHandler(data) {
    const comand = data.value;
    switch (comand) {
      case "Plan":
        ObjType.current = data.ID;

        mainGrid.source.onHandleRequest = handleAccountsPlanRequest({
          SectionID: props.SectionID,
          Module: "accounts",
          ObjType: ObjType.current,
        });
        mainGrid.grid.setSource(mainGrid.source).then(async () => {
          await mainGrid.grid.refreshSource(selectedRecord);
          mainGrid.grid.updateGridSize();
        });
        break;
      default:
        break;
    }
  }

  async function GetAccPlans(data) {
    const params = new Map();
    params
      .set("prefix", "accounts")
      .set("comand", "GetAccPlans")
      .set("SectionID", data.SectionID);

    return AxiosRequest(true, params);
  }

  function onResize() {
    if (subGrid.current) subGrid.current.grid.updateGridSize();
    if (mainGrid) mainGrid.grid.updateGridSize();
  }

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        border: "1px solid rgb(204, 204, 204)",
        borderTop: "0",
      }}
    >
      <div
        style={{
          height: "32px",
          width: "100%",
          border: "1px solid rgb(200, 181, 143)",
        }}
      >
        <IconButton title="Детали" onClick={colapseDetails}>
          <ViewComfyOutlinedIcon style={{ fontSize: "large" }} />
        </IconButton>
      </div>
      <div
        style={{
          height: "calc(100% - 32px)",
          width: "100%",
        }}
      >
        <SplitterLocal
          onResizeEnd={onResize}
          onCollapse={onResize}
          onExpand={onResize}
          orientation="horizontal"
          style={{
            width: "100%",
            height: "100%",
          }}
          primaryIndex={0}
          collapsed={paramsColapsed}
          transitionDuration={250}
          sizeSecondary={"20%"}
          hideButtons={true}
        >
          <ContextMenu
            for={"accountsPlan"}
            Menu={getDefaultMenu("accountsPlan")}
            onRightClick={() => {
              if (
                accPlans.current &&
                accPlans.current.items &&
                accPlans.current.items.length
              ) {
                const items = accPlans.current.items.map((item) => {
                  const id = item.ID !== undefined ? item.ID : "0";
                  return {
                    Caption: item.Text,
                    ID: id,
                    value: "Plan",
                    ImageIndex: ObjType.current === id ? "21" : undefined,
                  };
                });
                const plans = { Caption: "Планы счетов", items: items };
                return { arr: [plans] };
              }
            }}
            onMenuItemClick={ContextMenuHandler}
            refs={{ current: gridPanel?.current?.grid }}
          >
            <div style={{ height: "100%", width: "100%" }}>
              <div
                id={`rcgrid_panel`}
                style={{
                  height: "100%",
                  width: "100%",
                  position: "relative",
                }}
                ref={gridPanel}
              ></div>
            </div>
          </ContextMenu>
          <ContextMenu
            for={"accountsAnalitic"}
            Menu={getDefaultMenu("accountsAnalitic")}
            onMenuItemClick={ContextMenuHandler}
            refs={{ current: gridPanel2?.current?.grid }}
          >
            <div style={{ height: "100%", width: "100%" }}>
              <div
                id={`rcgrid_panel`}
                style={{
                  height: "100%",
                  width: "100%",
                  position: "relative",
                }}
                ref={gridPanel2}
              ></div>
            </div>
          </ContextMenu>
        </SplitterLocal>
      </div>
    </div>
  );
}
