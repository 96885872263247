import { getRightsData } from "../../Tools/Tools";

// Объект data должен содержать следующие свойства:
//  localGrid: true
//  Columns: getColumns() - *смотри пример
//  defaultFields: getDefaultFields() - массив дефолтных полей [{Name, Title, Width},{...}]
//  keyField: "ID" - ключевое поле
//  records: records - массив записей [{ключ записи: значение ключа, ...}, {...}]
//  recordKeys: ["ID", "UsedDate", "ObjRef", "Text", "EditVal"] - все возможные ключи записей в нужном порядке(в порядке дефолтных полей; ключи = названия дефолтных полей)
//  titleKey: "Text" - ключ для заголовка
//  getFieldText: getFieldText - функция будет выполнятся при открытии редактора
//  checkFieldText: checkFieldText - функция будет выполнятся при проверке редактирования
//  onPostRecord: onPostRecord - функция будет выполнятся после редактирования

// *Пример функции getColumns()
// function getColumns() {
//   return {
//     title: [
//       {
//         fieldName: "UsedDate",
//         title: "Дата",
//         width: 100,
//         fieldInfo: {
//           DataType: "6",
//           EditStyle: "2",
//           Options: "",
//           TitleAjust: "",
//           TextAjust: "",
//           Module: "",
//           Values: [],
//         },
//       },
//       {
//         fieldName: "Text",
//         title: "Значение",
//         width: 200,
//         fieldInfo: {
//           DataType: "2040",
//           EditStyle: "17",
//           Options: "",
//           TitleAjust: "",
//           TextAjust: "",
//           Module: "persons",
//           Values: [],
//         },
//       },
//     ],
//   };
// }

export async function localGrid(grid, source, props, data) {
  if (!grid || !source) return;
  const defaultFields = data.defaultFields ? data.defaultFields : [];
  const keyField = data.keyField ? data.keyField : "";
  const getRecords = data.getRecords;
  const recordKeys = data.recordKeys ? data.recordKeys : [];
  const titleKey = data.titleKey;

  if (data.Rights !== undefined) {
    source.rights = getRightsData(data.Rights);
  }

  if (data.showIndicator !== undefined) {
    grid.ShowIndicatorF(data.showIndicator);
  }

  source.onHandleRequest = handleRequest(props, {
    grid,
    source,
    defaultFields: defaultFields,
    keyField: keyField,
    getRecords: getRecords,
    recordKeys: recordKeys,
    titleKey: titleKey,
    getFieldText: data.getFieldText
      ? data.getFieldText
      : () => {
          console.warn("Нет функции на запрос текста");
          return {};
        },
    checkFieldText: data.checkFieldText
      ? data.checkFieldText
      : () => {
          console.warn("Нет функции на проверку введенного значения");
          return {};
        },
    onPostRecord: data.onPostRecord
      ? data.onPostRecord
      : () => {
          console.warn(
            "Нет функции на изменение массива записей при редактировании"
          );
        },
    deleteRecord: data.deleteRecord
      ? data.deleteRecord
      : () => {
          console.warn("Нет функции удаления из массива записей");
        },
    createRecord: data.createRecord
      ? data.createRecord
      : () => {
          console.warn("Нет функции создание записи");
        },
  });

  if (data.setSelectedRecord) {
    source.onRecordIndexChanged = function (source) {
      data.setSelectedRecord(source.getFieldTextSync("ID"));
    };
    data.setSelectedRecord(source.getFieldTextSync("ID"));
  }

  // для обновления данных
  if (data.refresh) {
    await source.close();
    await source.open();
    await source.first();
    await grid.refreshSource();
    await grid.setSource(data.source);
    await grid.updateGridSize();
    return { grid, source };
  }

  await source.open();
  if (data.Columns) {
    grid.setColumns(data.Columns);
  } else grid.defaultColumns = true;
  await grid.setSource(source);
  grid.updateGridSize();

  return { grid, source };
}

function handleRequest(props, data) {
  return async function onHandleRequest(request) {
    const command = request.command ? request.command : request.Command;
    const records = data.getRecords ? await data.getRecords() : [];
    const recordCount = records.length;
    let RecordIndex = data.source.activeRecord;
    switch (command) {
      case "getFields":
        return getDefaultColumns(
          data.defaultFields,
          data.keyField,
          recordCount
        );
      case "getRecords":
        if (data.getRecords) {
          return getRecord(
            request.first,
            recordCount,
            records,
            data.recordKeys,
            data.titlekey
          );
        }
      case "GetFieldText":
        // функция должна возвращать объект в виде { Text: "значение для редактора" }
        return await data.getFieldText(request, data);
      case "CheckFieldText":
        // функция должна возвращать объект в виде { Text: "значение для ячейки", ObjRef: "id значения", EditVal: "значение для редактора"(если его нет, то подставляется значение для ячейки) }
        return await data.checkFieldText(request, data);
      case "PostRecord":
        // функция должна изменять массив записей в зависимости от значений в request.RecordSet.Records[0]["$Data"]
        const record = request.RecordSet.Records.Record;

        await data.onPostRecord(request, record["$Data"], data);
        const res = {
          Command: "PostRecord",
          RecordCount: records.length,
          UpdatesPending: "1",
          RecordSet: {
            Attributes: request.RecordSet.Attributes,
            Records: { Record: { Data: record["$Data"] } },
          },
        };
        return res;
      case "DeleteRecord":
        return await data.deleteRecord(request, data);
      case "CreateRecord":
        const dataRecords = await data.createRecord(request, data);
        return getRecord(
          dataRecords.index,
          dataRecords.records.count,
          dataRecords.records,
          dataRecords.keys,
          dataRecords.titleKey
        );
      case "refresh":
        return {
          command: "refresh",
          RecordCount: recordCount,
          UpdatesPending: "1",
        };
      case "GetRecordCount":
        if (
          recordCount !== data.source.recordCount &&
          recordCount < RecordIndex + 1
        )
          RecordIndex = recordCount - 1;
        return {
          RecordIndex:
            Math.sign(RecordIndex) === -1 || RecordIndex === 0
              ? 0
              : RecordIndex,
          RecordCount: recordCount,
        };
      default:
        return;
    }
  };
}

// Создание объекта стандартных колонок
function getDefaultColumns(fields = [], keyField = "", count = "0") {
  return {
    Fields: fields,
    KeyFieldName: keyField,
    RecordCount: `${count}`,
    command: "getFields",
  };
}

// Создание объекта одной записи
async function getRecord(id, count, records, keys, titleKey) {
  if (false) {
    const rec = {
      RecordCount: count,
      RecordSet: {
        Attributes: keys,
        Records: records.map((recVal, index) => {
          return {
            Title: recVal && recVal[titleKey] ? recVal[titleKey] : "",
            Data: keys.map((item) => {
              return recVal && recVal[item] ? recVal[item] : "";
            }),
          };
        }),
      },
      command: "getRecords",
      count: count,
      first: id,
    };
    console.log(rec);

    return rec;
  } else {
    const recVal = records[id];
    const rec = {
      RecordCount: count,
      RecordSet: {
        Attributes: keys,
        Records: [
          {
            Title: recVal && recVal[titleKey] ? recVal[titleKey] : "",
            Data: keys.map((item) => {
              return recVal && recVal[item] ? recVal[item] : "";
            }),
          },
        ],
      },
      command: "getRecords",
      count: "1",
      first: id,
    };

    return rec;
  }
}
