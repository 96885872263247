import {
  Button,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RefreshIcon from "@mui/icons-material/Refresh";
// import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import KeyIcon from "@mui/icons-material/Key";
import CheckIcon from "@mui/icons-material/Check";
import UndoIcon from "@mui/icons-material/Undo";
import DeleteIcon from "@mui/icons-material/Delete";
import GridOnIcon from "@mui/icons-material/GridOn";
import GridOffIcon from "@mui/icons-material/GridOff";
import ViewModuleOutlinedIcon from "@mui/icons-material/ViewModuleOutlined";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import cn from "classnames";
import React from "react";

export default function ButtonsForGridComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [gridObject, setGridObject] = React.useState();
  const [canModify, setCanModify] = React.useState({
    delete: true,
    insert: true,
  });

  React.useEffect(() => {
    if (props.grid) {
      setGridObject(props.grid);
    }
  }, [props.grid]);

  const handleCloseAnchorEl = () => {
    setAnchorEl(null);
  };

  const checkAvailabilityFromCanModify = (flag, grid) => {
    let available = true;
    if (grid && !grid.setSourceLoad) {
      available = !grid.canModify(flag, undefined, grid);
      setCanModify({
        delete: !grid.canModify(flag, 0, grid),
        insert: !grid.canModify(flag, 2, grid),
      });
    }
  };

  return (
    <Grid
      id={"ButtonsForGrid"}
      style={{
        height: "100%",
        width: "100%",
        paddingLeft: "5px",
        backgroundColor: "rgb(250, 249, 245)",
        ...props.style,
      }}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      key={"ButtonsForGrid" + props.id}
    >
      {gridObject && gridObject.grid && gridObject.source ? (
        <>
          {props.children}
          <Tooltip title="Создать">
            <span>
              <IconButton
                style={{ display: props.buttons.create ? "" : "none" }}
                value="append"
                id="appendButtonForGrid"
                onClick={(e) => {
                  if (props.buttons.createList) {
                    props.onClick(e);
                  } else {
                    gridObject.grid.appendRecord();
                  }
                }}
                disabled={
                  !gridObject.grid.getActiveSource().rights.insert ||
                  (props.canModifyObj && props.canModifyObj.insert)
                }
              >
                <AddIcon fontSize={"small"} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Первая запись">
            <span>
              <IconButton
                style={{ display: props.buttons.navigate ? "" : "none" }}
                disabled={
                  gridObject.grid
                    ? gridObject.grid.getActiveSource().activeRecord === 0
                    : false
                }
                onClick={() => {
                  gridObject.grid.firstScroll();
                }}
              >
                <KeyboardDoubleArrowLeftIcon fontSize={"small"} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Предыдущая запись">
            <span>
              <IconButton
                style={{ display: props.buttons.navigate ? "" : "none" }}
                disabled={
                  gridObject.grid
                    ? gridObject.grid.getActiveSource().activeRecord === 0
                    : false
                }
                onClick={() => {
                  gridObject.grid.moveRecord(-1);
                }}
              >
                <KeyboardArrowLeftIcon fontSize={"small"} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Следующая запись">
            <span>
              <IconButton
                style={{ display: props.buttons.navigate ? "" : "none" }}
                disabled={
                  gridObject.grid
                    ? gridObject.grid.getActiveSource().activeRecord ===
                      gridObject.grid.getActiveSource().recordCount - 1
                    : false
                }
                onClick={() => {
                  gridObject.grid.moveRecord(1);
                }}
              >
                <KeyboardArrowRightIcon fontSize={"small"} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Последняя запись">
            <span>
              <IconButton
                style={{ display: props.buttons.navigate ? "" : "none" }}
                disabled={
                  gridObject.grid
                    ? gridObject.grid.getActiveSource().activeRecord ===
                      gridObject.grid.getActiveSource().recordCount - 1
                    : false
                }
                onClick={() => {
                  gridObject.grid.lastScroll();
                }}
              >
                <KeyboardDoubleArrowRightIcon fontSize={"small"} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Удалить запись">
            <span>
              <IconButton
                style={{ display: props.buttons.delete ? "" : "none" }}
                disabled={
                  !gridObject.grid.getActiveSource().rights.delete ||
                  (props.canModifyObj && props.canModifyObj.delete)
                }
                onClick={() => {
                  if (gridObject.source.rights.delete) {
                    gridObject.grid.deleteRecord();
                    gridObject.grid.refreshSource();
                  }
                }}
              >
                <DeleteIcon fontSize={"small"} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={
              props.buttonsState && props.buttonsState.card
                ? "Обычный режим"
                : "Режим карточки"
            }
          >
            <span>
              <IconButton
                style={{ display: props.buttons.card ? "" : "none" }}
                value="cardmode"
                onClick={(e) => {
                  props.onClick(e);
                }}
                // disabled
              >
                <ViewModuleOutlinedIcon
                  className={cn("CardIcon", {
                    false: props.buttonsState && props.buttonsState.card,
                  })}
                  fontSize={"small"}
                />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Детализация документа">
            <span>
              <IconButton
                style={{ display: props.buttons.detail ? "" : "none" }}
                value="details"
                onClick={props.onClick}
                disabled={props.DisableDetails}
              >
                {props.buttonsState && props.buttonsState.detail ? (
                  <GridOnIcon fontSize={"small"} />
                ) : (
                  <GridOffIcon fontSize={"small"} />
                )}
                {/* <BackupTableIcon fontSize={"small"} /> */}
              </IconButton>
            </span>
          </Tooltip>{" "}
          <Tooltip title="Подписать документ">
            <span>
              <IconButton
                style={{ display: props.buttons.sign ? "" : "none" }}
                value="sign"
                onClick={props.onClick}
              >
                <KeyIcon fontSize={"small"} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Фильтрация документа">
            <span>
              <IconButton
                style={{ display: props.buttons.filter ? "" : "none" }}
                onClick={(e) => {
                  // UpdateGridFunctional(1);
                  // setOptions({ filter: !options.filter, ...options });
                  gridObject.grid.setOptions(1);
                  gridObject.grid.switchFilter(
                    !(props.buttonsState && props.buttonsState.filter)
                  );
                }}
              >
                {/* {!data.options.filter ? ( */}
                {/* <FilterAltIcon fontSize={"small"} /> */}
                {props.buttonsState && props.buttonsState.filter ? (
                  <FilterAltIcon fontSize={"small"} />
                ) : (
                  <FilterAltOffIcon fontSize={"small"} />
                )}
                {/* ) : ( */}
                {/* <FilterAltOffIcon fontSize={"small"} />
            )} */}
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Показать только отмеченные">
            <span>
              <IconButton
                style={{ display: props.buttons.salarySelected ? "" : "none" }}
                disabled={false}
                value="ShowOnlySelected"
                onClick={(e) => {
                  props.onClick(e);
                }}
              >
                <CheckIcon fontSize={"small"} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Обновить данные">
            <span>
              <IconButton
                style={{ display: props.buttons.refreshFull ? "" : "none" }}
                value="RefreshFull"
                onClick={props.onClick}
              >
                <RefreshIcon fontSize={"small"} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={props.save ? "Сохранить изменения" : "Нечего сохранять"}
            disableInteractive
          >
            <span>
              <IconButton
                style={{ display: props.buttons.save ? "" : "none" }}
                value="save"
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
                disabled={!props.save}
              >
                <SaveIcon
                  fontSize={"small"}
                  className={props.save ? "SaveIcon" : ""}
                />
              </IconButton>
            </span>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseAnchorEl}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {[
              { Name: "Сохранить изменения", value: "CommitUpdates" },
              { Name: "Отменить изменения", value: "CancelUpdates" },
            ].map((item, index) => {
              return (
                <MenuItem
                  value={"Commit"}
                  command={item.value}
                  onClick={(e) => {
                    props.onClick(e);
                    setAnchorEl(null);
                  }}
                  style={{ height: "35px" }}
                  key={"ButtonsMenu" + index}
                >
                  <ListItemText>{item.Name}</ListItemText>
                </MenuItem>
              );
            })}
          </Menu>
          <Grid
            item
            style={{
              display:
                !!props.redactor && props.buttons.editButtons ? "" : "none",
            }}
            id={`CommitAndCancelButtForGrid${props.id}`}
          >
            <Tooltip
              title="Сохранить редактирование"
              className="ApplyButtonForGrid"
              leaveTouchDelay={0}
              TransitionProps={{ timeout: 0 }}
            >
              <span>
                <IconButton
                  value="SaveRedactor"
                  onClick={props.onClick}
                  className="ApplyButtonForGrid"
                >
                  <CheckIcon
                    fontSize={"small"}
                    className="ApplyButtonForGrid"
                  />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              leaveTouchDelay={0}
              title="Отменить редактирование"
              className="CloseButtonForGrid"
              TransitionProps={{ timeout: 0 }}
            >
              <span>
                <IconButton
                  value="CancelRedactor"
                  onClick={props.onClick}
                  className="CloseButtonForGrid"
                >
                  <UndoIcon fontSize={"small"} className="CloseButtonForGrid" />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </>
      ) : null}
      {props.onTheRightText ? props.onTheRightText : ""}
    </Grid>
  );
}
