import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import URL, { XMLrequest } from "../../Url";
import axios from "axios";
import ReactDOM from "react-dom";
import {
  SetLoadingCursor,
  tokenProcessingTest,
} from "../../TokenProcessing/TokenProcessing";
import ModalContainer from "../Module/ModalContainer";
import Button from "@mui/material/Button";
import { ClearAllLoadMask } from "../Tools/Tools";

const ChangeStatusProgressFooter = (props: any) => {
  const [Progress, setProgress] = React.useState(0);
  const [data, setData] = React.useState<any>({});
  const [Title, setTitle] = React.useState("");
  const [ReqId, setReqId] = React.useState("");
  //   const [open, setOpen] = React.useState(true);
  const [stop, setStop] = React.useState(false);

  React.useEffect(() => {
    SetLoadingCursor(true);
  }, []);

  React.useEffect(() => {
    if (data.error) {
      const Message = `Ошибка выполнения операции на сервере: ${data.error.content}`;
      ReactDOM.render(
        <ModalContainer dlgType={"Ошибка"} content={Message} />,
        document.getElementById("RenderDefault")
      );
    }

    tokenProcessing(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    tokenProcessing(props.Json);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  async function ChangeProgress(RequestID: any, which: boolean) {
    let params = new Map(),
      data;
    data = which ? { Result: "" } : { Result: "1" };
    params.set("prefix", "project");
    params.set("comand", "ResumeRequest");
    params.set("RequestID", RequestID);
    params.set("WSM", "1");
    which
      ? await axios
          .post(URL(params), JSON.stringify(data))
          .then((res) => setData(res.data))
      : Close(params, data);
  }

  const Close = (params: any, data: any) => {
    ReactDOM.render(<></>, document.getElementById("footerProgress"));
    setData(XMLrequest(params, data));
    SetLoadingCursor(true);
  };

  const normalise = (value: any, MAX: any) =>
    ((Number(value) - 0) * 100) / (Number(MAX) - 0);

  function tokenProcessing(json: any) {
    ///project~ResumeRequest?LicGUID=D100CAB54337ED32E087B59F6CE41511&RequestID=18892&WSM=1 HTTP/1.1
    if (json.Break !== undefined) {
      let Token, RequestID: any;

      Token = json.Token;
      RequestID = json.Params.RequestID;
      if (Token === "ChangeStatusProgress") {
        let Count, Stop, Title, Index;
        Count = json.Params.Count;
        Stop = json.Params.Stop;
        Title = json.Params.Title;
        Index = json.Params.Index;
        if (isNaN(Index)) Index = 0;
        if (Title !== undefined) setTitle(Title);
        if (Stop !== undefined) setStop(true);
        if (ReqId === "") setReqId(RequestID);
        setProgress(normalise(Index, Count));
        ChangeProgress(RequestID, true);
      } else if (Token === "ShellExecute") {
        ReactDOM.render(<></>, document.getElementById("footerProgress"));
        SetLoadingCursor(true);
        tokenProcessingTest(json, props.subData);
      } else if (Token === "SetProgressLabel") {
        ReactDOM.render(<></>, document.getElementById("footerProgress"));

        SetLoadingCursor(true);
        if (
          props.setReturnValue !== undefined &&
          props.from === "ModalProgress"
        ) {
          props.setReturnValue(json);
        } else {
          tokenProcessingTest(json);
        }
      } else {
        // if(props.from === "ModalProgress" && props.setReturnValue !== undefined){
        // props.setReturnValue(json);
        // }else{
        ReactDOM.render(<></>, document.getElementById("footerProgress"));
        tokenProcessingTest(json, props.subData);
        // }
      }
    } else if (isNaN(Progress)) {
      //todo при отсутствии каунта скрывать всё
      ReactDOM.render(<></>, document.getElementById("footerProgress"));
      if (props.setReturnValue !== undefined) {
        //
        if (props.from !== "Forms") {
          ReactDOM.render(<></>, document.getElementById("footerProgress"));
          props.setReturnValue(json);
        } else if (props.from === "Forms" && json.Form !== undefined)
          props.setReturnValue(json);
      }
      ClearAllLoadMask();
    } else {
    }
  }

  return (
    // <Backdrop
    //   sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    //   open={true}
    //   style={{ backgroundColor: "inherit" }}
    // >
    <Grid>
      <Grid
        container
        sx={{ pl: 2 }}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        style={{
          width: "fit-content",
          height: "33px",
          // position: "absolute",
          // bottom: "1.7%",
          // left: "0%",
        }}
        spacing={1}
      >
        <Grid item>
          <LinearProgress
            variant="determinate"
            value={Progress}
            style={{ height: "10px", width: "100px" }}
          />
        </Grid>
        <Grid item style={{ color: "black" }}>
          {Title}
        </Grid>
      </Grid>
      <Grid
        style={{
          width: "150px",
          height: "20px",
          // position: "absolute",
          // bottom: "1.15%",
          // right: "1%",
          display: stop ? "unset" : "none",
        }}
      >
        <Button
          id={ReqId}
          variant="contained"
          style={{
            height: "inherit",
            width: "inherit",
            textTransform: "none",
          }}
          onClick={(ev: any) => {
            ChangeProgress(ev.target.id, false);
          }}
        >
          Прервать
        </Button>
      </Grid>
    </Grid>
    // </Backdrop>
  );
};

export default ChangeStatusProgressFooter;
