//Парсинг полей объекта запросов GetTableLayout и GetDocumentLayout
export function parseColumns(
  json,
  inputColumns = { title: [] },
  collapsibleField,
  addInfo
) {
  let columns = { ...inputColumns }; // если есть входящие поля, они учитываются
  let dest, sour;
  for (const value of Object.values(json.Columns)) {
    sour = value;
    if (sour.FieldName) {
      dest = {};
      dest.fieldName = sour.FieldName;
      dest.title = sour.Title ? sour.Title : sour.Name; // у объекта GetDocumentLayout название поля приходит как Title, а у объекта GetTableLayout как Name
      if (sour.Width) dest.width = parseInt(sour.Width);
      else dest.width = 100;
      if (sour.Level) dest.level = parseInt(sour.Level);
      else dest.level = 0;
      columns.title.push(dest);
      dest.fieldInfo = {
        DataType: value.DataType ? value.DataType : "",
        EditStyle: value.EditStyle ? value.EditStyle : "",
        Options: value.Options ? value.Options : "",
        TitleAjust: value.TitleAjust ? value.TitleAjust : "",
        TextAjust: value.TextAjust ? value.TextAjust : "",
        HardCodeReadOnlyForEditor: json.readonly ? json.readonly : "",
        Module: value.Module
          ? value.Module
          : addInfo && addInfo.Module
          ? addInfo.Module
          : "",
        Values: value.Values ? Object.values(value.Values) : [],
        Details: value.Details ? value.Details : "",
        AccDetails:
          value.DataType === "-1" &&
          (value.FieldName === "D_Analitika" ||
            value.FieldName === "C_Analitika")
            ? true
            : false,
        OnEdit: value.OnEdit ? value.OnEdit : "",
        OnList: value.OnList ? value.OnList : "",
        OnHint: value.OnHint ? value.OnHint : "",
        EditMask: value.EditMask ? value.EditMask : "",
        ColumnID: value.ID ? value.ID : "",
        OnCloseUpList: value.OnCloseUpList ? value.OnCloseUpList : "",
        OnGetEditStyle: value.OnGetEditStyle ? value.OnGetEditStyle : "",
        FieldName: value.FieldName ? value.FieldName : "",
        Path: addInfo && addInfo.Path ? addInfo.Path : "",
        FilterReq: addInfo && addInfo.FilterReq ? addInfo.FilterReq : "",
        ButtonImage: value.ButtonImage ? value.ButtonImage : "",
        MultiCheckSet: value.MultiCheckSet ? value.MultiCheckSet : "",
      };
      if (sour.FieldName === collapsibleField) {
        dest.collapseRecord = true;
      }
    }
  }
  return { ...columns };
  // возвращает объект вида {title:[{nameField:valueField},...]}
}
