import React from "react";
import { Button, Grid } from "@mui/material";
import SectionDocuments from "../../Sections/ElementsSections/SectionDocuments/SectionDocuments";
import Tabs, { TabItem } from "smart-webcomponents-react/tabs";
import { AxiosRequest } from "../../../Url";
import {
  InputButton,
  tokenProcessingTest,
} from "../../../TokenProcessing/TokenProcessing";
import { formEdit } from "../../Tools/Tools";
import "./Components/ButtonsDivForDocs.css";
import { closeModal } from "../../Tools/modalManager";

export default function DialogDocTabs({ props }) {
  const buttonsRef = React.useRef([]);
  const documents = React.useRef();

  const mainBlock = React.useRef();

  const tabPanel = React.useRef(null);

  const [content, setContent] = React.useState();
  const [multipage, setMultipage] = React.useState();

  React.useEffect(() => {
    console.log(props);
    if (props.SelectDocument) {
      setMultipage({ Pages: props.SelectDocument });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (multipage) {
      firstLoadContent();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipage]);

  function firstLoadContent() {
    if (multipage && multipage.Pages !== undefined) {
      const elements = [];
      multipage.Pages.forEach((Page, index) => {
        if (Page.DocCfgID) {
          const innerContent =
            index === 0 ? (
              <SectionDocuments
                id={props.SectionID}
                DocCfgID={Page.DocCfgID}
                HideParams={
                  props.CLSID === "{03338F11-FC1E-11D4-A605-0000E8D9CBDB}" ||
                  props.section
                    ? false
                    : true
                }
                SectionToolsJS={false}
                setRecorcCount={() => {}}
                footerID={`footerData_${props.SectionID}`}
                buttonsContainerRef={buttonsRef.current[index]}
              />
            ) : null;
          elements.push(
            <TabItem
              key={`multipage-${index}`}
              style={{ height: "100%", width: "100%" }}
              label={Page.Name}
            >
              {
                <div style={{ height: "100%", width: "100%" }}>
                  {innerContent}
                </div>
              }
            </TabItem>
          );
        }
      });
      setContent([...elements]);
      buttonsRef.current[0].current.classList.remove("hideButtonDiv");
      buttonsRef.current[0].current.classList.add("showButtonDiv");
      return;
    }
    setContent(<></>);
  }

  function updatePage(ev) {
    if (ev.target !== null) return;
    if (content) {
      const id = tabPanel.current.selectedIndex
        ? tabPanel.current.selectedIndex
        : 0;
      const Page = multipage.Pages[id];
      const innerContent = (
        <SectionDocuments
          id={props.SectionID}
          DocCfgID={Page.DocCfgID}
          HideParams={
            props.CLSID === "{03338F11-FC1E-11D4-A605-0000E8D9CBDB}" ||
            props.section
              ? false
              : true
          }
          SectionToolsJS={false}
          setRecorcCount={() => {}}
          footerID={`footerData_${props.SectionID}`}
          buttonsContainerRef={buttonsRef.current[id]}
        />
      );
      content[id] = (
        <TabItem
          key={`multipage-${id}`}
          style={{ height: "100%", width: "100%" }}
          label={Page.Name}
        >
          {<div style={{ height: "100%", width: "100%" }}>{innerContent}</div>}
        </TabItem>
      );
      setContent([...content]);

      let oldButtonsBlock = mainBlock.current.querySelector(".showButtonDiv");
      oldButtonsBlock.classList.remove("showButtonDiv");
      oldButtonsBlock.classList.add("hideButtonDiv");

      buttonsRef.current[id].current.classList.remove("hideButtonDiv");
      buttonsRef.current[id].current.classList.add("showButtonDiv");
    }
  }

  async function onSelect() {
    console.log(props.onEdit);
    if (props.onEdit) {
      const gridObject = tabPanel.current.nativeElement
        .getTabContent(tabPanel.current.selectedIndex)
        .querySelector(".grid-main-parent").grid;
      if (!gridObject) return;
      props.onEdit({
        value: "",
        tag: gridObject.source.getFieldTextSync("ID"),
        name: props.Name,
        record: props.record,
        textchanged: "0",
        setValue: props.setValue,
        setInputValue: props.setInputValue,
        type: props.Module,
        requestId: props.RequestID,
        addInfo: props.addInfo,
        docID: true,
      });
      if (props.NewModalWindow) closeModal();
      return;
    }

    if (props.RequestID) {
      const gridObject = tabPanel.current.nativeElement
        .getTabContent(tabPanel.current.selectedIndex)
        .querySelector(".grid-main-parent").grid;

      await formEdit(
        "1",
        props.func,
        { Name: "ObjRef", Value: gridObject.source.getFieldTextSync("ID") },
        props.RequestID,
        props.from
      );
      return;
    }
  }
  return (
    <>
      <Grid
        id={"mainElement"}
        ref={mainBlock}
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        style={{ position: "relative", height: "100%" }}
      >
        <Grid
          item
          style={{
            border: "1px solid #C8B58F",
            height: "35px",
          }}
        >
          <Grid
            id={"mainElement"}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ position: "relative", height: "100%" }}
          >
            {multipage && multipage.Pages
              ? multipage.Pages.map((item, index) => {
                  return (
                    <Grid
                      key={`buttoons-container_${index}`}
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      ref={(el) => {
                        buttonsRef.current[index] = { current: el };
                      }}
                      className="hideButtonDiv"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    ></Grid>
                  );
                })
              : null}
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          style={{
            height: props.hiddenFutter
              ? "calc(100% - 35px - 21px)"
              : "calc(100% - 35px - 42px - 21px)",
            width: "100%",
          }}
        >
          <Grid
            style={{ height: "100%" }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              ref={documents}
              style={{
                height: "100%",
                width: "100%",
                borderLeft: "1px solid #C8B58F",
                borderRight: "1px solid #C8B58F",
              }}
              item
            >
              <Tabs
                ref={tabPanel}
                enableMouseWheelAction
                onChange={(ev) => updatePage(ev)}
                animation="none"
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "s",
                }}
              >
                {content}
              </Tabs>
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{
              height: "42px",
              width: "100%",
              border: "1px solid #C8B58F",
              display: props.hiddenFutter ? "none" : "",
            }}
          >
            <Grid
              item
              style={{
                margin: "8px",
                width: props.hiddenButton
                  ? "calc(100% - 89px)"
                  : props.Buttons && (props.Button & 1) === 0
                  ? "calc(100% - 99px)"
                  : "calc(100% - 178px)",
              }}
            ></Grid>
            <Grid item style={{ width: "fit-content" }}>
              <Grid
                container
                item
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                style={{ height: "100%", width: "100%" }}
              >
                {/* {Кнопка Выбрать} */}
                <Button
                  onClick={onSelect}
                  size="small"
                  variant="outlined"
                  style={{
                    textTransform: "none",
                    marginRight: "10px",
                    display:
                      props.Buttons && (props.Button & 1) === 0 ? "none" : "",
                  }}
                  value="1"
                  className="button_Modal_Select"
                >
                  Выбрать
                </Button>
                {/* {кнопка Закрыть} */}
                <Button
                  size="small"
                  variant="outlined"
                  style={{ textTransform: "none" }}
                  value="2"
                  className="button_Modal_Close"
                  onClick={() => {
                    if (props.NewModalWindow) closeModal();
                  }}
                >
                  Закрыть
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            id={`footerData_${props.SectionID}`}
            className="css-11vzlf0-MuiGrid-root"
            style={{
              width: "100%",
              paddingTop: "2px",
              paddingLeft: "15px",
              border: "1px solid #C8B58F",
              borderTop: "0px",
              height: "21px",
            }}
          ></Grid>
        </Grid>
      </Grid>
    </>
  );
}
