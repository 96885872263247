import { Button, Grid } from "@mui/material";
import TestEditor from "../../../Editor/testEditor";
import React from "react";
import { formEdit } from "../../Tools/Tools";

export default function DialogPeriodTime({ props }) {
  const [year, setYear] = React.useState("0");
  const [month, setMonth] = React.useState("0");
  const [day, setDay] = React.useState("0");

  function getData(objref) {
    const d = `${objref >> 16}`;
    const m = `${(objref >> 8) & 0xff}`;
    const y = `${objref & 0xff}`;

    return { year: y, month: m, day: d };
  }

  React.useEffect(() => {
    const data = getData(props.ObjRef);
    setYear(data.year);
    setMonth(data.month);
    setDay(data.day);
  }, []);

  function yearEdit(data) {
    if (data.value < 0) {
      setYear("0");
      data.setInputValue("0");

      return;
    }
    if (data.value > 251) {
      setYear("251");
      data.setInputValue("251");

      return;
    }
    setYear(data.value);
  }

  function monthEdit(data) {
    if (data.value < 0) {
      setMonth("0");
      data.setInputValue("0");

      return;
    }
    if (data.value > 251) {
      setMonth("251");
      data.setInputValue("251");

      return;
    }
    setMonth(data.value);
  }

  function dayEdit(data) {
    if (data.value < 0) {
      setDay("0");
      data.setInputValue("0");

      return;
    }
    if (data.value > 251) {
      setDay("251");
      data.setInputValue("251");
      return;
    }
    setDay(data.value);
  }

  function getTag() {
    return (day << 16) | (month << 8) | year;
  }

  async function onSelect() {
    if (props.onEdit) {
      props.onEdit({
        tag: getTag(),
        name: props.Name,
        record: props.record,
        textchanged: "0",
        setValue: props.setValue,
        setInputValue: props.setInputValue,
        type: props.Module,
        requestId: props.RequestID,
        addInfo: props.addInfo,
      });
    }
    if (props.RequestID) {
      await formEdit(
        "1",
        props.func,
        {
          Name: "ObjRef",
          Value: getTag(),
        },
        props.RequestID,
        props.from
      );
    }
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{
          marginBottom: "15px",
        }}
      >
        <Grid item style={{ width: "60px" }}>
          Годы:
        </Grid>
        <Grid
          item
          style={{
            width: "170px",
            height: "27px",
            border: "1px solid #eeeeee",
          }}
        >
          <TestEditor value={year} editStyle={"8"} onEdit={yearEdit} />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{
          marginBottom: "15px",
        }}
      >
        <Grid item style={{ width: "60px" }}>
          Месяцы:
        </Grid>
        <Grid
          item
          style={{
            width: "170px",
            height: "27px",
            border: "1px solid #eeeeee",
          }}
        >
          <TestEditor value={month} editStyle={"8"} onEdit={monthEdit} />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item style={{ width: "60px" }}>
          Дни:
        </Grid>
        <Grid
          item
          style={{
            width: "170px",
            height: "27px",
            border: "1px solid #eeeeee",
          }}
        >
          <TestEditor value={day} editStyle={"8"} onEdit={dayEdit} />
        </Grid>
      </Grid>
      <Grid
        item
        style={{
          width: "100%",
          height: "40px",
          marginTop: "5px",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ paddingTop: "8px" }}
        >
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none", marginRight: "10px" }}
            value="1"
            className="button_Modal_Select"
            onClick={onSelect}
          >
            Выбрать
          </Button>
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none" }}
            value="2"
            className="button_Modal_Close"
          >
            Отмена
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
