import { useLayoutEffect, useRef } from "react";
import ContentModalWindow from "./Components/ContentModalWindow";
import { GridLoader } from "../../rcsgrid/createGridTools";

export function DialogBook({ props }) {
  const gridpanelRef = useRef(null);

  useLayoutEffect(() => {
    GridLoader(gridpanelRef.current, props);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let content = (
    <ContentModalWindow gridpanelRef={gridpanelRef} props={props} />
  );

  return <>{content}</>;
}
