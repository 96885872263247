import { defaultFieldInfo } from "../../../Tools/Tools";

export function getPaytypesColumnsForSection(codeField) {
  let columns = { title: [] },
    dest = {};
  dest.fieldName = "Code";
  dest.title = "Код";
  dest.width = codeField ? codeField.displayWidth * 15 : 120;
  dest.level = 0;
  dest.fieldInfo = { ...defaultFieldInfo };
  dest.collapseRecord = true;
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "Name";
  dest.title = "Наименование";
  dest.width = 300;
  dest.level = 0;
  dest.fieldInfo = { ...defaultFieldInfo };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "ShortName";
  dest.title = "Сокращение";
  dest.width = 120;
  dest.level = 0;
  dest.fieldInfo = { ...defaultFieldInfo, EditStyle: "64" };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "Title";
  dest.title = "Заголовок";
  dest.width = 120;
  dest.level = 0;
  dest.fieldInfo = { ...defaultFieldInfo };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "ShortCode";
  dest.title = "Короткий код";
  dest.width = 120;
  dest.level = 0;
  dest.fieldInfo = { ...defaultFieldInfo };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "ImageIndex";
  dest.title = "Рисунок";
  dest.width = 120;
  dest.level = 0;
  dest.fieldInfo = { ...defaultFieldInfo, EditStyle: "8", Type: "Image" };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "Параметры";
  dest.title = "Набор параметров";
  dest.width = 120;
  dest.level = 0;
  dest.fieldInfo = {
    ...defaultFieldInfo,
    EditStyle: "16",
    DataType: "2789",
    Module: "Params",
  };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "Атрибуты";
  dest.title = "Атрибуты";
  dest.width = 120;
  dest.level = 0;
  dest.fieldInfo = {
    ...defaultFieldInfo,
    EditStyle: "32",
    DataType: "2380",
    Module: "paytypes",
  };
  columns.title.push(dest);
  return columns;
}

export function getPaytypesColumnsForPrograms() {
  let columns = { title: [] },
    dest = {};
  dest.fieldName = "Code2";
  dest.title = "Вид оплаты";
  dest.width = 120;
  dest.level = 0;
  dest.fieldInfo = {
    ...defaultFieldInfo,
    EditStyle: "17",
    Module: "paytypes",
    DataType: "2380",
  };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "ProgID";
  dest.title = "Программа";
  dest.width = 300;
  dest.level = 0;
  dest.fieldInfo = {
    ...defaultFieldInfo,
    EditStyle: "17",
    Module: "programs",
    DataType: "2001",
  };
  columns.title.push(dest);

  return columns;
}
