import { TabItem } from "smart-webcomponents-react/tabs";
import { getParamsObject } from "../../../Tools/Tools";
import AcoountsTableForTabs from "./detailComponents/AccountsTableForTabs";
import AddressForTabs from "./detailComponents/AddressForTabs";
import ClsBookTableForTabs from "./detailComponents/ClsBookTableForTabs";
import DocumentsForTabs from "./detailComponents/DocumentsForTabs";
import ParamsForTabs from "./detailComponents/ParamsForTabs";
import LoadingMask from "../../../NotWorkArea(Side&Head)/LoadingMask";
import TreeMultiCheckForTabs from "./detailComponents/TreeMultiCheckForTabs";
import PaytypesForTabs from "./detailComponents/PaytypesForTabs";
import PaytypesLinksForTabs from "./detailComponents/PaytypesLinksForTabs";
import PaytypesSearchForTabs from "./detailComponents/PaytypesSearchForTabs";

export function getDetailContent(selector, props, data) {
  switch (selector) {
    case "accounts":
      return <AcoountsTableForTabs props={{ ...props, ...data }} />;
    case "documents":
      return <DocumentsForTabs props={{ ...props, ...data }} />;
    case "params":
      return <ParamsForTabs props={{ ...props, ...data }} />;
    case "addressParams":
      return <AddressForTabs props={{ ...props, ...data }} />;
    case "clsbook":
      return <ClsBookTableForTabs props={{ ...props, ...data }} />;
    case "treeMultiCheck":
      return <TreeMultiCheckForTabs props={{ ...props, ...data }} />;
    case "paytypesSearch":
      return <PaytypesSearchForTabs props={{ ...props, ...data }} />;
    case "links":
      return <PaytypesLinksForTabs props={{ ...props, ...data }} />;
    case "paytypes":
      return <PaytypesForTabs props={{ ...props, ...data }} />;
    default:
      return;
  }
}

export function updateTabContentFunc(data) {
  switch (data.selector) {
    case "params":
      data.onRequest(data).then((res) => {
        const parambox = data.element.querySelector("#paramsBox");
        if (parambox && parambox.update) {
          parambox.update(res);
        }
      });
      break;

    case "addressParams":
      data.onRequest(data).then((res) => {
        const parambox = data.element.querySelector("#paramsBox");
        parambox.update(
          getParamsObject(res, data.selectedRecordID, res.Caption, data.Module)
        );
      });
      break;
    case "documents":
      const docDiv = data.element.querySelector(`#Documents${data.SectionID}`);
      if (docDiv && docDiv.refreshMainGrid) {
        docDiv.refreshMainGrid({
          DocCfgID: data.detailItem.DocCfgID
            ? data.detailItem.DocCfgID
            : data.detailItem.ObjType,
          ParentID: data.selectedRecordID,
        });
      }
      break;
    case "accounts":
      const accBox = data.element.querySelector(`#accBox`);
      if (accBox && accBox.refreshMainGrid) {
        accBox.refreshMainGrid({
          ObjType: data.detailItem.ObjType
            ? data.detailItem.ObjType
            : data.detailItem.Params.ObjType,
          GroupID: data.selectedRecordID,
        });
      }
      break;

    case "clsbook":
      const clsbookBox = data.element.querySelector(`#clsBookBox`);
      if (clsbookBox && clsbookBox.refreshMainGrid) {
        clsbookBox.refreshMainGrid({
          ObjType: data.detailItem.ObjType
            ? data.detailItem.ObjType
            : data.detailItem.Params.ObjType,
          GroupID: data.selectedRecordID,
        });
      }
      break;

    case "treeMultiCheck":
      const treeBox = data.element.querySelector(`#treeBox`);
      if (treeBox && treeBox.update) {
        treeBox.update(data.selectedRecordID);
      }
      break;
    case "paytypesSearch":
      break;
    case "links":
      const linksBox = data.element.querySelector(`#linksBox`);
      if (linksBox && linksBox.update) {
        linksBox.update(data.selectedRecordID);
      }
      break;
    case "paytypes":
      const paytypesBox = data.element.querySelector(`#paytypesBox`);
      if (paytypesBox && paytypesBox.update) {
        paytypesBox.update(data.selectedRecordID);
      }
      break;
  }
}

export function getLoadTab(Caption, index) {
  return (
    <TabItem
      id={`LoadTab_${Caption}-${index}`}
      key={`TabItem_${Caption}-${index}`}
      style={{
        textTransform: "none",
        width: "100%",
        height: "100%",
        padding: "0px",
        display: "inline-block",
      }}
      label={Caption}
    >
      <div
        id={`item_${Caption}`}
        load="true"
        style={{ width: "100%", height: "100%", position: "relative" }}
      >
        <LoadingMask />
      </div>
    </TabItem>
  );
}

export function getFullTab(detailItem, content, index) {
  return (
    <TabItem
      id={`Tab_${detailItem.Caption}-${index}`}
      key={`TabItem_${detailItem.Caption}-${index}`}
      style={{
        textTransform: "none",
        width: "100%",
        height: "100%",
        padding: "0px",
        display: "inline-block",
      }}
      label={detailItem.Caption}
    >
      <div
        id={`item_${detailItem.Caption}`}
        style={{ width: "100%", height: "100%", position: "relative" }}
      >
        {content}
      </div>
    </TabItem>
  );
}
