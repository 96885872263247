import DialogClsBook from "../../../Module/Dialogs/DialogClsBook";
import SectionToolsJS from "../../../Tools/SectionToolsJS";

export default function SectionClsBook({ sectionData, SectionID }) {
  const props = {
    height: "100%",
    ObjType: sectionData.ObjType ? sectionData.ObjType : "0",
    CLSID: sectionData.CLSID,
    Module: "clsbook",
    SectionID: !SectionID ? sectionData.ID : SectionID,
    hiddenFutter: true,
    KeyField: "Code",
  };
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {props.SectionID && props.SectionID > 100 ? (
          <div style={{ width: "100%", display: "flex" }}>
            <SectionToolsJS
              ID={!SectionID ? sectionData.ID : SectionID}
            ></SectionToolsJS>
          </div>
        ) : null}
        <div
          style={{
            width: "100%",
            display: "flex",
            flex: "1 1 0",
          }}
        >
          <DialogClsBook props={props} />
        </div>
      </div>
    </div>
  );
}
