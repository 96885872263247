import React from "react";
import SectionReportsAndDocuments from "../../../SectionReportsAndDocuments";

export default function DocsAndReportsContent(props) {
  const div = React.useRef();
  React.useEffect(() => {
    const arr = div.current.querySelectorAll(".smart-tabs-header-items");
    for (let item of arr) {
      if (item.ownerElement.id.includes("DocumentsAndReports")) item.style.display = "none";
    }
  }, []);

  return (
    <div ref={div} style={{ height: "100%", width: "100%" }}>
      <SectionReportsAndDocuments
        CLSID={props.CLSID}
        id={props.id}
        ElemJson={props.ElemJson}
        onBreadCrumbClick={props.onBreadCrumbClick}
        NoSectionTools={props.NoSectionTools}
      />
    </div>
  );
}
