import React from "react";
import { AxiosRequest } from "../../../../../Url";
import {
  CheckMultiSelect,
  GridLoader,
} from "../../../../rcsgrid/createGridTools";
import SplitterLocal from "../../../../Tools/Splitter";
import TestParams from "../../testParams";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";

import "./TransactionClasses.css";
import { IconButton } from "@mui/material";
import { ApplyTransFilter, getAccsParams } from "../../../../Tools/Requests";

export default function TransactionsMainBlock(props) {
  // GET /accounts~GetTransFilterParams?LicGUID=F889B4FD405F147BABD536B1A79B3C45&GroupID=0&NeedRefresh=1

  const [mainGrid, setMainGrid] = React.useState();
  const [paramData, setParamData] = React.useState();
  const [output, setOutput] = React.useState("");
  const [paramsColapsed, setParamsColapsed] = React.useState(false);
  const [selectedRecordID, setSelectedRecordID] = React.useState();

  const [recordInfo, setRecordInfo] = React.useState("");
  const [needUpdateInfo, setNeedUpdateInfo] = React.useState();

  const gridPanel = React.useRef();

  React.useEffect(() => {
    GridLoader(
      gridPanel.current,
      { Module: "accounts", Path: "TransactionFilter" },
      {
        setSelectedRecord: setSelectedRecordID,
        onMultiSelectChange: () => {
          setNeedUpdateInfo(true);
        },
      }
    ).then((res) => {
      setMainGrid(res);
    });
    getAccsParams().then((res) => {
      setParamData(res);
    });
  }, []);

  React.useEffect(() => {
    if (mainGrid) {
      setNeedUpdateInfo(true);
    }
  }, [mainGrid]);

  React.useEffect(() => {
    if (needUpdateInfo) {
      setTableInfo();
      setNeedUpdateInfo(false);
    }
  }, [needUpdateInfo]);

  async function setTableInfo() {
    if (!mainGrid) return;
    if (mainGrid.grid.getMultiSelect().length === 0) {
      setRecordInfo(`Количество записей: ${mainGrid.source.recordCount}`);
    } else {
      let sum = await CheckMultiSelect(mainGrid.grid, "Amount", ";");
      sum = sum.split(";");
      sum = getSum(sum);
      let count = await CheckMultiSelect(mainGrid.grid, "Quantity", ";");
      count = count.split(";");
      count = getSum(count);

      setRecordInfo(
        `Количество записей: ${
          mainGrid.grid.getMultiSelect().length
        } | Сумма: ${sum} | Кол-во: ${count}`
      );
    }
  }

  function getSum(arr) {
    let result = 0;
    if (arr && arr.length) {
      for (let item of arr) {
        let number = item.replace(",", ".").replaceAll(" ", "");
        number = Number(number);
        if (number) result += number;
      }
    }
    return result;
  }

  async function onApplyClick() {
    const json = await ApplyTransFilter(props.SectionID);
    if (json && json.Text) {
      setOutput(json.Text);
    }
    await mainGrid.grid.refreshSource();
    setNeedUpdateInfo(true);
  }

  function updateGrid() {
    if (mainGrid) mainGrid.grid.updateGridSize();
  }

  function colapseTree() {
    setParamsColapsed(!paramsColapsed);
  }

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        border: "1px solid rgb(204, 204, 204)",
        borderTop: "0",
      }}
    >
      <div
        style={{
          height: "32px",
          width: "100%",
          border: "1px solid rgb(200, 181, 143)",
        }}
      >
        <IconButton
          style={{ height: "32px", width: "32px" }}
          title="Фильтр по проводкам"
          onClick={colapseTree}
        >
          <SpaceDashboardOutlinedIcon
            style={{ transform: "rotate(+180deg)", fontSize: "large" }}
          />
        </IconButton>
      </div>
      <div
        style={{
          height: "calc(100% - 52px)",
          width: "100%",
        }}
      >
        <SplitterLocal
          onResizeEnd={updateGrid}
          onCollapse={updateGrid}
          onExpand={updateGrid}
          orientation="vertical"
          style={{
            width: "100%",
            height: "100%",
          }}
          primaryIndex={1}
          collapsed={paramsColapsed}
          transitionDuration={250}
          sizeSecondary={"20%"}
          hideButtons={true}
        >
          <div style={{ height: "100%", width: "100%" }}>
            <div
              style={{
                height: "30px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Фильтр по проводкам
            </div>
            <div style={{ height: "calc(50% - 60px)", width: "100%" }}>
              <TestParams
                Module={"accounts"}
                reqCommand={"GetTransFilterParams"}
                GroupID={"0"}
                SectionID={props.SectionID}
                data={paramData}
                NoTitle={true}
                NoHistory={true}
              />
            </div>
            <div style={{ height: "30px", width: "100%" }}>
              <button onClick={onApplyClick} className="applyButton">
                Применить
              </button>
            </div>
            <div style={{ height: "50%", width: "100%" }}>
              <textarea
                disabled
                style={{ height: "100%", width: "100%", resize: "none" }}
                value={output}
              />
            </div>
          </div>

          <div style={{ height: "100%", width: "100%" }}>
            <div
              id={`rcgrid_panel`}
              style={{
                height: "100%",
                width: "100%",
                position: "relative",
              }}
              ref={gridPanel}
            ></div>
          </div>
        </SplitterLocal>
      </div>
      <div
        style={{
          width: "100%",
          height: "20px",
          display: "flex",
          alignItems: "center",
          fontSize: "80%",
          paddingLeft: "10px",
          backgroundColor: "rgb(250,249,245)",
        }}
      >
        {recordInfo}
      </div>
    </div>
  );
}
