import React, { useState } from "react";
import ModalWindow from "../../../Module/ModalWindow";
import { Tabs, TabItem } from "smart-webcomponents-react/tabs";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
} from "@mui/material";
import CheckBoxedForDocandSettings from "./CheckBoxedForDocandSettings";

export default function DocInfoAndSettings(props) {
  const [grid, setGrid] = useState(props.grid);

  function getLink() {
    let link = window.location.href;
    link += `&modal&parsed&docid=${grid
      .getActiveSource()
      .getFieldTextSync("ID")}&card`;
    link = link.replace(/secid=[0-9]+[&]?/gm, "");
    let lastloginDrx = localStorage.getItem("LastLogin");
    if (lastloginDrx) {
      lastloginDrx = lastloginDrx.split(",");
      if (lastloginDrx.length > 0) {
        link += `&configname=${encodeURIComponent(lastloginDrx[0])}`;
      }
    }
    return link;
    try {
      navigator.clipboard.writeText(link);
    } catch {
      alert(link);
    }
  }

  return (
    // <ModalWindow
    //   Title="Документ"
    //   style={{ height: `${430}px`, width: `${580}px` }}
    //   Buttons={{
    //     jsonData: {
    //       Кнопка$Выбрать: "Закрыть",
    //     },
    //     Params: { Buttons: "1" },
    //   }}
    //   resizeMode={false}
    // >
    <Tabs id="optionsForUser" style={{ width: "100%", height: "100%" }}>
      <TabItem
        //   id={"ChangePassowrdTab"}
        label={"Информация"}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
          overflow: "hidden",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={3}
          sx={{ mt: 1, overflow: "hidden" }}
        >
          <Grid item>
            {`Время создания: ${grid
              .getActiveSource()
              .getFieldTextSync("CreateTime")}`}
            <br />
            {`Автор: ${grid.getActiveSource().getFieldTextSync("CreateUser")}`}
          </Grid>
          <Grid item>
            {`Время изменения: ${grid
              .getActiveSource()
              .getFieldTextSync("UpdateTime")}`}
            <br />
            {`Автор: ${grid.getActiveSource().getFieldTextSync("UpdateUser")}`}
          </Grid>
          <Grid item>
            {`Внутренний код: ${grid
              .getActiveSource()
              .getFieldTextSync("ID")}  `}
            {/* <br /> */}
            {`Индекс типа: ${grid.doccfgid ? grid.doccfgid : ""}`}
          </Grid>
          <Grid item>
            <a style={{ cursor: "pointer" }} href={getLink()}>
              Ссылка на документ
            </a>
          </Grid>
        </Grid>
      </TabItem>
      <TabItem
        id={"ThemeTab"}
        label={"Настройки"}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
          overflow: "hidden",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          // spacing={3}
          sx={{ mt: 1, overflow: "hidden" }}
        >
          <Grid item>
            <CheckBoxedForDocandSettings />
          </Grid>
          <Grid item></Grid>
          <Grid item></Grid>
        </Grid>
      </TabItem>
    </Tabs>
    // </ModalWindow>
  );
}
