import { createSlice } from "@reduxjs/toolkit";
import { UpdateOptionsForUser } from "../components/Url";
import { setActiveSection } from "../components/MainPage/Tools/Tools";

export const SideBarSlice = createSlice({
  // любое название файла
  name: "SideBar",
  initialState: {
    //переменная к которой будет доступ из любого места (чтение)
    selected: {
      ID: "",
      CLSID: "",
      Name: "",
      Image: "",
    },
    data: [],
  },
  reducers: {
    setSelectedSlice: (state, action) => {
      //1 значение - переменная что бы изменять; 2 значение - то что приходит при вызове функции
      if (action.payload.ID !== "") {
        UpdateOptionsForUser({
          ID: action.payload.ID,
          CLSID: action.payload.CLSID,
          Name: action.payload.Name,
        });
      }
      state.selected = action.payload;
      setActiveSection(action.payload.ID)
    },
    setSideBar: (state, action) => {
      //1 значение - переменная что бы изменять; 2 значение - то что приходит при вызове функции
      state.data = action.payload; // Установить сайдБар
    },
    pushSideBar: (state, action) => {
      //1 значение - переменная что бы изменять; 2 значение - то что приходит при вызове функции
      state.data.push(action.payload); // добавить элмент
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedSlice, setSideBar, pushSideBar } =
  SideBarSlice.actions;

export default SideBarSlice.reducer;
