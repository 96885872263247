import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Tabs, TabItem } from "smart-webcomponents-react/tabs";
import {
  EmptyRequest,
  executeCertList,
  tokenProcessingTest,
} from "../../../TokenProcessing/TokenProcessing";
import { AxiosRequest } from "../../../Url";
import RefreshIcon from "@mui/icons-material/Refresh";
import { GridLoader } from "../../rcsgrid/createGridTools";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { openModal } from "../../Tools/modalManager";
import ChooseCert from "../../../Singin/SignInWithCryptoPRO/ChooseCert";
import { CheckWorkSpace } from "../../../Singin/SingIn";

export default function SignDialog(props) {
  const [button, setDisableButton] = useState(false);
  const [errorTexts, setErrorTexts] = useState([]);
  const [newSignId, setNewSignId] = useState("");
  const [validText, setValidText] = useState(
    "Проверка подписи прошла успешно."
  ); //ТЕкущая выбранная запись в грид
  const SignGridRef = useRef(null);
  const InfoSignGridRef = useRef(null);
  const ErrorsGridRef = useRef(null);
  const objSignsRef = useRef([]);
  const InfoAboutSignRef = useRef(null);
  const ErrorDataSignRef = useRef(null);

  useEffect(() => {
    UpdateAllSigns();
  }, []);

  function UpdateAllSigns() {
    GetObjectSigns().then(async (res) => {
      objSignsRef.current = res;
      if (SignGridRef.current.grid)
        await SignGridRef.current.grid.refreshSource("!");
      if (res.length > 0) {
        const index = SignGridRef.current.grid
          ? Number(SignGridRef.current.grid.source.getFieldTextSync("Index"))
          : 0;
        ServerValidateStream(res[index].ID).then((resp) => {
          tokenProcessingTest(resp, {
            func: showDetailAboutSign,
            from: "grid",
          });
          // setValidatedStream(res)
        });
        if (res[index].valid === false) {
          setValidText("Оригинал документа не соответствует подписанному.");
        }
      } else {
        showDetailAboutSign([]);
      }
      if (!SignGridRef.current.grid) {
        const gridLoad = await GridLoader(
          SignGridRef.current,
          {},
          {
            defaultFields: getDefaultFieldsSigns(),
            keyField: "ID",
            getRecords: () => getRecords(objSignsRef.current),
            recordKeys: ["ID", "Index", "SignTime", "User", "Opisanie"],
            titleKey: "",
            localGrid: true,
            Columns: setCollumsForGridSigns(),
            indicator: false,
            canModify: () => {
              return false;
            },
          }
        );
        gridLoad.source.onRecordIndexChanged = function (source) {
          let ID = source.getFieldTextSync("ID"),
            index = Number(source.getFieldTextSync("Index"));
          ServerValidateStream(ID).then((resp) => {
            tokenProcessingTest(resp, {
              func: showDetailAboutSign,
              from: "grid",
            });
          });
          if (objSignsRef.current[index].valid === false) {
            setValidText("Оригинал документа не соответствует подписанному.");
          } else {
            setValidText("Проверка подписи прошла успешно.");
          }
        };
      }
    });
  }

  useEffect(() => {
    if (errorTexts.length > 0) {
      updateErrorGrid();
    } else {
      ErrorDataSignRef.current = [];
      if (ErrorsGridRef.current.grid)
        ErrorsGridRef.current.grid.refreshSource("!");
    }
  }, [errorTexts]);

  // useLayoutEffect(() => {}, [objSigns.length]);

  function updateErrorGrid() {
    let recs = [];
    errorTexts.forEach((val) => {
      const name = val.split(":");
      const vals = name[1].split("•");
      if (vals[0] !== "@" && vals[1] !== "@")
        recs.push({
          Name: name[0],
          Value: vals[0],
          NewValue: vals[1],
        });
    });
    ErrorDataSignRef.current = recs;
    if (!ErrorsGridRef.current.grid)
      GridLoader(
        ErrorsGridRef.current,
        {},
        {
          defaultFields: getDefaultFieldsError(),
          keyField: "ID",
          getRecords: () => ErrorDataSignRef.current,
          recordKeys: ["Name", "Value", "NewValue"],
          titleKey: "",
          localGrid: true,
          // Columns: setCollumsForGridSigns(),
          indicator: false,
          canModify: () => {
            return false;
          },
        }
      );
    else ErrorsGridRef.current.grid.refreshSource("!");
  }

  function getRecords(data) {
    let arrRecords = [];
    data.forEach((value, index) => {
      arrRecords.push({
        ID: value.ID,
        Index: `${index}`,
        SignTime: value.Time,
        User: value.User,
        Opisanie: value.Text,
      });
    });
    return arrRecords;
  }

  function showDetailAboutSign(data) {
    //todo обновление
    InfoAboutSignRef.current = data;
    if (!InfoSignGridRef.current.grid)
      GridLoader(
        InfoSignGridRef.current,
        {},
        {
          defaultFields: getDefaultFieldsSign(),
          keyField: "Field",
          getRecords: () => createStream(InfoAboutSignRef.current),
          recordKeys: ["Field", "Value"],
          titleKey: "",
          localGrid: true,
          indicator: false,
          canModify: () => {
            return false;
          },
        }
      );
    else InfoSignGridRef.current.grid.refreshSource("!");
  }

  function createStream(info) {
    //Root\ВходящийСчет\Поля\Основание:Счет-фактура•Исправление счет-фактуры
    let arrRecords = [],
      splitedVal,
      arrOfErros = [];
    if (info.Info && info.Info.items) {
      info.Info.items.forEach((value) => {
        splitedVal = value.Result.split("=");
        if (splitedVal.length === 2)
          arrRecords.push({
            Field: splitedVal[0],
            Value: splitedVal[1],
          });
        else arrOfErros.push(value.Result);
      });
    }
    setErrorTexts(arrOfErros);
    return arrRecords;
  }

  function setCollumsForGridSigns() {
    const Columns = {
      title: [
        { title: "Время подписи", width: 133, fieldName: "SignTime" },
        { title: "Пользователь", width: 174, fieldName: "User" },
        { title: "Описание", width: 233, fieldName: "Opisanie" },
      ],
    };
    return Columns;
  }

  function getDefaultFieldsSigns() {
    return [
      {
        Name: "ID",
        Title: "ID",
        Width: "7",
      },
      {
        Name: "Index",
        Title: "Индекс",
        Width: "7",
      },
      {
        Name: "SignTime",
        Title: "Время подписи",
        Width: "10",
      },
      {
        Name: "User",
        Title: "Пользователь",
        Width: "10",
      },
      {
        Name: "Opisanie",
        Title: "Описание",
        Width: "10",
      },
    ];
  }

  function getDefaultFieldsSign() {
    return [
      {
        Name: "Field",
        Title: "Поле",
        Width: "14.6",
      },
      {
        Name: "Value",
        Title: "Значение",
        Width: "14.7",
      },
    ];
  }

  function getDefaultFieldsError() {
    return [
      {
        Name: "Name",
        Title: "Поле",
        Width: "18.75",
      },
      {
        Name: "Value",
        Title: "Значение",
        Width: "17",
      },
      {
        Name: "NewValue",
        Title: "Значение",
        Width: "13.2",
      },
    ];
  }

  function ServerSignStream(serial) {
    if (props.Serial || serial) {
      let params = new Map();
      params
        .set("prefix", "audit")
        .set("comand", "ServerSignStream")
        .set("ObjType", props.ObjType)
        .set("Token", props.Token)
        .set("Options", props.Options)
        .set("Notes", props.Notes)
        .set("ID", props.ID)
        .set("Serial", props.Serial ? props.Serial : serial)
        .set("WSM", "1");
      const content = props.RCDATA ? props.RCDATA : props.content;
      AxiosRequest(true, params, { $content: props.content }).then((res) => {
        tokenProcessingTest(res, { func: AfterServerSignStream });
      });
    } else {
      selectCert();
    }
  }

  function selectCert() {
    executeCertList()
      .then((result) => {
        openModal(
          <ChooseCert
            data={result.split(/\r?\n/)}
            onSelect={ServerSignStream}
          />,
          {
            Title: "Выбор сертификата",
            style: {
              height: `${530}px`,
              width: `${580}px`,
              overflow: "hidden",
            },
            hiddenButton: true,
            blockMaximize: true,
            blockResize: true,
          }
        );
      })
      .catch(() => {
        if (CheckWorkSpace()) return;
      });
  }

  function DeleteSign() {
    const ID = SignGridRef.current.grid.source.getFieldTextSync("ID");
    let params = new Map();
    params
      .set("prefix", "audit")
      .set("comand", "DeleteSign")
      .set("ID", ID)
      .set("WSM", "1");
    AxiosRequest(true, params).then((res) => {
      if (res.Token === "GetSignDialogOptions") {
        params
          .set("prefix", "project")
          .set("comand", "ResumeRequest")
          .set("ID", res.Params.RequestID)
          .set("WSM", "1");
        AxiosRequest(true, params, {
          ID: props.ID,
          ObjType: props.ObjType,
          Token: props.Token,
          Serial: props.Serial,
          Item: ID,
          State: "1",
        }).then((resp) => {
          tokenProcessingTest(resp, { func: UpdateAllSigns, from: "grid" });
        });
      } else {
        alert("GetSignDialogOptions не первый");
        tokenProcessingTest(res, { func: UpdateAllSigns, from: "grid" });
      }
    });
  }

  function AfterServerSignStream(resp) {
    setNewSignId(resp.Result);
    GetObjectSigns().then((res) => {
      objSignsRef.current = res;
      if (SignGridRef.current.grid) SignGridRef.current.grid.refreshSource("!");
      ServerValidateStream(resp.Result).then((res) => {
        tokenProcessingTest(res, { func: AfterServerValidateStream });
      });
    });
  }

  async function GetSignOptions(ID) {
    let params = new Map();
    params
      .set("prefix", "audit")
      .set("comand", "GetSignOptions")
      .set("ID", ID)
      .set("WSM", "1");
    const json = await AxiosRequest(true, params);
    return json;
  }

  function SaveSign() {
    let sign0, sign1;
    const name = `${SignGridRef.current.grid.source.getFieldTextSync(
      "Opisanie"
    )} ${SignGridRef.current.grid.source.getFieldTextSync("ID")}`;
    function setSignZero(data) {
      sign0 = atob(data.RCDATA);
      DownloadData(name + ".sgn", sign0);
      GetSignStream("1").then((res) => {
        tokenProcessingTest(res, { func: setSignOne });
      });
    }
    function setSignOne(data) {
      sign1 = atob(data.RCDATA);
      DownloadData(name + ".dat", sign1);
    }
    GetSignStream("0").then((res) => {
      tokenProcessingTest(res, { func: setSignZero });
    });
  }

  const DownloadData = (name, data) => {
    const link = document.createElement("a");
    link.download = name;
    const blob = new Blob([data], { type: "text/plain" });
    link.href = window.URL.createObjectURL(blob);
    link.click();
  };

  function GetSignStream(Sign) {
    const ID = SignGridRef.current.grid.source.getFieldTextSync("ID");
    let params = new Map();
    params
      .set("prefix", "audit")
      .set("comand", "GetSignStream")
      .set("ID", ID)
      .set("isSign", Sign)
      .set("WSM", "1");
    return AxiosRequest(true, params);
  }

  function GetObjectSigns() {
    let params = new Map();
    params
      .set("prefix", "audit")
      .set("comand", "GetObjectSigns")
      .set("ObjType", props.ObjType)
      .set("ObjRef", props.ID)
      .set("WSM", "1");
    return AxiosRequest(true, params);
  }

  const ServerValidateStream = (id) => {
    let params = new Map();
    params
      .set("prefix", "audit")
      .set("comand", "ServerValidateStream")
      .set("ID", id)
      .set("WSM", "1");
    return AxiosRequest(true, params, { content: props.content });
  };

  const AfterServerValidateStream = (json) => {
    showDetailAboutSign(json);
    setDisableButton(true);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100%" }}
    >
      <Grid sx={{ height: "calc(100% - 40px)", width: "100%" }} item>
        <Tabs
          id="SignDialog"
          style={{ width: "100%", height: "100%" }}
          tabIndex={1}
          onChange={(e) => {
            if (e.detail.index === 1) {
              SignGridRef.current.grid.updateGridSize();
            }
          }}
        >
          <TabItem
            id={"ChangePassowrdTab"}
            label={"Новая подпись"}
            style={{
              textTransform: "none",
              width: "100%",
              height: "100%",
              padding: "0px",
              display: "inline-block",
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ width: "100%", height: "100%" }}
            >
              <Grid item sx={{ width: "100%" }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ width: "100%", height: "45px" }}
                  spacing={1}
                >
                  <Grid item>Описание</Grid>
                  <Grid item style={{ width: "calc(100% - 75px - 120px)" }}>
                    {props.Notes}
                  </Grid>
                  <Grid item>
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{ textTransform: "none" }}
                      onClick={() => {
                        ServerSignStream();
                      }}
                      disabled={button}
                    >
                      Подписать
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ height: "17px" }}>
                Образец документа для подписи:
              </Grid>
              <Grid item sx={{ width: "100%", height: "calc(100% - 59px)" }}>
                <textarea
                  disabled
                  value={props.content}
                  style={{ resize: "none", width: "100%", height: "100%" }}
                />
                {/* {props.content} */}
              </Grid>
            </Grid>
          </TabItem>
          <TabItem
            id={"ChangePassowrdTab"}
            label={"Текущие подписи"}
            style={{
              textTransform: "none",
              width: "100%",
              height: "100%",
              padding: "0px",
              display: "inline-block",
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ width: "100%", height: "100%" }}
            >
              <Grid item sx={{ width: "100%" }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ width: "100%", height: "45px" }}
                  spacing={1}
                >
                  <Grid item>
                    <Tooltip title="Сохранить цифровую подпись">
                      <IconButton onClick={SaveSign}>
                        <SaveIcon sx={{ fontSize: "75%" }} size="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Удалить подпись из базы данных">
                      <IconButton onClick={DeleteSign}>
                        <DeleteIcon sx={{ fontSize: "75%" }} size="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Обновить список">
                      <IconButton onClick={UpdateAllSigns}>
                        <RefreshIcon sx={{ fontSize: "75%" }} size="small" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    style={{ width: "calc(100% - 75px - 120px)" }}
                  ></Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ width: "100%", height: "calc(100% - 45px)" }}>
                <Grid
                  item
                  sx={{
                    width: "100%",
                    height: "38%",
                    // border: "1px solid"
                  }}
                >
                  <div
                    id={"gridpanelSigns"}
                    ref={SignGridRef}
                    style={{
                      height: "100%",
                      width: "100%",
                      position: "relative",
                    }}
                    rcsgrid_panel=""
                  ></div>
                </Grid>
                <Grid item sx={{ width: "100%", height: "62%" }}>
                  <Tabs
                    scrollMode="paging"
                    id={"CurrentSigns"}
                    animation="none"
                    tabPosition="bottom"
                    style={{
                      position: "absolute",
                      borderBottom: "0px",
                      height: "53%",
                      width: "100%",
                    }}
                  >
                    <TabItem
                      id={"InfoSignGrid"}
                      label={"Состав подписи"}
                      style={{
                        textTransform: "none",
                        width: "100%",
                        height: "100%",
                        padding: "0px",
                        display: "inline-block",
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{ width: "100%", height: "100%" }}
                      >
                        <Grid item sx={{ width: "60%", height: "100%" }}>
                          <div
                            id={"gridpanelSign"}
                            ref={InfoSignGridRef}
                            style={{
                              height: "100%",
                              width: "100%",
                              position: "relative",
                            }}
                            rcsgrid_panel=""
                          ></div>
                        </Grid>
                        <Grid item sx={{ width: "40%", height: "100%" }}>
                          <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            style={{ Width: "100%", height: "100%" }}
                            className="grid-main-parent"
                          >
                            <Grid
                              item
                              style={{ Width: "100%", textAlign: "center" }}
                              title="Результат проверки подписи"
                            >
                              {validText}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </TabItem>
                    <TabItem
                      id={"ErrorsGrid"}
                      label={"Ошибки образа"}
                      style={{
                        textTransform: "none",
                        width: "100%",
                        height: "100%",
                        padding: "0px",
                        display: "inline-block",
                      }}
                    >
                      {" "}
                      <div
                        id={"gridpanelErrors"}
                        ref={ErrorsGridRef}
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "relative",
                        }}
                        rcsgrid_panel=""
                      ></div>
                    </TabItem>
                  </Tabs>
                </Grid>
              </Grid>
            </Grid>
          </TabItem>
        </Tabs>
      </Grid>
      <Grid item sx={{ height: "38px", width: "100%" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={1}
        >
          <Grid item></Grid>
          <Grid item sx={{ mt: 1 }}>
            <Button
              size="small"
              variant="outlined"
              sx={{ textTransform: "none" }}
              className="button_Modal_Close"
              onClick={() => {
                EmptyRequest(props.RequestID, props.subData, newSignId);
              }}
            >
              Закрыть
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
