import PersonIcon from "@mui/icons-material/Person";
import IconButton from "@mui/material/IconButton/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Typography from "@mui/material/Typography";
import cn from "classnames";
import { useState } from "react";
import { useSelector } from "react-redux";
import { openModal } from "../../Tools/modalManager";
import Options from "./Options";

export default function UserProfile(props) {
  const theme = useSelector((state) => state.theme.theme);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const settings = [
    // { Label: "Установить расширение", onClick: "InstallWorkspace" },
    { Label: "Настройки", onClick: "OptionsForUser" },
    { Label: "Выйти", onClick: "leave" },
  ];

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItemClick = (event) => {
    handleCloseUserMenu();
    if (event.detail !== 1) return
    const id = event.currentTarget.getAttribute("id");
    switch (id) {
      case "leave":
        props.leave();
        break;
      case "OptionsForUser":
        openModal(<Options />,
          {
            Title: "Настройки",
            style: { height: `${400}px`, width: `${520}px` },
            hiddenButton: true,
            blockMaximize: true,
          });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Tooltip title="Профиль">
        <IconButton onClick={handleOpenUserMenu}>
          <PersonIcon className={cn("iconButtonStimate", { light: theme === "light" })} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "25px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem
            key={setting.Label}
            id={
              setting.onClick === undefined
                ? handleCloseUserMenu
                : setting.onClick
            }
            onClick={handleMenuItemClick}
          >
            <Typography textAlign="center">{setting.Label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
