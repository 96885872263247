import { IconButton } from "@mui/material";
import ViewModuleOutlinedIcon from "@mui/icons-material/ViewModuleOutlined";
import React from "react";

export default function CardModeButton({ onClick, rotateState }) {
  return (
    <IconButton onClick={onClick} sx={{height:"23px", width:"23px"}}>
      <ViewModuleOutlinedIcon
        fontSize={"small"}
        sx={{ transform: `rotate(${rotateState ? "0deg" : "90deg"})` }}
      />
    </IconButton>
  );
}
