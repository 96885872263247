import React from "react";
import { useState, useEffect } from "react";

export default function CountDown(props) {
  const { initialMinute = 0, initialSeconds = 0 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          props.onTimerEnd && props.onTimerEnd();
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    props.getInterval && props.getInterval(myInterval);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <>
      <div style={props.style}>
        {" "}
        {minutes === 0 ? null : `${minutes}:`}
        {seconds < 10 ? `0${seconds}` : seconds}
      </div>
    </>
  );
}
