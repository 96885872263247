import React from "react";
import TestEditor from "../../../../../Editor/testEditor";
import { GridLoader } from "../../../../rcsgrid/createGridTools";
import { defaultFieldInfo } from "../../../../Tools/Tools";
import { AxiosRequest } from "../../../../../Url";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

export default function PaytypesSearchForTabs({ props }) {
  const gridPanelRef = React.useRef();
  const [records, setRecords] = React.useState();
  const [mainGrid, setMainGrid] = React.useState();

  React.useEffect(() => {
    if (records) {
      if (mainGrid === undefined) {
        mainGridLoader();
      } else {
        mainGridLoader(true);
      }
    }
  }, [records]);

  async function mainGridLoader(refresh) {
    const res = await GridLoader(
      gridPanelRef.current,
      {},
      {
        localGrid: true,
        refresh: refresh,
        source: refresh ? mainGrid.source : undefined,
        Columns: getColumns(),
        defaultFields: getDefaultFields(),
        keyField: "ID",
        getRecords: () => records,
        recordKeys: ["ID", "Name"],
        titleKey: "Name",
      }
    );
    setMainGrid(res);
  }

  function getColumns() {
    return {
      title: [
        {
          fieldName: "Name",
          title: "Результаты поиска",
          width: document.body.getBoundingClientRect().width,
          fieldInfo: defaultFieldInfo,
        },
      ],
    };
  }

  function getDefaultFields() {
    return [
      {
        Name: "ID",
        Title: "ID",
        Width: "7",
      },

      {
        Name: "Name",
        Title: "Результаты поиска",
        Width: "20",
      },
    ];
  }

  async function SearchPayTypes(data) {
    const params = new Map();
    params
      .set("prefix", "paytypes")
      .set("comand", "SearchPayTypes")
      .set("Value", data.value);
    return AxiosRequest(true, params);
  }

  async function onEditSearch(data) {
    const json = await SearchPayTypes(data);
    if (json && json.items) setRecords(json.items);
  }

  function onDoubleClick() {
    if (props.onSearch) props.onSearch(mainGrid.source.getFieldTextSync("ID"));
  }

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "27px",
          border: "1px solid #cccccc",
          boxShadow: "0px 3px 5px #cccccc",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <SearchOutlinedIcon />
        <TestEditor
          placeholder={"Поиск по видам начислений"}
          onEdit={onEditSearch}
        />
      </div>
      <div
        onDoubleClick={onDoubleClick}
        style={{ height: "calc(100% - 27px)" }}
      >
        <div
          id={`rcgrid_panel`}
          ref={gridPanelRef}
          style={{
            height: "100%",
            width: "100%",
            position: "relative",
          }}
        ></div>
      </div>
    </div>
  );
}
