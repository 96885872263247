import { Box, Grid } from "@mui/material";
import Params from "../../../Sections/ElementsSections/Params";
import React from "react";
import {
  getSearchParams,
  initSearch,
  stopSearch,
} from "../../../Tools/Requests";
import TestParams from "../../../Sections/ElementsSections/testParams";

// функция формирования объекта для параметров
async function getData(props) {
  const json = await getSearchParams(props.GroupId, props.Module);
  if (json.Items[0].JSONType === "ParamItems") {
    json.Items.shift();
  }
  json.Items.forEach((item) => {
    item = { ...item, EditStyle: "16" };
  });
  return { ...json };
}

// при клике возвращает ID записи
function onClick(ev, records, returnRes) {
  returnRes(
    records[Number(ev.target.parentElement.firstChild.textContent) - 1].ObjRef
  );
}

//функция для создания массива записей для таблицы, для последующей отрисовки
function setTableRecords(records = [], returnF) {
  if (records.length === 0) return;
  const result = records.map((rec, index) => {
    return (
      <tr
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={(ev) => {
          onClick(ev, records, returnF);
        }}
        style={{ cursor: "pointer", height: "20px" }}
        key={`rec_id_${rec.Index}_${index}`}
      >
        <td hidden key={`rec_id_${rec.Index}_${index}`}>
          {index + 1}
        </td>
        <td key={`rec_name_${rec.Name}`}>{rec.Name}</td>
      </tr>
    );
  });
  return result;
}

function onMouseEnter(ev) {
  ev.target.style.backgroundColor = "#eeeeee";
}

function onMouseLeave(ev) {
  ev.target.style.backgroundColor = "";
}

// Входные данные:
// GroupID: ID выбранной таблицы(number)
// Module: название модуля родительского компонента(string)
// ObjType: тип Объекта родительского компонента(number)
// return: функция(хук) для получения результата поиска(setState())
export default function Search(props) {
  // хук итератор для последовательного поиска
  const [Index, setIndex] = React.useState();

  // массив найденых записей по установленному фильтру
  const records = React.useRef([]);

  // хук для обновления контента таблицы результатов
  const [contenTable, setContentTable] = React.useState(<></>);
  const [data, setData] = React.useState();

  //переменная для ограниченной итеррации
  const iterator = React.useRef(0);
  //переменная для ограничения итеррации
  const countLoadingRecords = React.useRef(50);
  //переменная для хранения значения последнего индекса для продолжения иттерации
  const indexMemory = React.useRef(null);
  const tableRef = React.useRef();

  // хук для обновления data при изменении props
  React.useEffect(() => {
    getData(props).then((res) => {
      setData(res);
    });
  }, [props]);

  // рекурсивная функция которая продолжает поиск до тех пор, пока приходят новые записи
  React.useLayoutEffect(() => {
    if (Index !== undefined) {
      const iSearch = async () => {
        const json = await initSearch(
          props.Module,
          data.Path,
          Index,
          props.ObjType
        );

        if (json.Index === undefined) {
          iterator.current = 0;
          setIndex(undefined);
          await stopSearch(props.Module);
          return;
        }

        if (iterator.current < countLoadingRecords.current) {
          setIndex(json.Index);
          records.current.push({
            Index: json.Index,
            ObjRef: json.ObjRef,
            Name: json.Name,
          });

          iterator.current = iterator.current + 1;
        } else {
          iterator.current = 0;
          indexMemory.current = json.Index;
        }
        // при первой загрузке загружает до 50 записей, далее по 3
        if (indexMemory.current !== null) {
          countLoadingRecords.current = 3;
        } else {
          countLoadingRecords.current = 20;
        }

        setContentTable(setTableRecords(records.current, props.return));
      };

      iSearch();
    }
  }, [Index]);

  // функция для продолжения заргрузки данных при приближении к концу таблицы
  function onScroll(ev) {
    if (Index !== undefined) {
      //нижняя граница блока
      const el = ev.target.getBoundingClientRect().bottom;
      //нижняя граница таблицы
      const table = tableRef.current.getBoundingClientRect().bottom;
      //при приближении границы таблицы к границе блока ближе чем на 100 пикселей продолжается загрузка данных
      if (table < el + 100) {
        setIndex(indexMemory.current);
      }
    }
  }

  return (
    <>
      {/* {Основной контейнер} */}
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ height: "100%", width: "100%", border: "2px solid #cccccc" }}
      >
        {/* {контейнер с Параметрами} */}
        <div
          style={{
            width: "calc(60%)",
            height: "inherit",
            position: "relative",
          }}
        >
          <TestParams
            Module={props.Module}
            GroupID={props.GroupId}
            reqCommand={"GetSearchParams"}
            SectionID={props.ObjType}
            id={props.ObjType}
            data={data}
            NoHistory={true}
            NoTitle={true}
          />
        </div>
        {/* </Grid> */}
        <Grid item style={{ height: "100%", width: "40%" }}>
          <div
            style={{
              border: "1px solid #cccccc",
              borderTop: "0px",
              borderRight: "0px",
              borderLeft: "2px solid #cccccc",
              height: "25px",
              width: "100%",
            }}
          >
            {/* {кнопка Поиска} */}
            <button
              style={{
                border: "0px",
                height: "100%",
                width: "100%",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              onClick={() => {
                setContentTable(<></>);
                records.current = [];
                setIndex("0");
                indexMemory.current = null;
              }}
            >
              Найти
            </button>
          </div>
          {/* {контейнер с таблицей результатов} */}
          <Box
            onScroll={onScroll}
            id={"search_Box"}
            style={{
              height: "calc(100% - 25px)",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              borderLeft: "2px solid #cccccc",
            }}
          >
            <Box
              style={{
                width: "100%",
              }}
            >
              <table
                id={"table_Search"}
                ref={tableRef}
                border={"1"}
                bordercolor={"#cccccc"}
                width={"100%"}
                cols={"1"}
                table-layout={"auto"}
              >
                <tbody
                  style={{
                    fontSize: "90%",
                    textAlign: "start",
                  }}
                >
                  {contenTable}
                </tbody>
              </table>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
