import { AxiosRequest } from "../../../Url";
import { defaultFieldInfo, getRightsData } from "../../Tools/Tools";
import { CreateCollapsibleRecords } from "../GridTools/collapsibleRecordsTools";

export async function accountsPlanTableGrid(grid, source, props, data) {
  source.onHandleRequest = handleAccountsPlanRequest(props, source);
  source.CreateCollapsibleRecords = CreateCollapsibleRecords;

  let columns = { title: [] },
    dest = {};
  dest.fieldName = "ID";
  dest.title = "ID";
  dest.width = 120;
  dest.level = 0;
  dest.hide = true;
  dest.fieldInfo = { ...defaultFieldInfo };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "Code";
  dest.title = "Код";
  dest.width = 120;
  dest.level = 0;
  dest.collapseRecord = true;
  dest.fieldInfo = { ...defaultFieldInfo };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "Name";
  dest.title = "Наименование счета";
  dest.width = 320;
  dest.level = 0;
  dest.fieldInfo = { ...defaultFieldInfo };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "Params";
  dest.title = "Параметры счета";
  dest.width = 120;
  dest.level = 0;
  dest.fieldInfo = {
    ...defaultFieldInfo,
    EditStyle: "32",
  };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "Alias";
  dest.title = "Замена кода";
  dest.width = 170;
  dest.level = 0;
  dest.fieldInfo = { ...defaultFieldInfo };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "KPS";
  dest.title = "КОСГУ";
  dest.width = 120;
  dest.level = 0;
  dest.fieldInfo = {
    ...defaultFieldInfo,
    EditStyle: "17",
    Module: "clsbook",
    DataType: "23001",
  };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "ClsType";
  dest.title = "ВидКБК";
  dest.width = 120;
  dest.level = 0;
  dest.fieldInfo = {
    ...defaultFieldInfo,
    EditStyle: "1",
  };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "ExpenceCls";
  dest.title = "КОСГУ";
  dest.width = 120;
  dest.level = 0;
  dest.fieldInfo = {
    ...defaultFieldInfo,
    EditStyle: "17",
    Module: "clsbook",
    DataType: "23001",
  };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "OGSD";
  dest.title = "ОГСД";
  dest.width = 120;
  dest.level = 1;
  dest.fieldInfo = {
    ...defaultFieldInfo,
    EditStyle: "17",
    Module: "clsbook",
    DataType: "23001",
  };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "OGSK";
  dest.title = "ОГСК";
  dest.width = 120;
  dest.level = 1;
  dest.fieldInfo = {
    ...defaultFieldInfo,
    EditStyle: "17",
    Module: "clsbook",
    DataType: "23001",
  };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "ExAccount";
  dest.title = "ДопСчет";
  dest.width = 120;
  dest.level = 0;
  dest.fieldInfo = {
    ...defaultFieldInfo,
    EditStyle: "16",
    Module: "accounts",
    DataType: "2170",
  };
  columns.title.push(dest);

  source.rights = getRightsData("-1");

  grid.setColumns(columns);

  await source.open();

  await grid.setSource(source);

  source.onRecordCollapsed = async function (event) {
    let RecordIndex;
    if (event.currentTarget !== undefined) {
      RecordIndex = event.currentTarget.getAttribute("recordindex");
    } else {
      RecordIndex = event;
    }
    let params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "RecordCollapsed")
      .set("SectionID", props.SectionID)
      .set("ObjType", props.ObjType)
      .set("RecordIndex", RecordIndex);
    await AxiosRequest(true, params);

    await grid.refreshSource("!");

    if (data.setCountRecords) data.setCountRecords(source.recordCount);
    return;
  };

  await grid.refreshSource();

  if (data.setSelectedRecord) {
    source.onRecordIndexChanged = async function (source) {
      data.setSelectedRecord(source.getFieldTextSync("ID"));
    };

    data.setSelectedRecord(source.getFieldTextSync("ID"));
  }
  if (data.onMultiSelectChange) {
    grid.onMultiSelectChange = function () {
      data.onMultiSelectChange();
    };
  }

  grid.updateGridSize();
}

export function handleAccountsPlanRequest(props, source) {
  return function onHandleRequest(request) {
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "HandlePlanTable")
      .set("SectionID", props.SectionID)
      .set("ObjType", props.ObjType);
    return AxiosRequest(true, params, request);
  };
}
