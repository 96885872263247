import SectionToolsJS from "../../../Tools/SectionToolsJS";
import PayTypesMainBlock from "./Components/PayTypesMainBlock";

export default function SectionPayTypes(props) {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {props.SectionID && props.SectionID > 100 ? (
          <div style={{ width: "100%", display: "flex" }}>
            <SectionToolsJS ID={props.SectionID}></SectionToolsJS>
          </div>
        ) : null}
        <div
          style={{
            width: "100%",
            display: "flex",
            flex: "1 1 0",
          }}
        >
          <PayTypesMainBlock props={props} />
        </div>
      </div>
    </div>
  );
}
