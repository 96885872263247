import React from "react";
// import Tree from "../Windows/ViewData/Tree/tree";
import Button from "@mui/material/Button";
import { XMLrequest, CreateCokies, AxiosRequest } from "../../Url";
import { Window } from "smart-webcomponents-react/window";
import { Box, Grid } from "@mui/material";
// import { CheckBox } from "smart-webcomponents-react/checkbox";
// import { DialogBook } from "./Dialogs/DialogBook";

import { useState, useEffect, useRef } from "react";
import {
  GetButtons,
  tokenProcessingTest,
} from "../../TokenProcessing/TokenProcessing";
import { getModalSectionID, updateGridData } from "../Tools/Tools";
import HandleTable from "../../TokenProcessing/components/HandleTable";
import ReactDOM from "react-dom";

export default function ModalWindow(props) {
  const WindowsModal = useRef(null);
  const [Result, setResult] = useState("");
  const [element, setElement] = useState();
  const contentBlockRef = useRef(null);
  let style = props.style;
  let widthDoc, heightDoc;
  widthDoc = document.documentElement.clientWidth;
  heightDoc = document.documentElement.clientHeight;
  function ShowWindow(window) {
    window.style.visibility = "";
  }

  React.useEffect(() => {
    WindowsModal.current.componentRef.current.path = props.Path;
    resizeWindow(style);
    setTimeout(ShowWindow, 200, WindowsModal.current.componentRef.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [WindowsModal, style]);

  useEffect(() => {
    ReactDOM.render(content, contentBlockRef.current);
  }, []);

  useEffect(() => {
    if (Result !== "") {
      // Проверка модальная форма пришла или общая
      if (
        Result.Form &&
        Result.Form.Path &&
        typeof props.setForm === "function"
      ) {
        const f = props.setForm();
        f(Result);
      } else {
        props.onSelect(
          { target: { value: Result.Result } },
          props.RequestID,
          props.setReturnValue,
          props.from
        );
        CloseModal(element);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Result]);

  function resizeWindow(style) {
    let window = WindowsModal.current.componentRef.current;
    let blockWidth, blockHeight, left, top;
    if (style) if (style.height) blockHeight = parseInt(style.height, 10);
    if (style) if (style.width) blockWidth = parseInt(style.width, 10);
    if (!blockWidth) blockWidth = window.getBoundingClientRect().width;
    if (!blockHeight) blockHeight = window.getBoundingClientRect().height;
    if (blockWidth) {
      left = (widthDoc - blockWidth) / 2;
      top = (heightDoc - blockHeight) / 2;
    }
    if (left < 0 || top < 0) {
      window.setAttribute("maximized", "");
      window.style.width = "100%";
      window.style.height = "90%";
    } else {
      window.style.left = left + "px";
      window.style.top = top + "px";
    }
  }

  let param = new Map(),
    json;
  let content,
    hiddenButton,
    title = props.Title;
  hiddenButton = props.hiddenButton;
  if (!props.children) {
    param.set("prefix", props.Module);
    param.set("comand", "GetDialogParams");
    if (props.ObjType) param.set("ObjType", props.ObjType);
    if (props.ObjRef) param.set("ObjRef", props.ObjRef);
    if (props.MultiSel) param.set("MultiSel", props.MultiSel);
    json = XMLrequest(param);
    if (json) {
      title = json.Title;
      switch (json.CLSID) {
        case "{5D1A1010-A394-43EC-A84B-AB401C1C2640}":
          content = (
            <HandleTable
              json={json}
              subData={{ props: props, SectionID: getModalSectionID() }}
            />
          );
          break;
        case "{37912C20-59B2-415F-8F59-DE7F85AABB00}":
          CreateCokies(json.CLSID + "_objref", props.ObjRef);
          content = (
            <HandleTable
              json={json}
              subData={{ props: props, SectionID: getModalSectionID() }}
            />
          );
          hiddenButton = true;
          break;
        case "{2AC0E428-23CD-49F6-AF96-3DB9D6B7AD0A}":
          style.height = window.innerHeight * 0.7 + "px";
          style.width = window.innerWidth * 0.7 + "px";
          content = (
            <HandleTable
              json={json}
              subData={{ props: props, SectionID: getModalSectionID() }}
            />
          );
          hiddenButton = true;
          break;
        case "{7FAC2B20-109D-4406-A757-61674277B71F}":
          style.height = window.innerHeight * 0.6 + "px";
          style.width = window.innerWidth * 0.65 + "px";
          content = (
            <HandleTable
              json={json}
              subData={{ props: props, SectionID: getModalSectionID() }}
            />
          );
          hiddenButton = true;
          break;
        case "{48C155C0-1336-11D6-9CF2-DB1D5BC3046D}":
          style.height = window.innerHeight * 0.7 + "px";
          style.width = window.innerWidth * 0.7 + "px";
          content = (
            <HandleTable
              json={json}
              subData={{ props: props, SectionID: getModalSectionID() }}
            />
          );
          hiddenButton = true;
          break;
        case "{AE5A67E0-EBEB-11D5-900D-000021E6A31F}":
          content = (
            <HandleTable
              json={json}
              subData={{ props: props, SectionID: getModalSectionID() }}
            />
          );
          hiddenButton = true;
          break;
        case "{295EA015-4573-4AD9-922A-A14CE0FD9C78}":
          content = (
            <HandleTable
              json={json}
              subData={{ props: props, SectionID: getModalSectionID() }}
            />
          );
          hiddenButton = true;
          break;
        default:
          content = (
            <HandleTable
              json={json}
              subData={{ props: props, SectionID: getModalSectionID() }}
            />
          );
          break;
      }
    }
  } else {
    content = props.children;
  }

  function CloseQuery(ev, el) {
    let params = new Map();
    // json;
    params.set("prefix", "forms");
    params.set("comand", "CloseQuery");
    params.set("Result", ev.target.value);
    params.set("Path", props.Path);
    params.set("WSM", 1);
    AxiosRequest(true, params).then((json) => {
      if (json.Result) {
        if (ev.target.value !== "2") {
          props.onSelect(
            { target: { value: json.Result } },
            props.RequestID,
            props.setReturnValue,
            props.from
          );
          CloseModal(el);
        } else if (ev.target.value === "2") {
          props.onClose(
            { target: { value: json.Result } },
            props.RequestID,
            props.setReturnValue,
            props.from
          );
          CloseModal(el);
        }
      } else {
        tokenProcessingTest(json, { func: setResult });
      }
    });
    // json = XMLrequest(params);
  }

  function CloseModal(el) {
    let background = el.parentElement;
    if (background.className === "background_ModalDialog") background.remove();
    else el.remove();
  }

  function handleWindowClick(ev) {
    let el = ev.target;
    while (el && el.tagName !== "SMART-WINDOW") {
      el = el.parentElement;
    }

    if (el)
      if (ev.target.classList.contains("button_Modal_Select")) {
        if (props.onSelect) {
          setElement(el);
          if (props.Path) {
            CloseQuery(ev, el);
          } else {
            props.onSelect(
              ev,
              props.RequestID,
              props.setReturnValue,
              props.from
            );
          }
          if (!props.Path) {
            CloseModal(el);
          }
          // let background = el.parentElement;
          // if (background.className == "background_ModalDialog")
          //   background.remove()
          // else el.remove()
        }
        if (!props.Path && !props.onSelect) {
          CloseModal(el);
        }
      } else if (
        ev.target.classList.contains("button_Modal_Close") ||
        ev.target.classList.contains("smart-close-button")
      ) {
        if (props.onClose) {
          setElement(el);
          if (props.Path) {
            if (ev.target.classList.contains("smart-close-button")) {
              CloseQuery({ target: { value: "2" } }, el);
            } else {
              CloseQuery(ev, el);
            }
          } else {
            props.onClose(
              { target: { value: "2" } },
              props.RequestID,
              props.setReturnValue,
              props.from
            );
            CloseModal(el);
          }
        }
        if (!props.Path) {
          CloseModal(el);
        }
        // let background = el.parentElement;
        // if (background.className == "background_ModalDialog")
        //   background.remove()
        // else el.remove()
      }
  }

  // function MultiSel(ev) {
  //   let elem;
  //   // alert(ev.detail.value)
  // }

  function GridResize(ev) {
    setTimeout(() => {
      updateGridData(10, { last: true });
    });
  }

  function Closing(e) {
    e.preventDefault();
  }

  return (
    <Window
      ref={WindowsModal}
      dropPosition={"center"}
      modal
      style={{
        ...style,
        minWidth: props.style.minWidth,
        minHeight: props.style.minHeight,
      }}
      opened
      active
      disableEscape={true}
      onClick={handleWindowClick}
      onResizeEnd={GridResize}
      resizeMode={props.resize === false ? "none" : "both"}
      label={title}
      headerButtons={props.headerButtons ? props.headerButtons : ["close"]}
      onClosing={Closing}
      onKeyDown={(ev) => {
        ev.stopPropagation();
      }}
    >
      <Box
        style={{
          width: "100%",
          height: hiddenButton ? "100%" : "calc(100% - 40px)",
          overflow: "auto",
        }}
        ref={contentBlockRef}
      >
        {/* {content} */}
      </Box>
      <Box style={{ width: "100%", display: "flex" }}>
        {/* <Box style={{ width: "50%" }}>
          <CheckBox
            id="MultiSel"
            onClick={MultiSel}
            style={props.MultiSel == 1 ? null : { display: "none" }}
          >
            Множественный выбор
          </CheckBox>
        </Box> */}
        {hiddenButton ? null : props.Buttons ? (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            style={{ marginTop: "10px" }}
          >
            {GetButtons(props.Buttons)}
          </Grid>
        ) : (
          <Box style={{ marginLeft: "auto", marginTop: "10px" }}>
            <Button
              size="small"
              variant="outlined"
              style={{ textTransform: "none" }}
              value="1"
              className="button_Modal_Select"
            >
              {"Выбрать"}
            </Button>
            <Button
              size="small"
              variant="outlined"
              style={{ textTransform: "none", marginLeft: 10 }}
              value="2"
              className="button_Modal_Close"
            >
              Отмена
            </Button>
          </Box>
        )}
      </Box>
    </Window>
  );

  /*let dialogWindow = <Box style={{ position: "fixed", zIndex: 1300, right: 0, bottom: 0, top: 0, left: 0, pointerEvents: "all" }}>
    {window}
  </Box>
  return dialogWindow*/
}
