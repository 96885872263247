import { createSlice } from "@reduxjs/toolkit";
import { GetOptionsForUser } from "../components/Url";
// import { WorkPlaceTools } from "../components/MainPage/Tools/Tools";

export const themeSlice = createSlice({
  // любое название файла
  name: "theme",
  initialState: {
    theme: GetOptionsForUser()?.theme === undefined
      ? "light"
      : GetOptionsForUser().theme,
    animations:
      GetOptionsForUser()?.animations === undefined
        ? false
        : GetOptionsForUser().animations,
    refreshSectionData: null,
    workPlaceTools: undefined,
    images: undefined,
    unloadCallbacks: [],
  },
  reducers: {
    setTheme: (state, action) => {
      //1 значение - переменная что бы изменять; 2 значение - то что приходит при вызове функции
      state.theme = action.payload;
    },
    setAnimations: (state, action) => {
      //1 значение - переменная что бы изменять; 2 значение - то что приходит при вызове функции
      state.animations = action.payload;
    },
    setRefreshSectionData: (state, action) => {
      state.refreshSectionData = action.payload;
    },
    setWorkPlaceTools: (state, action) => {
      state.workPlaceTools = action.payload;
    },
    setImages: (state, action) => {
      state.images = action.payload;
    },
    AddCallBack: (state, action) => {
      state.unloadCallbacks.push(action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTheme,
  setAnimations,
  setRefreshSectionData,
  setWorkPlaceTools,
  AddCallBack,
  setImages,
} = themeSlice.actions;

export default themeSlice.reducer;
