import { Box, Button, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import ContextMenu from "../../../NotWorkArea(Side&Head)/ContextMenu";
import SearchIcon from "@mui/icons-material/Search";
import UpdateIcon from "@mui/icons-material/Update";
import React from "react";
import { CreateBackgroundForModal } from "../../../../TokenProcessing/TokenProcessing";
import ModalWindow from "../../ModalWindow";
import HandleGrouping from "./HandleGrouping";
import { Tabs } from "smart-webcomponents-react/tabs";
import TestEditor from "../../../../Editor/testEditor";
import { getDefaultMenu, updateGridData } from "../../../Tools/Tools";
import ViewComfyOutlinedIcon from "@mui/icons-material/ViewComfyOutlined";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import VerticalAlignCenterOutlinedIcon from "@mui/icons-material/VerticalAlignCenterOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import LoadingMask from "../../../NotWorkArea(Side&Head)/LoadingMask";
import SplitterLocal from "../../../Tools/Splitter";
import FilterComponent from "./FilterComponent";
import { store } from "../../../../../store";

// Входные Данные необходимые для работы
// props = props(свойства родителя(пока используется только CLSID))
// data={{
//   tabs:<tabItem>||[<tabItem>,<tabItem>]
//   tree:<MyTree>
//   gridPanel: useRef()(ссылка на блок для табицы)
//   mainGrid: {grid,source}
//   countRecords: number
//   onSelect: function(){}(функция при нажатии на кнопку выбрать)
//   subGrid {grid,source}(необязательно)
//   updateTab: function(){}(функция обновления контента одного tabItem)(необязательно)
//   getMainOrganization: function(){}(функция для кнопки выбор основной организации)(необязательно)
// }}
// buttons={{
//   search: bool,
//   grouping: bool,
//   contragent: bool,
// }}

export default function ContentOrganization({ props, data, buttons }) {
  //хуки состояния отображения Деталей и Дерева
  const [colapsedDetail, setColapsedDetail] = React.useState(true);
  const [colapsedTree, setColapsedTree] = React.useState(false);
  const [colapsedUsers, setColapsedUsers] = React.useState(true);
  const [timerUpdateGrid, setTimerUpdateGrid] = React.useState();

  const [filterSwitch, setFilterSwitch] = React.useState(false);

  //хук для открытия и закрытия выпадающего списка Group
  const [anchorEl, setAnchorEl] = React.useState(null);

  //функции для обработки клика на элемент выпадающего списка
  //*
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value) => {
    handleGrouping(value);
    setAnchorEl(null);
  };
  //*

  React.useEffect(() => {
    refreshGrid();
    if (data.getCollapsed) {
      data.getCollapsed({
        detail: colapsedDetail,
        tree: colapsedTree,
        users: colapsedUsers,
      });
    }
  }, [colapsedDetail, colapsedTree, colapsedUsers]);
  /////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////Контекст меню///////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////
  //в разработке
  // const MenuMain = {
  //   DocumentsTable: [
  //     { Name: "Показать в группе" },
  //     { Name: "Отметить для перемещения" },
  //     { Name: "Переместить в группу" },
  //     { Name: "Divider" },
  //     { Name: "Новая запись" },
  //     { Name: "Удалить запись" },
  //     { Name: "Divider" },
  //     { Name: "Режим просмотра" },
  //     { Name: "Сохранять отметку" },
  //   ],
  //   DocumentsHeader: [
  //     { Name: "Скрыть столбец" },
  //     { Name: "Divider" },
  //     { Name: "По возрастанию" },
  //     { Name: "По убыванию" },
  //     { Name: "Divider" },
  //     { Name: "Область группировки", value: "GroupShow" },
  //     { Name: "Поиск по столбцу" },
  //   ],
  //   DocumentsGrouping: [
  //     { Name: "Область группировки", value: "GroupShow" },
  //     { Name: "Расширенная группировка", availability: false },
  //   ],
  // };

  // function UpdateGridFunctional(grid, index) {
  //   grid.setOptions(index);
  // }

  // function ContextMainMenuHandler(event) {
  //   const comand = event.target.parentElement.getAttribute("value");
  //   switch (comand) {
  //     case "GroupShow":
  //       UpdateGridFunctional(data.mainGrid.grid, 2);
  //       break;
  //     default:
  //       break;
  //   }
  // }

  // function ContextSubMenuHandler(event) {
  //   const comand = event.target.parentElement.getAttribute("value");
  //   switch (comand) {
  //     case "GroupShow":
  //       UpdateGridFunctional(data.subGrid.grid, 2);
  //       break;
  //     default:
  //       break;
  //   }
  // }

  // function ContextAddressMenuHandler(event) {
  //   const comand = event.target.parentElement.getAttribute("value");
  //   switch (comand) {
  //   }
  // }

  // function ContextParamsMainMenuHandler(event) {
  //   const comand = event.target.parentElement.getAttribute("value");
  //   switch (comand) {
  //   }
  // }

  // function ContextSearchMenuHandler(event) {
  //   const comand = event.target.parentElement.getAttribute("value");
  //   switch (comand) {
  //   }
  // }

  // function getContextMenuHandler(value) {
  //   switch (value) {
  //     case "Счета":
  //       return ContextSubMenuHandler;
  //     case "Адрес":
  //       return ContextAddressMenuHandler;
  //     case "Дополнительно":
  //       return ContextParamsMainMenuHandler;
  //     case "Поиск":
  //       return ContextSearchMenuHandler;
  //   }
  // }

  /////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////Функционал кнопок//////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////

  //кнопка из списка Объединить
  function handleGrouping(module) {
    CreateBackgroundForModal(
      <ModalWindow
        Title={module}
        style={{
          minWidth: `${window.innerWidth * 0.4}px`,
          minHeight: `${window.innerHeight * 0.4}px`,
          height: `${window.innerHeight * 0.4}px`,
          width: `${window.innerWidth * 0.4}px`,
        }}
        hiddenButton={true}
      >
        <HandleGrouping id={"grouping_grid"} module={module}></HandleGrouping>
      </ModalWindow>
    );
  }

  //кнопка детали
  function colapseDetail() {
    setColapsedDetail(!colapsedDetail);
    refreshGrid();
  }
  //кнопка дерево
  function colapseTree() {
    setColapsedTree(!colapsedTree);
    refreshGrid();
  }
  //кнопка пользователи
  function colapseUsers() {
    setColapsedUsers(!colapsedUsers);
    refreshGrid();
  }
  // функции для кнопки поиска
  const selectTab = (num) => {
    if (data.tabsPanel.current) data.tabsPanel.current.select(num);
  };

  function searchActive() {
    if (colapsedDetail) {
      selectTab(buttons.search);
      setColapsedDetail(false);
      return;
    }
    if (data.tabsPanel.current.selectedIndex === buttons.search) {
      setColapsedDetail(true);
      return;
    }
    selectTab(buttons.search);
  }

  //Функции для обновления Таблиц
  function refreshGrid() {
    if (store.getState().theme.animations) {
      clearTimeout(timerUpdateGrid);
      setTimerUpdateGrid(
        setTimeout(() => {
          updateGridData(0);
        }, 251)
      );
    } else updateGridData(0);
  }

  const styleButton = {
    margin: "2px 2px 2px 2px",
    width: "30px",
    height: "30px",
  };
  return (
    <>
      {
        //главный контейнер
      }
      <Grid
        id={"mainElement"}
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        style={{ position: "relative", height: "100%" }}
      >
        {
          //Шапка
        }
        <Grid
          item
          style={{
            border: "1px solid #C8B58F",
            height: "35px",
          }}
        >
          {
            //кнопки шапки
          }
          {/* {кнопка Пользователи} */}
          <IconButton
            style={
              buttons.users !== undefined ? styleButton : { display: "none" }
            }
            onClick={colapseUsers}
            title="Пользователи"
          >
            <AccountBoxOutlinedIcon style={{ fontSize: "large" }} />
          </IconButton>

          {/* {кнопка Дерево} */}
          <IconButton
            style={
              buttons.tree !== undefined ? styleButton : { display: "none" }
            }
            title="Каталог"
            onClick={colapseTree}
          >
            <SpaceDashboardOutlinedIcon
              style={{ transform: "rotate(+180deg)", fontSize: "large" }}
            />
          </IconButton>
          {/* {кнопка Детали} */}
          <IconButton
            title="Детали"
            disabled={!data.tabs || data.tabs.length === 0}
            style={
              buttons.detail !== undefined ? styleButton : { display: "none" }
            }
            onClick={colapseDetail}
          >
            <ViewComfyOutlinedIcon style={{ fontSize: "large" }} />
          </IconButton>
          {/* {кнопка Фильтр} */}
          <IconButton
            style={
              buttons.filter !== undefined ? styleButton : { display: "none" }
            }
            title="Фильтр"
            onClick={() => {
              setFilterSwitch(!filterSwitch);
            }}
          >
            <FilterAltOutlinedIcon style={{ fontSize: "large" }} />
          </IconButton>

          <IconButton
            style={
              buttons.deleteFilter !== undefined
                ? styleButton
                : { display: "none" }
            }
            title="Снять фильтр"
            onClick={(ev) => {
              data.onDeleteFilter(ev);
            }}
          >
            <FilterAltOffOutlinedIcon style={{ fontSize: "large" }} />
          </IconButton>

          {/* {кнопка Поиск} */}
          <IconButton
            title="Поиск"
            style={
              buttons.search !== undefined ? styleButton : { display: "none" }
            }
            onClick={searchActive}
          >
            <SearchIcon style={{ fontSize: "large" }} />
          </IconButton>
          {/* {кнопка Объединение} */}
          <IconButton
            title="Объединить.."
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            size={"small"}
            style={buttons.grouping ? styleButton : { display: "none" }}
          >
            <VerticalAlignCenterOutlinedIcon
              style={{ transform: "rotate(+90deg)", fontSize: "large" }}
            />
          </IconButton>
          {/* {Выпадающий список для Объединения} */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => {
              setAnchorEl(null);
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              id={`Org_${props.CLSID}`}
              onClick={() => {
                handleClose("Объединить организации");
              }}
            >
              Объединить организации
            </MenuItem>
            <MenuItem
              id={`Bank_${props.CLSID}`}
              onClick={() => {
                handleClose("Объединить банки");
              }}
            >
              Объединить банки
            </MenuItem>
            <MenuItem
              id={`Accounts_${props.CLSID}`}
              onClick={() => {
                handleClose("Объединить Счета");
              }}
            >
              Объединить Счета
            </MenuItem>
          </Menu>
          {/* {кнопка Обновить Таблицы(для тестов)} */}
          <IconButton
            title="Обновить Таблицы"
            style={{ display: "none" }}
            onClick={refreshGrid}
          >
            <UpdateIcon />
          </IconButton>
        </Grid>
        {
          //Контейнер с разделителями
        }
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          style={{
            height: props.hiddenFutter
              ? `calc(${props.height ? props.height : "100%"} - 35px)`
              : `calc(${props.height ? props.height : "100%"} - 35px - 42px - ${
                  data.docFooter ? "21px" : "0px"
                })`,
            width: "100%",
          }}
        >
          <SplitterLocal
            onResizeEnd={() => refreshGrid()}
            onCollapse={() => {
              refreshGrid();
            }}
            onExpand={() => {
              refreshGrid();
            }}
            orientation="vertical"
            style={{ width: "100%", height: "100%" }}
            primaryIndex={buttons.tree ? 1 : 0}
            collapsed={colapsedTree}
            transitionDuration={250}
            sizeSecondary={"30%"}
            hideButtons={true}
          >
            {
              //Контейнер с деревом
            }
            {buttons.tree ? (
              <div id={"org_splitter_item_1"} style={{ height: "100%" }}>
                <Grid
                  item
                  style={{
                    border: "1px solid #C8B58F",
                    borderTop: "0px",
                    height: "calc(100% - 25px)",
                    width: "100%",
                  }}
                >
                  {data.tree}
                </Grid>
                <div
                  style={{
                    border: "1px solid #C8B58F",
                    borderTop: "0px",
                    height: "25px",
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  <IconButton
                    style={
                      data.getMainOrganization
                        ? {
                            border: "0px",
                            borderRadius: "0",
                            backgroundColor: "#f9f9f9",
                            fontSize: "100%",
                            height: "100%",
                            width: "100%",
                            whiteSpace: "nowrap",
                            cursor: "pointer",
                          }
                        : { display: "none" }
                    }
                    onClick={data.getMainOrganization}
                  >
                    Основная организация
                  </IconButton>
                </div>
              </div>
            ) : null}
            {
              //Контейнер с основной Таблицей и Деталями
            }
            <div style={{ height: "100%" }}>
              {data.nfa ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{
                    height: "39px",
                    background:
                      "linear-gradient(to top, #cecece, 20%, rgb(250, 249, 245))",
                  }}
                >
                  <Grid
                    item
                    style={{
                      height: "27px",
                      width: "90px",
                      paddingTop: "3px",
                      paddingLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    Учреждение:
                  </Grid>
                  <Grid
                    item
                    style={{
                      height: "27px",
                      width: "calc(100% - 100px)",
                      border: "1px solid #C8B58F",
                      borderRadius: "3px",
                      padding: "3px",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <TestEditor {...data.editorProps} />
                  </Grid>
                </Grid>
              ) : null}
              {buttons.filter ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{
                    height: "39px",
                    background:
                      "linear-gradient(to top, #cecece, 20%, rgb(250, 249, 245))",
                    display: filterSwitch ? "" : "none",
                  }}
                >
                  <FilterComponent props={data.filterProps} />
                </Grid>
              ) : null}
              <SplitterLocal
                onResizeEnd={() => refreshGrid()}
                onCollapse={() => {
                  refreshGrid();
                }}
                onExpand={() => {
                  refreshGrid();
                }}
                orientation="horizontal"
                style={{
                  width: "100%",
                  height:
                    data.nfa || filterSwitch ? "calc(100% - 39px)" : "100%",
                }}
                primaryIndex={0}
                collapsed={colapsedDetail}
                defaultCollapsed={colapsedDetail}
                transitionDuration={250}
                sizeSecondary={"30%"}
                hideButtons={true}
              >
                {
                  //Основная Таблица
                }
                <div
                  style={{
                    height: "100%",
                  }}
                >
                  <SplitterLocal
                    onResizeEnd={() => refreshGrid()}
                    onCollapse={() => {
                      refreshGrid();
                    }}
                    onExpand={() => {
                      refreshGrid();
                    }}
                    orientation="vertical"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    primaryIndex={0}
                    collapsed={colapsedUsers}
                    transitionDuration={250}
                    sizeSecondary={"30%"}
                    hideButtons={true}
                  >
                    <div style={{ height: "100%" }}>
                      <ContextMenu
                        for={data.mainMenuSelector}
                        Menu={getDefaultMenu(data.mainMenuSelector)}
                        // onMenuItemClick={ContextMainMenuHandler}
                        onRightClick={() => {
                          const json = data.mainMenuRequest(
                            props.Module,
                            data.objType,
                            data.selectedRecordID,
                            props.SectionID
                          );
                          if (json && json.Items && json.Items.items) {
                            return { arr: json.Items.items };
                          }
                        }}
                        onMenuItemClick={data.ContextMenuHandler}
                        refs={{ current: data.mainGrid }}
                      >
                        <Grid
                          item
                          id={`rcgrid_panel`}
                          ref={data.gridPanel}
                          style={{
                            height: "100%",
                            width: "100%",
                            position: "relative",
                          }}
                        ></Grid>
                      </ContextMenu>
                    </div>
                    {buttons.users ? (
                      <div style={{ height: "100%" }}>
                        <Grid
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="center"
                          style={{
                            backgroundColor: "#ececec",
                            height: "100%",
                          }}
                        >
                          <Grid
                            style={{
                              width: "100%",
                              height: "27px",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                              overflow: "hidden",
                            }}
                          >
                            <div>Пользователи</div>
                          </Grid>
                          <Grid
                            style={{
                              width: "100%",
                              height: "calc(100% - 32px)",
                              backgroundColor: "#ffffff",
                              paddingTop: "5px",
                            }}
                          >
                            <Grid
                              container
                              direction="column"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              style={{ height: "100%" }}
                            >
                              <Box
                                style={{
                                  overflow: "auto",
                                  height: "100%",
                                  width: "100%",
                                }}
                              >
                                {data.users}
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    ) : null}
                  </SplitterLocal>
                </div>

                {
                  //Разделы с Деталями
                }
                {buttons.detail ? (
                  <div style={{ height: "100%" }}>
                    <Tabs
                      enableMouseWheelAction={true}
                      onChange={(ev) => {
                        if (data.updateTab && ev.detail) {
                          data.updateTab(ev.detail.index, data.tabs);
                        }
                      }}
                      ref={data.tabsPanel}
                      id={`tabs_organizations`}
                      scrollMode="paging"
                      animation="none"
                      tabPosition="bottom"
                      style={{
                        position: "relative",
                        border: "1px solid #C8B58F",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      {data.tabs}
                    </Tabs>
                  </div>
                ) : null}
              </SplitterLocal>
            </div>
          </SplitterLocal>
          {/* {Футтер с кнопками Выбор и Отмена} */}
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            style={{
              height: "42px",
              width: "100%",
              border: "1px solid #C8B58F",
              display: props.hiddenFutter ? "none" : "",
            }}
          >
            {/* {информация с количеством записей} */}
            <Grid
              item
              style={{
                margin: "8px",
                width: props.hiddenButton
                  ? "calc(100% - 89px)"
                  : "calc(100% - 188px)",
              }}
            >
              <span style={{ width: "92px" }}>
                Количество записей: {data.countRecords}
              </span>
            </Grid>
            <Grid item style={{ width: "fit-content" }}>
              {/* {Кнопка Выбрать} */}
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid style={{ marginRight: "10px", width: "75px" }}>
                  <Button
                    onClick={(ev) => {
                      data.onSelect();
                    }}
                    size="small"
                    variant="outlined"
                    style={{
                      textTransform: "none",
                      display: props.hiddenButton
                        ? "none"
                        : props.Buttons && (props.Button & 1) === 0
                        ? "none"
                        : "",
                    }}
                    value="1"
                    className="button_Modal_Select"
                  >
                    Выбрать
                  </Button>
                </Grid>
                {/* {кнопка Отмена} */}
                <Grid style={{ marginRight: "10px", width: "65px" }}>
                  <Button
                    onClick={() => {
                      data.onCancel();
                    }}
                    size="small"
                    variant="outlined"
                    style={{ textTransform: "none" }}
                    value="2"
                    className="button_Modal_Close"
                  >
                    Отмена
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {data.docFooter ? (
            <Grid
              item
              id={`footerData_${props.SectionID}`}
              className="css-11vzlf0-MuiGrid-root"
              ref={data.buttonsRef}
              style={{
                width: "100%",
                paddingLeft: "15px",
                border: "1px solid #C8B58F",
                borderTop: "0px",
                height: "21px",
              }}
            ></Grid>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}
