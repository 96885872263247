import { configureStore } from "@reduxjs/toolkit";
import SideBarSelectSlice from "./SideBarSlice";
import themeSlice from "./themeSlice";
import BackGroundProcessingSlice from "./BackGroundProcessingSlice";

export const store = configureStore({
  // Настройка скрывает предупреждения по указанным типам и путям о не сериализованных(ссылочных) данных
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // названия(типы) игнорируеммых действий, путь можно увидеть в предупреждении
        ignoredActions: ["theme/AddCallBack"],
        // конкретные вызовы не сериализованных данных по следующим путям
        ignoredPaths: ["payload.source", "theme.unloadCallbacks"],
      },
    }),

  reducer: {
    sideBar: SideBarSelectSlice,
    theme: themeSlice,
    backGrounds: BackGroundProcessingSlice,
  },
});
