import { Button, Grid } from "@mui/material";
import TestEditor from "../../../Editor/testEditor";
import React from "react";
import { AxiosRequest } from "../../../Url";
import {
  CreateBackgroundForModal,
  tokenProcessingTest,
} from "../../../TokenProcessing/TokenProcessing";
import DialogSetObjText from "./DialogSetObjText";
import ModalWindow from "../ModalWindow";
import { formEdit, getStyleWindow } from "../../Tools/Tools";
import { openModal } from "../../Tools/modalManager";

export default function DialogChildren({ props }) {
  const tableContainer = React.useRef(null);
  // массив всех записей
  const [records, setRecords] = React.useState([]);
  // выбранный эелемент
  const selected = React.useRef();

  React.useEffect(() => {
    // получение jsx записей для таблицы
    if (!records || records.length === 0) {
      if (props.PersonID)
        getChildren(props.PersonID).then((res) => {
          setRecords(getRecords(Object.values(res)));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getChildren(PersonID) {
    if (PersonID) {
      const params = new Map();
      params
        .set("prefix", "cadres")
        .set("comand", "GetPersonChildren")
        .set("PersonID", PersonID);

      const json = AxiosRequest(true, params);
      return json;
    }
  }

  async function onDropDownList(data) {
    // GET /persons~GetObjectValues?LicGUID=9EBB541E42C29AE6263EBE9D1DBE9D4A&ObjType=2040&ObjRef=0
    const params = new Map();
    params
      .set("prefix", "persons")
      .set("comand", "GetObjectValues")
      .set("ObjType", "2040")
      .set("ObjRef", data.objref);
    const json = await AxiosRequest(true, params);
    if (json && json.Items) {
      return json.Items.map((item, index) => {
        if (typeof item === "object") {
          return { label: item.text, id: item.id };
        }
        return { label: item, id: index };
      });
    }
  }

  function onEditButtonClick(data) {
    // GET /persons~GetDialogParams?LicGUID=BF553BE742A0C61C65250C9C1F7BCD1C&ObjType=2040&ObjRef=206
    if (data.json) {
      tokenProcessingTest(data.json, data.subData);
    }
  }

  function getObjText(tag) {
    const params = new Map();
    params
      .set("prefix", "persons")
      .set("comand", "GetObjectText")
      .set("Flag", "3")
      .set("ObjType", "2040")
      .set("ObjRef", tag);
    const json = AxiosRequest(true, params);
    return json;
  }

  function setObjText(value) {
    const params = new Map();
    params
      .set("prefix", "persons")
      .set("comand", "SetObjectText")
      .set("Flag", "-1")
      .set("ObjType", "2040")
      .set("Text", value);
    const json = AxiosRequest(true, params);
    return json;
  }

  async function onEdit(data) {
    if (data.proof) {
      const checkAnswer = await setObjText(data.value);
      if (checkAnswer) {
        if (checkAnswer.Selection && checkAnswer.Selection.length !== 0) {
          openModal(
            <DialogSetObjText
              list={checkAnswer.Selection}
              data={data}
              onEdit={onEdit}
            />,
            {
              Title: "Выберите элемент из списка",
              style: getStyleWindow(),
              hiddenButton: true,
              blockMaximize: true,
            }
          );
          return;
        }

        const json = await getObjText(checkAnswer.ObjRef);
        if (json && json.Text) {
          if (data.setInputValue && data.setValue) {
            data.setValue({ label: json.Text, id: checkAnswer.ObjRef });
            data.setInputValue(json.Text);
          }
          selected.current = null;
          getChildren(checkAnswer.ObjRef).then((res) => {
            setRecords(getRecords(Object.values(res)));
          });
        } else {
          setRecords(null);
        }
      }
    } else {
      // GET /persons~SetObjectText?LicGUID=9EBB541E42C29AE6263EBE9D1DBE9D4A&Flag=-1&ObjType=2040&ObjRef=106&Text=сорока
      // GET /persons~GetObjectText?LicGUID=BF553BE742A0C61C65250C9C1F7BCD1C&Flag=3&ObjType=2040&ObjRef=206
      const json = await getObjText(data.tag);
      if (json && json.Text) {
        if (data.setInputValue && data.setValue) {
          data.setValue({ label: json.Text, id: data.tag });
          data.setInputValue(json.Text);
        }
        selected.current = null;
        getChildren(data.tag).then((res) => {
          setRecords(getRecords(Object.values(res)));
        });
      } else {
        setRecords(null);
      }
    }
  }

  function getRecords(array) {
    if (array && array.length) {
      return array.map((item, id) => {
        const record = (
          <tr
            ref={item.id === props.ObjRef ? selected : null}
            onDoubleClick={(ev) => {
              selected.current = ev.target;
              if (props.onEdit && selected.current) {
                props.onEdit({
                  value: selected.current.innerText,
                  tag: selected.current.id.split("_")[1],
                  name: props.Name,
                  record: props.record,
                  textchanged: "0",
                  setValue: props.setValue,
                  setInputValue: props.setInputValue,
                  type: props.Module,
                  addInfo: props.addInfo,
                });
              }
              ev.target.closest("SMART-WINDOW").parentElement.remove();
            }}
            onClick={(ev) => {
              // на клик по записи она помечается как выбранная
              if (selected.current) selected.current.style.backgroundColor = "";
              selected.current = ev.target;
              selected.current.style.backgroundColor = "#ede2d3";
            }}
            onMouseEnter={(ev) => {
              if (selected.current === ev.target) return;
              ev.target.style.backgroundColor = "#eeeeee";
            }}
            onMouseLeave={(ev) => {
              if (selected.current === ev.target) return;
              ev.target.style.backgroundColor = "";
            }}
            key={`child_${item.id}`}
            style={{
              cursor: "default",
              backgroundColor: item.id === props.ObjRef ? "#ede2d3" : "",
            }}
            text={item.text}
          >
            <td
              id={`td_${item.id}`}
              style={{ borderBottom: "1px solid #cccccc", height: "27px" }}
              num={id}
            >
              {item.text}
            </td>
          </tr>
        );
        return record;
      });
    }
    return [];
  }

  async function onSelect() {
    if (props.onEdit && selected.current) {
      props.onEdit({
        value: selected.current.innerText,
        tag: selected.current.id.split("_")[1],
        name: props.Name,
        record: props.record,
        textchanged: "0",
        setValue: props.setValue,
        setInputValue: props.setInputValue,
        type: props.Module,
        requestId: props.RequestID,
        addInfo: props.addInfo,
      });
    }

    if (props.RequestID) {
      await formEdit(
        "1",
        props.func,
        { Name: "ObjRef", Value: selected.current.id.split("_")[1] },
        props.RequestID,
        props.from
      );
    }
  }

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ height: "calc(100% - 50px)", userSelect: "none" }}
      >
        <div
          style={{
            position: "absolute",
            top: "48px",
            left: "40px",
            backgroundColor: "#ffffff",
            height: "20px",
            color: "#cccccc",
            fontStyle: "italic",
          }}
        >
          Сотрудник
        </div>
        <div
          style={{
            position: "absolute",
            top: "105px",
            left: "40px",
            backgroundColor: "#ffffff",
            height: "20px",
            color: "#cccccc",
            fontStyle: "italic",
          }}
        >
          Дети сотрудника
        </div>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{
            height: "47px",
            marginBottom: "10px",
            border: "1px solid #eeeeee",
            borderRadius: "5px",
            width: "calc(100% - 1px)",
          }}
        >
          <Grid
            item
            style={{
              width: "calc(100% - 40px)",
              height: "27px",
              border: "1px solid #cccccc",
            }}
          >
            <TestEditor
              objref={props.PersonID}
              value={props.parent}
              placeholder="Сотрудник"
              editStyle={17}
              type={"persons"}
              datatype={"2040"}
              onDropDownList={onDropDownList}
              onEditButtonClick={onEditButtonClick}
              onEdit={onEdit}
            />
          </Grid>
        </Grid>

        <Grid
          item
          style={{
            width: "calc(100% - 1px)",
            height: "calc(100% - 57px)",
            border: "1px solid #eeeeee",
            borderRadius: "5px",
            padding: "20px",
          }}
          ref={tableContainer}
        >
          <div
            style={{
              border: "1px solid #cccccc",
              borderRadius: "5px",
              height: "100%",
              overflow: "auto",
            }}
          >
            <table
              style={{
                border: "0",
              }}
              cellSpacing={"1"}
              width={"100%"}
              cols={"1"}
              table-layout={"auto"}
              onMouseUp={(ev) => {
                if (ev.target.tagName === "TABLE") return;
                if (selected.current)
                  selected.current.style.backgroundColor = "";
                selected.current = ev.target;
                selected.current.style.backgroundColor = "#ede2d3";
              }}
            >
              <tbody>{records}</tbody>
            </table>
          </div>
        </Grid>
      </Grid>
      <Grid
        item
        style={{
          width: "100%",
          height: "40px",
          marginTop: "5px",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ paddingTop: "8px" }}
        >
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none", marginRight: "10px" }}
            value="1"
            className="button_Modal_Select"
            onClick={onSelect}
          >
            Выбрать
          </Button>
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none" }}
            value="2"
            className="button_Modal_Close"
          >
            Отмена
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
