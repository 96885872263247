import {
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React from "react";
import { ImgURL } from "../../../Url";
import { getIcons, getIconsAsync } from "../../Tools/Tools";

export default function StatusButtonCardMode({
  icon,
  handleStatuses,
  changeStatus,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [statuses, setStatuses] = React.useState([]);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const MenuItems = (json) => {
    return json.map((item, index) => {
      const Disabled = false,
        Caption = item.Caption,
        Image = item.Image,
        Status = item.Status;
      return (
        <MenuItem
          onClick={(e) => {
            handleClose();
            changeStatus(Status);
          }}
          style={{ height: "25px" }}
          key={"ButtonsMenuStatus" + index}
        >
          <ListItemIcon>{ImgURL(Image)}</ListItemIcon>
          <ListItemText>{Caption}</ListItemText>
        </MenuItem>
      );
    });
  };

  return (
    <div style={{ display: "grid", justifyContent: "center" }}>
      <IconButton
        sx={{width:"42px"}}
        onClick={(e) => {
          handleStatuses().then((res) => {
            setStatuses(res);
            setAnchorEl(e.target);
          });
        }}
      >
        {ImgURL(getIcons(icon), { width: "24px" })}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Grid
          container
          direction="row"
          вс
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid
            item
            style={{
              backgroundColor: "#e6e6e6",
              position: "absolute",
              height: "100%",
              width: "46px",
            }}
          />
          <Grid item>{MenuItems(statuses)}</Grid>
        </Grid>
      </Menu>
    </div>
  );
}
