import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';

function getMuiTheme(theme) {
  let muiTheme;
  switch (theme) {
    case "light":
      muiTheme = createTheme({});
      break;
    case "blue":
      muiTheme = createTheme({
        components: {
          MuiBadge: {
            styleOverrides: {
              badge: {
                backgroundColor: "#9c27b0",
              },
            },
          },
        },
      });
      break;
    default:
      muiTheme = createTheme({})
      break;
  }
  return muiTheme;
}

export const ThemeProvider = ({ children }) => {
  const theme = useSelector((state) => state.theme.theme);
  const muiTheme = getMuiTheme(theme);

  return (
    <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
  );
};
