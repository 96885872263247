export function getLabelKeyCode(code) {
  if (typeof code === "object") {
    return code
      .map((cod) => {
        return getLabelKeyCode(cod);
      })
      .join(" + ");
  }
  const data = {
    0: "",
    1: "ЛКМ",
    2: "ПКМ",
    4: "СКМ",
    112: "F1",
    113: "F2",
    114: "F3",
    115: "F4",
    116: "F5",
    117: "F6",
    118: "F7",
    119: "F8",
    120: "F9",
    121: "F10",
    122: "F11",
    123: "F12",
    32: "Пробел",
    8: "BackSpace",
    9: "Tab",
    13: "Enter",
    16: "Shift",
    17: "Ctrl",
    18: "Alt",
    20: "CapsLock",
    27: "Esc",
    45: "Insert",
    33: "PageUp",
    34: "PageDown",
    35: "End",
    36: "Home",
    37: "arrow <",
    38: "arrow ^",
    39: "arrow >",
    40: "arrow v",
    46: "Delete",
    44: "PrintScreen",
    145: "ScrollLock",
    48: "0",
    49: "1",
    50: "2",
    51: "3",
    52: "4",
    53: "5",
    54: "6",
    55: "7",
    56: "8",
    57: "9",
    192: "`",
    189: "-",
    187: "=",
    219: "[",
    221: "]",
    186: ";",
    222: "'",
    220: "\\",
    188: ",",
    190: ".",
    191: "/",
    65: "A",
    66: "B",
    67: "C",
    68: "D",
    69: "E",
    70: "F",
    71: "G",
    72: "H",
    73: "I",
    74: "J",
    75: "K",
    76: "L",
    77: "M",
    78: "N",
    79: "O",
    80: "P",
    81: "Q",
    82: "R",
    83: "S",
    84: "T",
    85: "U",
    86: "V",
    87: "W",
    88: "X",
    89: "Y",
    90: "Z",
    91: "WinL",
    92: "WinR",
    96: "Num0",
    97: "Num1",
    98: "Num2",
    99: "Num3",
    100: "Num4",
    101: "Num5",
    102: "Num6",
    103: "Num7",
    104: "Num8",
    105: "Num9",
    106: "Num*",
    107: "Num+",
    108: "Num-",
    109: "Num.",
    110: "Num/",
  };

  return data[code];
}
