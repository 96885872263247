// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.showButtonDiv{
    display: "";
}
.hideButtonDiv{
    display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/MainPage/Module/Dialogs/Components/ButtonsDivForDocs.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,wBAAwB;AAC5B","sourcesContent":[".showButtonDiv{\r\n    display: \"\";\r\n}\r\n.hideButtonDiv{\r\n    display: none !important;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
