import React, { useRef } from "react";
import { Tabs } from "smart-webcomponents-react/tabs";
import unpinned from "./../../../../../static/images/unpinned.png";
import pinned from "./../../../../../static/images/pinned.png";
import {
  ClickInFrame,
  InsertIdReport,
  isEmptyObject,
} from "../../../Tools/Tools";
import CloseIcon from "@mui/icons-material/Close";
import { AxiosRequest, XMLrequest } from "../../../../Url";
import ReactDOM from "react-dom";
import { tokenProcessingTest } from "../../../../TokenProcessing/TokenProcessing";
import { IconButton } from "@mui/material";
import ContextMenu from "../../../NotWorkArea(Side&Head)/ContextMenu";

export default function TabsForReports(props) {
  const TabsRef = useRef(null);
  const ContextMenuFunc = useRef(null);

  React.useEffect(() => {
    try {
      if (!isEmptyObject(props.reportData)) {
        PaperFormProcessing(props.reportData);
        if (setTabPosition() === "hidden") {
          let header =
            TabsRef.current.nativeElement.querySelector(".smart-header");
          if (header) header.style.display = "none";
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [props.reportData]);

  function AddButtonsAndChangeStyles(viewIdent) {
    let tabs, tabItems, Fixed, Buttons, childrens;
    tabs = TabsRef.current.nativeElement;
    if (tabs) {
      tabItems = tabs.getTabs();
      for (const [key, value] of Object.entries(tabs["_tabLabelContainers"])) {
        Fixed = props.reportData.Items[key].Fixed;
        Fixed = Fixed === "1" ? true : false;
        let idForBttn =
          props.reportData.ViewIdent +
          "." +
          props.reportData.Items[key].ViewIdent;
        childrens = value.firstChild.children;
        Buttons = Number(props.reportData.Items[key].Buttons);
        const closeButtonId = viewIdent + ",CloseButton" + key;
        if (
          (CheckChilder(childrens, closeButtonId) && Buttons === 3) ||
          Buttons === 1
        ) {
          //кнопки закрытия
          let CloseButtonContainer = document.createElement("div"); // создаем контейнер для кнопки
          let localId =
            props.reportData.ViewIdent +
            "." +
            props.reportData.Items[key].ViewIdent +
            ",CloseButton" +
            key;
          CloseButtonContainer.style.height = "10px";
          CloseButtonContainer.style.width = "10px";
          CloseButtonContainer.style.position = "absolute";
          CloseButtonContainer.style.right = "4.5%";
          CloseButtonContainer.style.top = "26.5%";
          CloseButtonContainer.viewIdent = localId;
          value.firstChild.appendChild(CloseButtonContainer);
          value.firstChild.children["0"].style.marginRight = "10px";
          ReactDOM.render(
            <IconButton
              id={idForBttn + "," + key}
              style={{ width: 10, height: 10, fontSize: "small" }}
              onClick={CustomClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>,
            CloseButtonContainer
          );
        }
        const PinButtonId = viewIdent + ",ButtonFixUp" + key;
        if (
          (CheckChilder(childrens, PinButtonId) && Buttons === 3) ||
          Buttons === 2
        ) {
          //кнопки для пинов
          let PinButtonContainer = document.createElement("div"); // создаем контейнер для кнопки
          let localId = viewIdent + ",ButtonFixUp" + key;
          PinButtonContainer.style.height = "10px";
          PinButtonContainer.style.width = "10px";
          PinButtonContainer.style.position = "absolute";
          PinButtonContainer.style.left = "3.2%";
          PinButtonContainer.style.top = "22%";
          PinButtonContainer.viewIdent = localId;
          value.firstChild.appendChild(PinButtonContainer);
          value.firstChild.children["0"].style.marginLeft = "17px";

          ReactDOM.render(
            <IconButton
              id={idForBttn + "," + Fixed + "," + key}
              style={{ width: 10, height: 10, fontSize: "small" }}
              onClick={FixUpTabPage}
            >
              {Fixed === true ? (
                <img src={pinned} style={{ width: "19px" }} />
              ) : (
                <img src={unpinned} style={{ width: "19px" }} />
              )}
            </IconButton>,
            PinButtonContainer
          );
        } else if (!document.getElementById(viewIdent + "," + key)) {
          let DataContainer = document.createElement("div");
          DataContainer.style.display = "none";
          DataContainer.id = viewIdent + "," + key;
          let Data = document.createElement("div");
          Data.id = viewIdent;
          DataContainer.appendChild(Data);
          value.firstChild.appendChild(DataContainer);
        }
      }
    }
  }

  function CustomClose(event) {
    let arrEvent = event.currentTarget.getAttribute("id").split(",");
    let tabs;
    tabs = TabsRef.current.nativeElement;
    handleClosing(arrEvent["0"]);
    tabs.removeAt(Number(arrEvent["1"]));
    onCloseTab(Number(arrEvent["1"]));
  }

  function onCloseTab(index) {
    let tabContent;
    if (index > 0) {
      if (tabContent === undefined || tabContent === "undefined") {
        OnTabIndexChangeForm({ detail: { index: index - 1 } });
      }
    }
  }

  async function handleClosing(ViewIdent) {
    let params = new Map(),
      tabs,
      tabsItem;
    tabs = TabsRef.current.nativeElement; // получаем блок вкладок
    tabsItem = tabs.getTabs();
    params.set("prefix", "reptabs");
    params.set("comand", "CloseTabPage");
    params.set("ViewIdent", ViewIdent);
    await AxiosRequest(true, params);
  }

  async function FixUpTabPage(event) {
    let params = new Map();
    let arrEvent = event.currentTarget.getAttribute("id").split(",");
    let TrueOrFalse = arrEvent[1] === "true" ? true : false;
    let ViewIdent = arrEvent[0];
    const rerenderBlock = event.currentTarget.parentElement;
    const key = arrEvent[2];
    TrueOrFalse = !TrueOrFalse;
    params.set("prefix", "reptabs");
    params.set("comand", "FixupTabPage");
    params.set("Fixed", TrueOrFalse ? "1" : "0");
    params.set("ViewIdent", ViewIdent);
    await AxiosRequest(true, params);
    ReactDOM.render(
      <IconButton
        id={ViewIdent + "," + `${TrueOrFalse}` + "," + key}
        style={{ width: 10, height: 10, fontSize: "small" }}
        onClick={FixUpTabPage}
      >
        {TrueOrFalse === true ? (
          <img src={pinned} style={{ width: "22px" }} />
        ) : (
          <img src={unpinned} style={{ width: "22px" }} />
        )}
      </IconButton>,
      rerenderBlock
    );
  }

  function CheckChilder(children, id) {
    let valueAny;
    for (const [key, value] of Object.entries(children)) {
      valueAny = value;
      if (valueAny.id === id) {
        return false;
      }
    }
    return true;
  }

  function reportsHandleToolButton(event) {
    //reports~HandleToolButton?LicGUID=B921C12049AC58E14F039A983633FE8E&ID=117&ReportID=453&SectionID=108&ViewIdent=Report453-Section108&WSM=1
    let params = new Map();
    let ID, ReportID, SectionID, ViewIdent;
    let e = event.currentTarget.getAttribute("id").split("-");
    ID = e[0];
    ReportID = e[1];
    SectionID = e[2];
    ViewIdent = `Report${ReportID}-Section${SectionID}`;
    params.set("prefix", "reports");
    params.set("comand", "HandleToolButton");
    params.set("ID", ID);
    params.set("ReportID", ReportID);
    params.set("SectionID", SectionID);
    params.set("ViewIdent", ViewIdent);
    params.set("WSM", "1");
    AxiosRequest(true, params).then((res) => {
      tokenProcessingTest(res.data);
    });
  }

  function PaperFormProcessing(form, key, viewIdent) {
    const tabs = TabsRef.current.nativeElement;
    if (key === undefined) {
      RemoveTabs(tabs);
    }
    let tabContent = tabs.getTabContent(key === undefined ? 0 : key);
    switch (form.CLSID) {
      case "{18CCCA1A-CD3D-41B3-8C20-9F80AA3ED8CE}": //групповые
        for (const [key, value] of Object.entries(form.Items)) {
          const div = document.createElement("div");
          const LabelDiv = document.createElement("div");
          LabelDiv.textContent = value.Title;
          if (value.Notes) LabelDiv.title = value.Notes;
          div.viewIdent = form.ViewIdent + "." + value.ViewIdent;
          if (tabs["_tabs"][key] === undefined) {
            tabs.insert(Number(key), {
              label: LabelDiv.outerHTML,
              content:
                value.content || value.CLSID
                  ? BasedOnTabContent(
                      value,
                      Number(key),
                      form.ViewIdent + "." + value.ViewIdent
                    )
                  : div,
            });
          } else {
            tabs.update(
              Number(key),
              LabelDiv.outerHTML,
              value.content || value.CLSID
                ? BasedOnTabContent(
                    value,
                    Number(key),
                    form.ViewIdent + "." + value.ViewIdent
                  )
                : div
            );
          }
        }
        if (form.TabIndex) {
          tabs.select(Number(form.TabIndex));
        }
        AddButtonsAndChangeStyles(form.ViewIdent);
        break;
      default:
        if (tabContent === null) {
          tabs.insert(key === undefined ? 0 : key, {
            label: form.Title,
            content: BasedOnTabContent(form, key, viewIdent),
          });
        } else {
          tabs.update(
            key === undefined ? 0 : key,
            form.Title,
            BasedOnTabContent(form, key, viewIdent)
          );
        }
    }
  }

  function BasedOnTabContent(form, key, viewIdent) {
    switch (form.CLSID) {
      case "{55D200F8-A5EE-4BB8-B9AD-762B6FB815D1}": //простой отчёт
        return CreateFormFrame(
          form.content,
          viewIdent === undefined ? form.ViewIdent : viewIdent,
          key === undefined ? 0 : key
        );
      case "{A5CDFCEB-A95A-4ABC-839E-77D1D1F5CD86}": //PDF
        const PdfFrame = InsertIdReport(
          "data:application/pdf;base64," + form.RCDATA,
          true
        );
        PdfFrame.index = key === undefined ? 0 : key;
        PdfFrame.viewIdent =
          viewIdent === undefined ? form.ViewIdent : viewIdent;
        return PdfFrame;
      default:
        return CreateFormFrame(
          form.content,
          viewIdent === undefined ? form.ViewIdent : viewIdent,
          key === undefined ? 0 : key
        );
    }
  }

  function RemoveTabs(tabs) {
    for (let i = 0; i <= tabs["_tabs"].length - 1; i++) {
      if (i !== 0) {
        tabs.removeAt(i);
      }
    }
  }

  function OnTabIndexChangeForm(event) {
    const tabs = TabsRef.current.nativeElement;
    const tabContent = tabs.getTabContent(event.detail.index);
    let params = new Map();
    params.set("prefix", "reptabs");
    params.set("comand", "PageChanged");
    params.set("ViewIdent", tabContent.viewIdent);
    AxiosRequest(false, params).then(async (resp) => {
      if (resp.NeedRefresh === "1") {
        await props.onTabIndexChange(undefined, true);
      }
      if (props.onViewIdentChange) {
        props.onViewIdentChange(tabContent.viewIdent);
      }
      if (tabContent.tagName !== "IFRAME") {
        params.set("comand", "GetPageContent");
        params.set("HTML", 0);
        AxiosRequest(true, params).then((res) => {
          if (!isEmptyObject(res)) {
            PaperFormProcessing(res, event.detail.index, tabContent.viewIdent);
          }
        });
      }
    });
  }

  function CreateFormFrame(content, viewIdent, index) {
    const FormFrame = InsertIdReport(content);
    FormFrame.viewIdent = viewIdent;
    FormFrame.index = index;
    FormFrame.onload = function () {
      ClickInFrame(viewIdent, FormFrame, { ID: props.ID });
      FormFrame.contentDocument.oncontextmenu = function (e) {
        e.preventDefault();
        const DOMRect = FormFrame.getBoundingClientRect();
        ContextMenuFunc.current({
          clientX: e.clientX + DOMRect.x,
          clientY: e.clientY + DOMRect.y,
          preventDefault: function () {
            e.preventDefault();
          },
        });
      };
    };
    return FormFrame;
  }

  function setTabPosition() {
    if (!isEmptyObject(props.reportData)) {
      if (props.reportData.Items) {
        return "bottom";
      } else {
        return "hidden";
      }
    } else {
      return "hidden";
    }
  }

  function contextMenuData() {
    let params = new Map();
    params.set("prefix", "cellobj");
    params.set("comand", "GetDocumentMenu");
    params.set("CLSID", "{408E20A3-4BE3-4DCD-98BD-2613A8968783}");
    return AxiosRequest(true, params);
  }

  function ExecMenuHandler(data) {
    let params = new Map();
    const tabs = TabsRef.current.nativeElement;
    const viewIdent = tabs
      .getTabContent(tabs.getAttribute("selected-index"))
      .viewIdent.split(".");
    params.set("prefix", "cellobj");
    params.set("comand", "ExecMenuHandler");
    params.set("ProgID", data.ID);
    params.set("ViewIdent", viewIdent.length > 1 ? viewIdent[1] : viewIdent[0]);
    params.set("WSM", "1");
    AxiosRequest(true, params).then((res) => {
      tokenProcessingTest(res);
    });
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {props.reportData.NoContextMenu ? (
        <Tabs
          scrollMode="paging"
          animation="none"
          tabPosition={setTabPosition()}
          ref={TabsRef}
          onChange={OnTabIndexChangeForm}
          style={{
            // position: "absolute",
            borderBottom: "0px",
            height: "calc(100% - 0px)",
            width: "100%",
          }}
        ></Tabs>
      ) : (
        <ContextMenu
          for={"reports"}
          onRightClick={contextMenuData}
          getContextMenuFunction={(func) => {
            ContextMenuFunc.current = func;
          }}
          onMenuItemClick={ExecMenuHandler}
        >
          <Tabs
            scrollMode="paging"
            animation="none"
            tabPosition={setTabPosition()}
            ref={TabsRef}
            onChange={OnTabIndexChangeForm}
            style={{
              // position: "absolute",
              borderBottom: "0px",
              height: "calc(100% - 0px)",
              width: "100%",
            }}
          ></Tabs>
        </ContextMenu>
      )}
    </div>
  );
}
