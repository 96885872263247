import React from "react";
import LoadingMask from "../../../../NotWorkArea(Side&Head)/LoadingMask";
import TestParams from "../../../../Sections/ElementsSections/testParams";

export default function ParamsForTabs({ props }) {
  const [data, setData] = React.useState();

  React.useEffect(() => {
    if (props.onRequest) {
      props.onRequest(props).then((res) => {
        setData({ ...res, GroupID: props.selectedRecordID });
      });
    } else {
      setData({ ...props.data, GroupID: props.selectedRecordID });
    }
  }, []);

  return (
    <div style={{ height: "100%" }}>
      {data ? (
        <TestParams
          Module={props.Module}
          reqCommand={props.detailItem.Token}
          GroupID={props.selectedRecordID}
          Path={props.Path}
          data={data}
          NoHistory={true}
          NoTitle={true}
          NoClearState={true}
          NoSaveSample={true}
        />
      ) : (
        <LoadingMask />
      )}
    </div>
  );
}
