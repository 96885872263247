import { Checkbox } from "@mui/material";
import React from "react";
import SquareIcon from "@mui/icons-material/Square";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import cn from "classnames";
import useTheme from "../../../../Hooks/useTheme";

// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import HeightIcon from "@mui/icons-material/Height";
// import CircleIcon from "@mui/icons-material/Circle";
// import SquareIcon from "@mui/icons-material/Square";
// import ClearIcon from "@mui/icons-material/Clear";
export default function CheckBoxPeriod(props) {
  const theme = useTheme();

  // Иконки состояний
  const Unchecked = <></>;
  const Checked = (
    <CheckOutlinedIcon
      className={cn("fontColorStimate", { light: theme === "light" })}
    />
  );

  const Required = <SquareIcon />;

  // функция получения иконки по целочисленному состоянию
  function getIcon(checkstate) {
    const icons = {
      0: Unchecked,
      1: Checked,
      2: Required,
    };
    return icons[checkstate];
  }

  // функция получения следующего состояния из массива состояний
  // аргументы : 1. массив с целочисленными состояниям, 2. текущее целочисленное состояние
  function getNextState(arr, state) {
    if (state === 2) return arr[1];
    const length = arr.length;
    const index = arr.indexOf(state);

    return index < length - 1 ? arr[index + 1] : arr[0];
  }

  // Иконка чекбокса
  const [icon, setIcon] = React.useState(Unchecked);
  // захардкоженое состояние чекбокса блокирующее состояние атрибута checked
  const [checked, setChecked] = React.useState(false);
  // текущее состояние чекбокса
  const [stateCheckBox, setStateCheckBox] = React.useState();
  // массив состояний, в котором хранятся допустимые состояния
  const checkStates = React.useRef();

  React.useEffect(() => {
    setChecked(false);
    // установка массива состояний
    checkStates.current = [0, 1];
    // получение текущего состояния
    setStateCheckBox(props.CheckState ? Number(props.CheckState) : 0);
    if (props.checkRef) props.checkRef.current = setStateCheckBox;
  }, []);

  React.useEffect(() => {
    // при изменении текущего состояния обновляется иконка чекбокса
    if (stateCheckBox !== undefined) {
      setIcon(getIcon(stateCheckBox));
    }
  }, [stateCheckBox]);

  React.useEffect(() => {
    setStateCheckBox(props.CheckState ? Number(props.CheckState) : 0);
  }, [props.CheckState]);

  return (
    <Checkbox
      // иконка чекбокса
      icon={icon}
      checkedIcon={icon}
      checked={checked}
      onChange={(ev) => {
        // получение следуйщего состояния
        let state = getNextState(checkStates.current, stateCheckBox);
        setStateCheckBox(state);
        if (props.onChange) {
          props.onChange({
            ev: ev,
            setCheck: setStateCheckBox,
            checked: state === 1 ? true : false,
          });
        }
        // функция на редактировние обновит состояние чекбокса из ответа внутри функции
      }}
      sx={{
        "& .MuiSvgIcon-root": { fontSize: 18 },
      }}
      style={{
        width: "15px",
        height: "15px",
        marginRight: "5px",
        marginLeft: "5px",
        borderRadius: "4px",
        border: "2px solid #aaaaaa",
        backgroundColor: "#f4f4f4",
      }}
      type="checkbox"
    />
  );
}
