import cn from "classnames";
import React, { forwardRef, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { store } from "../../../store";

const SplitterItem = forwardRef((props, ref) => {
  // const animationsOn = useSelector((state) => state.theme.animations);
  const animationsOn = store.getState().theme.animations;
  let styles = {};
  if (props.oneChild) {
    styles = { height: "100%", width: "100%" };
  }
  else {
    props.orientation === "vertical" ? styles.height = "100%" : styles.width = "100%";
    if (props.orientation === "horizontal") {
      styles.height = "100%"
    }
    styles.display = "flex"
  }
  if (!props.primary) {
    styles.flex = "0 0 auto";
    if (animationsOn) {
      props.orientation === "vertical"
        ? styles.transition = `width ${props.transitionDuration}ms`
        : styles.transition = `height ${props.transitionDuration}ms`;
    }
  }
  return (
    <div
      className="splitter-item"
      style={styles}
      ref={ref}
    >
      {props.children}
    </div>
  )
});

/**
 * @param {Object} [props]
 * @param {string} [props.id] - id который будет присвоен элементу где хранятся children и разделитель. В нем же хранятся функции collapse и expand.
 * @param {React.CSSProperties} [props.style]
 * @param {string} [props.orientation] - Ориентация сплиттера vertical | horizontal.
 * @param {number} [props.primaryIndex] - Индекс элемента который не коллапсится.
 * @param {string} [props.sizeSecondary] - Размер элемента который коллапсится. По умолчанию 25%.
 * @param {boolean} [props.collapsed] - Значение открытый или закрытый сплиттер. Используется при контролируемом компоненте.
 * @param {boolean} [props.defaultCollapsed] - Значение открытый или закрытый сплиттер. Используется при неконтролируемом компоненте.
 * @param {Function} [props.onResizeEnd] - Функция, которая будет вызвана после изменений размеров элемента. 
 * @param {Function} [props.onCollapse] - Функция, которая будет вызвана после закрытия сплиттера. При контролируемом компоненте будет вызвана без закрытия.
 * @param {Function} [props.onExpand] - Функция, которая будет вызвана после распахивания сплиттера. При контролируемом компоненте будет вызвана без распахивания.
 * @param {number} [props.transitionDuration] - Время анимации в ms.
 * @param {boolean} [props.blockResize] - Блокировка изменений размеров пользователем.
 * @param {boolean} [props.hideButtons] - Скрытие кнопок collapse и expand.
 * @param {number} [props.maxSize] - Максимальный размер изменяемого элемента.
 * @param {number} [props.minSize] - Минимальный размер изменяемого элемента.
 * @param {boolean} [props.hideSplitter] - Скрыть разделитель.
 * @param {boolean} [props.hideSplitterCollapsed] - Скрыть разделитель когда сплиттер свернут.
 * @param {any} [props.children]
 * @param {any} [props.splitterRef] - ref к которому присвоится ссылка на сплиттер.
 */

export default function SplitterLocal(props) {
  // const theme = useSelector((state) => state.theme.theme);
  const theme = store.getState().theme.theme;
  //Ссылка на сплиттер и его элементы
  const splitterRef = useRef();
  //Ссылка на разделитель
  const separatorRef = useRef();
  //Ссылка на элемент у которого меняются размеры
  const mutableElementRef = useRef();
  //Ссылка на движущийся разделитель при перетаскивании
  const splitterFeedbackRef = useRef(null);
  //Ссылка на задний фон при перетаскивании разделителя
  const splitterBackgroundFeedbackRef = useRef(null);
  //Состояние коллапса
  const collapsedRef = useRef();
  //Время анимации ресайза и коллапса
  const transitionDuration = props.transitionDuration || 500;
  //Ограничения движущегося разделителя
  let confinesFeedback = [0, 0];
  //Размеры контейнера где находится сплиттер
  const containerSize = useRef({ width: 0, height: 0 });

  //обновление размеров если при рендарах появились новые child
  useEffect(() => {
    if (props.children.filter(item => item).length > 1 & splitterRef.current.getAttribute("size") === "100%") {
      let size = props.sizeSecondary ?? "25%";
      splitterRef.current.setAttribute("size", size);
      let currentSize;
      props.orientation === "vertical"
        ? currentSize = mutableElementRef.current.style.width
        : currentSize = mutableElementRef.current.style.height;
      if (!currentSize) setSize(size);
    }
  }, [props.children])

  function handleResize() {
    var currentWidth = splitterRef.current.parentElement.offsetWidth;
    var currentHeight = splitterRef.current.parentElement.offsetHeight;
    if (containerSize.current.width !== currentWidth || containerSize.current.height !== currentHeight) {
      var changeRatio;
      var currentSize = splitterRef.current?.getAttribute("size") || "";
      if (currentSize.endsWith("px")) {
        if (props.orientation === "vertical") {
          changeRatio = currentWidth / containerSize.current.width
          var newWidth = Math.round(parseFloat(currentSize) * changeRatio) + "px";
          splitterRef.current.setAttribute("size", newWidth);
          if (!separatorRef.current.hasAttribute("item-collapsed")) setSize(newWidth);
        } else {
          changeRatio = currentHeight / containerSize.current.height
          var newHeight = Math.round(parseFloat(currentSize) * changeRatio) + "px";
          splitterRef.current.setAttribute("size", newHeight);
          if (!separatorRef.current.hasAttribute("item-collapsed")) setSize(newHeight);
        }
      }
      containerSize.current = { width: currentWidth, height: currentHeight };
      if (props.onResizeEnd) {
        if (store.getState().theme.animations) {
          setTimeout(() => {
            props.onResizeEnd(splitterRef.current);
          }, transitionDuration + 1);
        } else {
          props.onResizeEnd(splitterRef.current);
        }
      }
    }
  }

  //первичная установка размеров и функций
  useEffect(() => {
    splitterRef.current.resize = handleResize;
    containerSize.current = { width: splitterRef.current.parentElement.offsetWidth, height: splitterRef.current.parentElement.offsetHeight };
    if (mutableElementRef.current) {
      splitterRef.current.collapse = collapse;
      splitterRef.current.expand = expand;
      let size;
      props.children.filter(item => item).length === 1
        ? size = "100%"
        : size = props.sizeSecondary ?? "25%";
      splitterRef.current.setAttribute("size", size);
    }
  }, []);

  //обновление состояния коллапса
  useEffect(() => {
    if (mutableElementRef.current && props.children.filter(item => item).length > 1) {
      if (props.collapsed !== undefined) {
        props.collapsed ? collapse(false) : expand(false);
      } else props.defaultCollapsed ? collapse(false) : expand(false);
    }
  }, [props.collapsed])

  //обновление состояния блокировки ресайза
  useEffect(() => {
    if (mutableElementRef.current && separatorRef.current) {
      props.blockResize === true
        ? separatorRef.current.setAttribute("block-resize", "")
        : separatorRef.current.removeAttribute("block-resize");
    }
  }, [props.blockResize])

  //функция передвижения разделителя для тачскрин
  function handleTouchMove(event) {
    moveSplitterFeedback(event.changedTouches[0]);
  }

  //функция установки размеров изменяемого элемента
  function setSize(size) {
    props.orientation === "vertical"
      ? mutableElementRef.current.style.width = size
      : mutableElementRef.current.style.height = size;
  }

  //функция ограничения передвижения разделителя
  function getPositionFeedback(current) {
    let minimum = confinesFeedback[0];
    let maximum = confinesFeedback[1];
    if (current <= minimum) {
      splitterFeedbackRef.current.classList.add("limit-reached");
      return minimum;
    }
    if (current >= maximum) {
      splitterFeedbackRef.current.classList.add("limit-reached");
      return maximum;
    }
    splitterFeedbackRef.current.classList.remove("limit-reached");
    return current;
  }

  //функция передвижения разделителя
  function moveSplitterFeedback(event) {
    let splitterRect = splitterRef.current.getBoundingClientRect();
    if (props.orientation === "vertical") {
      splitterFeedbackRef.current.style.left = getPositionFeedback(event.clientX - splitterRect.x) + "px";
    } else {
      splitterFeedbackRef.current.style.top = getPositionFeedback(event.clientY + splitterRef.current.offsetTop - splitterRect.y) + "px";
    }
  }

  //функция конца передвижения разделителя
  function endResize(event) {
    let currentSize;
    if (props.primaryIndex === 1) {
      props.orientation === "vertical"
        ? currentSize = splitterFeedbackRef.current.offsetLeft - splitterRef.current.offsetLeft
        : currentSize = splitterFeedbackRef.current.offsetTop - splitterRef.current.offsetTop;
    } else {
      props.orientation === "vertical"
        ? currentSize = splitterRef.current.offsetWidth - splitterFeedbackRef.current.offsetLeft - separatorRef.current.offsetWidth + splitterRef.current.offsetLeft
        : currentSize = splitterRef.current.offsetHeight - splitterFeedbackRef.current.offsetTop - separatorRef.current.offsetHeight + splitterRef.current.offsetTop;
    }
    currentSize = Math.max(currentSize, 0);
    if (!props.minSize && !props.maxSize && currentSize > 0 && splitterFeedbackRef.current.classList.contains("limit-reached")) {
      setSize("calc(100% - 7px)");
      splitterRef.current.setAttribute("size", "calc(100% - 7px)");
    } else {
      setSize(currentSize + "px");
      splitterRef.current.setAttribute("size", currentSize + "px");
    }
    splitterRef.current.toggleAttribute("dragged");
    splitterBackgroundFeedbackRef.current.remove();
    splitterFeedbackRef.current.remove();
    document.removeEventListener("mousemove", moveSplitterFeedback);
    document.removeEventListener("touchmove", handleTouchMove);
    document.removeEventListener("mouseup", endResize);
    document.removeEventListener("touchend", endResize);
    splitterFeedbackRef.current = null;
    if (props.onResizeEnd) {
      if (store.getState().theme.animations) {
        setTimeout(() => {
          props.onResizeEnd(splitterRef.current);
        }, transitionDuration + 1);
      } else {
        props.onResizeEnd(splitterRef.current);
      }
    }
  }

  function collapse(needToDo) {
    separatorRef.current.setAttribute("item-collapsed", "");
    setSize("0px");
    collapsedRef.current = true
    if (props.hideSplitterCollapsed) separatorRef.current.setAttribute("hidden", "");
    if (props.onCollapse && needToDo) {
      if (store.getState().theme.animations) {
        setTimeout(() => {
          props.onCollapse(splitterRef.current);
        }, transitionDuration + 1);
      } else props.onCollapse(splitterRef.current);
    }
  }

  function expand(needToDo) {
    separatorRef.current.removeAttribute("item-collapsed");
    separatorRef.current.removeAttribute("hidden");
    setSize(splitterRef.current.getAttribute("size"));
    collapsedRef.current = false
    if (props.onExpand && needToDo) {
      if (store.getState().theme.animations) {
        setTimeout(() => {
          props.onExpand(splitterRef.current);
        }, transitionDuration + 1);
      } else props.onExpand(splitterRef.current);
    }
  }

  //функция начала передвижения разделителя и нажатии кнопки коллапса
  function handleSplitterMouseDown(event) {
    if (event.target.classList.contains("separator-splitter")) {
      //если нажали на разделитель
      if (!separatorRef.current.hasAttribute("item-collapsed")) {
        let minSize, maxSize;
        if (props.orientation === "vertical") {
          minSize = separatorRef.current.previousElementSibling.offsetLeft;
          maxSize = separatorRef.current.nextElementSibling.offsetLeft + separatorRef.current.nextElementSibling.offsetWidth - separatorRef.current.offsetWidth;
        } else {
          minSize = separatorRef.current.previousElementSibling.offsetTop;
          maxSize = separatorRef.current.nextElementSibling.offsetTop + separatorRef.current.nextElementSibling.offsetHeight - separatorRef.current.offsetHeight;
        }
        if (props.minSize) {
          if (minSize < props.minSize) props.primaryIndex === 1 ? minSize = props.minSize : maxSize -= props.minSize;
        }
        if (props.maxSize) {
          if (maxSize > props.maxSize) props.primaryIndex === 1 ? maxSize = props.maxSize : minSize = maxSize - props.maxSize + (props.minSize || 0);
        }
        confinesFeedback[0] = minSize;
        confinesFeedback[1] = maxSize;
        splitterBackgroundFeedbackRef.current = document.createElement("div");
        splitterBackgroundFeedbackRef.current.className = "splitter-background-feedback";
        props.orientation === "vertical"
          ? splitterBackgroundFeedbackRef.current.style.cursor = "ew-resize"
          : splitterBackgroundFeedbackRef.current.style.cursor = "ns-resize";
        splitterFeedbackRef.current = document.createElement("div");
        splitterFeedbackRef.current.className = "splitter-feedback";
        splitterFeedbackRef.current.style.left = separatorRef.current.offsetLeft + "px";
        splitterFeedbackRef.current.style.top = separatorRef.current.offsetTop + "px";
        splitterFeedbackRef.current.style.width = separatorRef.current.offsetWidth + "px";
        splitterFeedbackRef.current.style.height = separatorRef.current.offsetHeight + "px";
        splitterRef.current.appendChild(splitterFeedbackRef.current);
        document.body.appendChild(splitterBackgroundFeedbackRef.current);
        splitterRef.current.toggleAttribute("dragged");
        document.addEventListener("mousemove", moveSplitterFeedback);
        document.addEventListener("touchmove", handleTouchMove);
        document.addEventListener("mouseup", endResize);
        document.addEventListener("touchend", endResize);
      }
    } else {
      //если нажали на кнопку коллапса
      if (props.collapsed !== undefined) {
        props.collapsed ? props.onExpand?.(splitterRef.current) : props.onCollapse?.(splitterRef.current);
      } else collapsedRef.current ? expand(true) : collapse(true);
    }
    event.preventDefault();
  }

  //установка вида сплиттера
  let rootClassName = "splitter";
  props.orientation === "vertical"
    ? rootClassName += " vertical-splitter"
    : rootClassName += " horizontal-splitter";
  //создание дочерних элементов сплиттера
  const splitterElements = [];
  const children = React.Children.toArray(props.children);
  let primaryElement;
  props.primaryIndex === 1
    ? primaryElement = 1
    : primaryElement = 0;
  for (let i = 0; i < children.length; i++) {
    splitterElements.push(
      <SplitterItem
        orientation={props.orientation}
        primary={i === primaryElement}
        ref={i === primaryElement ? null : mutableElementRef}
        transitionDuration={transitionDuration}
        oneChild={children.length > 1 ? false : true}
      >
        {children[i]}
      </SplitterItem>
    );
  }

  return (
    <div
      className={rootClassName}
      ref={(el) => {
        splitterRef.current = el;
        if (props.splitterRef) props.splitterRef.current = el;
      }}
      id={props.id}
      style={props.style}
      smetaresizer=""
    >
      {splitterElements[0]}
      {splitterElements.length > 1 &&
        (
          <div
            role="separator"
            className={cn("separator-splitter", { light: theme === "light" })}
            primaryelement={primaryElement}
            ref={separatorRef}
            onMouseDown={handleSplitterMouseDown}
            onTouchStart={handleSplitterMouseDown}
            style={props.hideSplitter ? { height: 0, width: 0, visibility: "hidden", display: "none" } : {}}
          >
            <div className="collapse-button"
              role="button"
              far-button=""
              style={{ visibility: props.hideButtons ? "hidden" : "" }}
            >
              <span className={cn("splitter-arrow", { light: theme === "light" })} />
            </div>
            <div className="collapse-button"
              role="button"
              near-button=""
              style={{ visibility: props.hideButtons ? "hidden" : "" }}
            >
              <span className={cn("splitter-arrow", { light: theme === "light" })} />
            </div>
          </div>
        )
      }
      {splitterElements.length > 1 && splitterElements[1]}
    </div>
  );
}
