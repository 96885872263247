import { AxiosRequest } from "../../../Url";
import { PostHandleTable } from "../../Tools/Requests";
import { getModalSectionID, getRightsData } from "../../Tools/Tools";
import { parseColumns } from "../GridTools/parseColumnTools";

export async function programsGrid(grid, source, props, data) {
  let json = await PostHandleTable(
    props.Module,
    props.ObjType ? props.ObjType : "0",
    undefined,
    props.SectionID,
    undefined,
    props.Path
  );
  source.onHandleRequest = handleRequest(props);

  await source.open();

  if (json) {
    source.rights = json.Rights
      ? getRightsData(json.Rights)
      : getRightsData("-1");
    grid.setColumns(parseColumns(json));
  } else grid.defaultColumns = true;

  await grid.setSource(source);

  if (props.ObjRef) await grid.refreshSource(props.ObjRef);
  grid.updateGridSize();
}

function handleRequest(props) {
  return function onHandleRequest(request) {
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "HandleTable")
      .set("ObjType", props.ObjType)
      .set("Path", props.Path)
      .set(
        "SectionID",
        props.SectionID ? props.SectionID : getModalSectionID()
      );
    return AxiosRequest(true, params, request);
  };
}
