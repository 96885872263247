import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import React from "react";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { AxiosRequest } from "../../Url";
import {
  EmptyRequest,
  tokenProcessingTest,
} from "../../TokenProcessing/TokenProcessing";

export default function SaveAsButton(props) {
  const [anchorElButton, setAnchorElButton] = React.useState(null);

  async function getRequestByID(id) {
    if (!props.ViewIdent) return;
    const viewIdent = props.ViewIdent.split(".");

    switch (id) {
      case 1:
        await CellDocToExcel({
          ViewIdent: viewIdent.length > 1 ? viewIdent[1] : viewIdent[0],
          Table: "0",
          mimeType: "application/vnd.ms-excel",
          Format: ".xls",
        });
        break;

      case 2:
        await CellDocToExcel({
          ViewIdent: viewIdent.length > 1 ? viewIdent[1] : viewIdent[0],
          Version: "pdf",
          mimeType: "application/pdf",
          Format: ".pdf",
        });

        break;
      case 3:
        await CellDocToExcel({
          ViewIdent: viewIdent.length > 1 ? viewIdent[1] : viewIdent[0],
          Version: "2007",
          Table: "0",
          mimeType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          Format: ".xlsx",
        });

        break;
      case 4:
        await CellDocToExcel({
          ViewIdent: viewIdent.length > 1 ? viewIdent[1] : viewIdent[0],
          Table: "0",
          mimeType: "application/vnd.ms-excel",
          Format: ".xls",
        });

        break;
      case 5:
        await CellDocToExcel({
          ViewIdent: viewIdent.length > 1 ? viewIdent[1] : viewIdent[0],
          Version: "2007",
          Table: "0",
          mimeType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          Format: ".xlsx",
        });

        break;
    }
  }

  async function CellDocToExcel(data) {
    const params = new Map();
    params
      .set("prefix", "cellobj")
      .set("comand", "CellDocToExcel")
      .set("ViewIdent", data.ViewIdent)
      .set("Version", data.Version)
      .set("Table", data.Table)
      .set("WSM", "1");

    const json = await AxiosRequest(true, params);
    tokenProcessingTest(json, {
      func: (res) => {
        if (res.Result === "1") {
          downloadReport({ ...res, ...data });
        }
      },
      from: "saveReport",
    });
  }

  function downloadReport(data) {
    let a = document.createElement("a");
    a.href = "data:" + data.mimeType + ";base64," + data.RCDATA;
    a.download = `${data.Title}${data.Format}`;
    a.click();
  }

  function onClickMenuItem(index) {
    getRequestByID(index);
    setAnchorElButton(null);
  }

  function getMenuList() {
    const result = [
      <MenuItem
        onClick={() => {
          onClickMenuItem(1);
        }}
        key={"save_1"}
      >{`Сохранить как Файл в формате MS Excel(.xls)`}</MenuItem>,
      <MenuItem
        onClick={() => {
          onClickMenuItem(2);
        }}
        key={"save_2"}
      >{`Сохранить как Файл в формате PDF(.pdf)`}</MenuItem>,
      <MenuItem
        onClick={() => {
          onClickMenuItem(3);
        }}
        key={"save_3"}
      >{`Сохранить как Файл в формате MS Excel 2007(.xlsx)`}</MenuItem>,
      <MenuItem
        onClick={() => {
          onClickMenuItem(4);
        }}
        key={"save_4"}
      >{`Сохранить как Табличная часть в формате Excel(.xls)`}</MenuItem>,
      <MenuItem
        onClick={() => {
          onClickMenuItem(5);
        }}
        key={"save_5"}
      >{`Сохранить как Табличная часть в формате Excel 2007(.xlsx)`}</MenuItem>,
    ];
    return result;
  }

  function handleCloseAnchorEl() {
    setAnchorElButton(null);
  }

  return (
    <div>
      <Tooltip title="Сохранить как">
        <span>
          <IconButton
            disabled={!props.ViewIdent}
            onClick={(ev) => {
              setAnchorElButton(ev.target);
            }}
          >
            <SaveOutlinedIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        anchorEl={anchorElButton}
        open={Boolean(anchorElButton)}
        onClose={handleCloseAnchorEl}
      >
        {getMenuList()}
      </Menu>
    </div>
  );
}
