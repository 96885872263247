import React, { useState } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

export default function CheckBoxedForDocandSettings(props) {
  const [checkBoxes, setCheckBoxes] = useState({
    save: false,
    dbclick: false,
    border: false,
    activerecord: true,
  });

  React.useEffect(() => {
    let docState = localStorage.getItem("docState");
    if (docState === null || docState === "") {
      localStorage.setItem(
        "docState",
        JSON.stringify({
          save: false,
          dbclick: false,
          border: false,
          activerecord: true,
        })
      );
    } else {
      setCheckBoxes(JSON.parse(docState));
    }
  }, []);

  const onCheckChange = (e) => {
    const key = e.currentTarget.id;
    if (key) {
      const checkState = {
        ...checkBoxes,
        [key]: !checkBoxes[key],
      };
      localStorage.setItem("docState", JSON.stringify(checkState));
      setCheckBoxes({
        ...checkBoxes,
        [key]: !checkBoxes[key],
      });
    }
  };

  return (
    <FormGroup>
      <FormControlLabel
        disabled
        id="save"
        onChange={(e) => {
          onCheckChange(e);
        }}
        control={<Checkbox id="save" checked={checkBoxes.save} />}
        label="Предупреждать о несохраненных изменениях при переходе на другую секцию"
      />
      <FormControlLabel
        disabled
        id="dbclick"
        onChange={(e) => {
          onCheckChange(e);
        }}
        control={<Checkbox id="dbclick" checked={checkBoxes.dbclick} />}
        label="Открывать  карточку документа по двойному щелчку мыши"
      />
      <FormControlLabel
        disabled
        onChange={(e) => {
          onCheckChange(e);
        }}
        id="border"
        control={<Checkbox id="border" checked={checkBoxes.border} />}
        label="Выделять текущую запись запись таблицы детализации рамкой"
      />
      <FormControlLabel
        id="activerecord"
        control={
          <Checkbox
            id="activerecord"
            checked={checkBoxes.activerecord}
            onChange={(e) => {
              onCheckChange(e);
            }}
          />
        }
        label="Восстанавливать активный документ при следующем входе"
      />
    </FormGroup>
  );
}
