import SectionToolsJS from "../../../Tools/SectionToolsJS";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import LoadingMask from "../../../NotWorkArea(Side&Head)/LoadingMask";
import React from "react";
import ReactDOM from "react-dom";
import TestTree from "../../../Windows/ViewData/Tree/testTree";
import { AxiosRequest, ImgURL } from "../../../../Url";
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import {
  CreateBackgroundForModal,
  tokenProcessingTest,
} from "../../../../TokenProcessing/TokenProcessing";
import TabsForReports from "./TabsForReports";
import TestParams from "../testParams";
import {
  ClearAllLoadMask,
  getIcons,
  isEmptyObject,
  updateGridById,
} from "../../../Tools/Tools";
import SplitterLocal from "../../../Tools/Splitter";
import "./SectionReport.css";
import SaveAsButton from "../../../Tools/SaveAsButton";

export default function SectionReports(props) {
  const currentId = React.useRef(null);
  const applyButton = React.useRef(null);

  const [tree, setTree] = React.useState(null);
  const [GroupID, setGroupID] = React.useState();

  const [reportButtons, setReportButtons] = React.useState({});
  const [reportButtonsJsx, setReportButtonsJsx] = React.useState([]);
  const activReportButtonsContainer = React.useRef(null);
  const reportButtonsRefs = React.useRef({});

  const reportsRef = React.useRef();
  const viewIdentData = React.useRef({});
  const [viewIdent, setViewIdent] = React.useState();
  const [currentViewIdent, setCurrentViewIdent] = React.useState();

  React.useEffect(() => {
    // дерево перерисуется при состоянии null
    if (tree === null) {
      setTree(
        <TestTree
          props={{
            CLSID: props.CLSID,
            multiCheck: false,
            SectionID: props.id,
            Module: "reports",
            selectID: setGroupID,
            Current: currentId.current,
            ObjRef: currentId.current,
          }}
        />
      );
    }
  }, [tree]);

  React.useEffect(() => {
    if (GroupID !== undefined) {
      ShowParams(GroupID);
      ShowTabs(GroupID);
      // сохранение выбранного ID
      currentId.current = GroupID;

      changeSelected(GroupID);
      if (viewIdentData.current && viewIdentData.current[GroupID]) {
        setCurrentViewIdent(viewIdentData.current[GroupID]);
      } else setCurrentViewIdent(undefined);
    }
  }, [GroupID]);

  React.useEffect(() => {
    if (props.seacrhSelected && props.seacrhSelected.Selected) {
      setGroupID(props.seacrhSelected.Selected.ID);
      // установка ID элемента для дерева
      currentId.current = props.seacrhSelected.Selected.ID;
      // перерисвка дерева
      setTree(null);
    }
  }, [props.seacrhSelected]);

  React.useEffect(() => {
    setButtonsForReport();
  }, [reportButtons]);

  React.useEffect(() => {
    if (viewIdent) {
      viewIdentData.current[GroupID] = viewIdent;
      setCurrentViewIdent(viewIdent);
    }
  }, [viewIdent]);

  function setButtonsForReport() {
    if (Object.values(reportButtons).length) {
      const result = [];
      Object.entries(reportButtons).map(([key, value], index) => {
        result.push(
          <div
            key={`report-button-container_${key}_${index}`}
            className={GroupID === key ? "showButtons" : "hideButtons"}
            style={{
              height: "100%",
              width: "100%",
              alignItems: "center",
            }}
            ref={(el) => {
              reportButtonsRefs.current[key] = el;
              if (GroupID === key) {
                activReportButtonsContainer.current = el;
              }
            }}
          >
            {value}
          </div>
        );
      });

      setReportButtonsJsx(result);
    }
  }

  function changeSelected(id) {
    if (activReportButtonsContainer.current) {
      activReportButtonsContainer.current.classList.remove("showButtons");
      activReportButtonsContainer.current.classList.add("hideButtons");
    }

    if (reportButtonsRefs.current[id]) {
      reportButtonsRefs.current[id].classList.remove("hideButtons");
      reportButtonsRefs.current[id].classList.add("showButtons");
      activReportButtonsContainer.current = reportButtonsRefs.current[id];
    }
  }

  function ButtonsForReports(json, subData) {
    let items = [],
      iconbutton;
    if (json !== undefined && !isEmptyObject(json)) {
      for (const [key, value] of Object.entries(json)) {
        if (value.Caption !== "-") {
          // проверка на разделитель |
          let hintForButton = value.Hint;
          if (value.ShortCutString) {
            hintForButton += ` (${value.ShortCutString})`;
          }
          iconbutton =
            value.Width && value.Caption !== undefined ? ( //если есть ширина, то рисуем обычную кнопку а не айкон баттон
              <Button
                id={value.ID}
                data-viewident={value.ViewIdent}
                from={subData.from}
                style={{
                  width:
                    value.Width === undefined ? "" : `${value.Width * 1.286}px`,
                  fontSize: "13px",
                  textTransform: "none",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  height: "inherit",
                }}
                component="span"
                disabled={value.Disabled === "1"}
                index={key}
                key={key}
                onClick={(event) => {
                  HandleToolButton(event);
                }}
                startIcon={ImgURL(getIcons(value.Image))}
              >
                {value.Caption === undefined ? "" : ` ${value.Caption}`}
              </Button>
            ) : (
              <IconButton
                id={value.ID}
                data-viewident={value.ViewIdent}
                from={subData.from}
                key={key}
                variant="text"
                style={{
                  width:
                    value.Caption === undefined ? "40px" : `${value.Width}px`, //value.Width === undefined ||
                  fontSize: "13px",
                  height: "inherit",
                }}
                component="span"
                index={key}
                disabled={value.Disabled === "1"}
                onClick={(event) => {
                  HandleToolButton(event);
                }}
              >
                {ImgURL(getIcons(value.Image), { marginLeft: "0px" })}
                {value.Caption === undefined ? "" : ` ${value.Caption}`}
              </IconButton>
            );
          items.push(
            <Grid style={{ height: "100%" }} key={key} item>
              {value.Hint ? (
                <Tooltip title={hintForButton} arrow>
                  {iconbutton}
                </Tooltip>
              ) : (
                iconbutton
              )}
            </Grid>
          );
        } else {
          items.push(
            <Divider
              key={key}
              orientation="vertical"
              flexItem
              sx={{ mr: 0.5 }}
            />
          );
        }
      }

      setReportButtons({ ...reportButtons, [GroupID]: items });
    }
  }

  async function HandleToolButton(ev) {
    const bttn = ev.target.closest("span");
    const params = new Map();
    params
      .set("prefix", "reports")
      .set("comand", "HandleToolButton")
      .set("ID", bttn.id)
      .set("ReportID", GroupID)
      .set("SectionID", props.SectionID)
      .set("ViewIdent", bttn.dataset.viewident)
      .set("WSM", "1");
    const json = await AxiosRequest(true, params);

    if (json && json.Token) {
      tokenProcessingTest(json);
    }
  }

  function onResize(ev) {
    updateGridById();
  }

  function ShowTabs(ID) {
    const tabsParentBlock = document.getElementById(`print_reports${props.id}`);
    for (const [key, value] of Object.entries(tabsParentBlock.children)) {
      if (value.getAttribute("groupId") !== ID) {
        value.style.display = "none";
      } else {
        value.style.display = "";
      }
    }
  }

  function AddTabs(ID, json) {
    const tabsParentBlock = document.getElementById(`print_reports${props.id}`);
    ClearTab(ID);
    const div = document.createElement("div");
    div.setAttribute("groupId", ID);
    div.style.height = "100%";
    div.style.width = "100%";
    div.id = "ReportsTabDiv" + ID;
    ReactDOM.render(
      <TabsForReports
        reportData={json}
        ID={props.id}
        ReportID={ID}
        onTabIndexChange={UpdateParams}
        onViewIdentChange={setViewIdent}
      />,
      div
    );
    tabsParentBlock.appendChild(div);
  }

  function ClearTab(ID) {
    const tabsParentBlock = document.getElementById("ReportsTabDiv" + ID);
    if (tabsParentBlock) tabsParentBlock.remove();
  }

  function PrintReport() {
    //Params ActivParams
    let frame;
    const tabs = document.getElementById(`ReportsTabDiv${GroupID}`).firstChild
      .firstChild.firstChild;
    const selectedTabIndex = tabs.getAttribute("selected-index");
    frame = tabs.getTabContent(selectedTabIndex);

    if (frame.contentWindow) {
      frame.contentWindow.print();
    }
  }

  function ShowParams(id) {
    let param = document.getElementById("item_params_reports" + props.id);
    let reportSection = document.getElementById("print_reports" + props.id);
    let reportButton = document.getElementById(
      "buttons_for_section" + props.id
    );
    if (param) {
      let paramBox = document.getElementById(
        "item_params_reports" + props.id + "_" + id
      );
      let reportBox = document.getElementById(
        "print_reports" + props.id + "_" + id
      );
      let reportButtonBox = document.getElementById(
        "button_report_token" + props.id + "_" + id
      );
      if (paramBox && reportSection && reportBox) {
        param.querySelectorAll(".ActivParams").forEach((n) => {
          n.classList.remove("ActivParams");
          n.classList.add("NoActivParams");
        });
        paramBox.classList.add("ActivParams");
        paramBox.classList.remove("NoActivParams");
        reportSection.querySelectorAll(".ActivParams").forEach((n) => {
          n.classList.remove("ActivParams");
          n.classList.add("NoActivParams");
        });
        reportBox.classList.add("ActivParams");
        reportBox.classList.remove("NoActivParams");

        // reportButton.querySelectorAll('.ActivParams').forEach(n => {n.classList.remove('ActivParams'); n.classList.add('NoActivParams')})
        // reportButtonBox.classList.add("ActivParams");
        // reportButtonBox.classList.remove("NoActivParams");
      } else {
        let parametry = document.createElement("div");
        param.querySelectorAll(".ActivParams").forEach((n) => {
          n.classList.remove("ActivParams");
          n.classList.add("NoActivParams");
        });
        reportSection.querySelectorAll(".ActivParams").forEach((n) => {
          n.classList.remove("ActivParams");
          n.classList.add("NoActivParams");
        });
        // reportButton.querySelectorAll('.ActivParams').forEach(n => {n.classList.remove('ActivParams'); n.classList.add('NoActivParams')})
        parametry.classList.add("Params");
        parametry.classList.add("ActivParams");
        parametry.id = "item_params_reports" + props.id + "_" + id;
        parametry.style.height = "100%";

        param.appendChild(parametry);

        UpdateParams(parametry);
      }
    }
  }

  // Функция обновления с рабочей маской загрузки на основе Axios request
  async function UpdateParams(block, needRefresh) {
    let params = new Map();
    params.set("prefix", "reports");
    params.set("comand", "GetReportParams");
    params.set("ReportID", GroupID);
    params.set("SectionID", props.id);
    if (needRefresh) params.set("NeedRefresh", "1");
    const res = await AxiosRequest(true, params);
    const blockToRender =
      block === undefined
        ? document.getElementById(
            "item_params_reports" + props.id + "_" + GroupID
          )
        : block;
    const activeElement = document.activeElement;
    if (activeElement) activeElement.blur();
    ReactDOM.render(<LoadingMask />, blockToRender);
    // otv.then((res) => {
    let paramBox = (
      <TestParams
        Module={"reports"}
        reqCommand={"GetReportParams"}
        Path={res.Path}
        reportID={GroupID}
        id={GroupID}
        SectionID={props.id}
        data={res}
        NoTitle={true}
      />
    );
    ReactDOM.render(paramBox, blockToRender);
    if (activeElement) activeElement.focus();
    // });
  }

  async function OpenReport() {
    let params = new Map();
    params.set("prefix", "reports");
    params.set("comand", "ExecuteReport");
    params.set("SectionID", props.id);
    params.set("ReportID", GroupID);
    params.set("WSM", 1);
    const json = await AxiosRequest(true, params);
    ShowHideLoadReport(props.id);
    CreateBackgroundForModal(
      <div style={{ cursor: "wait", width: "100%", height: "100%" }}></div>,
      "loadMaskReport"
    );
    ClearTab(GroupID);
    switch (json.Token) {
      case "ShowProgressDialog":
        let doc = document.getElementById("RenderModal");
        if (doc !== null) {
          doc.innerHTML = "";
        }
        tokenProcessingTest(json, { func: setDataForCurrentReport });
        break;
      case undefined:
        setDataForCurrentReport(json);
        break;
      default:
        tokenProcessingTest(json, { func: setDataForCurrentReport });
        break;
    }
  }

  function setDataForCurrentReport(json) {
    if (json && json.Tools) {
      ButtonsForReports(json.Tools.Buttons, {});
    }
    ShowHideLoadReport(props.id);
    // SetLoadingCursor(true);
    AddTabs(GroupID, json);
    const Mask = document.getElementById("loadMaskReport");
    if (Mask) {
      ClearAllLoadMask();
      Mask.remove();
    }
  }

  function ShowHideLoadReport(id) {
    const Load = document.getElementById("ReportsLoad" + id);
    if (Load) {
      const LoadStyle = Load.style.display;
      Load.style.display = LoadStyle === "none" ? "flex" : "none";
      // SetLoadingCursor(true);
    }
  }

  function onKeyDown(ev) {
    switch (ev.code) {
      case "KeyD":
        if (ev.ctrlKey) {
          ev.preventDefault();
          const activeElement = document.activeElement;
          activeElement.blur();
          setTimeout(() => {
            applyButton.current.click();
            if (activeElement && activeElement.tagName === "INPUT") {
              activeElement.focus();
              activeElement.select();
            }
          }, 100);
        }
        break;
      default:
        break;
    }
  }

  return (
    <div
      onKeyDown={onKeyDown}
      style={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      {props.SectionToolsJS ? <SectionToolsJS ID={props.id} /> : null}
      <div className="heightForSectionWithTools" style={{ flex: "1 0 auto" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            border: "1px solid #cccccc",
            borderLeft: "0",
            borderRight: "0",
          }}
        >
          <Tooltip title="Сформировать отчет">
            <Button
              id="applyButton"
              ref={applyButton}
              variant="outlined"
              size="small"
              onClick={() => OpenReport()}
              style={{
                textTransform: "none",
                marginLeft: "0.88%",
              }}
            >
              Выполнить
            </Button>
          </Tooltip>
          <Tooltip title="Печать">
            <span>
              <IconButton disabled={!currentViewIdent} onClick={PrintReport}>
                <PrintRoundedIcon />
              </IconButton>
            </span>
          </Tooltip>

          <SaveAsButton ViewIdent={currentViewIdent} />

          <div
            style={{
              display: "flex",
              height: "40px",
              width: "100%",
              flex: "0 1 auto",
              alignItems: "center",
            }}
          >
            {reportButtonsJsx}
          </div>
        </div>
        <SplitterLocal
          onCollapse={onResize}
          onExpand={onResize}
          onResizeEnd={onResize}
          orientation="vertical"
          primaryIndex={1}
          defaultCollapsed={false}
          transitionDuration={250}
          sizeSecondary={"30%"}
          style={{
            height: !props.SectionToolsJS
              ? "calc(100% - 39px)"
              : "calc(100% - 40px)",
            width: "100%",
          }}
          id={"ParamsFor_" + props.id}
        >
          <div
            style={{ height: "100%", width: "100%" }}
            id={"item_tree_params_reports_" + props.id}
          >
            <SplitterLocal
              onCollapse={onResize}
              onExpand={onResize}
              onResizeEnd={onResize}
              primaryIndex={0}
              defaultCollapsed={false}
              transitionDuration={250}
              sizeSecondary={"50%"}
              style={{ width: "100%", height: "100%" }}
              orientation="horizontal"
            >
              <div
                id={"item_tree_reports_" + props.id}
                style={{ overflow: "hidden", height: "100%", width: "100%" }}
              >
                {tree}
              </div>
              <div
                ref={reportsRef}
                id={"item_params_reports" + props.id}
                style={{ overflowY: "hidden", height: "100%", width: "100%" }}
              ></div>
            </SplitterLocal>
          </div>
          <div
            id={"item_print_reports" + props.id}
            style={{ height: "100%", width: "100%" }}
          >
            {/* <Scrollbars> */}
            <Grid id={`print_reports${props.id}`} style={{ height: "100%" }}>
              {/* <div id={"tabs" + GroupID} groupId={GroupID} style={{height:"100%", width:"100%"}}>
              <TabsForReports reportData={openReportData} ID={props.id}/>
            </div> */}
            </Grid>
            <LoadingMask bool={true} id={"ReportsLoad" + props.id} />
            {/* </Scrollbars> */}
          </div>
        </SplitterLocal>
      </div>
    </div>
  );
}
