import React from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
} from "@mui/material";
import { closeModal } from "../../Tools/modalManager";
import { GridLoader } from "../../rcsgrid/createGridTools";
import Grid2 from "@mui/material/Unstable_Grid2";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Button as DlgButton,
  DayMask,
  DlgType,
  HourMask,
} from "../../Tools/ProjectX";
import TestEditor from "../../../Editor/testEditor";
import { AxiosRequest } from "../../../Url";
import { RenderMessageBox } from "../../../TokenProcessing/TokenProcessing";

const emptyBox = "0",
  arrowBlue = "10",
  arrowRed = "11";

export default function DialogTypeOfWorkTime({ props }) {
  const [checkboxes, setCheckboxes] = React.useState({
    Рабочие: false,
    НеРабочие: false,
    Обычные: false,
    Праздники: false,
    Выходные: false,
    Короткие: false,
    Пн: false,
    Вт: false,
    Ср: false,
    Чт: false,
    Пт: false,
    Сб: false,
    Вс: false,
    Дневные: false,
    Ночные: false,
    Вечерние: false,
    ВремяПоНорме: false,
    МножОтрб: false,
    ПоказОбщийСписок: false,
  });
  const [selectedRecordID, setSelectedRecordID] = React.useState("0");
  const [Summ, setSumm] = React.useState({ checkbox: false, data: [] });
  const [absenceText, setAbsenceText] = React.useState("");
  const gridPanel = React.useRef();

  const mainGrid = React.useRef();

  React.useEffect(() => {
    GridLoader(gridPanel.current, props, {}).then(async (res) => {
      mainGrid.current = res;
      updCheckBoxStateByMask();
      res.source.onRecordIndexChanged = async function (source) {
        source.getFieldText("ID").then((id) => {
          setSelectedRecordID(id);
        });
      };
    });
  }, []);

  React.useEffect(() => {
    if (selectedRecordID !== "0") {
      updCheckBoxStateByMask();
    }
  }, [selectedRecordID]);

  async function onCheckBoxChange(e) {
    switch (e.currentTarget.value) {
      case "ShowInList": {
        await gridPanel.current.grid.postSomeData("ShowInList", {
          id: !e.target.checked ? "" : "1",
        });
        await gridPanel.current.grid.postExicstedEditRec(true);
        break;
      }
      case "MultiUsing": {
        await gridPanel.current.grid.postSomeData("MultiUsing", {
          id: !e.target.checked ? "" : "1",
        });
        await gridPanel.current.grid.postExicstedEditRec(true);
        break;
      }
      case "Summ": {
        break;
      }
      default: {
        let value = Number(e.currentTarget.value);
        const Mask = Number(
          await gridPanel.current.grid.source.getFieldValue("Mask")
        );
        value = e.target.checked ? Mask - value : Mask + value;
        await gridPanel.current.grid.postSomeData("Mask", {
          text: Mask,
          id: value,
        });
        await gridPanel.current.grid.postExicstedEditRec(true);
      }
    }
    updCheckBoxStateByMask();
  }

  function updCheckBoxStateByMask() {
    gridPanel.current.grid.source.getFieldValue("Mask").then((mask) => {
      const ShowInList =
        gridPanel.current.grid.source.getFieldValue("ShowInList");
      const MultiUsing =
        gridPanel.current.grid.source.getFieldValue("MultiUsing");
      setCheckboxes({
        Рабочие: mask & DayMask.Charging ? true : false,
        НеРабочие: mask & DayMask.Free ? true : false,
        Обычные: mask & DayMask.Normal ? true : false,
        Праздники: mask & DayMask.Feast ? true : false,
        Выходные: mask & DayMask.DayOff ? true : false,
        Короткие: mask & DayMask.Before ? true : false,
        Пн: mask & DayMask.Monday ? true : false,
        Вт: mask & DayMask.Tuesday ? true : false,
        Ср: mask & DayMask.Wednesday ? true : false,
        Чт: mask & DayMask.Thursday ? true : false,
        Пт: mask & DayMask.Friday ? true : false,
        Сб: mask & DayMask.Saturday ? true : false,
        Вс: mask & DayMask.Sunday ? true : false,
        Дневные: mask & HourMask.Day ? true : false,
        Ночные: mask & HourMask.Night ? true : false,
        Вечерние: mask & HourMask.Evening ? true : false,
        ВремяПоНорме: mask & DayMask.Whole ? true : false,
        МножОтрб: MultiUsing === "1",
        ПоказОбщийСписок: ShowInList === "1",
      });
    });
    gridPanel.current.grid.source
      .getFieldText("IncludeLeaves")
      .then((includeLeaves) => {
        setAbsenceText(includeLeaves);
      });
  }

  async function onSelect() {
    // if (props.RequestID) {
    //   await formEdit(
    //     "1",
    //     props.func,
    //     {},
    //     props.RequestID,
    //     props.from
    //   );
    // }
  }

  function getLeaveMask(listMask) {
    if (listMask > 0) return listMask;
    const sign = listMask === -32 || listMask === -64 ? -1 : 1;
    if (listMask === -32) return sign * Math.pow(2, Math.abs(listMask + 1));
    return sign * Math.pow(2, Math.abs(listMask + 33));
  }

  // здесь нужно доработать получение значений из таблицы, данные должны браться из записи и преобразовываться в нужный формат
  async function GetAbsenceList() {
    const params = new Map();
    params.set("prefix", "tables").set("comand", "GetAbsenceList");
    const json = await AxiosRequest(true, params);
    const inclMask = {
        IncludeLeaves: await gridPanel.current.grid.source.getFieldValue(
          "IncludeLeaves"
        ),
        InclExtLeaves: await gridPanel.current.grid.source.getFieldValue(
          "InclExtLeaves"
        ),
      },
      exclMask = {
        ExcludeLeaves: await gridPanel.current.grid.source.getFieldValue(
          "ExcludeLeaves"
        ),
        ExclExtLeaves: await gridPanel.current.grid.source.getFieldValue(
          "ExclExtLeaves"
        ),
      };
    return json.map((item, index) => {
      let listMask = Number(item.Mask);
      let checkState = emptyBox;
      if (!isNaN(listMask)) {
        listMask = getLeaveMask(listMask);
        let checkedMask;
        if (item.Mask > 0 || item.Mask == -32) {
          checkedMask = {
            inclMask: inclMask.IncludeLeaves,
            exclMask: exclMask.ExcludeLeaves,
          };
        } else {
          checkedMask = {
            inclMask: inclMask.InclExtLeaves,
            exclMask: exclMask.ExclExtLeaves,
          };
        }
        if (checkedMask.inclMask & listMask) {
          checkState = arrowBlue;
        } else if (checkedMask.exclMask & listMask) {
          checkState = arrowRed;
        }
      }
      return {
        label: item.Name,
        id: item.Mask,
        // здесь набор возможных состояний - сумма всех нужных состояний в виде: Math.pow(2, checkstate)
        MultiCheckSet: "3073",
        // здесь указывается checkstate
        // "0" - пустой чекбокс
        // "10" - синяя стрелка вправо
        // "11" - красная стрелка вправо
        checkState: checkState,
      };
    });
  }

  async function clearListLeaves(ev) {
    if (ev.target.value === "6") {
      const currentGrid = gridPanel.current.grid;
      await currentGrid.postSomeData("IncludeLeaves", {
        id: "",
        text: "",
      });
      await currentGrid.postSomeData("InclExtLeaves", {
        id: "",
        text: "",
      });
      await currentGrid.postSomeData("ExcludeLeaves", {
        id: "",
        text: "",
      });
      await currentGrid.postSomeData("ExclExtLeaves", {
        id: "",
        text: "",
      });
      await currentGrid.postExicstedEditRec(true);
      setAbsenceText(await currentGrid.source.getFieldText("IncludeLeaves"));
    }
  }

  return (
    <>
      <Grid
        id={"mainElement"}
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        style={{ height: "100%" }}
      >
        <Grid item style={{ height: "calc(100% - 42px)", width: "100%" }}>
          <Grid2
            container
            direction="row"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Grid2 item size={8} sx={{ height: "100%", width: "65%" }}>
              <div style={{ height: "100%", width: "100%" }}>
                <div
                  id={`rcgrid_panel`}
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "relative",
                  }}
                  ref={gridPanel}
                ></div>
              </div>
            </Grid2>
            <Grid2 item size={4} sx={{ width: "35%", height: "100%" }}>
              <FormControlLabel
                sx={{ marginLeft: "1px" }}
                control={
                  <Checkbox
                    // checked={}
                    onChange={onCheckBoxChange}
                    name=""
                    value={"Summ"}
                  />
                }
                label="Суммировать по другим видам"
              />
              <FormControl
                sx={{ m: 1, ".MuiFormGroup-root": { flexDirection: "row" } }}
                component="fieldset"
                variant="standard"
                // row={true}
              >
                <FormLabel component="legend">Режимы работы</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    // sx={{width:"30px"}}
                    control={
                      <Checkbox
                        checked={checkboxes.Рабочие}
                        onChange={onCheckBoxChange}
                        name="Рабочие"
                        value={DayMask.Charging}
                      />
                    }
                    label="Рабочие"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkboxes.НеРабочие}
                        onChange={onCheckBoxChange}
                        name="НеРабочие"
                        value={DayMask.Free}
                      />
                    }
                    label="Не рабочие"
                  />
                </FormGroup>
              </FormControl>
              <FormControl
                sx={{ m: 1, ".MuiFormGroup-root": { flexDirection: "row" } }}
                component="fieldset"
                variant="standard"
                // row={true}
              >
                <FormLabel component="legend">Типы дней</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    // sx={{width:"30px"}}
                    control={
                      <Checkbox
                        checked={checkboxes.Обычные}
                        onChange={onCheckBoxChange}
                        name="Обычные"
                        value={DayMask.Normal}
                      />
                    }
                    label="Обычные"
                  />
                  <FormControlLabel
                    // sx={{width:"30px"}}
                    control={
                      <Checkbox
                        checked={checkboxes.Праздники}
                        onChange={onCheckBoxChange}
                        name="Праздники"
                        value={DayMask.Feast}
                      />
                    }
                    label="Праздники"
                  />
                  <FormControlLabel
                    // sx={{width:"30px"}}
                    control={
                      <Checkbox
                        checked={checkboxes.Выходные}
                        onChange={onCheckBoxChange}
                        name="Выходные"
                        value={DayMask.DayOff}
                      />
                    }
                    label="Выходные"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkboxes.Короткие}
                        onChange={onCheckBoxChange}
                        name="Короткие"
                        value={DayMask.Before}
                      />
                    }
                    label="Короткие"
                  />
                </FormGroup>
              </FormControl>
              <FormControl
                sx={{ m: 1, ".MuiFormGroup-root": { flexDirection: "row" } }}
                component="fieldset"
                variant="standard"
                // row={true}
              >
                <FormLabel component="legend">Дни недели</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    // sx={{width:"30px"}}
                    control={
                      <Checkbox
                        checked={checkboxes.Пн}
                        onChange={onCheckBoxChange}
                        name="Пн"
                        value={DayMask.Monday}
                      />
                    }
                    label="пн."
                  />
                  <FormControlLabel
                    // sx={{width:"30px"}}
                    control={
                      <Checkbox
                        checked={checkboxes.Вт}
                        onChange={onCheckBoxChange}
                        name="Вт"
                        value={DayMask.Tuesday}
                      />
                    }
                    label="вт."
                  />
                  <FormControlLabel
                    // sx={{width:"30px"}}
                    control={
                      <Checkbox
                        checked={checkboxes.Ср}
                        onChange={onCheckBoxChange}
                        name="Ср"
                        value={DayMask.Wednesday}
                      />
                    }
                    label="ср."
                  />
                  <FormControlLabel
                    // sx={{width:"30px"}}
                    control={
                      <Checkbox
                        checked={checkboxes.Чт}
                        onChange={onCheckBoxChange}
                        name="Чт"
                        value={DayMask.Thursday}
                      />
                    }
                    label="чт."
                  />
                  <FormControlLabel
                    // sx={{width:"30px"}}
                    control={
                      <Checkbox
                        checked={checkboxes.Пт}
                        onChange={onCheckBoxChange}
                        name="Пт"
                        value={DayMask.Friday}
                      />
                    }
                    label="пт."
                  />
                  <FormControlLabel
                    // sx={{width:"30px"}}
                    control={
                      <Checkbox
                        checked={checkboxes.Сб}
                        onChange={onCheckBoxChange}
                        name="Сб"
                        value={DayMask.Saturday}
                      />
                    }
                    label="сб."
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkboxes.Вс}
                        onChange={onCheckBoxChange}
                        name="Вс"
                        value={DayMask.Sunday}
                      />
                    }
                    label="вс."
                  />
                </FormGroup>
              </FormControl>
              <FormControl
                sx={{ m: 1, ".MuiFormGroup-root": { flexDirection: "row" } }}
                component="fieldset"
                variant="standard"
                // row={true}
              >
                <FormLabel component="legend">Типы часов</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    // sx={{width:"30px"}}
                    control={
                      <Checkbox
                        checked={checkboxes.Дневные}
                        onChange={onCheckBoxChange}
                        name="Дневные"
                        value={HourMask.Day}
                      />
                    }
                    label="Дневные"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkboxes.Ночные}
                        onChange={onCheckBoxChange}
                        name="Ночные"
                        value={HourMask.Night}
                      />
                    }
                    label="Ночные"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkboxes.Вечерние}
                        onChange={onCheckBoxChange}
                        name="Вечерние"
                        value={HourMask.Evening}
                      />
                    }
                    label="Вечерние"
                  />
                </FormGroup>
              </FormControl>
              <Grid2
                container
                direction="row"
                sx={{
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "200px",
                    height: "27px",
                    border: "1px solid",
                  }}
                >
                  <TestEditor
                    value={absenceText}
                    getItems={GetAbsenceList}
                    editStyle={"customCheckList"}
                    onEditCustomCheckList={async (data) => {
                      const includeBitOn = data.CheckState === arrowBlue,
                        excludeBitOn = data.CheckState === arrowRed;
                      let listMask = Number(data.id);
                      if (!isNaN(listMask)) {
                        if (data.id > 0 || data.id == -32) {
                          listMask = { Mask1: getLeaveMask(listMask) };
                        } else listMask = { Mask2: getLeaveMask(listMask) };
                        const source = gridPanel.current.grid.source;
                        const inclMask = {
                            IncludeLeaves: await source.getFieldValue(
                              "IncludeLeaves"
                            ),
                            InclExtLeaves: await source.getFieldValue(
                              "InclExtLeaves"
                            ),
                          },
                          exclMask = {
                            ExcludeLeaves: await source.getFieldValue(
                              "ExcludeLeaves"
                            ),
                            ExclExtLeaves: await source.getFieldValue(
                              "ExclExtLeaves"
                            ),
                          };
                        if (includeBitOn) {
                          inclMask.IncludeLeaves =
                            inclMask.IncludeLeaves | listMask.Mask1;
                          inclMask.InclExtLeaves =
                            inclMask.InclExtLeaves | listMask.Mask2;
                        } else {
                          inclMask.IncludeLeaves =
                            inclMask.IncludeLeaves & ~listMask.Mask1;
                          inclMask.InclExtLeaves =
                            inclMask.InclExtLeaves & ~listMask.Mask2;
                        }
                        if (excludeBitOn) {
                          exclMask.ExcludeLeaves =
                            exclMask.ExcludeLeaves | listMask.Mask1;
                          exclMask.ExclExtLeaves =
                            exclMask.ExclExtLeaves | listMask.Mask2;
                        } else {
                          exclMask.ExcludeLeaves =
                            exclMask.ExcludeLeaves & ~listMask.Mask1;
                          exclMask.ExclExtLeaves =
                            exclMask.ExclExtLeaves & ~listMask.Mask2;
                        }
                        await gridPanel.current.grid.postSomeData(
                          "IncludeLeaves",
                          {
                            id: inclMask.IncludeLeaves || "",
                          }
                        );
                        await gridPanel.current.grid.postSomeData(
                          "InclExtLeaves",
                          {
                            id: inclMask.InclExtLeaves || "",
                          }
                        );
                        await gridPanel.current.grid.postSomeData(
                          "ExcludeLeaves",
                          {
                            id: exclMask.ExcludeLeaves || "",
                          }
                        );
                        await gridPanel.current.grid.postSomeData(
                          "ExclExtLeaves",
                          {
                            id: exclMask.ExclExtLeaves || "",
                          }
                        );
                        await gridPanel.current.grid.postExicstedEditRec(true);
                        setAbsenceText(
                          await source.getFieldText("IncludeLeaves")
                        );
                      }
                    }}
                  />
                </div>
                <IconButton
                  title="Очистить список отвлечений"
                  onClick={() => {
                    RenderMessageBox(
                      DlgType.Confirmation,
                      DlgButton.Yes + DlgButton.No,
                      "Очистить список настроенных для вида времени отвлечений?",
                      undefined,
                      clearListLeaves
                    );
                  }}
                >
                  <ClearIcon size={"small"} />
                </IconButton>
              </Grid2>
              <FormControl
                sx={{ m: 1 }}
                component="fieldset"
                variant="standard"
                // row={true}
              >
                <FormGroup>
                  <FormControlLabel
                    // sx={{width:"30px"}}
                    control={
                      <Checkbox
                        checked={checkboxes.ВремяПоНорме}
                        onChange={onCheckBoxChange}
                        name="ВремяПоНорме"
                        value={DayMask.Whole}
                      />
                    }
                    label="Учитывать время по норме"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkboxes.МножОтрб}
                        onChange={onCheckBoxChange}
                        name="МножОтрб"
                        value={"MultiUsing"}
                      />
                    }
                    label="Множественная отработка"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkboxes.ПоказОбщийСписок}
                        onChange={onCheckBoxChange}
                        name="ПоказОбщийСписок"
                        value={"ShowInList"}
                      />
                    }
                    label="Показывать в общем списке"
                  />
                </FormGroup>
              </FormControl>
            </Grid2>
            {/* <Grid2 item size={4} sx={{ width: "35%", height: "100%" }}></Grid2> */}
          </Grid2>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{
            height: "42px",
            width: "100%",
            border: "1px solid #C8B58F",
            display: props.hiddenFutter ? "none" : "",
          }}
        >
          <Grid item style={{ width: "fit-content", marginRight: "5px" }}>
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              style={{ height: "100%", width: "100%" }}
            >
              {/* {Кнопка Выбрать} */}
              <Button
                onClick={onSelect}
                size="small"
                variant="outlined"
                style={{
                  textTransform: "none",
                  marginRight: "10px",
                  display:
                    props.Buttons && (props.Buttons & 1) === 0 ? "none" : "",
                }}
                value="1"
                className="button_Modal_Select"
              >
                Выбрать
              </Button>
              {/* {кнопка Закрыть} */}
              <Button
                size="small"
                variant="outlined"
                style={{ textTransform: "none" }}
                value="2"
                className="button_Modal_Close"
                onClick={() => {
                  if (props.NewModalWindow) closeModal();
                }}
              >
                Закрыть
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
