import { useSelector } from "react-redux";
import { store } from "../../store";

const useTheme = () => {
  // const value = useSelector((state) => state.theme.theme);
  const value = store.getState().theme.theme;
  return value;
};

export default useTheme;
