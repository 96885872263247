import { IconButton, Popover } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import DetailsParams from "../../MainPage/Sections/ElementsSections/DetailsParams";
export default function DetailsButton(props) {
  const buttonRef = React.useRef();
  // функции для Popover(выпадающим полем) с выбором даты
  const [anchorElDetails, setAnchorElDetails] = React.useState(null);
  // клик на дату
  const handleClickDetails = (event) => {
    setAnchorElDetails(event.currentTarget);
  };
  // функция на закрытие
  const handleCloseDetails = () => {
    setAnchorElDetails(null);
    props.details.updateRecord();
  };
  // переменные контроля открытия деталей
  const openDetails = Boolean(anchorElDetails);
  const idDetails = openDetails ? "details" : undefined;

  function getHeight() {
    if (props.details.GetDetails && props.details.GetDetails.Items) {
      return `${props.details.GetDetails.Items.length * 28 + 2}px`;
    }
    return "30px";
  }
  return (
    <>
      <IconButton
        disabled={props.disabled}
        ref={buttonRef}
        aria-describedby={idDetails}
        onClick={handleClickDetails}
        style={{
          width: "20px",
          height: "100%",
          borderRadius: "0px",
          padding: "0",
        }}
      >
        <ExpandMoreIcon style={{ maxHeight: "20px" }} />
      </IconButton>
      <Popover
        style={{
          zIndex:
            !buttonRef.current ||
            buttonRef.current.closest("SMART-WINDOW") === null
              ? "999"
              : buttonRef.current.closest("DIV.background_ModalDialog").style
                  .zIndex,
        }}
        transitionDuration={50}
        id={idDetails}
        open={openDetails}
        anchorEl={anchorElDetails}
        onClose={handleCloseDetails}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div
          details="true"
          style={{
            width: props.autoCompleteValue.current
              ? `${props.autoCompleteValue.current.clientWidth}px`
              : "400px",
            height: getHeight(),
            maxHeight: "250px",
            minWidth: "400px",
          }}
        >
          <DetailsParams
            Module={props.module}
            ID={props.details.ID}
            DocCfgID={props.details.Details}
            NoTitle={true}
            NoHistory={true}
            data={props.details.GetDetails}
            setParentEditorValueInput={props.setInputValue}
            setParentEditorValue={props.setValue}
            valueChanged={props.valueChanged}
            getText={props.details.getText}
            updateRecord={props.details.updateRecord}
            HardCodeReadOnlyForEditor={props.details.HardCodeReadOnlyForEditor}
            AccDetails={props.details.AccDetails}
            inputValue={props.inputValue}
            onList={props.details.GetDetails.OnList}
            onListCustom={props.details.GetDetails.OnListCustom}
            onEdit={props.details.GetDetails.OnEdit}
            onEditCustom={props.details.GetDetails.OnEditCustom}
            onCloseUpList={props.details.GetDetails.OnCloseUpList}
            onCloseUpListCustom={props.details.GetDetails.OnCloseUpListCustom}
          />
        </div>
      </Popover>
    </>
  );
}
