// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.applyButton {
  height: 100%;
  width: 100%;
  border-radius: 0;
  outline: 0;
  border: 0;
  border-top: 1px solid #aaaaaa;
}

.applyButton:hover {
  background-color: aliceblue;
}

.applyButton:active {
  background-color: #eeeeee;
}
`, "",{"version":3,"sources":["webpack://./src/components/MainPage/Sections/ElementsSections/Transactions/TransactionComponents/TransactionClasses.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,UAAU;EACV,SAAS;EACT,6BAA6B;AAC/B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".applyButton {\r\n  height: 100%;\r\n  width: 100%;\r\n  border-radius: 0;\r\n  outline: 0;\r\n  border: 0;\r\n  border-top: 1px solid #aaaaaa;\r\n}\r\n\r\n.applyButton:hover {\r\n  background-color: aliceblue;\r\n}\r\n\r\n.applyButton:active {\r\n  background-color: #eeeeee;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
