import React from "react";
import LoadingMask from "../../../../NotWorkArea(Side&Head)/LoadingMask";
import { getParamsObject } from "../../../../Tools/Tools";
import AddressParams from "../../../../Sections/ElementsSections/AddressParams";

export default function AddressForTabs({ props }) {
  const [data, setData] = React.useState();

  React.useEffect(() => {
    if (props.onRequest) {
      props.onRequest(props).then((res) => {
        setData(
          getParamsObject(
            res,
            props.selectedRecordID,
            res.Caption,
            props.Module
          )
        );
      });
    } else {
      setData(
        getParamsObject(
          props.data,
          props.selectedRecordID,
          props.data.Caption,
          props.Module
        )
      );
    }
  }, []);

  return (
    <div style={{ height: "100%" }}>
      {data ? (
        <AddressParams
          Module={props.Module}
          data={data}
          Token={"HandleAddress"}
          useKladr={props.kladr}
        />
      ) : (
        <LoadingMask />
      )}
    </div>
  );
}
