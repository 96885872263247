import { AxiosRequest } from "../../../Url";
import { getRightsData } from "../../Tools/Tools";
import { parseColumns } from "../GridTools/parseColumnTools";

export async function certGrid(grid, source, props, data) {
  source.onHandleRequest = handleTable(props);

  await source.open();

  let json = await PostHandleTable(props);

  if (json) {
    source.rights = getRightsData(json.Rights);

    grid.setColumns(parseColumns(json));
  } else grid.defaultColumns = true;

  await grid.setSource(source);
  if (props.RecordIndex) await grid.refreshSource(props.RecordIndex);
  grid.updateGridSize();
}

function handleTable(props) {
  return function (request) {
    const params = new Map();
    params.set("prefix", "programs");
    params.set("comand", "HandleTable");
    params.set("Path", props.Path);
    params.set("SectionID", props.SectionID);
    return AxiosRequest(true, params, request);
  };
}

const PostHandleTable = (props) => {
  const param = new Map();
  param.set("prefix", "programs");
  param.set("comand", "GetTableLayout");
  // param.set("ObjType", 0);
  param.set("Path", props.Path);
  param.set("SectionID", props.SectionID);
  //param.set("id", "-1");
  return AxiosRequest(true, param);
};
