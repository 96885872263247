import { Box, Button, Grid, IconButton } from "@mui/material";
import TabsForReports from "../../Sections/ElementsSections/SectionReport/TabsForReports";
import React from "react";
import PrintIcon from "@mui/icons-material/PrintOutlined";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

export default function DialogTabsForReports({ props }) {
  const reports = React.useRef();

  function PrintReport() {
    let frame;

    if (reports.current) {
      frame = reports.current.querySelector("IFRAME");
    }

    if (frame && frame.contentWindow) {
      frame.contentWindow.print();
    }
  }

  return (
    <Grid style={{ width: "100%", height: "100%" }}>
      <Grid
        style={{
          width: "100%",
          height: "30px",
          borderBottom: "1px solid #C8B58F",
        }}
      >
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          direction="row"
        >
          <IconButton
            title="Печать документа"
            style={{
              height: "30px",
              width: "30px",
              display:
                props.json.CLSID === "{55D200F8-A5EE-4BB8-B9AD-762B6FB815D1}"
                  ? "none"
                  : "",
            }}
            onClick={PrintReport}
          >
            <PrintIcon />
          </IconButton>
          <IconButton
            title="Параметры документа"
            disabled
            style={{
              height: "30px",
              width: "30px",
              display:
                props.json.CLSID === "{55D200F8-A5EE-4BB8-B9AD-762B6FB815D1}"
                  ? "none"
                  : "",
            }}
          >
            <DocumentScannerOutlinedIcon />
          </IconButton>
          <IconButton
            title="Сохранить в файл"
            disabled
            style={{
              height: "30px",
              width: "30px",
              display:
                props.json.CLSID === "{55D200F8-A5EE-4BB8-B9AD-762B6FB815D1}"
                  ? "none"
                  : "",
            }}
            onClick={() => {}}
          >
            <SaveOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box
        style={{
          width: "100%",
          height:
            props.Buttons === -1
              ? "calc(100% - 30px)"
              : "calc(100% - 40px - 30px)",
        }}
      >
        <Grid
          style={{
            width: "100%",
            height: "100%",
          }}
          ref={reports}
        >
          <TabsForReports reportData={{ ...props.jsonData, ...props }} />
        </Grid>
      </Box>
      <Box
        style={{
          height: "35px",
          width: "100%",
          marginTop: "5px",
          display: props.Buttons === -1 ? "none" : "",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Button
              onClick={() => {}}
              size="small"
              variant="outlined"
              style={{
                textTransform: "none",
                display:
                  props.Buttons && (props.Button & 1) === 0 ? "none" : "",
              }}
              value="1"
              className="button_Modal_Select"
            >
              Выбрать
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="outlined"
              style={{ textTransform: "none", marginRight: "5px" }}
              value="2"
              className="button_Modal_Close"
            >
              Отмена
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
