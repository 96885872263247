// import FormsMainFile from "../Sections/ElementsSections/Forms/FormsMainFile";
// import Params from "../Sections/ElementsSections/Params";
// import SectionDocuments from "../Sections/ElementsSections/SectionDocuments/SectionDocuments";
// import SectionReports from "../Sections/ElementsSections/SectionReport/SectionReports";
// import StorObj from "../Sections/StorObj";
// import SectionsDBview from "../Sections/ElementsSections/DbView/dbview";
// import BackgroundProcess from "../Tools/BackgroundProcess";
// import StillDevelopmentPage from "./StillDevelopmentPage";
// import SectionReportsAndDocuments from "../Sections/SectionReportsAndDocuments";
// import OtherObjects from "../Sections/ElementsSections/OhersSections/OtherObjects";
// import SectionOrganizations from "../Sections/ElementsSections/Organizations/SectionOrganizations";
// import SectionDocumentsChose from "../Sections/ElementsSections/SectionDocuments/SectionDocumentsChose";
// import TestParams from "../Sections/ElementsSections/testParams";
// import SectionDocuments from "../Sections/ElementsSections/SectionDocuments/SectionDocuments";
// import SectionToolsJS from "../Tools/SectionToolsJS";
// import SectionDepartaments from "../Sections/ElementsSections/Departament/SectionDepartament";
// import TabsForReports from "../Sections/ElementsSections/SectionReport/TabsForReports";
import { lazy, Suspense } from "react";
import LoadingMask from "../NotWorkArea(Side&Head)/LoadingMask";
import { XMLrequest } from "../../Url";
import AccountsTable from "../Module/Dialogs/Components/AccountsTable";
import { GetSectionDocs } from "../Tools/Requests";
import SectionAccounting from "../Sections/ElementsSections/Accounting/SectionAccounting";
import { isDevoloper } from "../Tools/Tools";
import SectionClsBook from "../Sections/ElementsSections/ClsBook/SectionClsBook";
import SectionPayTypes from "../Sections/ElementsSections/PayTypes/SectionPayTypes";
import { ViewClass } from "../Tools/Consts";

const SectionPersonalData = lazy(
  () =>
    import(
      "../Sections/ElementsSections/SectionPersonalData/SectionPersonalData"
    )
);
const Forms = lazy(
  () => import("../Sections/ElementsSections/Forms/FormsMainFile.jsx")
);
const DocAndRepo = lazy(
  () => import("../Sections/SectionReportsAndDocuments.jsx")
);
const Docs = lazy(
  () =>
    import(
      "../Sections/ElementsSections/SectionDocuments/SectionDocumentsChose.jsx"
    )
);
const Reports = lazy(
  () => import("../Sections/ElementsSections/SectionReport/SectionReports.jsx")
);
const DView = lazy(
  () => import("../Sections/ElementsSections/DbView/dbview.jsx")
);
const SectionNFA = lazy(
  () => import("../Sections/ElementsSections/NFA/SectionNFA.jsx")
);
const OtherObjects = lazy(
  () => import("../Sections/ElementsSections/OhersSections/OtherObjects.jsx")
);
const SectionOrganizations = lazy(
  () =>
    import(
      "../Sections/ElementsSections/Organizations/SectionOrganizations.jsx"
    )
);
const SectionDocuments = lazy(
  () => import("../Sections/ElementsSections/SectionDocuments/SectionDocuments")
);
const SectionToolsJS = lazy(() => import("../Tools/SectionToolsJS"));
const SectionDepartaments = lazy(
  () => import("../Sections/ElementsSections/Departament/SectionDepartament")
);
const TabsForReports = lazy(
  () => import("../Sections/ElementsSections/SectionReport/TabsForReports")
);
const TestParams = lazy(
  () => import("../Sections/ElementsSections/testParams.jsx")
);
const BackgroundProcess = lazy(() => import("../Tools/BackgroundProcess.jsx"));
const StillDevelopmentPage = lazy(() => import("./StillDevelopmentPage"));
const Salary = lazy(
  () => import("./../Sections/ElementsSections/Salary/SalaryComponent.jsx")
);
const SectionStaff = lazy(
  () => import("../Sections/ElementsSections/Staff/SectionStaff")
);
const Audit = lazy(() => import("../Sections/Audit/UsersAudit.jsx"));
const SectionTimeTable = lazy(
  () => import("../Sections/ElementsSections/TimeTable/SectionTimeTable")
);

export async function returnPageBasedOnCLSID(
  element: any,
  ElemJson: any,
  CommitFormOnSection?: any,
  SectionID?: any
) {
  switch (element.CLSID) {
    case "{A759DBA0-9FA2-11D5-B97A-C2A4095B2C3B}": //Просмотр данных
      return (
        <Suspense fallback={<LoadingMask />}>
          <DView CLSID={element.CLSID} id={element.ID} />
        </Suspense>
      );
    case "{A358FF4E-4CE5-4CDF-B32D-38CC28448C61}": //Документы + отчёт
      return (
        <Suspense fallback={<LoadingMask />}>
          <DocAndRepo
            CLSID={element.CLSID}
            id={element.ID}
            ElemJson={ElemJson}
          />
        </Suspense>
      );
    case "{1D3BB51B-8729-4F3F-91F3-574898BFA297}": //Документы + отчёт
      return (
        <Suspense fallback={<LoadingMask />}>
          <DocAndRepo
            CLSID={element.CLSID}
            id={element.ID}
            ElemJson={ElemJson}
          />
        </Suspense>
      );
    case "{DB69A304-4DED-46F6-8BD3-BADF1BB24393}": //Документы + отчёт
      return (
        <Suspense fallback={<LoadingMask />}>
          <DocAndRepo
            CLSID={element.CLSID}
            id={element.ID}
            ElemJson={ElemJson}
          />
        </Suspense>
      );
    case "{C0CED968-8834-405D-8801-A3838BF536F3}": // Формы
      return (
        <Suspense fallback={<LoadingMask />}>
          <Forms id={element.ID} CommitFormOnSection={CommitFormOnSection} />
        </Suspense>
      );
    case "{B357E5B2-137F-4253-BBEF-E5CFD697E362}": //Отчёты
      return (
        <Suspense fallback={<LoadingMask />}>
          <Reports
            CLSID={element.CLSID}
            id={element.ID ? element.ID : SectionID}
            SectionToolsJS
          />
        </Suspense>
      );
    case "{E39CC90D-5F17-4FDF-883E-E75DEA69BE60}":
      return (
        <Suspense fallback={<LoadingMask />}>
          <SectionNFA sectionData={element} SectionID={SectionID} />
        </Suspense>
      );
    case "{D22DFDA0-DB27-4FB4-865F-B478DB85F2A4}": // фоновые загрузки
      return (
        <Suspense fallback={<LoadingMask />}>
          <BackgroundProcess />
        </Suspense>
      );
    case "{D8402CE6-6582-4F0D-A82D-C2D9CA73F79E}":
      const params = new Map();
      params.set("prefix", "programs");
      params.set("comand", "GetParamDialog");
      params.set("GroupID", "0");
      params.set("Path", element.Params.Path);
      params.set("NeedRefresh", "1");
      const json = XMLrequest(params);
      return (
        <Suspense fallback={<LoadingMask />}>
          <TestParams
            Module={"programs"}
            reqCommand={"GetParamDialog"}
            GroupID={"0"}
            id={element.ID ? element.ID : SectionID}
            SectionID={element.ID ? element.ID : SectionID}
            Path={element.Params.Path}
            data={json}
            NoTitle={true}
            NoHistory={true}
          />
        </Suspense>
      );
    case "{03338F11-FC1E-11D4-A605-0000E8D9CBDB}": //Только документы
      return (
        <Suspense fallback={<LoadingMask />}>
          <Docs CLSID={element.CLSID} id={element.ID} ElemJson={ElemJson} />
        </Suspense>
      );
    case "{C79B4449-B9CE-41C2-A28F-FB40EBA7E9DA}":
      return (
        <Suspense fallback={<LoadingMask />}>
          <SectionDepartaments sectionData={element} SectionID={SectionID} />
        </Suspense>
      );
    case "{408E20A3-4BE3-4DCD-98BD-2613A8968783}":
      return (
        <Suspense fallback={<LoadingMask />}>
          <TabsForReports reportData={element} />
        </Suspense>
      );
    case "{B467C12A-3650-4161-AE58-CC54161596CA}":
      return (
        <Suspense fallback={<LoadingMask />}>
          <Audit />
        </Suspense>
      );
    case "{D9958041-524D-11D4-91A4-000021670179}": //Анкетные данные
      if (isDevoloper()) {
        return (
          <Suspense fallback={<LoadingMask />}>
            <SectionPersonalData
              sectionData={element}
              SectionID={SectionID}
            ></SectionPersonalData>
          </Suspense>
        );
      } else {
        return (
          <Suspense fallback={<LoadingMask />}>
            <DocAndRepo
              CLSID={element.CLSID}
              id={element.ID}
              ElemJson={ElemJson}
            />
          </Suspense>
        );
      }
    case "{48C155C0-1336-11D6-9CF2-DB1D5BC3046D}": // документы
      return (
        <Suspense fallback={<LoadingMask />}>
          <div
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <SectionDocuments
              id={SectionID ? SectionID : element.ID}
              DocCfgID={
                element.ObjType
                  ? element.ObjType
                  : await GetSectionDocs(SectionID ? SectionID : element.ID)
              }
              HideParams={
                (SectionID && SectionID > 100) ||
                (element.ID && element.ID > 100)
                  ? false
                  : true
              }
              SectionToolsJS={
                (SectionID && SectionID > 100) ||
                (element.ID && element.ID > 100)
                  ? true
                  : false
              }
              setRecorcCount={() => {}}
              tree={{
                multiCheck: false,
                Module: "documents",
                ObjType: element.ObjType,
                SectionID: SectionID ? SectionID : element.ID,
                ObjRef: "0",
                Info: "0",
                UsedDate: "0",
              }}
              // updateTree={setTree}
            />
          </div>
        </Suspense>
      );

    case "{ECEA4BF8-D2F2-4642-BB8F-FED0351B2339}":
      return (
        <Suspense fallback={<LoadingMask />}>
          <AccountsTable props={element} />
        </Suspense>
      );

    case "{2AC0E428-23CD-49F6-AF96-3DB9D6B7AD0A}":
      return (
        <Suspense fallback={<LoadingMask />}>
          <SectionOrganizations sectionData={element} SectionID={SectionID} />
        </Suspense>
      );
    case "{A958A9C0-92D0-11D4-91A4-000021670179}": //Бухгалтерия
      return (
        <Suspense fallback={<LoadingMask />}>
          <SectionAccounting
            CLSID={element.CLSID}
            id={element.ID}
            SectionID={SectionID ? SectionID : element.ID}
            sectionData={element}
            ElemJson={ElemJson}
            element={element}
          />
        </Suspense>
      );
    case "{2700EC50-32E1-11D4-A4A3-0000E8D9CBDB}": {
      //Расчёт зарплаты
      if (isDevoloper()) {
        return (
          <Suspense fallback={<LoadingMask />}>
            <Salary
              CLSID={element.CLSID}
              id={element.ID}
              SectionID={SectionID ? SectionID : element.ID}
              sectionData={element}
              ElemJson={ElemJson}
              element={element}
            />
          </Suspense>
        );
      } else {
        return (
          <Suspense fallback={<LoadingMask />}>
            <DocAndRepo
              CLSID={element.CLSID}
              id={element.ID}
              ElemJson={ElemJson}
            />
          </Suspense>
        );
      }
    }
    case ViewClass.Staff: //Штатное расписание
      return (
        <Suspense fallback={<LoadingMask />}>
          <SectionStaff
            sectionId={element.ID}
            CLSID={element.CLSID}
            ElemJson={ElemJson}
          />
        </Suspense>
      );

    case ViewClass.TimeTable: //Табельный учет
      return (
        <Suspense fallback={<LoadingMask />}>
          <SectionTimeTable
            sectionId={element.ID}
            CLSID={element.CLSID}
            ElemJson={ElemJson}
          />
        </Suspense>
      );

    case "{353FD9D7-651E-4840-9319-A8578806C496}":
      return (
        <Suspense fallback={<LoadingMask />}>
          <OtherObjects sectionData={element} />
        </Suspense>
      );

    case "{B9D28843-34BE-11D4-B977-C74803C52043}":
      return (
        <Suspense fallback={<LoadingMask />}>
          <SectionPayTypes
            SectionID={SectionID ? SectionID : element.ID}
            sectionData={element}
          />
        </Suspense>
      );
    case "{37912C20-59B2-415F-8F59-DE7F85AABB00}":
      return (
        <Suspense fallback={<LoadingMask />}>
          <SectionClsBook
            SectionID={SectionID ? SectionID : element.ID}
            sectionData={element}
          />
        </Suspense>
      );
    default:
      if (element.Documents) {
        return (
          <Suspense fallback={<LoadingMask />}>
            <DocAndRepo
              CLSID={element.CLSID}
              id={element.ID}
              ElemJson={ElemJson}
            />
          </Suspense>
        );
      } else {
        return (
          <Suspense fallback={<LoadingMask />}>
            <StillDevelopmentPage
              SectionToolsJS={true}
              id={element.ID ? element.ID : SectionID}
            />
          </Suspense>
        );
      }
  }
}

export async function contentMaker(
  sideBarSelected: any,
  ElemJson: any,
  CommitFormOnSection?: any
) {
  //
  let content;
  if (
    sideBarSelected.ID &&
    sideBarSelected.CLSID === "{353FD9D7-651E-4840-9319-A8578806C496}"
  ) {
    content = (
      <Suspense fallback={<LoadingMask />}>
        <OtherObjects sectionData={sideBarSelected} />
      </Suspense>
    );
  } else if (sideBarSelected.ID !== undefined) {
    content = await returnPageBasedOnCLSID(
      sideBarSelected,
      ElemJson,
      CommitFormOnSection
    );
  }
  return content;
}
