import { Button, Grid, IconButton } from "@mui/material";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import React from "react";

export default function TextAreaWithButtons({ props }) {
  const textArea = React.useRef();
  const fileStorage = React.useRef();

  function onSave(ev) {
    if (props.onEdit) {
      props.onEdit({
        ...props,
        requestId: props.RequestID,
        value: textArea.current.value,
      });
    }
    ev.target.closest("SMART-WINDOW").parentElement.remove();
  }

  function readFile(object) {
    var file = object.files[0];
    var reader = new FileReader();
    reader.onload = function () {
      textArea.current.value = reader.result;
    };
    reader.readAsText(file);
  }

  function saveFile() {
    var text = textArea.current.value;
    const b = new Blob([text], { type: "text/plain" });
    const url = window.URL.createObjectURL(b);

    let a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", props.Title + ".txt");
    a.type = "text/plain";
    a.addEventListener("click", () => {
      setTimeout(() => window.URL.revokeObjectURL(url), 10000);
    });

    a.click();
    a.remove();
  }

  return (
    <>
      <input
        onChange={() => {
          readFile(fileStorage.current);
        }}
        style={{ display: "none" }}
        accept=".txt, .json"
        ref={fileStorage}
        type="file"
      />
      <Grid style={{ height: "100%" }}>
        <div style={{ height: "40px", width: "100%" }}>
          <IconButton
            style={{
              borderRadius: "5px",
              width: "100px",
              height: "30px",
              fontSize: "100%",
              border: "1px solid  rgba(25, 118, 210, 0.5)",
              color: "rgb(25, 118, 210)",
              marginRight: "5px",
            }}
            onClick={onSave}
          >
            Сохранить
          </IconButton>
          <IconButton
            disabled
            style={{
              borderRight: "1px solid",
              width: "1px",
              height: "30px",
              borderRadius: "0",
            }}
          ></IconButton>
          <IconButton
            title="Открыть текст из файла"
            style={{
              borderRadius: "0",
              width: "30px",
              height: "30px",
              fontSize: "100%",
              marginLeft: "15px",
            }}
            onClick={() => {
              fileStorage.current.click();
            }}
          >
            <FolderOpenIcon />
          </IconButton>
          <IconButton
            title="Сохранить текст в файл"
            style={{
              borderRadius: "0",
              width: "30px",
              height: "30px",
              fontSize: "100%",
            }}
            onClick={saveFile}
          >
            <SaveAsIcon />
          </IconButton>
          <IconButton
            disabled
            style={{
              borderRight: "1px solid",
              width: "1px",
              height: "30px",
              borderRadius: "0",
            }}
          ></IconButton>
          <IconButton
            style={{
              borderRadius: "0",
              width: "30px",
              height: "30px",
              fontSize: "100%",
              marginLeft: "15px",
            }}
          >
            <FormatColorTextIcon />
          </IconButton>
        </div>
        <textarea
          defaultValue={props.Value ? props.Value : ""}
          ref={textArea}
          maxLength={props.maxLength}
          style={{
            height: "calc(100% - 50px)",
            width: "100%",
            outline: "0",
            resize: "none",
          }}
        />
      </Grid>
    </>
  );
}
