import {
  Button,
  ClickAwayListener,
  Grid,
  Pagination,
  PaginationItem,
  Tooltip,
} from "@mui/material";
import React from "react";
import { closeModal } from "../../Tools/modalManager";
import TestParams from "../../Sections/ElementsSections/testParams";
import { formEdit } from "../../Tools/Tools";

const switchFocusObj = {
  main: "buttons",
  buttons: "main",
};

export default function DialogParams({ props }) {
  const [pagination, setPagination] = React.useState();
  const [currentPage, setCurrentPage] = React.useState();

  const paramBox = React.useRef();
  const buttonsContainer = React.useRef();

  const focusSwitch = React.useRef("main");

  const focusButtonIndex = React.useRef(0);

  React.useEffect(() => {
    if (props.data?.Pages && props.data.Pages.length) {
      setPagination(props.data.Pages);
      setCurrentPage(1);
    }

    setTimeout(() => {
      if (paramBox.current) {
        paramBox.current.focus();
        const pBox = paramBox.current.querySelector("#paramsBox");
        if (pBox) pBox.focus();
      }
    });
  }, []);

  async function onSelect() {
    closeModal();

    if (props.RequestID) {
      await formEdit("1", props.func, {}, props.RequestID, props.from);
      return;
    }
  }

  function onKeyDown(ev) {
    switch (ev.code) {
      case "NumpadEnter":
      case "Enter":
        if (focusSwitch.current === "main") {
          setTimeout(() => {
            focusSwitch.current = switchFocusObj[focusSwitch.current];
            buttonsContainer.current.children[0].focus();
            focusButtonIndex.current = 0;
          }, 200);
        }
        break;
      case "Tab":
        ev.preventDefault();
        switch (focusSwitch.current) {
          case "main":
            focusSwitch.current = switchFocusObj[focusSwitch.current];
            buttonsContainer.current.children[0].focus();
            focusButtonIndex.current = 0;
            break;

          case "buttons":
            focusSwitch.current = switchFocusObj[focusSwitch.current];
            if (paramBox.current) {
              paramBox.current.focus();
              const pBox = paramBox.current.querySelector("#paramsBox");
              if (pBox) pBox.focus();
            }
            break;

          default:
            break;
        }
        break;

      case "ArrowLeft":
        if (focusSwitch.current === "buttons") {
          const bttns = buttonsContainer.current.children;
          if (focusButtonIndex.current > 0) {
            focusButtonIndex.current = focusButtonIndex.current - 1;
            bttns[focusButtonIndex.current].focus();
          }
        }
        break;

      case "ArrowRight":
        if (focusSwitch.current === "buttons") {
          const bttns = buttonsContainer.current.children;
          if (focusButtonIndex.current < bttns.length - 1) {
            focusButtonIndex.current = focusButtonIndex.current + 1;
            bttns[focusButtonIndex.current].focus();
          }
        }
        break;
    }
  }

  return (
    <Grid
      id={"mainElement"}
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      style={{ position: "relative", height: "100%" }}
      onKeyDown={onKeyDown}
    >
      <Grid
        container
        item
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        style={{
          height: "calc(100% - 42px)",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid
          tabIndex={0}
          ref={paramBox}
          style={{
            width: "100%",
            flex: "1 1 0",
          }}
        >
          <TestParams
            currentPage={
              currentPage !== undefined ? currentPage - 1 : undefined
            }
            pageTitles={pagination}
            Module={props.Module}
            reqCommand={props.reqCommand}
            GroupID={props.GroupID}
            Path={props.Path}
            data={props.data}
            NoTitle={props.NoTitle}
            NoHistory={props.NoHistory}
          />
        </Grid>
        {currentPage !== undefined ? (
          <Grid
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              whiteSpace: "nowrap",
              border: "2px solid #cccccc",
              borderBottom: "0",
            }}
          >
            <Pagination
              page={currentPage}
              onChange={(ev, id) => {
                setCurrentPage(Number(id));
              }}
              count={pagination.length}
              renderItem={(item) => {
                if (item.type === "page") {
                  return (
                    <Tooltip title={pagination[Number(item.page) - 1].Name}>
                      <span>
                        <PaginationItem {...item} />
                      </span>
                    </Tooltip>
                  );
                } else {
                  return <PaginationItem {...item} />;
                }
              }}
            />
          </Grid>
        ) : null}
      </Grid>

      <Grid
        container
        item
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        style={{
          height: "42px",
          width: "100%",
          border: "1px solid #C8B58F",
          display: props.hiddenFutter ? "none" : "",
        }}
      >
        <ClickAwayListener
          onClickAway={(ev) => {
            focusSwitch.current = "main";
          }}
        >
          <Grid item style={{ width: "fit-content", marginRight: "5px" }}>
            <Grid
              ref={buttonsContainer}
              container
              item
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              style={{ height: "100%", width: "100%" }}
            >
              {/* {Кнопка Выбрать} */}
              {currentPage !== undefined &&
              currentPage !== pagination.length ? (
                <Button
                  tabIndex={0}
                  onClick={() => {
                    if (currentPage !== undefined) {
                      setCurrentPage(currentPage + 1);
                    }
                  }}
                  size="small"
                  variant="outlined"
                  style={{
                    textTransform: "none",
                    marginRight: "10px",
                  }}
                  // onKeyDown={onKeyDown}
                >
                  Дальше
                </Button>
              ) : (
                <>
                  {currentPage !== undefined ? (
                    <Button
                      tabIndex={0}
                      onClick={() => {
                        if (currentPage !== undefined) {
                          setCurrentPage(currentPage - 1);
                        }
                      }}
                      size="small"
                      variant="outlined"
                      style={{
                        textTransform: "none",
                        marginRight: "10px",
                      }}
                    >
                      Назад
                    </Button>
                  ) : null}
                  <Button
                    tabIndex={0}
                    onClick={onSelect}
                    size="small"
                    variant="outlined"
                    style={{
                      textTransform: "none",
                      marginRight: "10px",
                      display:
                        props.Buttons && (props.Buttons & 1) === 0
                          ? "none"
                          : "",
                    }}
                    value="1"
                    className="button_Modal_Select"
                  >
                    Выбрать
                  </Button>
                </>
              )}

              {/* {кнопка Закрыть} */}
              <Button
                tabIndex={0}
                size="small"
                variant="outlined"
                style={{ textTransform: "none" }}
                value="2"
                className="button_Modal_Close"
                onClick={() => {
                  if (props.onClose) props.onClose();
                  closeModal();
                }}
              >
                Закрыть
              </Button>
            </Grid>
          </Grid>
        </ClickAwayListener>
      </Grid>
    </Grid>
  );
}
