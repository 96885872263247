import React, { useRef } from "react";
import SectionToolsJS from "../Tools/SectionToolsJS";
import { Tabs, TabItem } from "smart-webcomponents-react/tabs";
import SectionReports from "./ElementsSections/SectionReport/SectionReports";
import { addBreadCrumb } from "../Tools/Tools";
import SectionDocumentsChose from "./ElementsSections/SectionDocuments/SectionDocumentsChose";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { UpdateOptionsForUser } from "../../Url";

export default function SectionReportsAndDocuments(props) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedView, setSelectedView] = React.useState("");
  const [seacrhSelected, setSearchSelected] = React.useState({});
  const sectol = useRef(<SectionToolsJS ID={props.id} />);
  const sideBarSelected = useSelector((state) => state.sideBar.selected);

  React.useLayoutEffect(() => {
    if (props.id === sideBarSelected.ID) {
      if (sideBarSelected.Who) {
        setSearchSelected(sideBarSelected);
        if (sideBarSelected.Who === "Reports") {
          setSelectedIndex(1);
          setSelectedView("report");
          UpdateOptionsForUser({
            Selected: {},
          });
        }
      } else {
        //
      }
    }
  }, [sideBarSelected]);

  function SelectTab(event) {
    const Name = event.currentTarget.getAttribute("Name");
    const lastli = addBreadCrumb(Name);
    lastli.firstChild.onclick = function () {
      BreadCrumbClick();
    };
    setSelectedView("reports");
    setSelectedIndex(1);
  }

  const BreadCrumbClick = () => {
    setSelectedIndex(0);
    setSelectedView("");
    if (props.onBreadCrumbClick) props.onBreadCrumbClick();
  };

  return (
    <Grid
      style={{ height: "100%", flexWrap: "nowrap" }}
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      {selectedView !== "" && props.NoSectionTools !== true
        ? sectol.current
        : null}
      <Tabs
        class="Tabs"
        selectedIndex={selectedIndex}
        style={{
          height: "0px",
          width: "100%",
          flex: "1 1 auto",
        }}
        tabPosition="hidden"
        id={"DocumentsAndReports" + props.id}
      >
        <TabItem
          style={{ textTransform: "none", paddingBottom: "0px", width: "100%" }}
        >
          <SectionDocumentsChose
            id={props.id}
            ElemJson={selectedIndex === 0 ? props.ElemJson : undefined}
            onClick={SelectTab}
            onBreadCrumbClick={BreadCrumbClick}
            selectedIndex={selectedIndex}
            setSelectedView={setSelectedView}
          />
        </TabItem>
        <TabItem style={{ textTransform: "none" }} label="Отчеты">
          <SectionReports
            CLSID={props.CLSID}
            defaultButton={props.defaultButton}
            seacrhSelected={seacrhSelected}
            id={props.id}
          ></SectionReports>
        </TabItem>
      </Tabs>
    </Grid>
  );
}
