import React from "react";
import ContentOrganization from "./Components/ContentOrganization";
import { GridLoader } from "../../rcsgrid/createGridTools";
import { AxiosRequest } from "../../../Url";
import { createConfirmation, formEdit, isEmptyObject } from "../../Tools/Tools";
import { TabItem } from "smart-webcomponents-react/tabs";
import LoadingMask from "../../NotWorkArea(Side&Head)/LoadingMask";
import TestParams from "../../Sections/ElementsSections/testParams";
import GradesGrid from "./Components/departmentsComponents/LocalGroupGrid";
import { ApplyFilter } from "../../Tools/Requests";

export default function DialogDepartments({ props }) {
  const [mainGrid, setMainGrid] = React.useState(undefined);
  const [subGrid, setSubGrid] = React.useState(undefined);

  const [collapsedButtons, setCollapsedButtons] = React.useState({});

  const [countRecords, setCountRecords] = React.useState("0");
  const [tabs, setTabs] = React.useState(null);
  const [users, setUsers] = React.useState(null);
  const [needUpdate, setNeedUpdate] = React.useState(false);

  const [timer, setTimer] = React.useState(null);

  const [selectedRecordID, setSelectedRecordID] = React.useState(props.ID);

  const gridPanel = React.useRef();
  const tabsPanel = React.useRef();
  const Pages = React.useRef();

  React.useEffect(() => {
    GridLoader(gridPanel.current, props, {
      setSelectedRecord: setSelectedRecordID,
      Pages: Pages,
      setCountRecords,
    }).then((res) => {
      setMainGrid(res);
    });
  }, []);

  React.useEffect(() => {
    if (mainGrid) {
      setTabs(getTabs());
      setCountRecords(mainGrid.source.recordCount);
    }
  }, [mainGrid]);

  React.useEffect(() => {
    if (selectedRecordID) {
      setNeedUpdate(true);
    }
  }, [selectedRecordID]);

  React.useEffect(() => {
    if (users === undefined) {
      getUsers(selectedRecordID).then((res) => {
        if (!res || res.length === 0) {
          setUsers(null);
          return;
        }
        setUsers(res);
      });
    }
  }, [users]);

  React.useLayoutEffect(() => {
    if (needUpdate) {
      updateTab(tabsPanel.current.selectedIndex, tabs);
      setNeedUpdate(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needUpdate]);

  React.useLayoutEffect(() => {
    if (!collapsedButtons.detail) {
      setTimeout(() => {
        setNeedUpdate(true);
      }, 251);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsedButtons.detail]);

  React.useLayoutEffect(() => {
    if (!collapsedButtons.users) {
      setUsers(undefined);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsedButtons.users]);

  async function setSubGridLoader(ObjType, ParentID, panel) {
    const module = "documents";
    if (panel) {
      if (!panel.grid || !subGrid) {
        setSubGrid(
          await GridLoader(
            panel,
            { ...props, Module: module },
            {
              sub: true,
              GroupID: ParentID,
              ObjType: ObjType,
              DocCfgID: ObjType,
            }
          )
        );
        setNeedUpdate(true);
      } else {
        setSubGrid(
          await GridLoader(
            panel,
            { ...props, Module: module },
            {
              sub: true,
              refresh: true,
              source: subGrid.source,
              GroupID: ParentID,
              ObjType: ObjType,
              DocCfgID: ObjType,
            }
          )
        );
      }
    }
  }

  async function getUsers(id) {
    const json = await getDepUsers(id);
    if (json && json.Users && json.Users.items && json.Users.items.length) {
      return json.Users.items.map((item, index) => {
        return (
          <div
            key={`${item.Name}_${item.ID}_${index}`}
            style={{ height: "20px", whiteSpace: "nowrap" }}
          >
            <label>
              <input
                onChange={(ev) => {
                  if (
                    mainGrid.source.getRecordState() === 160 ||
                    mainGrid.source.getRecordState() === 161 ||
                    mainGrid.source.getRecordState() === 32 ||
                    mainGrid.source.getRecordState() === 33
                  ) {
                    createConfirmation(
                      "Установить права для всех подразделений в группе?",
                      () => {
                        onChangeCheckBox(ev.target.id, ev.target.checked, "1");
                      },
                      () => {
                        onChangeCheckBox(ev.target.id, ev.target.checked, "0");
                      }
                    );
                    return;
                  }
                  onChangeCheckBox(ev.target.id, ev.target.checked, "0");
                }}
                defaultChecked={item.State === "1"}
                id={`${item.ID}`}
                type="checkbox"
              />
              {item.Name}
            </label>
          </div>
        );
      });
    }
    return null;
  }

  function onChangeCheckBox(id, checked, all) {
    // departments~LinkUserToDep?LicGUID=DCE085AD4A0652F584006D8C1BC01BA1&ID=143&User=753&Checked=0&All=0

    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "LinkUserToDep")
      .set("ID", selectedRecordID)
      .set("User", id)
      .set("Checked", checked ? "1" : "0")
      .set("All", all);
    return AxiosRequest(true, params);
  }

  function getDepUsers(id) {
    // GET /departments~GetDepUsers?LicGUID=DCE085AD4A0652F584006D8C1BC01BA1&ID=143
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "GetDepUsers")
      .set("ID", id);
    return AxiosRequest(true, params);
  }

  function getTabs() {
    let details = [];
    if (Pages.current && Pages.current.length) {
      details = Pages.current.map((page) => {
        return getLoadTab(page.Caption);
      });
    }
    return details;
  }

  function getLoadTab(Caption) {
    return (
      <TabItem
        id={`LoadTab_${Caption}`}
        key={`TabItem_${Caption}`}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
        label={Caption}
      >
        <div
          id={`item_${Caption}`}
          load="true"
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          <LoadingMask />
        </div>
      </TabItem>
    );
  }

  function getEmptyTab(Caption) {
    return (
      <TabItem
        id={`LoadTab_${Caption}`}
        key={`TabItem_${Caption}`}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
        label={Caption}
      >
        <div
          id={`item_${Caption}`}
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Функционал в разработке
        </div>
      </TabItem>
    );
  }

  function getParamsTab(Caption, detailItem) {
    return (
      <TabItem
        id={`LoadTab_${Caption}`}
        key={`TabItem_${Caption}`}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
        label={Caption}
      >
        <div
          id={`item_${Caption}`}
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
          }}
        >
          <TestParams
            Module={props.Module}
            // reqCommand={detailItem.Token}
            GroupID={selectedRecordID}
            Path={detailItem.Path}
            data={detailItem}
            NoHistory={true}
            NoTitle={true}
          />
        </div>
      </TabItem>
    );
  }

  function getGradesTab(Caption, detailItem) {
    return (
      <TabItem
        id={`LoadTab_${Caption}`}
        key={`TabItem_${Caption}`}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
        label={Caption}
      >
        <div
          id={`item_${Caption}`}
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
          }}
        >
          <GradesGrid props={{ data: detailItem }} />
        </div>
      </TabItem>
    );
  }

  async function getContentForTab(pages, index) {
    let json, propsValue;
    let page = pages.current[index];

    if (page.Info !== undefined) {
      json = await GetParamSection({
        Module: props.Module,
        ID: selectedRecordID,
        Info: page.Info,
        ProgID: page.ProgID,
      });
      if (json.Props) {
        page.Items = page.Items.map((item) => {
          if (json.Props[item.ShortName]) {
            item = {
              ...item,
              Value: json.Props[item.ShortName].Value,
              EditVal: json.Props[item.ShortName].EditVal,
              ID: json.Props[item.ShortName].ID,
              TextColor: json.Props[item.ShortName].TextColor,
              CheckState: json.Props[item.ShortName].CheckState,
            };
            return item;
          }
        });
      }
      page.ObjRef = json.ObjRef;
      page.ParentID = json.ParentID;
      page = pages.current[index] = { ...page, ...json };
    } else {
      json = await GetParamSection({
        Module: props.Module,
        ID: selectedRecordID,
        SectionID: props.SectionID,
        Name: page.Caption,
      });
      page = pages.current[index] = { ...page, ...json };
    }

    switch (page.CLSID) {
      case "{D8402CE6-6582-4F0D-A82D-C2D9CA73F79E}":
        return "params";
      case "{48C155C0-1336-11D6-9CF2-DB1D5BC3046D}":
        return "documents";
      case "{C3A20435-640D-4653-A821-C6C6E76A1B63}":
        return "localGrid";
      default:
        return "emptyTab";
    }
    // "{D8402CE6-6582-4F0D-A82D-C2D9CA73F79E}"
    // {48C155C0-1336-11D6-9CF2-DB1D5BC3046D}
    // {C3A20435-640D-4653-A821-C6C6E76A1B63}
  }

  function GetParamSection(data) {
    // GET /departments~GetParamSection?LicGUID=5990C47045C162C22FE533998708B172&SectionID=100&ID=142&Name=Зарплата
    const params = new Map();
    params
      .set("prefix", data.Module)
      .set("comand", "GetParamSection")
      .set("ID", data.ID);
    if (data.Info) {
      params.set("Info", data.Info);
    } else params.set("SectionID", data.SectionID).set("Name", data.Name);
    if (data.ProgID) params.set("ProgID", data.ProgID);
    return AxiosRequest(true, params);
  }

  function updateParams(data, firstLoad) {
    if (firstLoad) {
      tabs[data.index] = getParamsTab(data.detailItem.Caption, data.detailItem);
      setTabs([...tabs]);
    } else {
      const parambox = data.element.querySelector("#paramsBox");
      if (parambox && parambox.update) {
        parambox.update(data.detailItem);
      }
    }
  }

  function updateLocalGridBox(data, firstLoad) {
    if (firstLoad) {
      tabs[data.index] = getGradesTab(data.detailItem.Caption, data.detailItem);
      setTabs([...tabs]);
    } else {
      const localGridBox = data.element.querySelector("#localGridBox");
      if (localGridBox && localGridBox.update) {
        localGridBox.update(data.detailItem);
      }
    }
  }

  async function updateTab(index, tabs) {
    if (index !== undefined && tabs && Pages.current && Pages.current.length) {
      if (collapsedButtons.detail) {
        if (collapsedButtons.users === false) setUsers(undefined);
        return;
      }
      // tabs[index] = getLoadTab(Pages.current[index].Caption);
      // setTabs([...tabs]);
      clearTimeout(timer);
      const newTimer = setTimeout(async () => {
        const content = await getContentForTab(Pages, index);
        const detailItem = Pages.current[index];

        switch (content) {
          case "params":
            tabsPanel.current.getTabContent(index).then((res) => {
              updateParams(
                { detailItem: detailItem, index: index, element: res },
                res.querySelector("div[load='true']")
              );
            });
            break;
          case "documents":
            setSubGridLoader(
              detailItem.ObjType,
              detailItem.ParentID,
              tabsPanel.current.nativeElement.getTabContent(index).firstChild
            );
            break;
          case "localGrid":
            tabsPanel.current.getTabContent(index).then((res) => {
              updateLocalGridBox(
                { detailItem: detailItem, index: index, element: res },
                res.querySelector("div[load='true']")
              );
            });
            break;
          case "emptyTab":
            tabs[index] = getEmptyTab(detailItem.Caption);
            setTabs([...tabs]);
            break;
        }
        setUsers(undefined);
      }, 100);
      setTimer(newTimer);
    }
  }

  function GetObjectText(objref) {
    // GET /departments~GetObjectText?LicGUID=59FBDD674C8CE67B852E438DE130F564&Flag=-1&ObjType=2773&ObjRef=101000000
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "GetObjectText")
      .set("Flag", "-1")
      .set("ObjType", props.ObjType)
      .set("ObjRef", objref);
    return AxiosRequest(true, params);
  }

  async function onSelect() {
    const objref = mainGrid.source.getFieldTextSync("Code").split(".").join("");
    const text = await GetObjectText(objref);
    if (props.onEdit) {
      props.onEdit({
        value: text ? text.Text : undefined,
        tag: objref,
        name: props.Name,
        record: props.record,
        textchanged: "0",
        setValue: props.setValue,
        setInputValue: props.setInputValue,
        type: props.Module,
        requestId: props.RequestID,
        addInfo: props.addInfo,
      });
    }
    if (props.RequestID) {
      await formEdit(
        "1",
        props.func,
        { Name: "ObjRef", Value: objref },
        props.RequestID,
        props.from
      );
    }
  }

  async function fliterOnEdit(data) {
    await ApplyFilter({
      Filter: data.filter,
      Parents: data.parents,
      SectionID: props.SectionID,
      Module: props.Module,
    });
    mainGrid.grid.refreshSource();
  }

  return (
    <>
      <ContentOrganization
        props={props}
        data={{
          getCollapsed: setCollapsedButtons,
          gridPanel: gridPanel,
          mainGrid: mainGrid,
          countRecords: countRecords,
          tabs: tabs,
          users: users,
          tabsPanel: tabsPanel,
          updateTab: updateTab,
          onSelect: onSelect,
          selectedRecordID: selectedRecordID ? selectedRecordID : "0",
          filterProps: { onEdit: fliterOnEdit },
        }}
        buttons={{ detail: true, filter: true, users: true }}
      />
    </>
  );
}
