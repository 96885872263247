import { Button, Grid } from "@mui/material";
import React from "react";
import { store } from "../../../../store";
import TestEditor from "../../../Editor/testEditor";
import { AxiosRequest } from "../../../Url";
import { closeModal } from "../../Tools/modalManager";
import SplitterLocal from "../../Tools/Splitter";
import { formEdit } from "../../Tools/Tools";
import TestTree from "../../Windows/ViewData/Tree/testTree";

export default function DialogOficialStructure({ props }) {
  const [tree, setTree] = React.useState(null);
  const [groupId, setGroupID] = React.useState(props.NodeID);
  const [onlyActual, setOnlyActual] = React.useState("1");
  const [jobModes, setJobModes] = React.useState(props.jobModes);
  const [jobModeValue, setJobModeValue] = React.useState("");
  const [rateCount, setRateCount] = React.useState("1");
  const [newRendering, setNewRendering] = React.useState("1");
  const [firstDate, setFirstDate] = React.useState(props.firstDateNewRendering);
  const [lastDate, setLastDate] = React.useState("");
  const [firstRender, setFirstRender] = React.useState(true);

  const tableContainer = React.useRef(null);
  // массив всех записей
  const [records, setRecords] = React.useState([]);
  // выбранный эелемент
  const selected = React.useRef(null);
  //   LicGUID=1116AB9141967E1538796A886AABFAA7&ObjType=2790&SectionID=145&Info=0&UsedDate=0&Current=102
  React.useEffect(() => {
    if (tree === null) {
      setTree(
        <TestTree
          props={{
            Module: "departments",
            ObjType: "2790",
            SectionID: store.getState().sideBar.selected.ID,
            Info: "0",
            UsedDate: "0",
            Current: props.NodeID ? props.NodeID : "0",
            ObjRef: props.NodeID ? props.NodeID : "0",
            selectID: setGroupID,
          }}
        />
      );
    }
  }, [tree]);

  React.useEffect(() => {
    if (props.createNewRendering && groupId) {
      getJobModes(groupId).then((res) => {
        const list = res.Value.split("\r\n");
        setJobModes(res.Value);
        if (firstRender) {
          setJobModeValue(props.jobModeValueIndex >= list.length ? list[0] : list[props.jobModeValueIndex]);
          setFirstRender(false);
        } else setJobModeValue(list[0]);
      });
    }
  }, [groupId]);

  React.useEffect(() => {
    if (groupId) {
      getAppointment(groupId, onlyActual).then((result) => {
        const res = Object.values(result);
        setRecords(getRecords(res));
      });
    }
  }, [groupId, onlyActual]);

  function getAppointment(groupId, onlyActual) {
    // GET /staff~GetAppointmentsStringList?LicGUID=1116AB9141967E1538796A886AABFAA7&GroupID=102&OnlyActual=1
    const params = new Map();
    params
      .set("prefix", "staff")
      .set("comand", "GetAppointmentsStringList")
      .set("GroupID", groupId)
      .set("OnlyActual", onlyActual);
    const json = AxiosRequest(true, params);
    return json;
  }

  function getJobModes(groupId) {
    const params = new Map();
    params
      .set("prefix", "staff")
      .set("comand", "GetJobModes")
      .set("ObjType", 2790)
      .set("ObjRef", groupId);
    const json = AxiosRequest(true, params);
    return json;
  }

  function getRecords(array) {
    if (array && array.length) {
      return array.map((item, id) => {
        const record = (
          <tr
            ref={item.ID === props.ObjRef ? selected : null}
            onDoubleClick={(ev) => {
              selected.current = ev.target;
              if (!props.createNewRendering) {
                if (props.onEdit && selected.current) {
                  props.onEdit({
                    value: selected.current.innerText,
                    tag: selected.current.id.split("_")[1],
                    name: props.Name,
                    record: props.record,
                    textchanged: "0",
                    setValue: props.setValue,
                    setInputValue: props.setInputValue,
                    type: props.Module,
                    addInfo: props.addInfo,
                  });
                }
                if (props.NewModalWindow) {
                  closeModal();
                } else ev.target.closest("SMART-WINDOW").parentElement.remove();
              }
            }}
            onClick={(ev) => {
              // на клик по записи она помечается как выбранная
              if (selected.current) selected.current.style.backgroundColor = "";
              selected.current = ev.target;
              selected.current.style.backgroundColor = "#ede2d3";
            }}
            onMouseEnter={(ev) => {
              if (selected.current === ev.target) return;
              ev.target.style.backgroundColor = "#eeeeee";
            }}
            onMouseLeave={(ev) => {
              if (selected.current === ev.target) return;
              ev.target.style.backgroundColor = "";
            }}
            key={`child_${item.ID}`}
            style={{
              cursor: "default",
              backgroundColor: item.ID === props.ObjRef ? "#ede2d3" : "",
            }}
            text={item.Name}
          >
            <td
              id={`td_${item.ID}`}
              style={{ borderBottom: "1px solid #cccccc" }}
              num={id}
            >
              {item.Name}
            </td>
          </tr>
        );
        return record;
      });
    }
    return [];
  }

  async function onSelect() {
    if (props.onEdit && selected.current) {
      const paramsNewRendering = {};
      if (props.createNewRendering) {
        paramsNewRendering.FirstDate = firstDate;
        paramsNewRendering.LastDate = lastDate;
        paramsNewRendering.NewRendering = newRendering;
        paramsNewRendering.RateCount = rateCount;
        paramsNewRendering.JobMode = jobModes.split("\r\n").findIndex(item => item === jobModeValue);
      }
      props.onEdit({
        value: selected.current.innerText,
        tag: selected.current.id.split("_")[1],
        name: props.Name,
        record: props.record,
        textchanged: "0",
        setValue: props.setValue,
        setInputValue: props.setInputValue,
        type: props.Module,
        requestId: props.RequestID,
        addInfo: props.addInfo,
        paramsNewRendering: paramsNewRendering,
      });
      if (props.NewModalWindow) closeModal();
    }
    if (props.RequestID) {
      await formEdit(
        "1",
        props.func,
        {
          Name: "ObjRef",
          Value: selected.current.id.split("_")[1],
        },
        props.RequestID,
        props.from
      );
    }
  }

  return (
    <>
      <Grid style={{ height: props.createNewRendering ? "calc(100% - 130px)" : "calc(100% - 50px)" }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          style={{ height: "30px" }}
        >
          <div style={{ width: "calc(100% - 171px)" }}>
            Теущее штатное расписание
          </div>
          <div style={{ width: "150px" }}>Только действующие</div>
          <input
            defaultChecked
            type="checkbox"
            onChange={(ev) => {
              setOnlyActual(ev.target.checked ? "1" : "0");
            }}
          />
        </Grid>
        <SplitterLocal
          style={{
            height: "calc(100% - 30px)",
          }}
          hideButtons={true}
          sizeSecondary="50%"
          orientation="vertical"
        >
          <div>{tree}</div>
          <div ref={tableContainer}
            style={{ border: "1px solid #cccccc" }}>
            <table
              style={{
                border: "0px solid #cccccc",
              }}
              bordercolor={"#cccccc"}
              cellSpacing={"1"}
              width={"100%"}
              cols={"1"}
              table-layout={"auto"}
              onMouseUp={(ev) => {
                if (selected.current)
                  selected.current.style.backgroundColor = "";
                selected.current = ev.target;
                selected.current.style.backgroundColor = "#ede2d3";
              }}
            >
              <tbody>{records}</tbody>
            </table>
          </div>
        </SplitterLocal>
      </Grid>
      <Grid
        item
        style={{
          width: "100%",
          marginTop: "5px",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ paddingTop: "8px" }}
        >
          {props.createNewRendering &&
            (<>
              <Grid container spacing={2}>
                <Grid item style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "70px"
                }}>
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "24px",
                    width: "200px"
                  }}>
                    <div
                      style={{ alignContent: "center", marginLeft: "10px" }}>
                      Назначение:
                    </div>
                    <div style={{ border: "2px solid grey", width: "100px", height: "100%" }}>
                      <TestEditor
                        editStyle={2}
                        value={firstDate}
                        onEdit={(ev) => setFirstDate(ev.value)}
                      />
                    </div>
                  </div>
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "24px",
                    width: "200px"
                  }}>
                    <div
                      style={{ alignContent: "center", marginLeft: "10px" }}>
                      Снятие:
                    </div>
                    <div style={{ border: "2px solid grey", width: "100px", height: "100%" }}>
                      <TestEditor
                        editStyle={2}
                        onEdit={(ev) => setLastDate(ev.value)}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "70px"
                }}>
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "24px",
                    width: "320px"
                  }}>
                    <div
                      style={{ alignContent: "center", marginLeft: "10px" }}>
                      Форма назначения:
                    </div>
                    <div style={{ border: "2px solid grey", width: "175px" }}>
                      <TestEditor
                        editStyle={65}
                        list={jobModes}
                        value={jobModeValue}
                        onEdit={(ev) => setJobModeValue(ev.value)}
                      />
                    </div>
                  </div>
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "24px",
                    width: "320px"
                  }}>
                    <div
                      style={{ alignContent: "center", marginLeft: "10px" }}>
                      Число занимаемых ставок:
                    </div>
                    <div style={{ border: "2px solid grey", width: "120px" }}>
                      <TestEditor
                        value={rateCount}
                        onEdit={(ev) => setRateCount(ev.value)}
                        editStyle={8}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                style={{ height: "30px", marginLeft: "6px" }}
              >
                <input
                  defaultChecked
                  type="checkbox"
                  onChange={(ev) => setNewRendering(ev.target.checked ? "1" : "0")}
                />
                <div>Вести отдельный табель по должности</div>
              </Grid>
            </>
            )
          }
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none", marginRight: "10px" }}
            value="1"
            className="button_Modal_Select"
            onClick={onSelect}
          >
            Выбрать
          </Button>
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none" }}
            value="2"
            className="button_Modal_Close"
            onClick={() => {
              if (props.NewModalWindow) closeModal();
            }}
          >
            Отмена
          </Button>
        </Grid>
      </Grid >
    </>
  );
}
