import { Close, CloseFullscreen, OpenInFull } from "@mui/icons-material";
import { Box, Button, Grid, IconButton } from "@mui/material";
import cn from "classnames";
import React from "react";
import ReactDOM from 'react-dom';
import { useSelector } from "react-redux";
import { CheckModalBackGrounds, GetButtons } from "../../TokenProcessing/TokenProcessing";
import HandleTable from "../../TokenProcessing/components/HandleTable";
import { CreateCokies, GetOptionsForUser, UpdateOptionsForUser, XMLrequest } from "../../Url";
import { getModalSectionID, updateGridData } from "../Tools/Tools";
import { closeModal, initModalManager } from "../Tools/modalManager";

function ModalWindows(props) {
  const theme = useSelector((state) => state.theme.theme);
  let style = props.style || {};
  const modalRef = React.useRef();
  const parentElRef = React.useRef();
  const resizeContainerRef = React.useRef();
  const modalFeedbackRef = React.useRef(null);
  const posStartDrag = { x: 0, y: 0, left: 0, top: 0 };
  const posBeforeMax = React.useRef({ top: 0, left: 0 });
  const size = React.useRef();
  const [fullScreen, setFullScreen] = React.useState(false);
  const animationsOn = useSelector((state) => state.theme.animations);
  const [opacity, setOpacity] = React.useState(animationsOn ? "0" : "");
  const initialWindowSize = React.useRef({ width: window.innerWidth, height: window.innerHeight })

  React.useEffect(() => {
    if (props.closing) setOpacity("0");
  }, [props.closing])

  React.useEffect(() => {
    modalRef.current.resize = windowResizeHandler;
    parentElRef.current.style.zIndex = props.zIndex === undefined
      ? 999 + CheckModalBackGrounds()
      : props.zIndex - 1 + CheckModalBackGrounds();
    const modalLocation = GetOptionsForUser()?.modalsLocation?.[title];
    const currentLocation = modalLocation?.currentLocation;
    if (currentLocation && !props.blockSaveLocation) {
      var currentWidth = window.innerWidth;
      var currentHeight = window.innerHeight;
      modalRef.current.style.height = currentLocation.height;
      modalRef.current.style.width = currentLocation.width;
      if (currentLocation.windowHeight !== currentHeight || currentLocation.windowWidth !== currentWidth) {
        if (currentLocation.windowHeight !== currentHeight) {
          modalRef.current.style.top = parseFloat(currentLocation.top) / (currentLocation.windowHeight - modalRef.current.offsetHeight) * (currentHeight - modalRef.current.offsetHeight) + "px";
          posBeforeMax.current.top = modalLocation.posBeforeMax.top / (currentLocation.windowHeight - parseFloat(modalLocation.size.height)) * (currentHeight - parseFloat(modalLocation.size.height));
        } else {
          modalRef.current.style.top = currentLocation.top;
          posBeforeMax.current.top = modalLocation.posBeforeMax.top;
        }
        if (currentLocation.windowWidth !== currentWidth) {
          modalRef.current.style.left = parseFloat(currentLocation.left) / (currentLocation.windowWidth - modalRef.current.offsetWidth) * (currentWidth - modalRef.current.offsetWidth) + "px";
          posBeforeMax.current.left = modalLocation.posBeforeMax.left / (currentLocation.windowWidth - parseFloat(modalLocation.size.width)) * (currentWidth - parseFloat(modalLocation.size.width));
        } else {
          modalRef.current.style.left = currentLocation.left;
          posBeforeMax.current.left = modalLocation.posBeforeMax.left;
        }
      } else {
        modalRef.current.style.left = currentLocation.left;
        modalRef.current.style.top = modalLocation.currentLocation.top;
        posBeforeMax.current = modalLocation.posBeforeMax;
      }
      size.current = modalLocation.size;
      if (currentLocation.height === "100%" && currentLocation.width === "100%") {
        modalRef.current.setAttribute("maximized", "");
        setFullScreen(true);
      }
    } else {
      const heightModal = props.style?.height || "30%";
      const widthModal = props.style?.width || "40%";
      modalRef.current.style.height = heightModal;
      modalRef.current.style.width = widthModal;
      size.current = { height: heightModal, width: widthModal };
      saveModalParams();
      let bodyWidth = document.documentElement.clientWidth;
      let bodyHeight = document.documentElement.clientHeight;
      modalRef.current.style.left = (bodyWidth - modalRef.current.offsetWidth) / 2 + "px";
      modalRef.current.style.top = (bodyHeight - modalRef.current.offsetHeight) / 2 + "px";
    }
    if (animationsOn) setOpacity("");
    if (props.setFocus) modalRef.current.focus();
  }, [])

  function windowResizeHandler() {
    var currentWidth = window.innerWidth;
    var currentHeight = window.innerHeight;
    if (currentWidth !== initialWindowSize.current.width) {
      var newLeft;
      if (modalRef.current.hasAttribute("maximized")) {
        newLeft = posBeforeMax.current.left / (initialWindowSize.current.width - parseFloat(size.current.width)) * (currentWidth - parseFloat(size.current.width));
        posBeforeMax.current.left = newLeft;
      } else {
        newLeft = modalRef.current.offsetLeft / (initialWindowSize.current.width - modalRef.current.offsetWidth) * (currentWidth - modalRef.current.offsetWidth);
        modalRef.current.style.left = newLeft + "px";
      }
    }
    if (currentHeight !== initialWindowSize.current.height) {
      var newTop;
      if (modalRef.current.hasAttribute("maximized")) {
        newTop = posBeforeMax.current.top / (initialWindowSize.current.height - parseFloat(size.current.height)) * (currentHeight - parseFloat(size.current.height));
        posBeforeMax.current.top = newTop;
      } else {
        newTop = modalRef.current.offsetTop / (initialWindowSize.current.height - modalRef.current.offsetHeight) * (currentHeight - modalRef.current.offsetHeight);
        modalRef.current.style.top = newTop + "px";
      }
    }
    if (modalRef.current.hasAttribute("maximized")) saveModalParams();
    initialWindowSize.current = { width: currentWidth, height: currentHeight }
  }

  function modalResizeHandler() {
    modalRef.current.querySelectorAll("[smetaresizer]").forEach((el) => el.resize());
    setTimeout(() => {
      updateGridData(10, { last: true });
    });
  }

  function saveLocation() {
    if (!props.blockSaveLocation) {
      const modalsLocation = GetOptionsForUser()?.modalsLocation || {};
      const currentLocation = {
        height: modalRef.current.style.height,
        width: modalRef.current.style.width,
        left: modalRef.current.style.left,
        top: modalRef.current.style.top,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      }
      const modalLocation = modalsLocation?.[title] || {};
      UpdateOptionsForUser({
        modalsLocation: {
          ...modalsLocation,
          [title]: {
            ...modalLocation,
            currentLocation: currentLocation
          }
        }
      });
    }
  }

  function saveModalParams() {
    if (!props.blockSaveLocation) {
      const modalsLocation = GetOptionsForUser()?.modalsLocation || {};
      const modalLocation = modalsLocation?.[title] || {};
      UpdateOptionsForUser({
        modalsLocation: {
          ...modalsLocation,
          [title]: {
            ...modalLocation,
            posBeforeMax: posBeforeMax.current,
            size: size.current,
          }
        }
      });
    }
  }

  function endMoving(event) {
    modalRef.current.removeAttribute("dragged");
    if (modalFeedbackRef.current) {
      modalFeedbackRef.current.remove();
      modalFeedbackRef.current = null;
      modalRef.current.style.height = "100%";
      modalRef.current.style.width = "100%";
      modalRef.current.style.top = 0;
      modalRef.current.style.left = 0;
      modalRef.current.setAttribute("maximized", "");
      setFullScreen(true);
      if (animationsOn) {
        setTimeout(() => {
          modalResizeHandler();
        }, 251);
      } else modalResizeHandler();
    }
    saveLocation();
    document.removeEventListener("mousemove", moveModal);
    document.removeEventListener("touchmove", touchMoveHandler);
    document.removeEventListener("mouseup", endMoving);
    document.removeEventListener("touchend", endMoving);
  }

  function moveModal(event) {
    if (!modalRef.current.hasAttribute("dragged")) {
      modalRef.current.setAttribute("dragged", "");
      posBeforeMax.current = { top: modalRef.current.offsetTop, left: modalRef.current.offsetLeft };
      saveModalParams();
    }
    if (modalRef.current.hasAttribute("maximized")) {
      modalRef.current.removeAttribute("maximized");
      setFullScreen(false);
      modalRef.current.style.height = size.current.height;
      modalRef.current.style.width = size.current.width;
      posStartDrag.left = event.clientX - parseFloat(window.getComputedStyle(modalRef.current).getPropertyValue('width')) / 2;
      modalResizeHandler();
    }
    let newLeft = Math.max(0, posStartDrag.left + event.clientX - posStartDrag.x);
    let newTop = Math.max(0, posStartDrag.top + event.clientY - posStartDrag.y);
    if (newLeft + modalRef.current.offsetWidth > parentElRef.current.clientWidth) {
      newLeft = parentElRef.current.clientWidth - modalRef.current.offsetWidth;
    }
    if (newTop + modalRef.current.offsetHeight > parentElRef.current.clientHeight) {
      newTop = parentElRef.current.clientHeight - modalRef.current.offsetHeight;
    }
    modalRef.current.style.left = newLeft + "px";
    modalRef.current.style.top = newTop + "px";
    if (event.pageY <= parentElRef.current.offsetTop && !props.blockMaximize) {
      if (modalFeedbackRef.current === null) {
        modalFeedbackRef.current = document.createElement("div");
        modalFeedbackRef.current.className = "modalWindowTopFeedback visibilityHidden";
        parentElRef.current.appendChild(modalFeedbackRef.current);
        setTimeout(() => {
          modalFeedbackRef.current?.classList.remove("visibilityHidden");
        }, 1);
      }
    } else if (modalFeedbackRef.current !== null) {
      modalFeedbackRef.current.remove();
      modalFeedbackRef.current = null;
    }
  }

  function touchMoveHandler(event) {
    moveModal(event.changedTouches[0]);
    event.preventDefault();
  }

  function resizeStart() {
    document.addEventListener("mousemove", resizeHandler);
    document.addEventListener("mouseup", resizeEnd);
    parentElRef.current.style.userSelect = "none";
  }

  function resizeEnd(event) {
    document.removeEventListener("mousemove", resizeHandler);
    document.removeEventListener("mouseup", resizeEnd);
    parentElRef.current.style.userSelect = "";
    if (modalFeedbackRef.current) {
      modalRef.current.style.height = modalFeedbackRef.current.style.height;
      modalRef.current.style.width = modalFeedbackRef.current.style.width;
      modalRef.current.style.left = modalFeedbackRef.current.style.left;
      modalRef.current.style.top = modalFeedbackRef.current.style.top;
      modalRef.current.removeAttribute("resized");
      if (!modalRef.current.hasAttribute("maximized")) {
        size.current = { height: modalFeedbackRef.current.style.height, width: modalFeedbackRef.current.style.width };
        saveModalParams();
      }
      modalFeedbackRef.current.remove();
      modalFeedbackRef.current = null;
      saveLocation();
      if (animationsOn) {
        setTimeout(() => {
          modalResizeHandler();
        }, 251);
      } else modalResizeHandler();
    }
  }

  function resize(side, event) {
    const modalFeedbackRect = modalFeedbackRef.current.getBoundingClientRect(),
      modalRect = modalRef.current.getBoundingClientRect(),
      parentElRect = parentElRef.current.getBoundingClientRect();
    const minHeight = parseFloat(window.getComputedStyle(modalRef.current).getPropertyValue("min-height")),
      minWidth = parseFloat(window.getComputedStyle(modalRef.current).getPropertyValue("min-width"));
    var maxHeight = parseFloat(window.getComputedStyle(modalRef.current).getPropertyValue("max-height")),
      maxWidth = parseFloat(window.getComputedStyle(modalRef.current).getPropertyValue("max-width"));
    if (isNaN(maxHeight)) maxHeight = 0;
    if (isNaN(maxWidth)) maxWidth = 0;
    switch (side) {
      case "Top":
        var newHeight;
        // Ограничение сверху
        if (event.clientY >= 0) {
          newHeight = modalFeedbackRef.current.offsetHeight - (event.clientY - modalFeedbackRect.top);
          // Ограничение снизу
          if (newHeight > minHeight) {
            if (maxHeight && newHeight > maxHeight) {
              modalFeedbackRef.current.style.top = modalRect.top + modalRect.height - maxHeight + "px";
              modalFeedbackRef.current.style.height = maxHeight + "px";
            } else {
              modalFeedbackRef.current.style.top = event.clientY + "px";
              modalFeedbackRef.current.style.height = newHeight + "px";
            }
          } else {
            modalFeedbackRef.current.style.top = modalRect.top + modalRect.height - minHeight + "px";
            modalFeedbackRef.current.style.height = minHeight + "px";
          }
        } else {
          newHeight = modalRect.top + modalRect.height;
          if (maxHeight && newHeight > maxHeight) {
            modalFeedbackRef.current.style.top = newHeight - maxHeight + "px";
            modalFeedbackRef.current.style.height = maxHeight + "px";
          } else {
            modalFeedbackRef.current.style.top = "0px";
            modalFeedbackRef.current.style.height = newHeight + "px";
          }
        }
        break;
      case "Left":
        var newWidth;
        // Ограничение слева
        if (event.clientX >= 0) {
          newWidth = modalFeedbackRef.current.offsetWidth - (event.clientX - modalFeedbackRect.left);
          // Ограничение справа
          if (newWidth > minWidth) {
            if (maxWidth && newWidth > maxWidth) {
              modalFeedbackRef.current.style.left = modalRect.left + modalRect.width - maxWidth + "px";
              modalFeedbackRef.current.style.width = maxWidth + "px";
            } else {
              modalFeedbackRef.current.style.left = event.clientX + "px";
              modalFeedbackRef.current.style.width = newWidth + "px";
            }
          } else {
            modalFeedbackRef.current.style.left = modalRect.left + modalRect.width - minWidth + "px";
            modalFeedbackRef.current.style.width = minWidth + "px";
          }
        } else {
          newWidth = modalRect.left + modalRect.width;
          if (maxWidth && newWidth > maxWidth) {
            modalFeedbackRef.current.style.left = newWidth - maxWidth + "px";
            modalFeedbackRef.current.style.width = maxWidth + "px";
          } else {
            modalFeedbackRef.current.style.left = "0px";
            modalFeedbackRef.current.style.width = newWidth + "px";
          }
        }
        break;
      case "Bottom":
        maxHeight === 0 ? maxHeight = parentElRect.height - modalFeedbackRect.top : maxHeight = Math.min(maxHeight, parentElRect.height - modalFeedbackRect.top);
        modalFeedbackRef.current.style.height = Math.min(maxHeight, Math.max(minHeight, event.clientY - modalFeedbackRect.top)) + "px";
        break;
      case "Right":
        maxWidth === 0 ? maxWidth = parentElRect.width - modalFeedbackRect.left : maxWidth = Math.min(maxWidth, parentElRect.width - modalFeedbackRect.left);
        modalFeedbackRef.current.style.width = Math.min(maxWidth, Math.max(minWidth, event.clientX - modalFeedbackRect.left)) + "px";
        break;
      default:
        break;
    }
  }

  function resizeHandler(event) {
    if (modalFeedbackRef.current === null) {
      modalFeedbackRef.current = document.createElement("div");
      modalFeedbackRef.current.className = "modalWindowResizeFeedback";
      modalFeedbackRef.current.style.height = modalRef.current.offsetHeight + "px";
      modalFeedbackRef.current.style.width = modalRef.current.offsetWidth + "px";
      modalFeedbackRef.current.style.top = modalRef.current.style.top;
      modalFeedbackRef.current.style.left = modalRef.current.style.left;
      modalFeedbackRef.current.setAttribute("side", resizeContainerRef.current.className.substring(resizeContainerRef.current.className.indexOf("resizing") + 8, resizeContainerRef.current.className.length));
      parentElRef.current.appendChild(modalFeedbackRef.current);
      modalRef.current.setAttribute("resized", "");
    }
    const side = modalFeedbackRef.current.getAttribute("side");
    switch (side) {
      case "TopLeft":
        resize("Top", event);
        resize("Left", event);
        break;
      case "BottomLeft":
        resize("Bottom", event);
        resize("Left", event);
        break;
      case "BottomRight":
        resize("Bottom", event);
        resize("Right", event);
        break;
      case "TopRight":
        resize("Top", event);
        resize("Right", event);
        break;
      default:
        resize(side, event);
        break;
    }
  }

  function mouseDownHandler(event) {
    if (resizeContainerRef.current.className.indexOf("resizing") > -1) {
      resizeStart();
    } else if (event.target.classList.contains("headModal") || event.target.parentElement.classList.contains("headModal")) {
      if (event.type === "touchstart") {
        posStartDrag.x = event.targetTouches[0].clientX;
        posStartDrag.y = event.targetTouches[0].clientY;
      } else {
        posStartDrag.x = event.clientX;
        posStartDrag.y = event.clientY;
      }
      posStartDrag.left = modalRef.current.offsetLeft;
      posStartDrag.top = modalRef.current.offsetTop;
      document.addEventListener("mousemove", moveModal);
      document.addEventListener("touchmove", touchMoveHandler, { passive: false });
      document.addEventListener("mouseup", endMoving);
      document.addEventListener("touchend", endMoving);
    }
  }

  function maximize() {
    if (!props.blockMaximize) {
      if (modalRef.current.hasAttribute("maximized")) {
        modalRef.current.style.height = size.current.height;
        modalRef.current.style.width = size.current.width;
        modalRef.current.style.top = posBeforeMax.current.top + "px";
        modalRef.current.style.left = posBeforeMax.current.left + "px";
      } else {
        modalRef.current.style.height = "100%";
        modalRef.current.style.width = "100%";
        posBeforeMax.current = { top: modalRef.current.offsetTop, left: modalRef.current.offsetLeft };
        saveModalParams();
        modalRef.current.style.top = 0;
        modalRef.current.style.left = 0;
      }
      modalRef.current.toggleAttribute("maximized");
      setFullScreen(!fullScreen);
      saveLocation();
      if (animationsOn) {
        setTimeout(() => {
          modalResizeHandler();
        }, 251);
      } else modalResizeHandler();
    }
  }

  function clickButtonHandler(event) {
    const action = (event.target.classList.contains("button_Modal_Close") && "close") ||
      (event.currentTarget.classList.contains("button_Modal_Close") && "close") ||
      (event.target.classList.contains("button_Modal_Select") && "select");
    switch (action) {
      case "close":
        if (props.onClose) props.onClose();
        closeModal();
        break;
      case "select":
        if (props.onSelect) props.onSelect();
        closeModal();
        break;
      default:
        break;
    }
  }

  function moveHandler(event) {
    const containerResize = resizeContainerRef.current;
    containerResize.classList.remove("resizingBottomRight", "resizingTopRight",
      "resizingTopLeft", "resizingBottomLeft",
      "resizingTop", "resizingBottom",
      "resizingLeft", "resizingRight");
    const modalRect = modalRef.current.getBoundingClientRect(),
      topPos = event.clientY < modalRect.top + 10,
      bottomPos = event.clientY > modalRect.bottom - 10,
      rightPos = event.clientX > modalRect.right - 10,
      leftPos = event.clientX < modalRect.left + 10;
    if (leftPos || rightPos)
      return topPos ? containerResize.classList.add("resizingTop" + (leftPos ? "Left" : "Right")) :
        bottomPos ? containerResize.classList.add("resizingBottom" + (leftPos ? "Left" : "Right")) :
          containerResize.classList.add("resizing" + (leftPos ? "Left" : "Right"));
    if (topPos || bottomPos) {
      if (rightPos)
        return containerResize.classList.add("resizing" + (topPos ? "TopRight" : "BottomRight"));
      if (leftPos)
        return containerResize.classList.add("resizing" + (topPos ? "TopLeft" : "BottomLeft"));
      containerResize.classList.add("resizing" + (topPos ? "Top" : "Bottom"));
    }
  }

  let param = new Map(),
    json;
  let content,
    hiddenButton,
    title = props.Title;
  hiddenButton = props.hiddenButton;
  if (!props.children) {
    param.set("prefix", props.Module);
    param.set("comand", "GetDialogParams");
    if (props.ObjType) param.set("ObjType", props.ObjType);
    if (props.ObjRef) param.set("ObjRef", props.ObjRef);
    if (props.MultiSel) param.set("MultiSel", props.MultiSel);
    json = XMLrequest(param);
    if (json) {
      title = json.Title;
      switch (json.CLSID) {
        case "{5D1A1010-A394-43EC-A84B-AB401C1C2640}":
          content = (
            <HandleTable
              json={json}
              subData={{ props: props, SectionID: getModalSectionID() }}
            />
          );
          break;
        case "{37912C20-59B2-415F-8F59-DE7F85AABB00}":
          CreateCokies(json.CLSID + "_objref", props.ObjRef);
          content = (
            <HandleTable
              json={json}
              subData={{ props: props, SectionID: getModalSectionID() }}
            />
          );
          hiddenButton = true;
          break;
        case "{2AC0E428-23CD-49F6-AF96-3DB9D6B7AD0A}":
          style.height = window.innerHeight * 0.7 + "px";
          style.width = window.innerWidth * 0.7 + "px";
          content = (
            <HandleTable
              json={json}
              subData={{ props: props, SectionID: getModalSectionID() }}
            />
          );
          hiddenButton = true;
          break;
        case "{7FAC2B20-109D-4406-A757-61674277B71F}":
          style.height = window.innerHeight * 0.6 + "px";
          style.width = window.innerWidth * 0.65 + "px";
          content = (
            <HandleTable
              json={json}
              subData={{ props: props, SectionID: getModalSectionID() }}
            />
          );
          hiddenButton = true;
          break;
        case "{48C155C0-1336-11D6-9CF2-DB1D5BC3046D}":
          style.height = window.innerHeight * 0.7 + "px";
          style.width = window.innerWidth * 0.7 + "px";
          content = (
            <HandleTable
              json={json}
              subData={{ props: props, SectionID: getModalSectionID() }}
            />
          );
          hiddenButton = true;
          break;
        case "{AE5A67E0-EBEB-11D5-900D-000021E6A31F}":
          content = (
            <HandleTable
              json={json}
              subData={{ props: props, SectionID: getModalSectionID() }}
            />
          );
          hiddenButton = true;
          break;
        case "{295EA015-4573-4AD9-922A-A14CE0FD9C78}":
          content = (
            <HandleTable
              json={json}
              subData={{ props: props, SectionID: getModalSectionID() }}
            />
          );
          hiddenButton = true;
          break;
        default:
          content = (
            <HandleTable
              json={json}
              subData={{ props: props, SectionID: getModalSectionID() }}
            />
          );
          break;
      }
    }
  } else {
    content = props.children;
  }

  return (
    <div
      ref={parentElRef}
      className="background_ModalDialog"
      style={{
        height: "100%",
        width: "100%",
        position: "absolute",
        top: "0px",
      }}
    >
      <div
        style={{ zIndex: "9997" }}
        className="backgroundModalWindow"
      />
      <div
        className={cn("modalWindow", { light: theme === "light" })}
        style={{
          ...style, zIndex: "9998",
          transition: animationsOn
            ? "width .2s cubic-bezier(.4, 0, .2, 1), height .25s cubic-bezier(.4, 0, .2, 1), top .2s cubic-bezier(.4, 0, .6, 1), left .2s cubic-bezier(.4, 0, .6, 1), transform .2s cubic-bezier(.4, 0, .2, 1), visibility .2s cubic-bezier(.4, 0, .2, 1), min-height .25s cubic-bezier(.4, 0, .2, 1), min-width .25s cubic-bezier(.4, 0, .2, 1), opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform .2s cubic-bezier(.4, 0, .2, 1)"
            : "",
          opacity: opacity,
        }}
        ref={modalRef}
        tabIndex={"-1"}
        onMouseDown={mouseDownHandler}
        onTouchStart={mouseDownHandler}
        onMouseMove={props.blockResize || fullScreen ? null : moveHandler}
        onMouseLeave={() => {
          resizeContainerRef.current.classList.remove("resizingBottomRight", "resizingTopRight",
            "resizingTopLeft", "resizingBottomLeft",
            "resizingTop", "resizingBottom",
            "resizingLeft", "resizingRight");
        }}
        smetaresizer=""
      >
        <div className="modalResizeContainer" ref={resizeContainerRef}>
          <div
            className={cn("headModal", "headerStimate", { light: theme === "light" })}
            onDoubleClick={maximize}
          >
            <div className={cn("fontColorStimate", { light: theme === "light" })}>{title}</div>
            <div className={cn("iconButtonStimate", { light: theme === "light" })}
              style={{ whiteSpace: "nowrap" }}
            >
              {props.blockMaximize ? null : (
                <IconButton
                  size="small"
                  onClick={() => maximize()}
                  color="inherit"
                >
                  {fullScreen ? (<CloseFullscreen fontSize="small" />) : (<OpenInFull fontSize="small" />)}
                </IconButton>
              )}
              <IconButton
                size="small"
                onClick={clickButtonHandler}
                className="button_Modal_Close"
                color="inherit"
              >
                <Close fontSize="small" />
              </IconButton>
            </div>
          </div>
          <div className="contentModal">
            <Box
              style={{
                width: "100%",
                height: hiddenButton ? "100%" : "calc(100% - 40px)",
                overflow: "auto",
              }}
            >
              {content}
            </Box>
            <Box style={{ width: "100%", display: "flex" }}>
              {hiddenButton ? null : props.Buttons ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  style={{ marginTop: "10px" }}
                  onClick={clickButtonHandler}
                >
                  {GetButtons(props.Buttons)}
                </Grid>
              ) : (
                <Box style={{ marginLeft: "auto", marginTop: "10px" }}>
                  <Button
                    size="small"
                    variant="outlined"
                    style={{ textTransform: "none" }}
                    value="1"
                    className="button_Modal_Select"
                    onClick={clickButtonHandler}
                  >
                    {"Выбрать"}
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    style={{ textTransform: "none", marginLeft: 10 }}
                    value="2"
                    className="button_Modal_Close"
                    onClick={clickButtonHandler}
                  >
                    Отмена
                  </Button>
                </Box>
              )}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function ModalPortal() {
  const [modals, setModals] = React.useState([]);

  initModalManager(setModals);

  return ReactDOM.createPortal(modals.map((modal, index) => (
    <ModalWindows
      key={index}
      index={index}
      {...modal.props}
    >
      {modal.content}
    </ModalWindows>
  )), document.getElementById("ModalPortal")
  );
};