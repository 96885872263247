import React from "react";
import { GridLoader } from "../../../../rcsgrid/createGridTools";
import { clsBookGrid } from "../../../../rcsgrid/GridFunctions/clsbookGrid";
import { PostHandleTable } from "../../../../Tools/Requests";

export default function ClsBookTableForTabs({ props }) {
  const [mainGrid, setMainGrid] = React.useState();
  const gridPanel = React.useRef();
  const clsBookBox = React.useRef();

  React.useEffect(() => {
    PostHandleTable(
      "clsbook",
      props.detailItem.DocCfgID,
      undefined,
      props.SectionID
    )
      .then((res) => {
        return GridLoader(
          gridPanel.current,
          { ...props, ObjType: props.detailItem.DocCfgID },
          {
            json: res,
            GroupID: props.selectedRecordID,
            ObjType: props.detailItem.DocCfgID,
          }
        );
      })
      .then((res) => {
        setMainGrid(res);
      });
  }, []);

  React.useEffect(() => {
    if (mainGrid) {
      clsBookBox.current.refreshMainGrid = refreshMainGrid;
    }
  }, [mainGrid]);

  async function refreshMainGrid(data) {
    const json = await PostHandleTable(
      "clsbook",
      props.detailItem.DocCfgID,
      undefined,
      props.SectionID
    );

    clsBookGrid(
      mainGrid.grid,
      mainGrid.source,
      { ...props, ObjType: props.detailItem.DocCfgID },
      {
        json: json,
        GroupID: props.selectedRecordID,
        ObjType: props.detailItem.DocCfgID,
      }
    );
  }

  return (
    <div ref={clsBookBox} id="clsBookBox" style={{ height: "100%" }}>
      <div
        id={`rcgrid_panel`}
        ref={gridPanel}
        style={{
          height: "100%",
          width: "100%",
          position: "relative",
        }}
      ></div>
    </div>
  );
}
