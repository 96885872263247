export async function personsGrid(grid, source, props, data) {
  // source.onHandleRequest = handleTable(
  //   props.Module,
  //   props.ObjType,
  //   data.GroupID,
  //   data.selectedRecordID,
  //   props.SectionID
  // );
  // source.open();
  // if (data.ReturnRecord) {
  //   source.onRecordIndexChanged = function (source) {
  //     data.ReturnRecord(source.getFieldText("ID"));
  //   };
  // }
  // grid.updateGridSize();
}
