import { AxiosRequest } from "../../../Url";
import { defaultFieldInfo, getRightsData } from "../../Tools/Tools";

export async function accountsAnaliticTableGrid(grid, source, props, data) {
  source.onHandleRequest = handleAnaliticRequest(props, source);

  let columns = { title: [] },
    dest = {};
  dest.fieldName = "AnalyticID";
  dest.title = "Название";
  dest.width = 120;
  dest.level = 0;
  dest.fieldInfo = {
    ...defaultFieldInfo,
    EditStyle: "17",
    Module: "accounts",
    DataType: "2223",
  };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "Flags";
  dest.title = "Признаки";
  dest.width = 320;
  dest.level = 0;
  dest.fieldInfo = { ...defaultFieldInfo, EditStyle: "32" };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "InitialValue";
  dest.title = "Значение по умолчанию";
  dest.width = 120;
  dest.level = 0;
  dest.fieldInfo = {
    ...defaultFieldInfo,
    EditStyle: "17",
    Module: "clsbook",
    DataType: "2466",
  };
  columns.title.push(dest);

  source.rights = getRightsData("-1");

  grid.setColumns(columns);

  await source.open();
  await grid.setSource(source);
  if (props.ObjRef) {
    await grid.refreshSource(props.ObjRef);
  } else await grid.refreshSource();

  if (data.setSelectedRecord) {
    source.onRecordIndexChanged = async function (source) {
      data.setSelectedRecord(source.getFieldTextSync("ID"));
    };
    data.setSelectedRecord(source.getFieldTextSync("ID"));
  }
  if (data.onMultiSelectChange) {
    grid.onMultiSelectChange = function () {
      data.onMultiSelectChange();
    };
  }

  grid.updateGridSize();
}

export function handleAnaliticRequest(props) {
  return function onHandleRequest(request) {
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "HandleAnalyticTable")
      .set("ObjType", props.ObjType)
      .set("GroupID", props.ObjRef)
      .set("SectionID", props.SectionID);
    if (props.User) params.set("User", props.User);

    return AxiosRequest(true, params, request);
  };
}
