import { AxiosRequest } from "../../../Url";
import { PostHandleTable } from "../../Tools/Requests";
import { defaultFieldInfo, getRightsData } from "../../Tools/Tools";
import { CreateCollapsibleRecords } from "../GridTools/collapsibleRecordsTools";
import { parseColumns } from "../GridTools/parseColumnTools";

export async function accToolsGrid(grid, source, props, data) {
  let json = await PostHandleTable(
    props.Module,
    props.ObjType,
    undefined,
    props.SectionID
  );
  source.onHandleRequest = HandleRequest(props);
  //создание стилей для сворачивающихся записей
  source.CreateCollapsibleRecords = CreateCollapsibleRecords;

  await source.open();

  //создание дефолтных полей()
  let columns = { title: [] };
  let dest;
  dest = {};
  dest.collapseRecord = true;
  dest.fieldName = "Name";
  dest.title = "Наименование операции";
  dest.width = 300;
  dest.level = 0;
  dest.fieldInfo = { ...defaultFieldInfo };
  columns.title.push(dest);
  //парсинг полей запроса GetTableLayout
  if (json) {
    source.rights = getRightsData(json.Rights);

    columns = parseColumns(json, columns);
    grid.setColumns(columns);
  } else grid.setColumns(columns);

  await grid.setSource(source);
  source.onRecordCollapsed = async function (event) {
    let RecordIndex;
    if (event.currentTarget !== undefined) {
      RecordIndex = event.currentTarget.getAttribute("recordindex");
    } else {
      RecordIndex = await event;
    }
    let params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "RecordCollapsed")
      .set("SectionID", props.SectionID)
      .set("RecordIndex", RecordIndex);
    await AxiosRequest(true, params);
    await grid.refreshSource("!");
  };
  if (data.setSelectedRecord) {
    source.onRecordIndexChanged = function (source) {
      data.setSelectedRecord(source.getFieldTextSync("ID"));
    };
    data.setSelectedRecord(source.getFieldTextSync("ID"));
  }
  if (props.ObjRef) await grid.refreshSource(props.ObjRef);
  grid.updateGridSize();
}

function HandleRequest(props) {
  return function onHandleRequest(request) {
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "HandleTable")
      .set("SectionID", props.SectionID);
    return AxiosRequest(true, params, request);
  };
}
