import { useState } from "react";
// import TextField from "@mui/material/TextField/TextField";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import Button from "@mui/material/Button/Button";
import { AxiosRequest, XMLrequest } from "../../../Url";
import { RenderMessageBox } from "../../../TokenProcessing/TokenProcessing";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function ChangePassowrd(props) {
  const [currentPassowrd, setCurrentPassowrd] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState({
    currentPassowrd: false,
    NewPassword: false,
    ConfirmNewPassword: false,
  });

  function handleSubmit(event) {
    event.preventDefault();
    let params = new Map();
    const Postdata = {
      Value: newPassword,
    };
    if (props.where !== "signin")
      Object.assign(Postdata, {
        Password: currentPassowrd,
        Check: "1",
      });
    params.set("prefix", "project");
    params.set("comand", "ChangePassword");
    AxiosRequest(true, params, Postdata).then((json) => {
      if (json.Result === "1") {
        RenderMessageBox(2, 1, "Пароль изменен.");
        document.getElementsByClassName("background_ModalDialog")[0].remove();
        if (props.func) props.func();
      }
    });
  }

  const handleClickShowPassword = (event) => {
    setShowPassword({
      ...showPassword,
      [event.currentTarget.id]: !showPassword[event.currentTarget.id],
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 0.8 }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={6}
        sx={{ pl: 2 }}
      >
        <Grid item style={{ width: "300px" }}>
          {props.where !== "signin" ? (
            <Grid item style={{ width: "100%" }}>
              <FormControl fullWidth size="small" variant="standard">
                <InputLabel htmlFor="standard-adornment-password">
                  Текущий пароль
                </InputLabel>
                <Input
                  id="currentPassowrd"
                  type={showPassword.currentPassowrd ? "text" : "password"}
                  name="currentPassowrd"
                  onChange={(e) => {
                    setCurrentPassowrd(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        id="currentPassowrd"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword.currentPassowrd ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          ) : null}

          <Grid item>
            <FormControl
              sx={{ mt: 1 }}
              fullWidth
              size="small"
              variant="standard"
            >
              <InputLabel htmlFor="standard-adornment-password">
                Новый пароль
              </InputLabel>
              <Input
                id="NewPassword"
                type={showPassword.NewPassword ? "text" : "password"}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      id="NewPassword"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword.NewPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl
              sx={{ mt: 1 }}
              fullWidth
              size="small"
              variant="standard"
            >
              <InputLabel htmlFor="standard-adornment-password">
                Подтверждение
              </InputLabel>
              <Input
                id="NewPassword"
                type={showPassword.ConfirmNewPassword ? "text" : "password"}
                name="ConfirmNewPassword"
                onChange={(e) => {
                  setConfirmNewPassword(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      id="ConfirmNewPassword"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword.ConfirmNewPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Button
            style={{
              textTransform: "none",
              position: "absolute",
              bottom: "15px",
              right: "21px",
              width: "82px",
            }}
            type="submit"
            variant="outlined"
            size="small"
            disabled={
              confirmNewPassword === newPassword && newPassword !== ""
                ? false
                : true
            }
          >
            Изменить
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
