import { Grid } from "@mui/material";
import TestEditor from "../../../../Editor/testEditor";
import React from "react";
import CheckBoxMultiCheck from "../../../Sections/ElementsSections/CheckBoxMultiCheck";

export default function FilterComponent({ props }) {
  const [filter, setFilter] = React.useState("");
  const [parents, setParents] = React.useState("1");

  function onEditFilter(data) {
    if (props.onEdit) {
      props.onEdit({ filter: data.value, parents: parents });
    }
    setFilter(data.value);
  }

  function onCheckFilter(data) {
    if (props.onEdit) {
      props.onEdit({ filter: filter, parents: data.CheckState });
    }
    setParents(data.CheckState);
  }

  return (
    <>
      <Grid
        item
        style={{
          height: "27px",
          width: "90px",
          paddingTop: "3px",
          paddingLeft: "5px",
          marginRight: "5px",
        }}
      >
        Фильтр:
      </Grid>
      <Grid
        item
        style={{
          height: "27px",
          width: "calc(100% - 400px)",
          border: "1px solid #C8B58F",
          borderRadius: "3px",
          padding: "3px",
          backgroundColor: "#ffffff",
        }}
      >
        <TestEditor onEdit={onEditFilter} />
      </Grid>
      <Grid
        item
        style={{
          height: "27px",
          width: "30px",
          paddingTop: "3px",
          paddingLeft: "5px",
        }}
      >
        <CheckBoxMultiCheck CheckState={"1"} onEdit={onCheckFilter} />
      </Grid>
      <Grid
        item
        style={{
          height: "27px",
          width: "250px",
          paddingTop: "3px",
          paddingLeft: "5px",
        }}
      >
        Показывать родительские узлы
      </Grid>
    </>
  );
}
