import { AxiosRequest, XMLrequest } from "../../../Url";
import { PostHandleTable } from "../../Tools/Requests";
import {
  defaultFieldInfo,
  getModalSectionID,
  getRightsData,
} from "../../Tools/Tools";
import { CreateCollapsibleRecords } from "../GridTools/collapsibleRecordsTools";
import { parseColumns } from "../GridTools/parseColumnTools";

export async function departmentsGrid(grid, source, props, data) {
  let json = await PostHandleTable(
    props.Module,
    "2790",
    undefined,
    props.SectionID
  );
  source.onHandleRequest = HandleRequest(props);
  //создание стилей для сворачивающихся записей
  source.CreateCollapsibleRecords = CreateCollapsibleRecords;

  await source.open();

  //создание дефолтных полей()
  let columns = { title: [] },
    dest = {};
  dest.fieldName = "Code";
  dest.title = "Код";
  dest.width = 120;
  dest.level = 0;
  dest.collapseRecord = true;
  dest.fieldInfo = { ...defaultFieldInfo };
  columns.title.push(dest);
  dest = {};
  dest.fieldName = "Title";
  dest.title = "Организация";
  dest.width = 200;
  dest.level = 0;
  dest.fieldInfo = { ...defaultFieldInfo };
  columns.title.push(dest);
  dest = {};
  dest.fieldName = "Title";
  dest.title = "Название";
  dest.width = 200;
  dest.level = 1;
  dest.fieldInfo = { ...defaultFieldInfo };
  columns.title.push(dest);
  dest = {};
  dest.fieldName = "KeyNo";
  dest.title = "Краткий код";
  dest.width = 120;
  dest.level = 1;
  dest.fieldInfo = { ...defaultFieldInfo };
  columns.title.push(dest);
  dest = {};
  dest.fieldName = "DepTypes";
  dest.title = "Тип подразделения";
  dest.width = 120;
  dest.level = 1;
  dest.fieldInfo = { ...defaultFieldInfo, EditStyle: 32 };
  columns.title.push(dest);
  dest = {};
  dest.fieldName = "Options";
  dest.title = "Параметры отдела";
  dest.width = 120;
  dest.level = 1;
  dest.fieldInfo = { ...defaultFieldInfo, EditStyle: 32 };
  columns.title.push(dest);
  //парсинг полей запроса GetTableLayout
  if (json) {
    source.rights = getRightsData(json.Rights);

    columns = parseColumns(json, columns);
    grid.setColumns(columns);
  } else grid.setColumns(columns);

  if (data.setSelectedRecord) {
    source.onRecordIndexChanged = async function (source) {
      data.setSelectedRecord(source.getFieldTextSync("ID"));
    };

    if (data.Pages && json.Pages) {
      data.Pages.current = json.Pages.split("\r\n").map((page) => {
        return { Caption: page };
      });
    }
  }

  source.onRecordCollapsed = async function (event) {
    let RecordIndex;
    if (event.currentTarget !== undefined) {
      RecordIndex = event.currentTarget.getAttribute("recordindex");
    } else {
      RecordIndex = await event;
    }
    let params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "RecordCollapsed")
      .set("SectionID", props.SectionID)
      .set("ObjType", "2790")
      .set("RecordIndex", RecordIndex);
    AxiosRequest(true, params);
    await grid.refreshSource("!");
    if (data.setCountRecords) data.setCountRecords(source.recordCount);
  };

  await grid.setSource(source);

  if (props.ID) await grid.refreshSource(props.ID);
  if (data.setSelectedRecord) {
    data.setSelectedRecord(source.getFieldTextSync("ID"));
  }

  grid.updateGridSize();
}

function HandleRequest(props) {
  return function onHandleRequest(request) {
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "HandleTable")
      .set("ObjType", "2790")
      .set("SectionID", props.SectionID)
      .set("All");
    return AxiosRequest(true, params, request);
  };
}
