import SectionToolsJS from "../../../Tools/SectionToolsJS";
import AccountPlanMainBlock from "./AccountPlanComponents/AccountPlanMainBlock";

export default function SectionAccountPlan(props) {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flex: "1 1 0",
          }}
        >
          <AccountPlanMainBlock props={{ ...props }} />
        </div>
      </div>
    </div>
  );
}
