import React from "react";
import { AxiosRequest } from "../../../../Url";
import DialogTypeOfWorkTime from "../../../Module/Dialogs/DialogTypeOfWorkTime";

export default function TypesOfWorkTime({ props }) {
  // React.useEffect(() => {
  //   GetDialogParams();
  // }, []);

  // async function GetDialogParams() {
  //   let params = new Map();
  //   params.set("prefix", "tables");
  //   params.set("comand", "GetDialogParams");
  //   params.set("ObjRef", ObjRef ? ObjRef : "0");
  //   params.set("ObjType", "2721");

  //   const result = await AxiosRequest(
  //     load === undefined ? true : load,
  //     params,
  //     request
  //   );
  // }
  ///tables~GetDialogParams?LicGUID=319EDF4E4FDF49FC62471A9D53144953&ObjType=2721&ObjRef=0
  return <DialogTypeOfWorkTime props={props} />;
}
