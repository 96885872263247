import { Box, Button, Grid, IconButton, Popover } from "@mui/material";
import React from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CheckBoxMultiCheck from "../../Sections/ElementsSections/CheckBoxMultiCheck";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import { isEmptyObject } from "../../Tools/Tools";
import LoadingMask from "../../NotWorkArea(Side&Head)/LoadingMask";

export default function FilterGridComponent({ props }) {
  // 1024
  const [data, setData] = React.useState();
  const [records, setRecords] = React.useState([]);

  const [load, setLoad] = React.useState();

  const [multiCheck, setMultiCheck] = React.useState(
    props.filterData[props.dataForReq.FieldName]
      ? props.filterData[props.dataForReq.FieldName].multiCheck
      : undefined
  );
  const checkData = React.useRef(
    props.filterData[props.dataForReq.FieldName]
      ? props.filterData[props.dataForReq.FieldName].checkData
      : {}
  );
  const [filtered, setFiltered] = React.useState(
    props.filterData[props.dataForReq.FieldName]
      ? props.filterData[props.dataForReq.FieldName].filtered
      : undefined
  );

  const [needUpdateRec, setNeedUpdateRec] = React.useState();

  const [disabledAcceptBttn, setDisabledAcceptBttn] = React.useState(true);

  const filterValueChange = React.useRef(false);

  const focusRecID = React.useRef(
    props.filterData[props.dataForReq.FieldName]
      ? props.filterData[props.dataForReq.FieldName].focusRecID
      : undefined
  );

  const focusRec = React.useRef();

  const oldCheckData = React.useRef({});
  const filterBox = React.useRef();
  const filterButton = React.useRef();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onClick();
  };

  const handleClose = () => {
    setAnchorEl(null);
    filterValueChange.current = false;
    setDisabledAcceptBttn(true);
    checkData.current = oldCheckData.current;
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  React.useEffect(() => {
    filterButton.current.clearFilter = clearFilterState;
  }, []);

  React.useEffect(() => {
    if (data) {
      setRecords(getRecords(data));
    }
  }, [data]);

  React.useEffect(() => {
    if (multiCheck !== undefined && data) {
      setRecords(getRecords(data));
    }
  }, [multiCheck]);

  React.useEffect(() => {
    if (needUpdateRec) {
      setRecords(getRecords(data));
      setNeedUpdateRec(false);
    }
  }, [needUpdateRec]);

  React.useEffect(() => {
    let objectForFilter;
    if (props.filterData[props.dataForReq.FieldName]) {
      objectForFilter = props.filterData[props.dataForReq.FieldName];

      objectForFilter.multiCheck = multiCheck;
      objectForFilter.filtered = filtered;
      objectForFilter.checkData = checkData ? checkData.current : undefined;
      objectForFilter.focusRecID = focusRecID.current
        ? focusRecID.current
        : undefined;
    } else {
      objectForFilter = {};

      objectForFilter.multiCheck = multiCheck;
      objectForFilter.filtered = filtered;
      objectForFilter.checkData = checkData ? checkData.current : undefined;
      objectForFilter.focusRecID = focusRecID.current
        ? focusRecID.current
        : undefined;

      props.filterData[props.dataForReq.FieldName] = objectForFilter;
    }
  }, [multiCheck, filtered, checkData, focusRecID]);

  function onMouseEnter(ev) {
    const tr = ev.target.closest("TR");
    if (!focusRec.current || tr.id !== focusRecID.current) {
      tr.style.backgroundColor = "#eeeeee";
    }
  }

  function onMouseLeave(ev) {
    const tr = ev.target.closest("TR");
    if (!focusRec.current || tr.id !== focusRecID.current) {
      tr.style.backgroundColor = "";
    }
  }

  function onClick() {
    oldCheckData.current = checkData.current;
    setLoad(true);
    props.request(props.dataForReq).then((res) => {
      setData(res);
      setLoad(false);
    });
  }

  function onClickTr(ev) {
    if (focusRec.current !== undefined) {
      focusRec.current.style.backgroundColor = "";
    }

    const record = ev.target.closest("TR");
    record.style.backgroundColor = "#e0e0e0";
    focusRec.current = record;
    focusRecID.current = record.id;

    filterValueChange.current = true;
    setDisabledAcceptBttn(false);

    if (ev.target.tagName === "INPUT") {
      onCheckTr(record);
      return;
    }
    checkData.current[record.id] = true;
    onAccept(record);
    handleClose();
  }

  function onCheckTr(record) {
    const result = !checkData.current[record.id];
    checkData.current[record.id] = result;
    if (result === false && record) {
      record.style.backgroundColor = "";
      focusRec.current = undefined;
      focusRecID.current = undefined;
    }
  }

  function onCheck() {
    setMultiCheck(!multiCheck);
  }

  function onDeleteFilter() {
    const result = {};

    clearFilterState();

    const data = props.filterData;

    if (data[props.dataForReq.FieldName]) {
      delete data[props.dataForReq.FieldName];
    }

    for (const [fildNameData, fieldData] of Object.entries(data)) {
      if (fieldData && fieldData.value) result[fildNameData] = fieldData.value;
    }

    if (props.filterData.rowFilter) {
      for (const [fildNameData, fieldData] of Object.entries(
        props.filterData.rowFilter
      )) {
        result[fildNameData] = fieldData;
      }
    }

    props.applyFilter(result);

    handleClose();
  }

  function clearFilterState() {
    setFiltered(false);
    oldCheckData.current = {};
    focusRec.current = undefined;
    focusRecID.current = undefined;
  }

  function onCheckAll() {
    filterValueChange.current = true;
    setDisabledAcceptBttn(false);

    let checkValues = {};
    records.forEach((item) => {
      checkValues[item.props.id] = true;
    });
    checkData.current = checkValues;

    setRecords([]);
    setNeedUpdateRec(true);
  }

  function onUnCheckAll() {
    if (focusRec.current) {
      focusRec.current.style.backgroundColor = "";
      focusRec.current = undefined;
      focusRecID.current = undefined;
    }
    filterValueChange.current = true;
    setDisabledAcceptBttn(false);
    checkData.current = {};
    setRecords([]);
    setNeedUpdateRec(true);
  }

  function onAccept(record, placeholder) {
    oldCheckData.current = { ...checkData.current };
    if (record) {
      if (!multiCheck) oldCheckData.current = {};
      oldCheckData.current[record.id] = true;
    }
    if (filterValueChange.current) {
      setFiltered(true);
    }
    applyFilter(record);
    handleClose();
  }

  function onCanceled() {
    handleClose();
  }

  function applyFilter(record) {
    const fieldName = props.dataForReq.FieldName;
    let data = {};
    if (multiCheck) {
      data[fieldName] = [];
      let valueItem;
      for (const [key, value] of Object.entries(checkData.current)) {
        if (value) {
          valueItem = filterBox.current.querySelector(`#${getPureString(key)}`);
          if (valueItem === null) continue;
          valueItem = valueItem.querySelector("div[textvalue=true]").innerText;
          data[fieldName].push(valueItem);
        }
      }
      if (data[fieldName] && data[fieldName].length === 0) {
        data = {};
      }
    } else {
      let value = record.querySelector("div[textvalue=true]").innerText;

      if (record.id.match("onlyText")) {
        data[fieldName] = [];
        data[fieldName].push(value);
      } else {
        let id = record.id.split("_")[1];
        data[fieldName] = [];
        data[fieldName].push(value);
      }
    }

    props.filterData[fieldName].value = data[fieldName];

    for (const [fildNameData, fieldData] of Object.entries(props.filterData)) {
      if (
        props.filterData.rowFilter &&
        props.filterData.rowFilter[fildNameData]
      ) {
        delete props.filterData.rowFilter[fildNameData];
        for (const input of props.filterDiv.children) {
          if (
            input.tagName === "INPUT" &&
            input.field.fieldName === fildNameData
          ) {
            input.value = "";
            break;
          }
        }
      }
      if (fildNameData === fieldName) continue;
      if (fieldData.value && !isEmptyObject(fieldData.value)) {
        data[fildNameData] = fieldData.value;
      }
    }

    const rowFilter = {};
    if (props.filterData.rowFilter) {
      for (const [fildNameData, fieldData] of Object.entries(
        props.filterData.rowFilter
      )) {
        rowFilter[fildNameData] = fieldData;
      }
    }

    props.applyFilter({ ...rowFilter, ...data });

    if (isEmptyObject(data)) {
      setFiltered(false);
    }
  }

  function getPureString(str) {
    // [ ] \ ^ $ . | ? * + (
    let result = str;
    result = result.replaceAll("[", "\\[");
    result = result.replaceAll("]", "\\]");
    result = result.replaceAll("\\", "\\\\");
    result = result.replaceAll("^", "\\^");
    result = result.replaceAll("$", "\\$");
    result = result.replaceAll(".", "\\.");
    result = result.replaceAll("|", "\\|");
    result = result.replaceAll("?", "\\?");
    result = result.replaceAll("*", "\\*");
    result = result.replaceAll("+", "\\+");
    result = result.replaceAll("(", "\\(");

    return result;
  }

  function getRecords(data) {
    const arrRec = [];
    if (data && data.Values) {
      const headerElId =
        data.Values[`$tag`] !== undefined && `${data.Values[`$tag`]}`;

      const values = Object.values(data.Values);

      values.forEach((item, id) => {
        if (typeof item === "number") return;
        if (typeof item === "object") {
          if (item.id !== headerElId) {
            arrRec.push(
              <tr
                id={`item_${item.id}_${id}`}
                key={`item_${item.id}_${id}`}
                style={{
                  height: "27px",
                  userSelect: "none",
                  backgroundColor:
                    focusRecID.current === `item_${item.id}_${id}`
                      ? "#e0e0e0"
                      : "",
                }}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={onClickTr}
              >
                <td>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ border: "1px solid #eeeeee" }}
                  >
                    <Grid
                      style={{
                        height: "27px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: multiCheck ? "flex" : "none",
                      }}
                    >
                      <CheckBoxMultiCheck
                        CheckState={checkData.current[`item_${item.id}_${id}`]}
                      />
                    </Grid>
                    <Grid
                      textvalue="true"
                      style={{
                        height: "27px",
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: multiCheck ? "calc(100% - 27px)" : "100%",
                      }}
                    >
                      {item.text}
                    </Grid>
                  </Grid>
                </td>
              </tr>
            );
          } else {
            arrRec.unshift(
              <tr
                id={`item_${item.id}_${id}`}
                key={`item_${item.id}_${id}`}
                style={{
                  height: "27px",
                  backgroundColor: "#f5f5f5",
                  userSelect: "none",
                  cursor: "pointer",
                }}
                onClick={onClickTr}
              >
                <td>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ border: "1px solid #eeeeee" }}
                  >
                    <Grid
                      textvalue="true"
                      style={{
                        height: "27px",
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                    >
                      {item.text}
                    </Grid>
                  </Grid>
                </td>
              </tr>
            );
          }
        } else {
          arrRec.push(
            <tr
              id={`item_onlyText_${item}`}
              key={`item_onlyText_${item}`}
              style={{
                height: "27px",
                userSelect: "none",
                backgroundColor:
                  focusRecID.current === `item_onlyText_${item}`
                    ? "#e0e0e0"
                    : "",
              }}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onClick={onClickTr}
            >
              <td>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    border: "1px solid #eeeeee",
                  }}
                >
                  <Grid
                    style={{
                      height: "27px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: multiCheck ? "flex" : "none",
                    }}
                  >
                    <CheckBoxMultiCheck
                      CheckState={checkData.current[`item_onlyText_${item}`]}
                    />
                  </Grid>
                  <Grid
                    textvalue="true"
                    style={{
                      height: "27px",
                      display: "flex",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      width: multiCheck ? "calc(100% - 27px)" : "100%",
                    }}
                  >
                    {item}
                  </Grid>
                </Grid>
              </td>
            </tr>
          );
        }
      });
    }
    return arrRec;
  }

  return (
    <>
      <IconButton
        id={`filter-bttn_${props.dataForReq.FieldName}`}
        ref={filterButton}
        aria-describedby={id}
        onClick={handleClick}
        style={{ width: "15px", height: "15px" }}
      >
        {filtered ? (
          <FilterAltIcon style={{ fontSize: "12px" }} />
        ) : (
          <FilterAltOutlinedIcon style={{ fontSize: "12px" }} />
        )}
      </IconButton>
      <Popover
        sx={{ height: "100%" }}
        slotProps={{
          paper: {
            style: {
              height: "210px",
              width: "210px",
              minHeight: "210px",
              minWidth: "210px",
              resize: "both",
            },
          },
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          ref={filterBox}
          style={{
            height: "100%",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <Box
            style={{
              height: "27px",
              border: "1px solid #eeeeee",
              width: "100%",
              display: filtered ? "flex" : "none",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={onDeleteFilter}
              style={{ height: "100%", width: "100%" }}
            >
              снять фильтр
            </Button>
          </Box>
          <Box
            style={{
              height: filtered
                ? "calc(100% - 27px - 27px)"
                : "calc(100% - 27px)",
              border: "1px solid #eeeeee",
              width: "100%",
              overflow: "auto",
            }}
          >
            {load ? (
              <LoadingMask />
            ) : (
              <table style={{ width: "100%" }}>
                <tbody>{records}</tbody>
              </table>
            )}
          </Box>
          <Box
            style={{
              height: "27px",
              border: "1px solid #eeeeee",
              width: "100%",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid style={{ width: "120px", height: "100%" }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{
                    display: records && records.length > 2 ? "" : "none",
                  }}
                >
                  <Grid style={{ width: "20%", display: "flex" }}>
                    <CheckBoxMultiCheck
                      CheckState={multiCheck}
                      onEdit={onCheck}
                    />
                  </Grid>
                  <Grid style={{ width: "80%" }}>множество</Grid>
                </Grid>
              </Grid>
              <Grid
                style={{
                  width: "calc(100% - 120px)",
                  borderLeft: "1px solid #eeeeee",
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ display: multiCheck === true ? "" : "none" }}
                >
                  <Grid item>
                    <IconButton
                      disabled={
                        Object.values(checkData.current).length ===
                        records.length
                      }
                      onClick={onCheckAll}
                      style={{ width: "20px", height: "20px" }}
                    >
                      <ChecklistOutlinedIcon style={{ fontSize: "12px" }} />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      disabled={Object.values(checkData.current).length === 0}
                      onClick={onUnCheckAll}
                      style={{ width: "20px", height: "20px" }}
                    >
                      <IndeterminateCheckBoxOutlinedIcon
                        style={{ fontSize: "12px" }}
                      />
                    </IconButton>
                  </Grid>

                  <Grid item>
                    <IconButton
                      disabled={disabledAcceptBttn}
                      onClick={() => onAccept(focusRec.current)}
                      style={{ width: "20px", height: "20px" }}
                    >
                      <CheckOutlinedIcon style={{ fontSize: "12px" }} />
                    </IconButton>
                  </Grid>
                  <Grid>
                    <IconButton
                      onClick={onCanceled}
                      style={{ width: "20px", height: "20px" }}
                    >
                      <ClearOutlinedIcon style={{ fontSize: "12px" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Popover>
    </>
  );
}
