import {
  Button,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { AxiosRequest, ImgURL, XMLrequest } from "../../Url";
import ModalWindow from "../Module/ModalWindow";
import PostAddIcon from "@mui/icons-material/PostAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import SortIcon from "@mui/icons-material/Sort";
import ContentCut from "@mui/icons-material/ContentCut";
import ContentPaste from "@mui/icons-material/ContentPaste";
import ContentCopy from "@mui/icons-material/ContentCopy";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import {
  CheckModalBackGrounds,
  CreateBackgroundForModal,
  tokenProcessingTest,
} from "../../TokenProcessing/TokenProcessing";
import { store } from "../../../store";
import { setImages, setRefreshSectionData } from "../../../store/themeSlice";
import React, { useRef } from "react";
import { NestedMenuItem } from "./NestedMenuOrigin/NestedMenuItem";
import { ParseCommand } from "../NotWorkArea(Side&Head)/Header/Header";
import { createUrlParams } from "./urlParams";
// import utf8 from "utf8";
let resultArray;

export const defaultFieldInfo = {
  DataType: "",
  EditStyle: "",
  Options: "",
  TitleAjust: "",
  TextAjust: "",
  Module: "",
  Values: [],
  Details: "",
  OnEdit: "",
  OnList: "",
  OnHint: "",
  ColumnID: "",
  OnCloseUpList: "",
  OnGetEditStyle: "",
  FieldName: "",
  Path: "",
  FilterReq: "",
  ButtonImage: "",
};

export function isEmptyObject(obj) {
  for (var i in obj) {
    if (obj.hasOwnProperty(i)) {
      return false;
    }
  }
  return true;
}

export function isDevoloper() {
  const LastLogin = localStorage.getItem("LastLogin").split(",");
  switch (LastLogin[1]) {
    case "Романов Станислав Павлович":
    case "Смирнов Сергей Валерьевич":
    case "Беляев Даниил Александрович":
    case "Ковалев Андрей Владимирович":
    case "Голенкевич Владимир Александрович":
      return true;
    default:
      return false;
  }
}

export function isSessionActive(load) {
  let params = new Map();
  const LicGUID = localStorage.getItem("login");
  params.set("comand", "info");
  if (
    LicGUID !== "" &&
    LicGUID !== null &&
    LicGUID !== "leave" &&
    LicGUID !== "null"
  )
    params.set("LicGUID", localStorage.getItem("login"));
  params.set("info", "3");
  checkDocState();
  const json = XMLrequest(params, undefined);
  return json.Active === "1";
}

function checkDocState() {
  let docState = localStorage.getItem("docState");
  if (docState === null || docState === "") {
    localStorage.setItem(
      "docState",
      JSON.stringify({
        save: false,
        dbclick: false,
        border: false,
        activerecord: true,
      })
    );
  }
}

export const MenuItems = (json, click, state, GetDisable, props) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid
        item
        style={{
          backgroundColor: "#e6e6e6",
          position: "absolute",
          height: "100%",
          width: "46px",
        }}
      />
      <Grid item>
        {json.map((item, index) => {
          if (item.Caption === "-") {
            return (
              <Divider
                key={`ButtonsMenu-Divider_${index}`}
                orientation="horizontal"
                flexItem
              />
            );
          } else if (item.items) {
            let Disabled = false,
              itemsArr = [];
            itemsArr = MenuItems(item.items, click, state, GetDisable, props);
            if (state) {
              Disabled = GetDisable(item.ID, state, Disabled);
            }
            return (
              <NestedMenuItem
                id={item.ID}
                onClick={click}
                disabled={Disabled}
                formid={item.ID}
                // style={{ height: "25px" }}
                key={"ButtonsMenu" + index}
                doccfgid={props && props.doccfgid ? props.doccfgid : undefined}
                style={{
                  paddingLeft: "26px",
                  height: "32px",
                  marginLeft: 2,
                }}
                leftIcon={
                  item.Image && item.ID
                    ? ImgURL(item.Image, {
                        height: "16px",
                        width: "16px",
                        marginLeft: -13,
                        marginTop: 3,
                        marginRight: 13,
                      })
                    : ""
                  // {item.Image && !item.ID && !item.Status ? item.Image : null}
                }
                label={item.Name ? item.Name : item.Caption}
                parentMenuOpen={true}
              >
                {itemsArr}
              </NestedMenuItem>
            );
          } else {
            let Disabled = false;
            if (state) {
              Disabled = GetDisable(item.ID, state, Disabled);
            }
            return (
              <MenuItem
                id={item.ID}
                onClick={click}
                disabled={Disabled}
                formid={item.ID}
                style={{ height: "25px" }}
                key={"ButtonsMenu" + index}
                doccfgid={props && props.doccfgid ? props.doccfgid : undefined}
              >
                <ListItemIcon>
                  {item.Image && item.ID
                    ? ImgURL(item.Image, { width: "20px" })
                    : null}
                  {item.Image && !item.ID && !item.Status ? item.Image : null}
                </ListItemIcon>
                <ListItemText>
                  {item.Name ? item.Name : item.Caption}
                </ListItemText>
              </MenuItem>
            );
          }
        })}
      </Grid>
    </Grid>
  );
};

export function setActiveSection(id) {
  if (id !== "") {
    let params = new Map();
    params.set("comand", "SetActiveSection").set("SectionID", id);
    AxiosRequest(false, params);
  }
}

export function getIcons(id) {
  let json;
  if (!store.getState().theme.images) {
    const params = new Map();
    params
      .set("prefix", "resources")
      .set("comand", "GetImage")
      .set("Handle", "161601")
      .set("Index", "-1");
    json = XMLrequest(params);
    store.dispatch(setImages(json));
  } else {
    json = store.getState().theme.images;
  }
  return json.Images[id] ? json.Images[id].Image : null;
}

export function OutPutShow(text) {
  const OutPutInput = document.getElementById("OutPutInput");
  if (OutPutInput) {
    const OutPutTextSplitter = document.getElementById("OutPutTextSplitter");
    OutPutTextSplitter.expand(true);
    if (text === undefined) {
      OutPutInput.value = "";
    } else {
      OutPutInput.value += text + "\n";
    }
  }
}

export async function getIconsAsync(id) {
  let json;
  if (!store.getState().theme.images) {
    const params = new Map();
    params
      .set("prefix", "resources")
      .set("comand", "GetImage")
      .set("Handle", "161601")
      .set("Index", "-1");
    json = await AxiosRequest(false, params);
    store.dispatch(setImages(json));
  } else {
    json = store.getState().theme.images;
  }
  return json.Images[id] ? json.Images[id].Image : null;
}

export function clearNoneBreadCrumbs() {
  const breadcrumbs = document.getElementById("BreadcrumbsSelected").firstChild;
  for (const val of Object.values(breadcrumbs.children)) {
    if (val.id === "noneBreadcrumbs") {
      val.remove();
    }
  }
}

export function extend() {
  // Variables
  var extended = {};
  var deep = false;
  var i = 0;

  // Проверьте, не произошло ли глубокое слияние
  if (typeof arguments[0] === "boolean") {
    deep = arguments[0];
    i++;
  }

  // Объедините объект с расширенным объектом
  var merge = function (obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (
          deep &&
          Object.prototype.toString.call(obj[prop]) === "[object Object]"
        ) {
          // Если мы выполняем глубокое слияние и свойство является объектом
          extended[prop] = extend(true, extended[prop], obj[prop]);
        } else {
          // В противном случае выполните обычное слияние
          extended[prop] = obj[prop];
        }
      }
    }
  };

  // Пройдитесь по каждому объекту и выполните слияние
  for (; i < arguments.length; i++) {
    merge(arguments[i]);
  }

  return extended;
}

export function addBreadCrumb(textContent) {
  const breadcrumbs = document.getElementById("BreadcrumbsSelected").firstChild;
  const li = document.createElement("li");
  li.className = "MuiBreadcrumbs-li";
  li.textContent = textContent;
  li.id = "noneBreadcrumbs";
  let separator;
  if (breadcrumbs.children[1]) {
    separator = breadcrumbs.children[1].cloneNode(true);
  } else {
    separator = CreateSeparator();
  }
  separator.id = "noneBreadcrumbs";
  const lastLi = breadcrumbs.lastChild;
  breadcrumbs.appendChild(separator);
  breadcrumbs.appendChild(li);
  return lastLi;
}

export function InsertIdReport(Html, pdf) {
  Html = String(Html).replaceAll("overflow: hidden;", "");
  Html = String(Html).replaceAll('onClick="ClickCells(event)"', "");
  const iframe = document.createElement("iframe");
  iframe.classList = "iframeStimateCustom";
  if (!pdf) {
    iframe.srcdoc = Html;
  } else {
    iframe.title = "PdfPage";
    iframe.src = Html;
  }
  return iframe;
}

export function ClickInFrame(ViewIdent, frameElem, data) {
  ChangeCellText(ViewIdent, frameElem, data);
  LinkrefClick(ViewIdent, frameElem, data);
}

function LinkrefClick(ViewIdent, frameElem, data) {
  // /cellobj~CallCellHandler?LicGUID=4F75AB084C00BEEC47D6CBB52258AB11&Command=DropDown&ViewIdent={1B3C2820-705D-48DD-BA7B-19FFA95073C3}&Path=Root\1\2\2&WSM=1
  try {
    // /cellobj~CallCellHandler?LicGUID=4F75AB084C00BEEC47D6CBB52258AB11&Command=EditRef&ViewIdent={1B3C2820-705D-48DD-BA7B-19FFA95073C3}&Path=Root\1\2\4&SectionID=109&WSM=1 ...
    let frame;
    if (frameElem) {
      frame = frameElem;
    } else {
      let frams = document.querySelectorAll(".Params.ActivParams");
      for (let n = 0; n <= frams.length - 1; n++) {
        frame =
          frameElem === undefined
            ? frams[n].querySelector("iframe.ActivReport")
            : frameElem;
      }
    }
    let AllLinksInFrame =
      frame.contentDocument.getElementsByClassName("linkref");
    for (const value of Object.values(AllLinksInFrame)) {
      if (value.onclick === null) {
        value.command = "EditRef";
        value.viewident = ViewIdent;
        value.path = value.getAttribute("data-path");
        value.sectionid = data.ID;
        value.onclick = function (event) {
          CallCellHandler(event);
        };
      }
    }
    let AllAInFrame = frame.contentDocument.getElementsByTagName("a");
    for (const value of Object.values(AllAInFrame)) {
      if (value.href && value.onclick === null) {
        value.comand = ParseCommand(value.href);
        value.onclick = function (event) {
          const comand = event.currentTarget.comand;
          if (comand.module === "programs") {
            let params = new Map();
            params.set("prefix", "programs");
            params.set("comand", "execABLProgram");
            for (const [key, value] of Object.entries(comand.params)) {
              params.set(key, value);
            }
            // if(command.params.name)params.set("name", command.params.name)
            params.set("WSM", "1");
            tokenProcessingTest(XMLrequest(params));
          }
        };
      }
    }

    frame.changed = "1";
  } catch {}
}

function ChangeCellText(ViewIdent, frameElem) {
  try {
    let frame;
    if (frameElem) {
      frame = frameElem;
    } else {
      let frams = document.querySelectorAll(".Params.ActivParams");
      for (let n = 0; n <= frams.length - 1; n++) {
        frame =
          frameElem === undefined
            ? frams[n].querySelector("iframe.ActivReport")
            : frameElem;
      }
    }
    let AllBlocksThatCanChangeValue =
      frame.contentDocument.querySelectorAll("[data-path]");
    for (const value of Object.values(AllBlocksThatCanChangeValue)) {
      if (value.ondblclick === null) {
        value.ondblclick = function (event) {
          const textBlock = event.currentTarget.children[0].children[0];
          const input = document.createElement("input");
          input.type = "text";
          input.path = value.getAttribute("data-path");
          input.viewident = ViewIdent;
          input.command = "setText";
          input.style.outline = "none";
          input.style.position = "relative";
          input.style.width = value.offsetWidth - 6 + "px";
          // input.style.width = "inherit";
          input.style.height = value.offsetHeight - 4 + "px";
          input.style.border = "none";
          input.style.fontSize = "inherit";
          input.value = textBlock.textContent;
          input.onblur = function (event) {
            value.children[0].style.display = "";
            value.style.padding = "";
            changeEvent(event, textBlock);
          };
          input.onkeydown = function (event) {
            if (event.key === "Enter") {
              value.children[0].style.display = "";
              value.style.padding = "";
              changeEvent(event, textBlock);
            }
          };
          value.children[0].style.display = "none";
          value.style.padding = "0px";
          value.appendChild(input);
          input.focus();
        };
      }
    }
  } catch {}
}

function changeEvent(event, textBlock) {
  if (textBlock.textContent !== event.currentTarget.value) {
    CallCellHandler(event);
    textBlock.textContent = event.currentTarget.value;
  }
  event.target.remove();
}

function CallCellHandler(event) {
  let params = new Map(),
    currentTarget = event.currentTarget;
  const viewIdent = currentTarget.viewident.split(".");
  params.set("prefix", "cellobj");
  params.set("comand", "CallCellHandler");
  if (currentTarget.Command === "setText") {
    params.set("Text", currentTarget.value);
    params.set("Changed", "1");
  }
  if (currentTarget.sectiodid) {
    params.set("SectionID", currentTarget.sectiodid);
  }
  params.set("Command", currentTarget.command);
  params.set("ViewIdent", viewIdent.length > 1 ? viewIdent[1] : viewIdent[0]);
  params.set("Path", currentTarget.path);
  params.set("WSM", "1");
  AxiosRequest(true, params).then((res) => {
    tokenProcessingTest(res);
  });
}

function CreateSeparator() {
  const svg = document
    .getElementById("BreadcrumbsNavigateNextIcon")
    .firstChild.children[1].cloneNode(true);
  return svg;
}

export function FixSplitterHeight(delay) {
  setTimeout(
    () => {
      const elements = document.getElementsByClassName("smart-splitter-bar");
      for (const value of Object.values(elements)) {
        if (value.getAttribute("aria-orientation") !== "horizontal") {
          value.style.height = "100%";
        }
      }
    },
    delay === undefined ? 100 : delay
  );
}

export function GetJsonAttributeByID(CurrnetID, List, AdditionalInformation) {
  let backvalue = {};
  List.filter((Elements) => {
    if (Elements["ID"] === CurrnetID) return (backvalue = Elements);
    return false;
  });
  if (AdditionalInformation) {
    Object.assign(backvalue, AdditionalInformation);
  }
  return backvalue;
}

//функция обновления секций
//id: String
//если не указать id обновится текущая секция
export function updateSection(id, subID) {
  store.dispatch(
    setRefreshSectionData({
      refresh: true,
      refreshId: id,
      CommitFormOnSection: subID,
    })
  );
}

export function SetMiniTabs(element, time) {
  setTimeout(() => {
    let Tabs, tabsHeader, span, textBlock;
    Tabs =
      typeof element === "string" ? document.getElementById(element) : element;
    if (Tabs && !Tabs.getAttribute("styled")) {
      Tabs.setAttribute("styled", "true");
      const tabHeaderItems = Tabs["$tabsHeaderItems"].element;
      tabHeaderItems.style.height = "27px";
      for (const value of Object.values(Tabs["_tabLabelContainers"])) {
        textBlock = value.children[0].children[0];
        textBlock.style.position = "relative";
        textBlock.style.top = "-5px";
      }
      tabsHeader = Tabs.children[0].children[0];
      tabsHeader.style.backgroundColor = "#ffffff";
      tabsHeader.classList.add("disableBorder");
      Tabs.children[0].children[0].children[0].classList.add("SmartCustom");
      Tabs.children[0].children[1].style.overflowY = "hidden";
      Tabs.children[0].children[1].classList.add("headerSmartCustom");
      span =
        Tabs.children[0].children[0].children[0].getElementsByTagName(
          "span"
        )[0];
      span.classList.add("selectionBarCustom");
    }
  }, time);
}

/////////////////////////////////////////////////////////////////////////////////
/////////////////////////секция Адрес////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////

//перевод входных данных(если оставить поле пустым("") оно не будет отображаться)
export function localization() {
  return {
    Country: "Страна", //0 = 0
    Region: "Регион", //1 = 1
    District: "Район", //2 = 2
    Settlement: "Сельское/городское поселение", //30 = 3
    City: "Город", //4 = 4
    CityPart: "Городской район", //19 = 5
    Community: "Населенный пункт", //3 = 6
    PlanStruct: "Элемент планировочной структуры", //22 = 7
    Street: "Улица", //5 = 8
    LandPlot: "Земельный участок", //23 = 9
    House: "Дом", //6 = 10
    BulkNo: "Корпус", //7 = 11
    BuildNo: "Строение", //25 = 12
    FlatNo: "Квартира", //8 = 13
    OfficeNo: "Помещение", //18 = 14
    Parking: "Машино-место", //29 = 15
    PostIndex: "Почтовый индекс", //10 = 16
    Phone: "Телефон", //12 = 17
    EMail: "Эл. Адрес", //13 = 18
    CodeNo: "Код НО", //11 = 19
    Classificator: "Классификатор", //9 = 20
    Identifier: "ФИАС (ГАР)", //21 = 21
    RegDate: "Дата регистрации", //17 = 22
    Options: "Адрес из ЕГРЮЛ", //20 = 23
    ShortName: "Сокращение",
    Code: "Код",
    IsShared: "", //15 = 23
    OKATD: "", //16 = 24
    BulkType: "", //14 = 25
    HouseType: "", //24 = 26
    BuildType: "", //26 = 27
    FlatType: "", //27 = 28
    RoomType: "", //28 = 29
  };
}

export function usePrevious(value) {
  // хук для получения предыдущего значения
  const ref = useRef();
  React.useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}

//функция для получения ID записи
export function getIdByField(FieldName) {
  let name = FieldName;
  const rename = {
    HouseType: "House",
    BulkType: "BulkNo",
    BuildType: "BuildNo",
    FlatType: "FlatNo",
    RoomType: "OfficeNo",
  };
  if (rename[name] !== undefined) {
    name = rename[name];
  }
  let id;
  for (let i = 0; i < resultArray.length; i++) {
    if (resultArray[i].FieldName === name) {
      id = resultArray[i].ID;
      break;
    }
  }
  return id;
}

//возвращает название строения по сокращению если оно есть в карте
export function getNameByValue(value) {
  const result = value.replace(".", "");
  const mapValue = mapBuildings();
  if (mapValue[result]) {
    return mapValue[result];
  }
}
//карта со значениями названий строений
function mapBuildings() {
  return {
    д: "Дом",
    влд: "Владение",
    двлд: "Домовладение",
    "г-ж": "Гараж",
    зд: "Здание",
    шахта: "Шахта",
    стр: "Строение",
    соор: "Сооружение",
    литера: "Литера",
    к: "Корпус",
    подв: "Подвал",
    кот: "Котельная",
    "п-б": "Погреб",
    ОНС: "Объект незавершенного строительства",
  };
}
/*
Домоволадение=двлд
/=value[.]?$/
/value=/
*/

//данные для сортировки(порядок записей: {входной индекс от сервера : необходимый индекс })
export function getSortAddressMap() {
  return {
    0: 0,
    1: 1,
    2: 2,
    3: 6,
    4: 4,
    5: 8,
    6: 10,
    7: 11,
    8: 13,
    9: 20,
    10: 16,
    11: 19,
    12: 17,
    13: 18,
    14: 26,
    15: 24,
    16: 25,
    17: 22,
    18: 14,
    19: 5,
    20: 23,
    21: 21,
    22: 7,
    23: 9,
    24: 27,
    25: 12,
    26: 28,
    27: 29,
    28: 30,
    29: 15,
    30: 3,
  };
}

//Сортировка записей по заданному порядку
export function sortAddresArray(array = [], sortedMap = getSortAddressMap()) {
  const result = new Array(30);
  const sortMap = sortedMap;
  for (let i = 0; i < array.length; i++) {
    result[sortMap[i]] = array[i];
  }
  return result;
}

//Создание объекта для Params
export function getParamsObject(items, selectedRecordID, title, parentModule) {
  resultArray = [];
  const itemArray = sortAddresArray(items.Values, getSortAddressMap());
  const localize = localization();
  let ID = 0;
  let EditStyle, HouseType;
  itemArray.forEach((item, index) => {
    if (localize[item.FieldName] !== "") {
      switch (index) {
        case 10:
          HouseType = true;
          item.Conditions = "1";
          EditStyle = "81";
          break;
        case 11:
          HouseType = true;
          item.Conditions = "1";
          EditStyle = "81";
          break;
        case 12:
          HouseType = true;
          item.Conditions = "1";
          EditStyle = "81";
          break;
        case 13:
          HouseType = true;
          item.NoKldr = true;
          EditStyle = undefined;
          break;
        case 14:
          HouseType = true;
          item.NoKldr = true;
          EditStyle = undefined;
          break;
        case 16:
          EditStyle = "64";
          break;
        case 20:
          EditStyle = "64";
          break;
        case 21:
          EditStyle = "64";
          break;
        case 22:
          EditStyle = "2";
          break;
        case 23:
          EditStyle = "68";
          item.CheckState = item.Value;
          item.Value = undefined;
          break;
        default:
          if (item.DataType) {
            EditStyle = "80";
          } else {
            if (item.FieldName === "FlatNo" || item.FieldName === "OfficeNo") {
              item.Conditions = "1";
            }
            EditStyle = undefined;
            HouseType = false;
          }
      }
      resultArray.push({
        ID: ID,
        FieldName: item.FieldName,
        Name: item.Name ? item.Name : localize[item.FieldName],
        Value: item.Value,
        CheckState: item.CheckState,
        EditStyle: EditStyle,
        HouseType: HouseType,
        DataType: item.DataType ? item.DataType : "",
        ObjRef: item.ID,
        Conditions: item.Conditions,
        NoKldr: item.NoKldr,
        Level: item.FieldName === "CityPart" ? "1" : undefined,
        Current: items.ID,
        GroupID: selectedRecordID,
        Module: "books",
      });
      ID++;
      if (
        item.ShortName ||
        item.FieldName === "District" ||
        item.FieldName === "City" ||
        item.FieldName === "Community" ||
        item.FieldName === "Region" ||
        item.FieldName === "Settlement"
      ) {
        resultArray.push({
          ID: ID,
          FieldName: "ShortName",
          Name: localize["ShortName"],
          Value: item.ShortName,
          EditStyle: "64",
          Level: "1",
        });
        ID++;
      }
      if (item.Code || item.FieldName === "Region") {
        if (item.FieldName !== "Region") return;
        resultArray.push({
          ID: ID,
          FieldName: "Code",
          Name: localize["Code"],
          Value: item.Code,
          EditStyle: "64",
          Level: "1",
        });
        ID++;
      }
    }
  });
  return {
    ID: items.ID,
    address: title,
    Module: "address",
    parentModule: parentModule,
    Caption: items.Caption,
    Items: resultArray,
    Path: "Address",
    GroupID: selectedRecordID,
    NoHistory: true,
    division: items.dv ? items.dv : "0",
  };
}

export function GetBookDialogParams(DataType, ObjRef) {
  const params = new Map();
  params
    .set("prefix", "books")
    .set("comand", "GetDialogParams")
    .set("ObjType", DataType);
  if (ObjRef !== undefined) params.set("ObjRef", ObjRef);
  return XMLrequest(params);
}

export function AddressEditKladrObj(ID, DataType, Name) {
  const params = new Map();
  params
    .set("prefix", "address")
    .set("comand", "AddressEditKladrObj")
    .set("ID", ID)
    .set("Level", DataType ? DataType : "1")
    .set("Name", Name)
    .set("WSM", "1");

  const res = XMLrequest(params);
  return res;
}

//функция создания всплывающего окна с подтверждением
//Входные данные:
//title : текст сообщения
//onConfirm : функция которая выполнится в случае подтверждения
export function createConfirmation(title, onConfirm, setPrevVal, PrevVal) {
  CreateBackgroundForModal(
    <ModalWindow
      Title={"Подтверждение"}
      style={{
        height: `${150}px`,
        width: `${380}px`,
        minWidth: "380px",
        minHeight: "150px",
        maxWidth: "380px",
        maxHeight: "150px",
      }}
      hiddenButton={true}
    >
      <div
        style={{
          height: "calc(100% - 40px)",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <span>{title}</span>
      </div>
      <div
        style={{ height: "40px", display: "flex", justifyContent: "center" }}
      >
        <Button
          onClick={onConfirm}
          variant="outlined"
          style={{ marginRight: "30px", height: "34px" }}
          className="button_Modal_Select"
        >
          Да
        </Button>
        <Button
          onClick={() => {
            if (setPrevVal) {
              setPrevVal(PrevVal);
            }
          }}
          variant="outlined"
          style={{ height: "34px" }}
          className="button_Modal_Close"
        >
          Нет
        </Button>
      </div>
    </ModalWindow>
  );
}

/////////////////////////////////////////////////////////////////////////////////
/////////////////////////секция SearchTools//////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////

export function AutoTextTranslate(str) {
  var search = [
    "й",
    "ц",
    "у",
    "к",
    "е",
    "н",
    "г",
    "ш",
    "щ",
    "з",
    "х",
    "ъ",
    "ф",
    "ы",
    "в",
    "а",
    "п",
    "р",
    "о",
    "л",
    "д",
    "ж",
    "э",
    "я",
    "ч",
    "с",
    "м",
    "и",
    "т",
    "ь",
  ];
  var replace = [
    "q",
    "w",
    "e",
    "r",
    "t",
    "y",
    "u",
    "i",
    "o",
    "p",
    "\\[",
    "\\]",
    "a",
    "s",
    "d",
    "f",
    "g",
    "h",
    "j",
    "k",
    "l",
    ";",
    "'",
    "z",
    "x",
    "c",
    "v",
    "b",
    "n",
    "m",
  ];

  function checkCase(letter) {
    return letter === letter.toLowerCase()
      ? search[i]
      : search[i].toUpperCase();
  }

  for (var i = 0; i < replace.length; i++) {
    var reg = new RegExp(replace[i], "mig");
    str = str.replace(reg, (a) => {
      checkCase(a);
    });
  }
  return str;
}

/////////////////////////////////////////////////////////////////////////////////
/////////////////////////секция elementsTools////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////

//функция обновления таблицы по id
//Настройки нужны для функции getElementsById
//если настроек нет, то будут обновленны все таблицы связанные этим id
export function updateGridById(settings) {
  const currentSec = document.querySelector(".TabDocActiv");
  let el;
  if (currentSec && CheckModalBackGrounds() === 0) {
    el = currentSec.querySelectorAll("[rcsgrid_panel]");
  } else {
    el = [];
    const modals = document.querySelectorAll(".background_ModalDialog");
    modals.forEach((elem) => {
      const gridpanels = elem.querySelectorAll("[rcsgrid_panel]");
      if (gridpanels.length) {
        el = [...el, ...gridpanels];
      }
    });
  }
  if (!settings || !(settings.last || settings.first)) {
    if (el.length !== 0) {
      el.forEach((elem) => {
        if (elem.grid) elem.grid.updateGridSize();
      });
      return;
    }
  }
  if (el && el.length > 0 && el[el.length - 1].grid)
    el[el.length - 1].grid.updateGridSize();
}

export function HiddenNavSet(dataState) {
  createUrlParams(dataState, true);
  // window.history.pushState(dataState, dataState.Patch);
  document.title = dataState.Patch;
}

//функция для обновления всех таблиц
//timer - количество миллисекунд по истечении которых произойдет обновление
export function updateGridData(timer, settings) {
  setTimeout(() => {
    updateGridById(settings);
    //здесь можно добавить id таблицы
  }, timer);
}

//функция фозвращает массив элементов по указонному id
//Настройки: {last:true || first:true} - возвращает последний или первый элемент
//без настроек вернет маcсив с элементами
//Примечание: id не должен содержать символов входящих в синтаксис JSON например: "}","{",":"
export function getElementsById(id, settings) {
  if (id.match(/[{}:]+/)) {
    console.log(
      'ERROR:\n ID element contains JSON object character - "} { :" '
    );
    return [];
  }
  const elmentsArray = document.querySelectorAll(`#${id}`);
  if (settings) {
    if (settings.last) return elmentsArray[elmentsArray.length - 1];
    if (settings.first) return elmentsArray[0];
  }
  return elmentsArray;
}

export function refreshSplitters(elements = []) {
  if (elements.length) {
    elements.forEach((el) => {
      el.collapse();
      setTimeout(() => {
        el.expand();
      }, 251);
    });
  }
  // const element0 = document.getElementById("org_splitter_item_1");
  // element0.collapse();
  // setTimeout(() => {
  //   element0.expand();
  // }, 251);
  // const element = document.getElementById("org_splitter_item_2");
  // element.collapse();
  // setTimeout(() => {
  //   element.expand();
  // }, 251);
}

export function throttle(callee, timeout) {
  // Таймер будет определять,
  // надо ли нам пропускать текущий вызов.
  let timer = null;

  // Как результат возвращаем другую функцию.
  // Это нужно, чтобы мы могли не менять другие части кода,
  // чуть позже мы увидим, как это помогает.
  return function perform(...args) {
    // Если таймер есть, то функция уже была вызвана,
    // и значит новый вызов следует пропустить.
    if (timer) return;

    // Если таймера нет, значит мы можем вызвать функцию:
    timer = setTimeout(async () => {
      // Аргументы передаём неизменными в функцию-аргумент:
      await callee(...args);

      // По окончании очищаем таймер:
      clearTimeout(timer);
      timer = null;
    }, timeout);
  };
}

export function getStyleWindow(CLSID) {
  const data = {
    "{5D1A1010-A394-43EC-A84B-AB401C1C2640}": {
      height: `${420}px`,
      width: `${(window.innerWidth / 100) * 35}px`,
    },
    "{37912C20-59B2-415F-8F59-DE7F85AABB00}": {
      height: window.innerHeight * 0.6 + "px",
      width: window.innerWidth * 0.4 + "px",
    },
    "{2AC0E428-23CD-49F6-AF96-3DB9D6B7AD0A}": {
      height: window.innerHeight * 0.7 + "px",
      width: window.innerWidth * 0.7 + "px",
      minHeight: "500px",
      minWidth: "680px",
    },
    "{7FAC2B20-109D-4406-A757-61674277B71F}": {
      height: window.innerHeight * 0.6 + "px",
      width: window.innerWidth * 0.65 + "px",
      minHeight: "500px",
      minWidth: "680px",
    },
    "{48C155C0-1336-11D6-9CF2-DB1D5BC3046D}": {
      width: window.innerWidth * 0.7 + "px",
      height: window.innerHeight * 0.75 + "px",
      minHeight: "500px",
      minWidth: "680px",
    },
    "{AE5A67E0-EBEB-11D5-900D-000021E6A31F}": {
      height: `${420}px`,
      width: `${(window.innerWidth / 100) * 35}px`,
      minHeight: "300px",
      minWidth: "600px",
    },
    "{295EA015-4573-4AD9-922A-A14CE0FD9C78}": {
      height: `${420}px`,
      width: `${(window.innerWidth / 100) * 35}px`,
    },
    "{3748C5C5-C0E9-4D45-8E05-17A046FD5C56}": {
      height: "300px",
      width: "310px",
      minHeight: "300px",
      minWidth: "310px",
      maxHeight: "300px",
      maxWidth: "310px",
    },
    "{C525B0F8-232B-4136-A5F2-AB22C77F55F0}": {
      minHeight: "230px",
      minWidth: "265px",
      maxHeight: "230px",
      maxWidth: "265px",
    },
    "{5FEE058E-8083-453F-B31E-FB8DD7ED5174}": {
      height: "300px",
      width: "480px",
      minHeight: "300px",
      minWidth: "480px",
    },
    "{3D16C8B0-9C78-474C-9D3D-10112A3F96A6}": {
      height: "500px",
      width: "410px",
      minHeight: "500px",
      minWidth: "410px",
      maxHeight: "500px",
      maxWidth: "410px",
    },
    "{5F859354-243D-449C-A4BC-B89EA9A5D1B2}": {
      height: "180px",
      width: "480px",
      minHeight: "180px",
      minWidth: "480px",
    },
    "{5F859354-243D-449C-A4BC-B89EA9A5D1B2}Group": {
      height: `450px`,
      width: `${(window.innerWidth / 100) * 40}px`,
      minHeight: "420px",
      minWidth: "480px",
    },
    "{010C6A4E-4954-4380-9E9F-40712B45D0DE}": {
      height: window.innerHeight * 0.6 + "px",
      width: window.innerWidth * 0.65 + "px",
      minHeight: "500px",
      minWidth: "680px",
    },
    "{E39CC90D-5F17-4FDF-883E-E75DEA69BE60}": {
      height: window.innerHeight * 0.7 + "px",
      width: window.innerWidth * 0.7 + "px",
      minHeight: "500px",
      minWidth: "680px",
    },
    "{C79B4449-B9CE-41C2-A28F-FB40EBA7E9DA}": {
      height: window.innerHeight * 0.6 + "px",
      width: window.innerWidth * 0.65 + "px",
      minHeight: "500px",
      minWidth: "680px",
    },
    "{03338F11-FC1E-11D4-A605-0000E8D9CBDB}": {
      width: window.innerWidth * 0.7 + "px",
      height: window.innerHeight * 0.75 + "px",
      minHeight: "500px",
      minWidth: "680px",
    },
    "{353FD9D7-651E-4840-9319-A8578806C496}": {
      height: window.innerHeight * 0.8 + "px",
      width: window.innerWidth * 0.8 + "px",
      minHeight: "500px",
      minWidth: "680px",
    },
    "{408E20A3-4BE3-4DCD-98BD-2613A8968783}": {
      height: window.innerHeight * 0.7 + "px",
      width: window.innerWidth * 0.7 + "px",
      minHeight: "500px",
      minWidth: "680px",
    },
    complex: {
      height: window.innerHeight * 0.6 + "px",
      width: window.innerWidth * 0.65 + "px",
      minHeight: "500px",
      minWidth: "680px",
    },
    InputText: {
      height: "150px",
      width: "350px",
      minHeight: "150px",
      minWidth: "350px",
    },
    "{B52AB815-36CC-4921-A1C0-6C3649D9D0E9}": {
      height: "470px",
      width: "470px",
      minHeight: "470px",
      minWidth: "470px",
    },
    "{231F49EB-85F0-4226-9AC6-E3BD853CD7DA}": {
      height: `${800}px`,
      width: `${(window.innerWidth / 100) * 75}px`,
      minHeight: "800px",
      minWidth: `${(window.innerWidth / 100) * 60}px`,
    },
    Sample: {
      height: "150px",
      width: "350px",
      minHeight: "150px",
      minWidth: "350px",
      maxHeight: "150px",
    },
    ShowDataSet: {
      height: "500px",
      width: window.innerWidth * 0.55 + "px",
      minHeight: "500px",
      width: window.innerWidth * 0.55 + "px",
    },
    params: {
      height: "500px",
      width: window.innerWidth * 0.55 + "px",
      minHeight: "500px",
      width: window.innerWidth * 0.55 + "px",
    },
    sequence: {
      height: "550px",
      width: "526px",
      minHeight: "550px",
      minWidth: "526px",
      maxHeight: "550px",
      maxWidth: "526px",
    },
    default: {
      height: `${450}px`,
      width: `${(window.innerWidth / 100) * 40}px`,
      minHeight: "420px",
      minWidth: "480px",
    },
  };
  if (data[CLSID]) {
    return data[CLSID];
  } else {
    return data.default;
  }
}

export function getModalSectionID() {
  return 100 - CheckModalBackGrounds();
}

export function getDefaultMenu(selector, data) {
  switch (selector) {
    case "organizations":
      // Values
      //
      // Table:
      //
      // SelectToMove -
      // InsertToGroup -
      // Insert +
      // Delete +
      // ViewMode -
      // SaveMark -
      //
      // Header:
      //
      // Sort +
      // Sort- +
      // ClearSort +
      // GroupShow +
      // ColumnFilter -
      //
      // Grouping:
      //
      // GroupShow +
      // GroupShowExtended -

      return {
        //grid
        Table: [
          {
            Caption: "Отметить для перемещения",
            value: "SelectToMove",
            Image: <ContentCut fontSize="small" />,
          },
          {
            Caption: "Перенести в группу",
            value: "InsertToGroup",
            Image: <ContentPaste fontSize="small" />,
          },
          { Caption: "Divider" },
          {
            Caption: "Новая запись",
            value: "Insert",
            Image: <PostAddIcon fontSize="small" />,
          },
          {
            Caption: "Удалить запись",
            value: "Delete",
            Image: <DeleteIcon fontSize="small" />,
          },
          { Caption: "Divider" },
          { Caption: "Режим просмотра", value: "ViewMode" },
          { Caption: "Сохранять отметку", value: "SaveMark" },
        ],
        Header: [
          { Caption: "Divider" },
          {
            Caption: "По возрастанию",
            Image: <SortIcon fontSize="small" />,
            value: "Sort",
          },
          {
            Caption: "По убыванию",
            Image: (
              <SortIcon fontSize="small" style={{ transform: "scale(1,-1)" }} />
            ),
            value: "Sort-",
          },
          {
            Caption: "Снять сортировку",
            value: "ClearSort",
          },
          { Caption: "Divider" },
          { Caption: "Область группировки", value: "GroupShow" },
          { Caption: "Поиск по столбцу", value: "ColumnFilter" },
        ],
        Grouping: [
          { Caption: "Область группировки", value: "GroupShow" },
          {
            Caption: "Расширенная группировка",
            value: "GroupShowExtended",
            Disabled: true,
          },
        ],
      };

    case "grades":
      return {
        Table: [
          {
            Caption: "Конфигурационные данные",
            value: "ConfigData",
            Disabled: true,
          },
          {
            Caption: "Восстановить из конфигурации",
            value: "ConfigRestore",
          },
          {
            Caption: "Копировать запись",
            value: "Copy",
            Image: <ContentCopy fontSize="small" />,
            Disabled: true,
          },
          {
            Caption: "Вырезать запись",
            value: "Cut",
            Image: <ContentCut fontSize="small" />,
            Disabled: true,
          },
          {
            Caption: "Вставить запись",
            value: "Paste",
            Image: <ContentPaste fontSize="small" />,
            Disabled: true,
          },
          { Caption: "Divider" },
          {
            Caption: "Новая запись",
            value: "Insert",
            Image: <PostAddIcon fontSize="small" />,
          },
          {
            Caption: "Удалить запись",
            value: "Delete",
            Image: <DeleteIcon fontSize="small" />,
          },
          { Caption: "Divider" },
          { Caption: "Режим просмотра", value: "ViewMode", Disabled: true },
          { Caption: "Сохранять отметку", value: "SaveMark", Disabled: true },
        ],
        Header: [{ Caption: "Поиск по столбцу", value: "ColumnFilter" }],
      };
    case "clsbook":
      return {
        Table: [
          {
            Caption: "Добавить группу",
            value: "AddGroup",
            Image: <AddBoxOutlinedIcon />,
          },
          {
            Caption: "Добавить подгруппу",
            value: "AddGroupInner",
            Image: <AddToPhotosOutlinedIcon />,
          },
          {
            Caption: "Восстановить из конфигурации",
            value: "Restore",
            Disabled: true,
          },
          { Caption: "Divider" },
          { Caption: "Режим просмотра", value: "ViewMode", Disabled: true },
          { Caption: "Сохранять отметку", value: "SaveMark", Disabled: true },
        ],
        Header: [
          { Caption: "Divider" },
          { Caption: "Область группировки", value: "GroupShow" },
          { Caption: "Поиск по столбцу", value: "ColumnFilter" },
        ],
        Grouping: [
          { Caption: "Область группировки", value: "GroupShow" },
          {
            Caption: "Расширенная группировка",
            value: "GroupShowExtended",
            Disabled: true,
          },
        ],
      };

    case "accountsPlan":
      return {
        Table: [
          {
            Caption: "Создать счет",
            value: "AddGroup",
            Image: <AddBoxOutlinedIcon />,
          },
          {
            Caption: "Создать субсчет",
            value: "AddGroupInner",
            Image: <AddToPhotosOutlinedIcon />,
          },
          {
            Caption: "Удалить запись",
            value: "Delete",
            Image: <DeleteIcon fontSize="small" />,
          },
          { Caption: "Divider" },
          { Caption: "Режим просмотра", value: "ViewMode", Disabled: true },
          { Caption: "Сохранять отметку", value: "SaveMark", Disabled: true },
        ],
        Header: [
          { Caption: "Divider" },
          { Caption: "Область группировки", value: "GroupShow" },
          { Caption: "Поиск по столбцу", value: "ColumnFilter" },
        ],
        Grouping: [
          { Caption: "Область группировки", value: "GroupShow" },
          {
            Caption: "Расширенная группировка",
            value: "GroupShowExtended",
            Disabled: true,
          },
        ],
      };

    case "accountsAnalitic":
      return {
        Table: [
          // {
          //   Caption: "Добавить пользовательскую аналитику",
          //   value: "InsertByUser",
          //   Image: <AccountBoxIcon fontSize="small" />,
          // },
          {
            Caption: "Новая запись",
            value: "Insert",
            Image: <PostAddIcon fontSize="small" />,
          },
          {
            Caption: "Удалить запись",
            value: "Delete",
            Image: <DeleteIcon fontSize="small" />,
          },
          { Caption: "Divider" },
          { Caption: "Режим просмотра", value: "ViewMode", Disabled: true },
          { Caption: "Сохранять отметку", value: "SaveMark", Disabled: true },
        ],
        Header: [
          { Caption: "Divider" },
          { Caption: "Область группировки", value: "GroupShow" },
          { Caption: "Поиск по столбцу", value: "ColumnFilter" },
        ],
        Grouping: [
          { Caption: "Область группировки", value: "GroupShow" },
          {
            Caption: "Расширенная группировка",
            value: "GroupShowExtended",
            Disabled: true,
          },
        ],
      };

    case "NFA":
      // Values
      //
      // Table:
      //
      // ShowGroupID -
      // SelectToMove -
      // SelectToCopy -
      // InsertToGroup -
      // CreateGroup -
      // AddToObject -
      // AllObjects -
      // Insert +
      // Delete +
      // ViewMode -
      // SaveMark -
      //
      // Header:
      //
      // Sort +
      // Sort- +
      // ClearSort +
      // GroupShow +
      // ColumnFilter -
      //
      // Grouping:
      //
      // GroupShow +
      // GroupShowExtended -
      return {
        //grid
        Table: [
          {
            Caption: "Показать в группе",
            value: "ShowGroupID",
          },
          {
            Caption: "Отметить для перемещения",
            Image: <ContentCut fontSize="small" />,
            value: "SelectToMove",
          },
          {
            Caption: "Отметить для копирования",
            Image: <ContentCopy fontSize="small" />,
            value: "SelectToCopy",
          },
          {
            Caption: "Перенести в группу",
            Image: <ContentPaste fontSize="small" />,
            value: "InsertToGroup",
          },
          { Caption: "Divider" },
          {
            Caption: "Создать группу объектов...",
            value: "CreateGroup",
          },
          {
            Caption: "Добавить к объекту",
            value: "AddToObject",
          },
          {
            Caption: "Все объекты группы",
            value: "AllObjects",
          },
          { Caption: "Divider" },
          {
            Caption: "Новая запись",
            value: "Insert",
            Image: <PostAddIcon fontSize="small" />,
          },
          {
            Caption: "Удалить запись",
            value: "Delete",
            Image: <DeleteIcon fontSize="small" />,
          },
          { Caption: "Divider" },
          { Caption: "Режим просмотра", value: "ViewMode" },
          { Caption: "Сохранять отметку", value: "SaveMark" },
        ],
        Header: [
          { Caption: "Divider" },
          {
            Caption: "По возрастанию",
            Image: <SortIcon fontSize="small" />,
            value: "Sort",
          },
          {
            Caption: "По убыванию",
            Image: (
              <SortIcon fontSize="small" style={{ transform: "scale(1,-1)" }} />
            ),
            value: "Sort-",
          },
          {
            Caption: "Снять сортировку",
            value: "ClearSort",
          },
          { Caption: "Divider" },
          { Caption: "Область группировки", value: "GroupShow" },
          { Caption: "Поиск по столбцу", value: "ColumnFilter" },
        ],
        Grouping: [
          { Caption: "Область группировки", value: "GroupShow" },
          {
            Caption: "Расширенная группировка",
            value: "GroupShowExtended",
            Disabled: true,
          },
        ],
      };
    case "HandleAccounts":
      // Values
      //
      // Table:
      //
      // ReverseSelected -
      // Calc -
      // Insert +
      // Delete +
      // ViewMode -
      // SaveMark -
      //
      // Header:
      //
      // Sort +
      // Sort- +
      // ClearSort +
      // GroupShow +
      // ColumnFilter -
      //
      // Grouping:
      //
      // GroupShow +
      // GroupShowExtended -
      return {
        //grid
        Table: [
          {
            Caption: "Обратить выделение",
            value: "ReverseSelected",
          },
          {
            Caption: "Калькулятор",
            value: "Calc",
          },
          { Caption: "Divider" },
          {
            Caption: "Новая запись",
            value: "Insert",
            Image: <PostAddIcon fontSize="small" />,
          },
          {
            Caption: "Удалить запись",
            value: "Delete",
            Image: <DeleteIcon fontSize="small" />,
          },
          { Caption: "Divider" },
          { Caption: "Режим просмотра", value: "ViewMode" },
          { Caption: "Сохранять отметку", value: "SaveMark" },
        ],
        Header: [
          { Caption: "Divider" },
          {
            Caption: "По возрастанию",
            Image: <SortIcon fontSize="small" />,
            value: "Sort",
          },
          {
            Caption: "По убыванию",
            Image: (
              <SortIcon fontSize="small" style={{ transform: "scale(1,-1)" }} />
            ),
            value: "Sort-",
          },
          {
            Caption: "Снять сортировку",
            value: "ClearSort",
          },
          { Caption: "Divider" },
          { Caption: "Область группировки", value: "GroupShow" },
          { Caption: "Поиск по столбцу", value: "ColumnFilter" },
        ],
        Grouping: [
          { Caption: "Область группировки", value: "GroupShow" },
          {
            Caption: "Расширенная группировка",
            value: "GroupShowExtended",
            Disabled: true,
          },
        ],
      };
    case "history":
      // Values
      //
      // Table:
      //
      // CopyRecord" -
      // CutRecord -
      // Paste -
      //
      // Header:
      //
      // ColumnFilter -
      return {
        Table: [
          {
            Caption: "Копировать запись",
            value: "CopyRecord",
          },
          {
            Caption: "Вырезать запись",
            value: "CutRecord",
          },
          {
            Caption: "Вставить из буфера",
            value: "Paste",
          },
          {
            Caption: "Новая запись",
            value: "Insert",
            Image: <PostAddIcon fontSize="small" />,
          },
          {
            Caption: "Удалить запись",
            value: "Delete",
            Image: <DeleteIcon fontSize="small" />,
          },
          { Caption: "Divider" },
          { Caption: "Режим просмотра", value: "ViewMode" },
          { Caption: "Сохранять отметку", value: "SaveMark" },
        ],
        Header: [{ Caption: "Поиск по столбцу", value: "ColumnFilter" }],
      };
    case "HandleParams":
      // Values
      //
      // Table:
      //
      // SaveSample -
      // ClearValues -
      // FilterParams -

      return {
        Field: [
          {
            Caption: "Сохранить значения параметров",
            value: "SaveSample",
            Disabled: data && data.NoSaveSample ? true : false,
          },
          {
            Caption: "Очистить значения",
            value: "ClearValues",
            Disabled: data && data.NoClearState ? true : false,
          },
        ],
      };
    case "addressParams":
      // Values
      //
      // Table:
      //
      // ClearValues -

      return {
        Field: [],
      };
    case "tree":
      // Values
      //
      // Table:
      //
      // AddSection -
      // AddSubSection -

      return {
        Field: [],
      };
    case "docParams":
      // Values
      //
      // Table:
      //
      // SaveSample -
      // ClearValues -
      // FilterParams -
      // ParamsSettings -

      return {
        Field: [
          { Caption: "Сохранить значения параметров", value: "SaveSample" },
          { Caption: "Очистить значения", value: "ClearValues" },
          { Caption: "Фильтр параметров", value: "FilterParams" },
          // { Caption: "Настроить перечень параметров", value: "ParamsSettings" },
        ],
      };
    default:
      return;
  }
}

export function getRightsData(rights, other) {
  // вставка = 1
  // редактирование = 2
  // удаление = 4
  // доступ = 8

  let res = {
    insert: false,
    modify: false,
    delete: false,
    accsess: false,
  };

  if (rights === "-1") {
    return {
      insert: true,
      modify: true,
      delete: true,
      accsess: true,
    };
  }

  if (rights & 1) res.insert = true;
  if (rights & 2) res.modify = true;
  if (rights & 4) res.delete = true;
  if (rights & 8) res.accsess = true;

  return res;
}

export function ClearAllLoadMask() {
  const loadMasks = document.querySelectorAll("[loadmask]");

  if (loadMasks.length > 0) {
    for (const val of loadMasks) {
      val.parentElement.remove();
    }
  }
}

// функция для перекодирования целого числа в цвет
export function setColor(intColor) {
  function toHex(i) {
    let res = 0xff & i;
    if (res) {
      res = res.toString(16);
    } else res = 0;
    res = String(res).padStart(2, "0");
    return res;
  }
  if (intColor === 0 || intColor === "" || isNaN(intColor)) return;
  let result = "#" + toHex(intColor);
  result += toHex(intColor >> 8);
  result += toHex(intColor >> 16);
  return result;
}

export function getKeyCodeOfInt(int) {
  const letter = int & 0xff;
  let controlButton = int >> 8;
  if (controlButton === 64) controlButton = 17;
  if (controlButton === 0) return [letter];
  else return [controlButton, letter];
}

export async function formEdit(ClickedButton, func, request, RequestId, from) {
  let data, json;
  if (ClickedButton === "1") {
    data = {
      Result: ClickedButton,
      [request.Name]: request.Value,
    };
    if (request.Name === "ObjRef") {
      data["DocID"] = request.Value;
    }
  } else {
    data = { Result: ClickedButton };
  }
  if (RequestId) {
    const params = new Map();
    params.set("prefix", "project");
    params.set("comand", "ResumeRequest");
    params.set("RequestID", RequestId);
    params.set("WSM", "1");
    json = await AxiosRequest(true, params, data);
  }
  json = json ? json : {};
  tokenProcessingTest(json, {
    func: func,
    propsId: RequestId,
    from: from,
    props: { Request: request, Button: ClickedButton },
  });
}

export function getDateOfInt(int) {
  const startDate = new Date("1899-12-30");
  let date = new Date(startDate.setDate(startDate.getDate() + Number(int)));

  date = getStrDateOfDateObj(date);

  return date;
}

export function getStrDateOfDateObj(date) {
  return `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}.${
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  }.${date.getFullYear()}`;
}

export function getIntOfStrDate(value) {
  const date = getDateForDateObj(value);
  if (!date) return -1;
  // // вычисление id даты (количество дней с 30.12.1899)
  const oneDay = 1000 * 60 * 60 * 24;
  const startDate = new Date("1899-12-30");
  const currentDate = new Date(date);
  let id = Math.round((currentDate.getTime() - startDate.getTime()) / oneDay);

  if (!id) return -1;
  return id;
}

export function getDateForDateObj(str) {
  let date = str;

  if (date) {
    if (date.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)) return date;
    if (date.match(/[0-9]{2}.[0-9]{2}.[0-9]{4}/)) {
      date = date.split(".");
      date = [date[2], date[1], date[0]];
      return date.join("-");
    }
  }
}

export function getDateRusFormatWithId(str) {
  const date = getDateForDateObj(str);

  if (date) {
    // // вычисление id даты (количество дней с 30.12.1899)
    const oneDay = 1000 * 60 * 60 * 24;
    const startDate = new Date("1899-12-30");
    const currentDate = new Date(date);
    let id = Math.round((currentDate.getTime() - startDate.getTime()) / oneDay);

    if (!id) id = "-1";
    return { label: str === ".." ? "" : str, id: id };
  } else return { label: "", id: "" };
}

export function getLocalAuditParams() {
  return {
    Items: [
      {
        EditStyle: "2",
        Options: "",
        Level: "",
        ID: "0",
        DataType: "",
        FieldName: "StartDate",
        Name: "Начальная дата",
        Value: getStrDateOfDateObj(new Date()),
        EditVal: getStrDateOfDateObj(new Date()),
        ObjRef: "",
        CheckState: "",
        TextStyle: "",
      },
      {
        EditStyle: "2",
        Options: "",
        Level: "",
        ID: "1",
        DataType: "",
        FieldName: "EndDate",
        Name: "Конечная дата",
        Value: "",
        EditVal: "",
        ObjRef: "",
        CheckState: "",
        TextStyle: "",
      },
      {
        EditStyle: "1",
        Options: "",
        Level: "",
        ID: "2",
        DataType: "",
        FieldName: "EventType",
        Name: "Событие",
        Value: "",
        EditVal: "",
        ObjRef: "",
        CheckState: "",
        TextStyle: "",
      },
      {
        EditStyle: "1",
        Options: "",
        Level: "",
        ID: "3",
        DataType: "",
        FieldName: "ConfigName",
        Name: "Конфигурация",
        Value: "",
        EditVal: "",
        ObjRef: "",
        CheckState: "",
        TextStyle: "",
      },
      {
        EditStyle: "1",
        Options: "",
        Level: "",
        ID: "4",
        DataType: "",
        FieldName: "OrgName",
        Name: "Организация",
        Value: "",
        EditVal: "",
        ObjRef: "",
        CheckState: "",
        TextStyle: "",
      },
      {
        EditStyle: "1",
        Options: "",
        Level: "",
        ID: "5",
        DataType: "",
        FieldName: "UserName",
        Name: "Пользователь",
        Value: "",
        EditVal: "",
        ObjRef: "",
        CheckState: "",
        TextStyle: "",
      },
      {
        EditStyle: "1",
        Options: "",
        Level: "",
        ID: "6",
        DataType: "",
        FieldName: "Address",
        Name: "IP-адрес",
        Value: "",
        EditVal: "",
        ObjRef: "",
        CheckState: "",
        TextStyle: "",
      },
      {
        EditStyle: "1",
        Options: "",
        Level: "",
        ID: "7",
        DataType: "",
        FieldName: "Computer",
        Name: "Компьютер",
        Value: "",
        EditVal: "",
        ObjRef: "",
        CheckState: "",
        TextStyle: "",
      },
      {
        EditStyle: "1",
        Options: "",
        Level: "",
        ID: "8",
        DataType: "",
        FieldName: "Server",
        Name: "Сервер",
        Value: "",
        EditVal: "",
        ObjRef: "",
        CheckState: "",
        TextStyle: "",
      },
      {
        EditStyle: "8",
        Options: "",
        Level: "",
        ID: "9",
        DataType: "",
        FieldName: "ObjectID",
        Name: "Значение Объекта",
        Value: "",
        EditVal: "",
        ObjRef: "",
        CheckState: "",
        TextStyle: "",
      },
      {
        EditStyle: "",
        Options: "",
        Level: "",
        ID: "10",
        DataType: "",
        FieldName: "EventInfo",
        Name: "Информация",
        Value: "",
        EditVal: "",
        ObjRef: "",
        CheckState: "",
        TextStyle: "",
      },
      {
        EditStyle: "",
        Options: "",
        Level: "",
        ID: "11",
        DataType: "",
        FieldName: "Sender",
        Name: "Дополнительно",
        Value: "",
        EditVal: "",
        ObjRef: "",
        CheckState: "",
        TextStyle: "",
      },
    ],
  };
}

// Иконки состояний
const Unchecked = (
  <svg
    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeMedium svg-icon css-kry165"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="CheckBoxOutlineBlankIcon"
    tabindex="-1"
    title="CheckBoxOutlineBlank"
  >
    <path d="M19 5v14H5V5zm0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2"></path>
  </svg>
);

const Checked = (
  <svg
    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeMedium svg-icon css-kry165"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="CheckBoxIcon"
    tabindex="-1"
    title="CheckBox"
  >
    <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2m-9 14-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z"></path>
  </svg>
);

const LeftArrow = (
  <img
    checkicon={"true"}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAUUlEQVQokWNgGFZAkIGBoZ4cjYYMDAx3GBgYnpKqMY6BgeEbAwPDf1I0czMwMCyBaiIGH0PWPJ8Ejf8ZGBjOUGLzUar5GRmQHdowQHY8DwEAAEi9LqoZcEUOAAAAAElFTkSuQmCC"
  />
);

const UpArrow = (
  <img
    checkicon={"true"}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAd0lEQVQokeXPMQrCUAyA4c9ScHARPEgHL6UeSnDqTbxFi6cQHaQOvooEbH1Ip/4QAkl+kjARKxxTzuaEDnWuuE9iH7tfxQrXIN+wHRPXaILYxwWbz+EiyIeU21Bv8fB6581i4IpubC5uzmKOcjnQO2OJ+z8LvvIExvYZVSJjKmIAAAAASUVORK5CYII="
  />
);

const Horizontal = (
  <img
    checkicon={"true"}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAjElEQVQokd3RMQ4BQRyF8d8Wyw10duMmDqHZytlUShUNCQchQesGFDT/STayI7IdXzLVl5n3Xoa/YIAi44rwnVQ4YpbxTfjqXYxDbDDMXC6xwhmTVGWMPa7x+j1XLWovUWMKWzx7nF1KPuCGOR4fkkssMErJ7c3rLzZf0uY2NU6xu4smfJ2r1fuff4gXahUi5bfUpsIAAAAASUVORK5CYII="
  />
);

const Vertical = (
  <img
    checkicon={"true"}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAk0lEQVQokd3SSwrCMBRG4a9CXURBW9ck2GWK8wrSdbTiwIG70IFXEGzSjj2T8Cc5N0/SbHDGLjMnKQ544pYqsEqIFzwi39FFf5Z1rHhCEys3kQeUOblAG0WqkKvIbYwv4lueZOrMi/kTucDe75OUOJi57RIjjqi9b7uOPHo/WZYtruhD7iPXc+KHRX97bgddtJO8AC8rHItGG/0wAAAAAElFTkSuQmCC"
  />
);

const RightArrow = (
  <img
    checkicon={"true"}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAT0lEQVQokWNgGLagnoGBQZBczU8ZGBgeMjAwmJOr+T8DA8MPBgaGfGwKjkEVEIOXMDAwcCNrPkOC5v8MDAzzqWYzRX4mpJns0KYongchAABsAC7n0QjPxwAAAABJRU5ErkJggg=="
  />
);

const DownArrow = (
  <img
    checkicon={"true"}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAcklEQVQokWNgoAAw4pE7xsDAwMbAwPCTgYHBmlTN/wmpYyLkNHxgVDMDQw0DA8NdKEYGMLEqfIYJQBX9x4IfMDAwCBNyjT4DA8M3NI0/GBgYjAlphIEMNM3pxGqEgYVQjctJ1cjAwMDAzcDAMB9KUx8AALHdGmkOx7M9AAAAAElFTkSuQmCC"
  />
);

const Bullet = (
  <img
    checkicon={"true"}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABmJLR0QA/wD/AP+gvaeTAAAAWUlEQVQYlX2PUQqAMAxDo+dwnkiK59d5FJ0/KcRuNvBgH28hBfoU8psdwAWgkQrARtIjknOrPIWmyOnimkhOmbPRkuaPmrQd+sM4fHTMFuuNw7WpkzQL+eQFxVQu0d8mj+cAAAAASUVORK5CYII="
  />
);

const Required = (
  <img
    checkicon={"true"}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAANUlEQVQokWNgGCjAiMTmZGBgUCdCz00GBobv6IIGDAwM/4nABjANTOQ6eVTzkNJMtRRGXwAAhc8NauSW/JMAAAAASUVORK5CYII="
  />
);

const LeftArrowBlue = (
  <img
    checkicon={"true"}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAq0lEQVQokWNgGJ6AKWZrPUPUFkGscoQ0MzIxpjEzM15gjd1qTrJmKJD7x8BwkClmaz6KwQwMDAzMsduOMTD8tyTOnP9L/zKwpjMsdv/KAhVgI9IFDAwMjNHMDH9+/2VgSIQ6m/EX8Zr/L/3LwJIDdzY+wBy37SnD//9SDAwMP///Zyj/t8R7IkyOhUjrHjExMIT9XuJ9ElmQYGj///d/1t+//w1+L0bVOIQBAKmcMNWgTlTtAAAAAElFTkSuQmCC"
  />
);

const RightArrowRed = (
  <img
    checkicon={"true"}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAkUlEQVQokd2RsQlCQRBE3xwfcxuwhR+KjZgYmWgiWILVWIiJYHZnCYIlCKJwY+APRETXUCfc3Tezy8J/qsBqD/1XvfQJtjSzlAsMv4YBDANLmwLLx7oAirQ1jCJGSGvVOm/h1HTOvRAIYE+c0pVap6mLv4The/IiNJulY5acpfPzzU0oDA7Y4xZ24Q3h/Z9/VDeRPSpapGpugQAAAABJRU5ErkJggg=="
  />
);

const Unknown = (
  <img
    checkicon={"true"}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAwUlEQVQokeXRPQrCQBCG4XdUgoUI2gqCF/AIXkEFPUGy4A1MGW9gFQjZkDOIhaUeRPAOKcV1rQzxJ2IqC79y4Jn5YOA/kiTJQmvdBahVgVrrFdaurbVDgEYVKOBbmCmlDgBSFXqet73P89phGHbSNG2WwCUikyJ8wI7jpFdjNsUFBTh1XXf3vDivHUVRv1GvHwSOrXZ7nGWZ/67qWwwQx/GgJrIHDNAru/hSG0ApdboYM0LkbGH+CZYmCIKvX/ib3ACBFEg+iHRLAAAAAABJRU5ErkJggg=="
  />
);

const Reject = (
  <img
    checkicon={"true"}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAABE0lEQVQokc2SO07DQBBA39hNEKk4QSokROhgnSaSlb0BV0BKEKfIGSBA+PUUSJwAKVKaYEhBFYkKOACV7TTYQ0FimWQpqGC6ndn3RjO78C9iGjZqCuKqKcg0bNTKOa8MZn7+GNud/qJAQWJrTjM/H5cFBVwZjF4FvRaknVhzod2vmoKkNugJdIDbSjh6mzNLHVIb9BQ9AK5W16JO+h4cFudm1JYuuRN2CCbAhgt0wjOBl7TME0Jd4bnajDaly8fiPc8BSmqDI4Q6yERgPRma/nwHP8LLM99vCXIC7CVDc+4SFGBig+PYGo2tuSxv25X/NvPsnceK3lTvHvYFtCyOW8GZiO76mbe9Mhi9LHX/7Q/7u/gEmuuBZpiHMdwAAAAASUVORK5CYII="
  />
);

// функция получения иконки по целочисленному состоянию
export function getMulticheckIcon(checkstate) {
  const icons = {
    0: Unchecked,
    1: Checked,
    2: LeftArrow,
    3: UpArrow,
    4: Horizontal,
    5: Vertical,
    6: RightArrow,
    7: DownArrow,
    8: Bullet,
    9: Required,
    10: LeftArrowBlue,
    11: RightArrowRed,
    12: Unknown,
    13: Reject,
  };

  return icons[checkstate];
}

// иконки в виде текста для вставки напрямую в разметку
const UncheckedString = "";
const CheckedString = `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeMedium svg-icon css-kry165" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckIcon" tabindex="-1" title="Check"><path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path></svg>`;
const BulletString = `<img
    checkicon={"true"}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABmJLR0QA/wD/AP+gvaeTAAAAWUlEQVQYlX2PUQqAMAxDo+dwnkiK59d5FJ0/KcRuNvBgH28hBfoU8psdwAWgkQrARtIjknOrPIWmyOnimkhOmbPRkuaPmrQd+sM4fHTMFuuNw7WpkzQL+eQFxVQu0d8mj+cAAAAASUVORK5CYII="
  />`;
const LeftArrowBlueString = `<img
    checkicon={"true"}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAq0lEQVQokWNgGJ6AKWZrPUPUFkGscoQ0MzIxpjEzM15gjd1qTrJmKJD7x8BwkClmaz6KwQwMDAzMsduOMTD8tyTOnP9L/zKwpjMsdv/KAhVgI9IFDAwMjNHMDH9+/2VgSIQ6m/EX8Zr/L/3LwJIDdzY+wBy37SnD//9SDAwMP///Zyj/t8R7IkyOhUjrHjExMIT9XuJ9ElmQYGj///d/1t+//w1+L0bVOIQBAKmcMNWgTlTtAAAAAElFTkSuQmCC"
  />`;
const RightArrowRedString = `<img
      checkicon={"true"}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAkUlEQVQokd2RsQlCQRBE3xwfcxuwhR+KjZgYmWgiWILVWIiJYHZnCYIlCKJwY+APRETXUCfc3Tezy8J/qsBqD/1XvfQJtjSzlAsMv4YBDANLmwLLx7oAirQ1jCJGSGvVOm/h1HTOvRAIYE+c0pVap6mLv4The/IiNJulY5acpfPzzU0oDA7Y4xZ24Q3h/Z9/VDeRPSpapGpugQAAAABJRU5ErkJggg=="
    />`;

// Для секции виды начислений
export function getMulticheckIconForTree(checkstate) {
  const icons = {
    1: UncheckedString,
    9: BulletString,
    11: LeftArrowBlueString,
    12: RightArrowRedString,
  };

  return icons[checkstate];
}

function GetCurrentUserRights(ObjType) {
  const userIsAdmin = false;
  const UserRights = {};
  if (userIsAdmin) return "-1";
  if (UserRights[ObjType] !== undefined) return UserRights[ObjType];
  let objTypeRights;
  const params = new Map();
  params
    .set("prefix", "usercfg")
    .set("comand", "GetObjectRights")
    .set("ObjType", ObjType);
  const json = XMLrequest(params);
  if (json.Result) {
    objTypeRights = json.Result;
  } else objTypeRights = "-1";
  UserRights[ObjType] = objTypeRights;
  return objTypeRights;
}

export function CheckCurrentUserRights(ObjType, flag) {
  let result = false;
  const mfInsert = 0,
    mfEdit = 1,
    mfDelete = 2;
  const rightsData = getRightsData(GetCurrentUserRights(ObjType));
  switch (flag) {
    case mfInsert:
      result = rightsData.insert;
      break;
    case mfEdit:
      result = rightsData.modify;
      break;
    case mfDelete:
      result = rightsData.delete;
      break;
  }
  return result;
}
