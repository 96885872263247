import axios from "axios";

class API {
  static current_queue = [];
  static pendingPromise = false;

  static queue(promise) {
    return new Promise((resolve, reject) => {
      API.current_queue.push({
        promise,
        resolve,
        reject,
      });
      API.dequeue();
    });
  }

  static afterPromise = (item, resolve = false, data) => {
    this.workingOnPromise = false;
    if (resolve) item.resolve(data);
    else item.reject(data);
    API.dequeue();
  };

  static dequeue() {
    if (this.workingOnPromise) {
      return false;
    }

    const item = API.current_queue.shift();
    if (!item) {
      return false;
    }
    try {
      this.workingOnPromise = true;
      item
        .promise()
        .then((value) => API.afterPromise(item, true, value))
        .catch((error) => API.afterPromise(item, false, error));
    } catch (error) {
      API.afterPromise(item, false, error);
    }
    return true;
  }

  static scrollGrid(e,func) {
    return func(e).then(()=>e);
  }

  static stopQueued(){
    API.current_queue.length = 0;
  }
}

new API();

const queuedGridScroll = {
  currentQueue: API.current_queue,

  scrollGrid(e, func){
    return API.queue(() => API.scrollGrid(e, func));
  },
  stopQueued(){
    API.stopQueued()
  }
};

export default queuedGridScroll;
