import { ClickAwayListener, Grid, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { AxiosRequest } from "../../../Url";
import React from "react";
import TestEditor from "../../../Editor/testEditor";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactDOM from "react-dom";
import {
  CreateBackgroundForModal,
  tokenProcessingTest,
} from "../../../TokenProcessing/TokenProcessing";
import DialogSetObjText from "../../Module/Dialogs/DialogSetObjText";
import DialogCreateObj from "../../Module/Dialogs/DialogCreateObj";
import ModalWindow from "../../Module/ModalWindow";
import {
  getDefaultMenu,
  getStyleWindow,
  isEmptyObject,
} from "../../Tools/Tools";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CheckBoxMultiCheck from "./CheckBoxMultiCheck";
import LoadingMask from "../../NotWorkArea(Side&Head)/LoadingMask";
import HistoryIcon from "@mui/icons-material/History";
import TextAreaWithButtons from "../../Module/Dialogs/Components/TextAreaWithButtons";
import ContextMenu from "../../NotWorkArea(Side&Head)/ContextMenu";
import DialogHistory from "../../Module/Dialogs/DialogHistory";
import { dark } from "@mui/material/styles/createPalette";
import { openModal } from "../../Tools/modalManager";

// опции стилей текста названия параметра
const FontStyle_Bold = 1;
const FontStyle_Italic = 2;
const FontStyle_Underline = 4;
const FontStyle_StrikeOut = 8;

// опции параметров
const ParamSetOption_Disabled = 1; // +
const ParamSetOption_InConfig = 2; // -
const ParamSetOption_Calculated = 4; // -
const ParamSetOption_Detalization = 8; // -
const ParamSetOption_Markable = 16; // -
const ParamSetOption_Collapsed = 32; // +
const ParamSetOption_Reference = 64; // -
const ParamSetOption_RedNegative = 128; // -
const ParamSetOption_Inspector = 256; // -
const ParamSetOption_Grid = 512; // -
const ParamSetOption_Sortable = 1024; // -
const ParamSetOption_Filterable = 2048; // -
const ParamSetOption_Override = 4096; // -
const ParamSetOption_Hidden = 8192; // +
const ParamSetOption_History = 16384; // +-
const ParamSetOption_CanGroup = 32768; // -
const ParamSetOption_Fixed = 65536; // -
const ParamSetOption_NotEdit = 131072; // -

// функции для сплиттера с возможностью изменения размеров полей
var resize = {};

// функция включения live изменения размеров
function onMouseDown(ev) {
  resize.resizeMode = true;
  resize.target = ev.target;
  resize.col = ev.target.closest("TABLE").firstChild.firstChild;
}
// функция установки размеров после изменения
function onMouseUp(ev, setWidth) {
  if (resize.size !== undefined) {
    setWidth(resize.size);
    resize = {};
  }
}

// функция live изменения размеров
function onMouseMove(ev) {
  if (resize.resizeMode) {
    let size =
      ev.clientX -
      resize.target.clientWidth -
      resize.target.getBoundingClientRect().x;
    size =
      (resize.col.clientWidth / resize.col.parentElement.clientWidth) * 100 +
      (size / resize.col.parentElement.clientWidth) * 100;
    if (size < 30) size = 30;
    if (size > 70) size = 70;
    resize.size = size;
    resize.col.style.width = `${size}%`;
  }
}

// функция расчета отступов для параметров в завизимости от уровня вложенности
function getPadding(items, item, index) {
  let padding = 15;
  const level = item.Level ? item.Level : 0;

  if (items.length - 1 > index && level < items[index + 1].Level) {
    padding -= 15;
  }
  if (item.Level) {
    padding += item.Level * 15;
  }
  return padding;
}

// функция для выбора запроса
// возвращает функицю в которую нужно передать data
// пример: getSetParamRequest(selector)({data})
function getSetParamRequest(selector, props) {
  const requests = {
    custom: (data) => {
      return props.onEditCustom({ ...data, parentID: props.ID });
    },

    default: (data) => {
      const params = new Map();
      params
        .set("prefix", "documents")
        .set("comand", "SetDetailValue")
        .set("DocCfgID", props.DocCfgID)
        .set("DocID", data.ID)
        .set("ObjRef", data.ObjRef ? data.ObjRef : "0")
        .set("State", data.CheckState)
        .set("Type", data.Type);
      return AxiosRequest(true, params, { Text: data.Value });
    },
  };
  return selector && requests[selector] ? requests[selector] : requests.default;
}

// запрос следующего или предыдущего состояния параметров
function getHistory(data) {
  const params = new Map();
  params
    .set("prefix", "programs")
    .set("comand", "GetParamDialog")
    .set("Path", data.Path)
    .set("History", data.history);
  return AxiosRequest(true, params);
}

// функция для перекодирования целого числа в цвет
function setColor(intColor) {
  function toHex(i) {
    let res = 0xff & i;
    if (res) {
      res = res.toString(16);
    } else res = 0;
    res = String(res).padStart(2, "0");
    return res;
  }
  let result = "#" + toHex(intColor);
  result += toHex(intColor >> 8);
  result += toHex(intColor >> 16);
  return result;
}

// функция установки стилей для текста названия параметра
function getStyleName(item) {
  return {
    color: item.NameColor ? setColor(item.NameColor) : "",
    fontWeight: item.NameStyle & FontStyle_Bold ? "bold" : "",
    fontStyle: item.NameStyle & FontStyle_Italic ? "italic" : "",
    textDecoration:
      item.NameStyle & FontStyle_Underline
        ? "underline"
        : item.NameStyle & FontStyle_StrikeOut
        ? "line-through"
        : "",
  };
}

// функция для установки стилей для текста значения параметра
function getStyleText(item) {
  // костыль
  // понять как кодируется этот цвет
  if (item.TextColor === "-16777199" || item.TextColor === "-16777198") {
    return "#aaaaaa";
  }

  if (item.TextColor !== undefined) {
    return setColor(item.TextColor);
  }

  return "";
}

export default function DetailsParams(props) {
  // полные данные параметров
  const [paramsData, setParamData] = React.useState();
  // данные записей
  const [paramsItems, setParamsItems] = React.useState();
  // записи для отрисовки
  const [paramsJsx, setParamsJsx] = React.useState();

  // размер 1-ой колонки для контроля
  const [width, setWidth] = React.useState(35);
  // размер контейнера таблицы с параметрами в зависимости от настроек
  const [height, setHeight] = React.useState();
  const [buttonBlockWidth, setbuttonBlockWidth] = React.useState(0);
  // переменная для хранения элемента с редактором
  const editor = React.useRef();

  // карта с отрисованными редакторами
  const [editorMap, setEditorMap] = React.useState();
  // карта с функциями редактирования отрисованных редакторов
  const [editionMap, setEditionMap] = React.useState();

  // хук для получения функций редактирования и записи их в карту выше
  const [getEdition, setGetEdition] = React.useState();

  // костыль для Просмотра проводок
  const inputValue = React.useRef([]);

  // переменная для динамической отрисовки редактора, контролирует отрисвку по нажатой клавише мыши
  const mouseDown = React.useRef(null);

  // ссылка на контейнер параметров
  const paramsBox = React.useRef();

  const editorVals = React.useRef({});

  // Первичная установка объекта параметров
  React.useEffect(() => {
    // получение данных если они не были переданны из вне
    if (props.data) {
      setParamData(props.data);
    }

    // инициализация карт хранения
    setEditorMap(new Map());
    setEditionMap(new Map());
    // установка высоты блока параметров
    setHeight(getHeight());

    if (props.inputValue) {
      inputValue.current = props.inputValue.split(";");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // при изменении данных снаружи они обновляются
  React.useEffect(() => {
    if (props.data) {
      setEditorMap(new Map());
      setEditionMap(new Map());
      setParamsJsx(null);
      setParamData(props.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  React.useEffect(() => {
    // обновление параметров при изменении общих данных
    if (paramsData) {
      // скрытие текущего редактора если он был открыт
      if (editor.current) {
        deleteEditor(editor.current);
      }
      // обновление массива записей
      setParamsItems(paramsData.Items);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsData]);

  React.useEffect(() => {
    // обновление отрисованных записей
    if (paramsItems) {
      setParamsJsx(getJsxParams(paramsItems, setWidth));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsItems]);

  // заполнение карты с функциями редактирования при отрисовке нового редактора
  React.useEffect(() => {
    if (getEdition) {
      editionMap.set(getEdition.id, {
        setValue: getEdition.setValue,
        setInputValue: getEdition.setInputValue,
        value: getEdition.value,
        inputValue: getEdition.inputValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEdition]);

  // разворачивание записей при первой отрисовке, если они должны быть развернуты
  React.useLayoutEffect(() => {
    if (paramsJsx) {
      paramsBox.current
        .querySelectorAll("#expandButtonParams")
        .forEach((el) => {
          if (
            (Number(el.dataset.collapsed) & ParamSetOption_Collapsed) !==
            ParamSetOption_Collapsed
          ) {
            el.closest("TR").dataset.collapsed = "1";
            expandRec({ target: el });
          }
        });
    }
  }, [paramsJsx]);

  // функция для получения кнопки разворачивания записей
  function getExpandButton(items, item, index) {
    const level = item.Level ? item.Level : 0;
    if (items.length - 1 > index && level < items[index + 1].Level) {
      return (
        <IconButton
          id="expandButtonParams"
          data-collapsed={item.Options}
          name="expandButton"
          onClick={async (ev) => {
            const record = ev.target.closest("tr");
            await collapseReq(
              record.id.split("_")[1],
              props.Path ? props.Path : paramsData.Path,
              record.dataset.collapsed === "1" ? "0" : "1"
            );
            expandRec(ev);
          }}
          style={{
            height: "15px",
            width: "15px",
            transform:
              Number(item.Options) & ParamSetOption_Collapsed
                ? "rotate(-90deg)"
                : "",
            transition: "all 0.2s",
          }}
        >
          <ExpandMoreIcon style={{ height: "15px", width: "15px" }} />
        </IconButton>
      );
    }
  }

  // запрос сворачивания и разворачивания записи для сервера
  function collapseReq(id, Path, collapsed) {
    const params = new Map();
    params
      .set("prefix", "programs")
      .set("comand", "ParamCollapsed")
      .set("ID", id)
      .set("Path", Path)
      .set("Collapsed", collapsed);
    return AxiosRequest(true, params);
  }

  // Функция скрытия и отображения записей при клике на кнопку expandButton
  function expandRec(ev) {
    // получение кнопки на текущей записи
    let button = ev.target.closest("BUTTON");
    // получение текущей записи
    let record = ev.target.closest("TR");
    // получение уровня вложенности
    let level = record.dataset.level;
    // получение следующей записи
    let nextRec = record.nextElementSibling;

    //выражение для проверки состояния развернутости записи
    const condition = record.dataset.collapsed === "1";
    // сокрытие или раскрытие всех дочерних записей у родительской
    // цикл работает до тех пор, пока уровень вложенности следующей записи больше чем уровень первоначальной записи
    while (nextRec && nextRec.dataset.level > level) {
      // проверка привязки к родительской записи
      // if (nextRec.dataset.parentid === parentID) {
      // проверка на наличие вложенных записей
      const nextButton = nextRec.querySelector("button[name=expandButton]");
      // если вложенные записи были развернуты то они свернутся при сворачивании записей родителя
      if (nextButton && nextRec.dataset.collapsed === "0") {
        nextRec.dataset.collapsed = "1";
        nextButton.style.transform = "rotate(-90deg)";
      }
      // сокрытие или раскрытие записей в зависимости от условия
      nextRec.style.display = condition ? "" : "none";
      // проверка привязки к родительской записи
      if (
        nextRec.dataset.level !== `${Number(level) + 1}` ||
        Number(nextRec.dataset.options) & ParamSetOption_Disabled
        // Number(nextRec.dataset.options) & ParamSetOption_Hidden
      ) {
        nextRec.style.display = "none";
      }
      nextRec = nextRec.nextElementSibling;
    }

    // поворот кнопки при сворачивании
    button.style.transform = condition ? "rotate(0deg)" : "rotate(-90deg)";
    record.dataset.collapsed = condition ? "0" : "1";
  }

  // функция на редактирование параметров
  async function onEdit(data) {
    const textValueDiv = data.record.querySelector('[textvalue="true"]');
    // если нет изменений значения или id, или состояния чекбокса, то устанавливается старое
    if (data.value === undefined) data.value = data.record.dataset.value;
    if (data.tag === undefined) data.tag = data.record.dataset.objref;

    mouseDown.current = false;
    if (data.value !== undefined) textValueDiv.innerText = data.value;

    // получение запроса для редактирования и ответа

    if (data.proof === true && data.type !== undefined && data.value !== "") {
      const params = new Map();
      params
        .set("prefix", data.type)
        .set("comand", "SetObjectText")
        .set("Flag", "-1")
        .set("ObjType", data.datatype)
        .set("ObjRef", data.ObjRef ? data.ObjRef : "0")
        .set("Text", data.value === undefined ? "" : data.value);
      const json = await AxiosRequest(true, params);
      data.editType = "ObjRef";
      data.proof = false;
      settingValues(
        {
          Selection: json.Selection,
          Token: json.Token,
          CreateNew: json.Selection ? undefined : json.CreateNew,
          ObjRef: json.ObjRef,
          Text: json.Text,
          EditVal: json.Text,
          noUpdate: true,
        },
        data
      );
      if (!json.Token && !json.CreateNew && json.Selection.length === 0) {
        await getSetParamRequest(
          props.onEditCustom ? "custom" : undefined,
          props
        )({
          ID: data.record.id.split("_")[1],
          ObjRef: data.tag,
          Value: data.value,
          CheckState: data.CheckState
            ? data.CheckState
            : data.record.dataset.checkstate,
          Type: "Text",
        }).then((res) => {
          // Установка значений параметров
          settingValues(res, data);
        });
      }

      return;
    }

    if (data.value === "") {
      data.tag = 0;
      data.record.dataset.value = "";
      data.record.dataset.tag = "0";
    }

    await getSetParamRequest(
      props.onEditCustom ? "custom" : undefined,
      props
    )({
      ID: data.record.id.split("_")[1],
      ObjRef: data.tag,
      Value: data.value,
      CheckState: data.CheckState
        ? data.CheckState
        : data.record.dataset.checkstate,
      Type: data.editType
        ? data.editType
        : data.CheckState
        ? "State"
        : "ObjRef",
      fieldname: data.fieldname,
    }).then((res) => {
      // Установка значений параметров
      settingValues(res, data);
    });
  }

  // функция установки локальных значений параметров
  async function settingValues(res, data) {
    props.valueChanged.current = true;

    // при наличии Токена установка идет через токен процесс
    if (res) {
      if (res.Token) {
        tokenProcessingTest(res, {
          func: (val) => {
            settingValues(val, data);
          },
        });
        return;
      }

      // открытие окна с выбором элементов при неточном вводе в ручную
      if (res.Selection && res.Selection.length) {
        openModal(
          <DialogSetObjText
            list={res.Selection}
            onEdit={onEdit}
            record={data.record}
            data={data}
          />,
          {
            Title: "Выберите элемент из списка",
            style: getStyleWindow(),
            hiddenButton: true,
            blockMaximize: true,
          }
        );
        return;
      }

      // открытие окна с созданием нового объекта
      if (res.CreateNew) {
        openModal(
          <DialogCreateObj
            onEdit={onEdit}
            inputNameObj={res.CreateNew.Text}
            spanText={res.CreateNew.Label}
            CLSID={res.CreateNew.CLSID}
            ObjType={data.datatype}
            Module={data.type}
            groups={res.CreateNew.Groups}
            record={data.record}
          />,
          {
            Title: "Создать новый объект",
            style: getStyleWindow(
              `${res.CreateNew.CLSID}${res.CreateNew.Groups ? "Group" : ""}`
            ),
            hiddenButton: true,
            blockMaximize: true,
          }
        );
        return;
      }

      // обновление значений из ответа
      // редактируемая запись
      const rec = data.record;
      // контейнер со значением параметра
      const textValueDiv = rec.querySelector('[textvalue="true"]');
      const editText = res.EditVal
        ? res.EditVal
        : res.Text !== undefined
        ? res.Text
        : data.record.dataset.value !== undefined
        ? data.record.dataset.value
        : "";
      const text =
        res.Text !== undefined
          ? res.Text
          : res.EditVal !== undefined
          ? res.EditVal
          : data.record.dataset.value !== undefined
          ? data.record.dataset.value
          : "";

      editorVals.current[rec.dataset.name] = editText;

      const resultText = Object.values(editorVals.current)
        .filter((item) => {
          if (item) return item;
        })
        .join(";");

      props.setParentEditorValue({
        label: resultText,
      });
      props.setParentEditorValueInput(resultText);

      if (data.CheckState !== 0) {
        // заполнение записи данными
        rec.dataset.editval = editText;
        rec.dataset.value = text;
        rec.dataset.objref = res.ObjRef
          ? res.ObjRef
          : data.record.dataset.objref !== undefined
          ? data.record.dataset.objref
          : "0";
        textValueDiv.innerText = text;

        textValueDiv.style.color =
          textValueDiv.innerText < 0 &&
          rec.dataset.options & ParamSetOption_RedNegative
            ? "red"
            : getStyleText(res);

        // обновление значения в редакторе
        if (editor.current) {
          const edition = editionMap.get(rec.id);
          if (edition) {
            edition.setValue({
              label: editText,
              id: res.ObjRef ? res.ObjRef : "0",
            });
            edition.setInputValue(editText);
          }
        }
      } else {
        rec.dataset.editval = "";
        rec.dataset.value = "";
        rec.dataset.objref = "0";
        textValueDiv.innerText = "";
        if (editor.current) {
          const edition = editionMap.get(rec.id);
          if (edition) {
            edition.setValue({
              label: "",
              id: "0",
            });
            edition.setInputValue("");
          }
        }
      }
    }
  }

  // функция открытия модального окна в редакторе
  async function onEditButtonClick(data) {
    if (props.onEdit) {
      // GET /documents~ExecDetailHandler?LicGUID=236FD4814A007F493B03CEB81AE47F55&DocCfgID=3002&DocID=20695172&Type=EditRef&WSM=1
      const params = new Map();
      params
        .set("prefix", "documents")
        .set("comand", "ExecDetailHandler")
        .set("DocCfgID", props.DocCfgID)
        .set("DocID", data.subData.props.record.id.split("_")[1])
        .set("Type", "EditRef")
        .set("WSM", "1");
      const json = await AxiosRequest(true, params);
      if (json) tokenProcessingTest(json, data.subData);
    } else {
      if (data.datatype <= 12 && data.datatype >= 7) {
        CreateBackgroundForModal(
          <ModalWindow
            Title={"Текст параметра"}
            style={getStyleWindow()}
            hiddenButton={true}
          >
            <TextAreaWithButtons
              props={{
                ...data.subData.props,
                setValue: data.setValue,
                setInputValue: data.setInputValue,
                Title: data.subData.props.record.dataset.name,
              }}
            />
          </ModalWindow>
        );
        return;
      }

      if (data.subData.props.editParam !== undefined) {
        const params = new Map();
        params
          .set("prefix", "programs")
          .set("comand", "EditParamProperty")
          .set("ID", data.subData.props.record.id.split("_")[1])
          .set("Path", props.Path ? props.Path : paramsData.Path)
          .set("WSM", "1");
        const json = await AxiosRequest(true, params);
        if (json) tokenProcessingTest(json, data.subData);
      } else {
        if (data.json && Object.values(data.json).length > 2) {
          tokenProcessingTest(data.json, data.subData);
        }
      }
    }
  }

  // функция значений для выпадающего списка в редакторе
  async function onDropDownList(data, checklist) {
    if (props.onListCustom) {
      return await props.onListCustom(data);
    }
    if (props.onList) {
      const params = new Map();
      params
        .set("prefix", "documents")
        .set("comand", "ExecDetailHandler")
        .set("DocCfgID", props.DocCfgID)
        .set("DocID", data.record.id.split("_")[1])
        .set("Type", "DropDown")
        .set("WSM", "1");
      const json = await AxiosRequest(true, params);
      if (json && json.Items) {
        return Object.values(json.Items).map((item, index) => {
          if (typeof item !== "object") {
            if (checklist) return { label: item, id: `${Math.pow(2, index)}` };
            return { label: item, id: `${index}` };
          }
          return { label: item.text, id: item.id };
        });
      }
    } else {
    }
  }

  async function onEditList(data) {
    if (props.onCloseUpListCustom) {
      const json = await props.onCloseUpListCustom({
        ...data,
        parentID: props.ID,
      });
      settingValues(json, data);
      return;
    }
  }

  // функция для рендера или сокрытия/раскрытия редактора
  function renderEditor(ev) {
    if (props.HardCodeReadOnlyForEditor === "1") return;
    // выбранная запись
    const record = ev.target.closest("TR");
    // проверка на отображение редактора
    if (record.querySelector('div[editor="true"]').style.display !== "none")
      return;

    record.style.backgroundColor = "whitesmoke";

    // контейнер для редактора
    const editorDiv = record.querySelector('[editor="true"]');
    // контейнер для текста параметров
    const textValueDiv = record.querySelector('[textvalue="true"]');
    // сохраненный редактор
    const elementEditor = editorMap.get(record.id);
    // удаление(скрытие) другого отрисованного редактора
    if (editor.current && editor.current !== editorDiv) {
      deleteEditor(editor.current);
    }
    // если редактор уже отрисован то он отображается, а текст параметров скрывается
    // в противном случае редактор отрисуется, а текст параметров также скроется
    if (elementEditor && editionMap && editionMap.get(record.id)) {
      elementEditor.style.display = "";
      textValueDiv.style.display = "none";
      editionMap
        .get(record.id)
        .setInputValue(
          record.dataset.editval
            ? record.dataset.editval
            : record.dataset.value
            ? record.dataset.value
            : ""
        );
      editionMap.get(record.id).setValue({
        label: record.dataset.editval
          ? record.dataset.editval
          : record.dataset.value
          ? record.dataset.value
          : "",
        id: record.dataset.objref,
      });
    } else {
      editorDiv.style.display = "";
      textValueDiv.style.display = "none";
      // отрисовка редактора

      ReactDOM.render(
        <TestEditor
          styleInput={{ height: "20px" }}
          styleComponent={{ height: "20px" }}
          placeholder=" "
          groupid={record.dataset.groupid}
          current={record.dataset.current}
          fieldname={record.dataset.fieldname}
          name={record.dataset.name}
          type={record.dataset.module}
          record={record}
          objref={record.dataset.objref}
          value={record.dataset.editval}
          datatype={record.dataset.datatype}
          editStyle={record.dataset.editstyle}
          checkstate={record.dataset.checkstate}
          onEdit={onEdit}
          onEditButtonClick={onEditButtonClick}
          onDropDownList={onDropDownList}
          onEditList={onEditList}
          getEdition={setGetEdition}
          editParam={record.dataset.onedit}
          disabled={false}
        />,
        editorDiv
      );
    }

    // сохранение редактора
    editorMap.set(record.id, editorDiv);
    // отрисованный редактор
    editor.current = editorDiv;

    // фокус на поле ввода
    const input = editorDiv.querySelector("input[type=text]");
    if (input) {
      input.focus();
      // проверка на readOnly
      if (record.dataset.editstyle & 64) return;
      input.select();
    }
  }

  // функция скрытия редактора и отображения текста параметров
  function deleteEditor(editor) {
    if (editor && editor.style.display !== "none") {
      const record = editor.closest("TR");
      record.style.backgroundColor = "";
      const textValueDiv = record.querySelector('[textvalue="true"]');
      editor.style.display = "none";
      textValueDiv.style.display = "";
    }
  }

  // функция на нажатие стрелок
  // проверяет следующий элемент на отображение и рисует в нем редактор
  function onKeyArrow(ev) {
    let record;
    switch (ev.key) {
      case "ArrowDown":
        record = ev.target.closest("TR").nextElementSibling;
        if (record) {
          if (record.style.display === "none") {
            while (record !== null) {
              record = record.nextElementSibling;
              if (record && record.style.display !== "none") {
                renderEditor({ target: record });
                break;
              }
            }
          } else {
            renderEditor({ target: record });
          }
        }

        break;
      case "ArrowUp":
        record = ev.target.closest("TR").previousElementSibling;
        if (record) {
          if (record.style.display === "none") {
            while (record !== null) {
              record = record.previousElementSibling;
              if (record && record.style.display !== "none") {
                renderEditor({ target: record });
                break;
              }
            }
          } else {
            renderEditor({ target: record });
          }
        }
        break;
      default:
        break;
    }
  }

  // функция установки размеров блока с кнопками
  function getButtonColWidth(options, other) {
    let res = 0;
    if (options & ParamSetOption_History) {
      res += 20;
    }
    return res;
  }

  // Функция отрисовки записей для таблицы параметров
  function getJsxParams(items) {
    let colWidth;
    const returnArr = items.map((item, index) => {
      editorVals.current[item.Title ? item.Title : item.Name] = item.EditVal;
      const expandButton = getExpandButton(items, item, index);
      // условие для отрисовки чекбокса отдельно
      const editStyle =
        item.EditStyle & 4 ? item.EditStyle - 4 : item.EditStyle;

      colWidth = getButtonColWidth(item.Options);
      if (buttonBlockWidth < colWidth) {
        setbuttonBlockWidth(colWidth);
      }
      let accText;
      if (props.AccDetails && item.ObjRef) {
        accText = inputValue.current.shift();
      }

      return (
        <tr
          onMouseEnter={(ev) => {
            // hover
            const record = ev.target.closest("TR");
            if (editor.current && editor.current.closest("TR") !== record)
              record.style.backgroundColor = "#f5f5f5";
            // динамический рендер редактора
            if (mouseDown) {
              if (mouseDown.current) renderEditor(ev);
            }
          }}
          onMouseLeave={(ev) => {
            // hover off
            const record = ev.target.closest("TR");
            if (editor.current && editor.current.closest("TR") !== record)
              record.style.backgroundColor = "";
          }}
          onClick={(ev) => {
            renderEditor(ev);
          }}
          onKeyDown={(ev) => {
            onKeyArrow(ev);
          }}
          style={{
            display:
              item.Level ||
              Number(item.Options) & ParamSetOption_Disabled ||
              Number(item.Options) & ParamSetOption_Hidden
                ? "none"
                : "",
          }}
          // данные параметра хранятся внутри записи
          id={`param-key_${item.ID}_${index}`}
          key={`param-key_${item.ID}_${index}`}
          data-datatype={item.ObjType}
          data-editstyle={editStyle}
          data-fieldname={item.FieldName ? item.FieldName : item.Name}
          data-name={item.Title ? item.Title : item.Name}
          data-mapid={item.MapID}
          data-module={item.Module}
          data-options={item.Options}
          data-orderno={item.OrderNo}
          data-shortname={item.ShortName}
          data-level={item.Level ? item.Level : 0}
          data-parentid={item.ParentID}
          data-collapsed={
            (Number(item.Options) & ParamSetOption_Collapsed) === 32 ? "1" : "0"
          }
          data-value={accText ? accText : item.Text}
          data-editval={item.EditVal !== undefined ? item.EditVal : item.Value}
          data-objref={item.ObjRef}
          data-groupid={paramsData.GroupID}
          data-current={item.Current}
          data-checkstate={
            item.EditStyle & 4 && item.ObjRef === "1" ? "1" : item.CheckState
          }
          data-onedit={item.OnEdit}
        >
          {/* Имя параметра */}
          <td
            id={`name_${item.ID}-${index}`}
            title={item.Title ? item.Title : item.Name}
            style={{
              height: "27px",
              borderCollapse: "collapse",
              backgroundColor: "#c9c8c82e",
              border: "0.1px solid",
              borderRight: "0",
              borderColor: "#e0e0e0",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              userSelect: "none",
              paddingLeft: getPadding(items, item, index),
            }}
          >
            {expandButton}
            <span style={getStyleName(item)}>
              {item.Title ? item.Title : item.Name}
            </span>
          </td>
          {/* Колонка для кнопок история, выражение и тд */}
          <td
            style={{
              borderCollapse: "collapse",
              backgroundColor: "#c9c8c82e",
              overflow: "hidden",
              border: "0.1px solid",
              borderLeft: "0",
              borderRight: "0",
              borderColor: "#e0e0e0",
              height: "27px",
              width: getButtonColWidth(item.Options),
            }}
          >
            <IconButton
              onClick={() => {
                const paramsRequest = new Map();
                paramsRequest
                  .set("prefix", "programs")
                  .set("Path", props.Path ? props.Path : paramsData.Path)
                  .set("ID", item.ID);
                openModal(
                  <DialogHistory
                    paramsRequest={paramsRequest}
                    Module={item.Module}
                    EditStyle={item.EditStyle}
                    DataType={item.DataType}
                    Options={item.Options}
                    Values={item.Values}
                    onSelect={(json) => {
                      settingValues(
                        { Values: [json] },
                        {
                          record: paramsBox.current.querySelector(
                            `#param_${item.ID}_${index}`
                          ),
                        }
                      );
                    }}
                  />,
                  {
                    Title: item.Name,
                    style: getStyleWindow(),
                    hiddenButton: true,
                    blockMaximize: true,
                    blockSaveLocation: true,
                  }
                );
              }}
              style={{
                height: "15px",
                width: "15px",
                display: item.Options & ParamSetOption_History ? "" : "none",
              }}
            >
              <HistoryIcon style={{ height: "15px", width: "15px" }} />
            </IconButton>
          </td>
          {/* Разделитель */}
          <td
            onMouseDown={onMouseDown}
            style={{
              cursor: "col-resize",
              userSelect: "none",
              backgroundColor: "#e0e0e0",
            }}
            id="splitter-ID"
          >
            <br></br>
          </td>
          {/* Значение параметра */}
          <td
            id={`value_${item.ID}-${index}`}
            style={{
              borderCollapse: "collapse",
              overflow: "hidden",
              border: "0.1px solid",
              borderLeft: "0",
              borderColor: "#e0e0e0",
              height: "20px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                style={{
                  width: "15px",
                  marginRight: "5px",
                  display: item.EditStyle & 4 ? "" : "none",
                }}
              >
                {/* чекбокс с множеством состояний */}
                <CheckBoxMultiCheck
                  onEdit={onEdit}
                  MultiCheckSet={item.MultiCheckSet}
                  CheckState={
                    item.EditStyle & 4 && item.ObjRef === "1"
                      ? "1"
                      : item.CheckState
                  }
                />
              </Grid>
              {/* текстовое значение параметра */}
              <Grid
                item
                style={{
                  display: "flex",
                  alignContent: "center",
                  fontSize: "13px",
                  paddingLeft: "2px",
                  width: item.EditStyle & 4 ? "calc(100% - 20px)" : "100%",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  letterSpacing: "0px",
                  border: "1px solid #ffffff",
                  height: "20px",
                  color:
                    item.Text < 0 && item.Options & ParamSetOption_RedNegative
                      ? "red"
                      : getStyleText(item),
                }}
                textvalue={"true"}
              >
                {accText ? accText : item.Text}
              </Grid>
              {/* контейнер для редактора */}
              <Grid
                item
                style={{
                  display: "none",
                  height: "100%",
                  width: item.EditStyle & 4 ? "calc(100% - 20px)" : "100%",
                }}
                editor={"true"}
              ></Grid>
            </Grid>
          </td>
        </tr>
      );
    });

    return returnArr;
  }

  // функция для получения высоты параметров
  function getHeight() {
    const title = props.NoTitle ? 0 : 30;
    const correction = title;
    return `calc(100% - ${correction}px)`;
  }

  return (
    // при клике вне контейнера параметров редактор скроется
    <ClickAwayListener
      onClickAway={(ev) => {
        mouseDown.current = false;
        if (
          ev.target.closest("[checklist]") ||
          ev.target.closest("[calendar]") ||
          (ev.target.closest("SMART-WINDOW") &&
            ev.target.closest("SMART-WINDOW") !==
              paramsBox.current.closest("SMART-WINDOW")) ||
          ev.target.closest("LI")
        ) {
          return;
        }
        if (editor.current) {
          editor.current.closest("TR").style.backgroundColor = "";
          deleteEditor(editor.current);
        }
      }}
    >
      <Box
        ref={paramsBox}
        onMouseDown={(ev) => {
          mouseDown.current = true;
        }}
        onMouseUp={() => {
          mouseDown.current = false;
        }}
        style={{ height: "100%" }}
      >
        {/* <Box style={{ height: "max-content" }}> */}
        <div style={{ display: props.NoTitle ? "none" : "", height: "30px" }}>
          <span>
            {paramsData ? paramsData.Title : props.Title ? props.Title : ""}
          </span>
        </div>
        {/* контейнер с таблицей параметров */}
        <Box
          style={{
            height: height,
            overflowY: "auto",
            display: "flex",
          }}
        >
          {paramsJsx ? (
            <table
              onMouseMove={onMouseMove}
              onMouseUp={(ev) => onMouseUp(ev, setWidth)}
              style={{
                height: "fit-content",
                borderSpacing: "0px",
                tableLayout: "fixed",
                width: "100%",
                border: "1px solid #e0e0e0",
              }}
            >
              <colgroup>
                <col style={{ width: `${width}%` }} />
                <col style={{ width: `${buttonBlockWidth}px` }} />
                <col
                  style={{
                    width: "2px",
                  }}
                />
                <col
                  style={{
                    minWidth: "120px",
                  }}
                />
              </colgroup>
              <tbody>{paramsJsx}</tbody>
            </table>
          ) : (
            <LoadingMask />
          )}
        </Box>
      </Box>
    </ClickAwayListener>
  );
}
