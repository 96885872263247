import { store } from "../../../store";

let setModals = () => { };
let timer = null;

export function initModalManager(setModalsFunction) {
  setModals = setModalsFunction;
};

/**
 * Открывает модальное окно.
 * @param {React.ReactElement|HTMLElement} [content] - Содержимое, которое будет отображаться в модальном окне.
 * @param {Object} [props] - Настройки модального окна.
 * @param {React.CSSProperties} [props.style] - CSS стили модального окна. В них можно задать ограничения максимального/минимального размера окна.
 * @param {boolean} [props.blockMaximize] - Скрытие кнопки "Во весь экран" и блокировка события двойного клика на шапке модального окна.
 * @param {Function} [props.onClose] - Функция, которая будет вызвана при закрытии модального окна через кнопку "Отмена"(если она не скрыта) или через кнопку в шапке документа.
 * @param {Function} [props.onSelect] - Функция, которая будет вызвана при нажатии стандартной кнопки "Выбрать" если она не скрыта.
 * @param {string} [props.Title] - Заголовок модального окна.
 * @param {boolean} [props.hiddenButton] - Скрытие стандартных кнопок.
 * @param {string} [props.Module]
 * @param {number|string} [props.ObjType]
 * @param {number|string} [props.ObjRef]
 * @param {} [props.MultiSel]
 * @param {number} [props.zIndex] - Число от которого будет считаться zIndex у элемента заднего фона модального окна.
 * @param {boolean} [props.blockResize] - Блокировка изменений размеров модального окна.
 * @param {Object} [props.Buttons]
 * @param {boolean} [props.blockSaveLocation] - Блокировка сохранения позиционирования и размеров модального окна.
 * @param {boolean} [props.setFocus] - Установка фокуса на модальное окно.
 * @returns {function(): void} - Функция закрытия модального окна.
 */
export function openModal(content, props) {
  const newModal = {
    content: content,
    props: props,
  };
  setModals((prevModals) => [...prevModals, newModal]);
  return closeModal;
};

export function closeModal() {
  const closingFunction = (prevModals) => {
    if (prevModals.length === 1) {
      return [];
    } else return prevModals.slice(0, prevModals.length - 1);
  }

  if (store.getState().theme.animations) {
    if (!timer) {
      setModals((prevModals) => {
        let newModals = [...prevModals];
        let lastModal = newModals[newModals.length - 1];
        lastModal.props ? lastModal.props.closing = true : lastModal.props = { closing: true };
        return newModals;
      })
      timer = setTimeout(() => {
        setModals(closingFunction);
        timer = null;
      }, 226);
    }
  } else setModals(closingFunction);
}